import { ATHLETES_AGE_OBJECT } from '@/store/modules/signUp/constants';
import getDefaultState from '@/store/modules/signUp/helpers/getDefaultState';

export default {
  UPDATE_ENABLE_ORG_SEARCH_BTN(context, value) {
    context.enableOrgSearchBtn = value;
  },
  UPDATE_SIGNATURE(context, payload) {
    context.signature = payload;
  },
  UPDATE_COVERAGE_SUMMARY_CHECKBOXES(context, payload) {
    context.manualReportCheckboxes.rules = payload.isRulesRead;
    context.manualReportCheckboxes.privacy_policy = payload.isPrivacyPolicyAgreed;
  },
  UPDATE_MANUAL_REPORTS_CHECKBOXES(context, payload) {
    Object.assign(context.manualReportCheckboxes, payload);
  },
  UPDATE_SIGNUP_STEPS_STATE(context, payload) {
    context.signUpSteps = { ...payload };
  },
  INCREMENT_STEP(context) {
    context.step += 1;
  },
  INCREMENT_SUB_STEP(context) {
    context.subStep += 1;
  },
  RESET_SUB_STEP(context) {
    context.subStep = 1;
  },
  DECREMENT_STEP(context) {
    const ctx = context;
    ctx.step -= 1;
  },
  DECREMENT_SUB_STEP(context) {
    const ctx = context;
    ctx.subStep -= 1;
  },
  FORCE_STEP(context, step) {
    context.step = step;
  },
  FORCE_SUB_STEP(context, subStep) {
    context.subStep = subStep;
  },
  SET_SELECTED_ORG(context, org) {
    context.selectedOrg = org;
  },
  SET_SELECTED_ORG_TYPE(context, type) {
    context.selectedOrgType = type;
  },
  SET_USER_CREATED(context, value) {
    context.userCreated = value;
  },
  FORCE_UPDATE_CREATE_NEW_ORG(context, data) {
    const { address, mailingAddress, ...fields } = data;
    context.createNewOrgForm = Object.assign(context.createNewOrgForm, fields);
    context.createNewOrgForm.address = Object.assign(context.createNewOrgForm.address, address);
    context.createNewOrgForm.mailingAddress = Object.assign(context.createNewOrgForm.mailingAddress, mailingAddress || address);
  },
  RESET_SIGNUP_DATA(context, data) {
    let preserveDataObj = {
      userCreated: context.userCreated,
    };

    if (data && data.preserveOrgCreation) {
      preserveDataObj = {

        ...preserveDataObj,
        createNewOrgShown: context.createNewOrgShown,
        createNewOrgForm: {
          ...context.createNewOrgForm,
        },
      };
    }
    Object.assign(context, getDefaultState(), preserveDataObj);
  },
  SET_LAST_VISITED_STEP(context, step) {
    context.lastVisitedStep = step;
  },
  SET_LAST_VISITED_SUB_STEP(context, subStep) {
    context.lastVisitedSubStep = subStep;
  },
  UPDATE_CREATE_NEW_ORG_ADDRESS_FIELD(context, data) {
    context.createNewOrgForm.address[data.field] = data.value;
  },
  UPDATE_CREATE_NEW_ORG_MAILING_ADDRESS_FIELD(context, data) {
    context.createNewOrgForm.mailingAddress[data.field] = data.value;
  },
  UPDATE_CREATE_NEW_ORG_NAME(context, value) {
    context.createNewOrgForm.name = value;
  },
  UPDATE_CREATE_NEW_ORG_PHONE(context, value) {
    context.createNewOrgForm.phone = value;
  },
  UPDATE_CREATE_NEW_ORG_SITE(context, value) {
    context.createNewOrgForm.site = value;
  },
  UPDATE_CREATE_NEW_ORG_ICON(context, file) {
    context.createNewOrgForm.icon = file;
  },
  UPDATE_ORG_PARAM(context, { payload, field }) {
    Object.assign(context.params, {
      [field]: payload,
    });
  },
  UPDATE_CREATE_NEW_ORG_NO_SEPARATE_MAILING_ADDRESS(context, value) {
    context.createNewOrgForm.noSeparateMailingAddress = value;
  },
  UPDATE_CREATE_NEW_ORG_VALID(context, value) {
    context.createNewOrgForm.valid = value;
  },
  UPDATE_USER_INFO_FIELD(context, data) {
    context.userInfo[data.field] = data.value;
  },
  SET_AGE_GROUPS_BY_SPORTS(context, ageGroupsObj) {
    context.athletesAgeBySports = ageGroupsObj;
  },
  SET_AGE_GROUPS_BY_SPORTS_ARRAY(context, selSports) {
    context.athletesAgeBySports = selSports.reduce((acc, obj) => {
      if (obj.sport) {
        const { sport, ...ages } = obj;
        const sportId = sport instanceof Object && sport.id ? sport.id : sport;
        const baseObject = { sport, ...ATHLETES_AGE_OBJECT, ...context.athletesAgeBySports[sportId] };
        acc[sportId] = { ...baseObject, ...ages };
      }
      return acc;
    }, {});
  },
  UPDATE_SEARCH_ORG_QUERY(context, query) {
    context.searchOrgQuery = query;
  },
  SET_SESSION(context, session) {
    context.session = { ...session };
  },
  UPDATE_ORG_PERSONNEL_FIELD(context, data) {
    context.personnel[data.field] = data.value;
  },
  ADD_ATHLETES_AGE_SPORT(context, sportId) {
    if (sportId) {
      context.athletesAgeBySports[sportId] = { ...ATHLETES_AGE_OBJECT };
    }
  },
  REMOVE_ATHLETES_AGE_SPORT(context, sportId) {
    if (sportId && Object.keys(context.athletesAgeBySports).includes(sportId)) {
      const objectClone = { ...context.athletesAgeBySports };
      delete objectClone[sportId];
      context.athletesAgeBySports = { ...objectClone };
    }
  },
  UPDATE_ATHLETES_AGE_FIELD(context, data) {
    if (context.athletesAgeBySports[data.sport]) {
      context.athletesAgeBySports[data.sport][data.field] = data.value;
    }
  },
  UPDATE_EXTERNAL_COVERAGES_FIELDS(context, data) {
    if (context.externalCoverages[data.covType]) {
      context.externalCoverages[data.covType][data.field] = data.value;
    }
  },
  SET_ATHLETES_AGE_BY_SPORT_IDS(context, sportIds) {
    if (sportIds instanceof Array) {
      context.athletesAgeBySports = sportIds.reduce((acc, sportId) => {
        if (sportId && typeof sportId === 'string') {
          acc[sportId] = { ...ATHLETES_AGE_OBJECT };
        }
        return acc;
      }, {});
    }
  },
  SET_LOADING_SESSION(context, value) {
    context.loadingSession = !!value;
  },
  UPDATE_VISIBILITY_MAP(context, mapFragment) {
    context.visibilityMap = { ...context.visibilityMap, ...mapFragment };
  },
  SAVE_SIGNUP_BROKER_ID(context, id) {
    context.brokerId = id;
  },
};
