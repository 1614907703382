import {
  AIG_MIN_ATHLETES_PER_STATE_MAP,
  API_DATE_FORMAT,
  COVERAGE_TYPE_ALIASES,
  DEFAULT_PAYMENT_CYCLE_VALUE,
} from '@/utils/constants';
import { CHAINABLE_SUB_STEPS, PROPOSAL, STEP_NAMES_MAP } from '@/store/modules/orgApplication/constants';
import {
  checkStateHasCoverages,
  filterProviderSubStepsForAllowedCoverages,
  objectHasAllValues,
  getTotalNumberOfAthletesFromAthletesAge,
} from '@/utils/helpers';
import {
  checkAnySelectedSportsAreConcussionCausing,
  checkAnySelectedSportsRequireMinTwoLifeguards,
  checkAnySelectedSportsShouldBeRestrictedByAge,
  checkOrgTypeHasEventDates,
  checkQuestionChainValid,
  filterOutUnnecessarySubSteps,
  getCoveragesMappedWithParams,
  getOrgApplicationStepsValidityMap,
  getQuestionChain,
  getCurrentQuestionChainSectionProp,
  getSubCoveragesWithOverriddenLimits,
} from '@/store/modules/orgApplication/helpers';

export default {
  coverages: (state) => state.coverages,
  orgStateHasCoverages: (state, getters, rootState, rootGetters) => !!(getters.organizationToComplete
    && getters.organizationToComplete.address
    && getters.organizationToComplete.address.state
    && rootGetters['glossary/sports'].length
    && checkStateHasCoverages(getters.organizationToComplete.address.state, rootGetters['glossary/states'])),
  manualReportCheckboxes: (state) => state.manualReportCheckboxes,
  subStep: (context) => context.subStep,
  currentStep: (context, getters) =>
    // eslint-disable-next-line
    Object.keys(getters.orgApplicationSteps)[~-getters.currentStepNum],
  currentStepNum: (context) => context.step,
  currentSubStep: (context, getters) => {
    let currStep = Object.keys(getters.orgApplicationSteps)[~-getters.currentStepNum];
    let currSubStep = getters.orgApplicationSteps[currStep];
    return currSubStep[getters.subStep - 1];
  },
  // chained question getters start
  rawChainedQuestions: (state) => state.chainedQuestions,
  chainedQuestionsSteps: (state, getters) => Object.keys(getters.rawChainedQuestions),
  chainableSubStepsQuestionChains: (state, getters, rootState, rootGetters) => getters.chainedQuestionsSteps.reduce((acc, subStep) => {
    if (getters.rawChainedQuestions[subStep]) {
      acc[subStep] = getQuestionChain(getters.rawChainedQuestions, subStep, rootGetters);
    }
    return acc;
  }, {}),
  chainableSubStepsQuestionChainsValidityMap: (state, getters) => Object.entries(getters.chainableSubStepsQuestionChains).reduce((acc, chainEntry) => {
    acc[chainEntry[0]] = checkQuestionChainValid(chainEntry[1]);
    return acc;
  }, {}),
  currentSubStepQuestionChain: (state, getters) => getters.chainableSubStepsQuestionChains[getters.currentSubStep] || [],
  currentSubStepQuestionChainTitleLocalizationKey: (state, getters) => getters.chainableSubStepsQuestionChains[getters.currentSubStep]
    ? getCurrentQuestionChainSectionProp(getters.rawChainedQuestions, getters.currentSubStep, 'title')
    : '',
  currentSubStepQuestionChainValid: (state, getters) => getters.chainableSubStepsQuestionChainsValidityMap.hasOwnProperty(getters.currentSubStep)
    ? getters.chainableSubStepsQuestionChainsValidityMap[getters.currentSubStep]
    : false,
  currentSubStepQuestionChainVisibleIndex: (state, getters) => getters.chainableSubStepsQuestionChains[getters.currentSubStep]
    ? getCurrentQuestionChainSectionProp(getters.rawChainedQuestions, getters.currentSubStep, 'current')
    : 0,
  // chained question getters end
  currentSubStepNumber: (state, getters) => {
    let currStep = Object.keys(getters.orgApplicationSteps)[~-getters.currentStepNum];
    let currSubStepIndex = getters.orgApplicationSteps[currStep].indexOf(getters.currentSubStep);
    return currSubStepIndex !== -1 ? currSubStepIndex + 1 : 1;
  },
  orgApplicationStepsValidityMap: getOrgApplicationStepsValidityMap,
  lastStepSubSteps: (state, getters) => getters.orgApplicationSteps[getters.orgApplicationStepKeys[getters.currentStepNum - 2]] || [],
  currentStepSubSteps: (state, getters) => getters.orgApplicationSteps[getters.currentStep] || [],
  currentStepSubStepsValidityMap: (state, getters) => getters.orgApplicationStepsValidityMap[getters.currentStep] || {},
  currentStepLastAllowedSubStep: (state, getters) => {
    if (state.lastVisitedStep === getters.currentStepNum && getters.currentStepLastCompleteSubStep > state.lastVisitedSubStep) {
      return state.lastVisitedSubStep;
    }
    return getters.currentStepLastCompleteSubStep;
  },
  currentStepLastCompleteSubStep: (state, getters) => {
    const comparisonObj = getters.currentStepSubSteps.reduce((acc, currVal, i, arr) => {
      if (i > 0) {
        if (acc.allPrevStepsValid && getters.currentStepSubStepsValidityMap[currVal] && getters.currentStepSubStepsValidityMap[arr[i - 1]]) {
          acc.lastValidStep = i + 1;
        } else {
          acc.allPrevStepsValid = false;
        }
      }
      return acc;
    }, {
      lastValidStep: 1,
      allPrevStepsValid: true,
    });
    return comparisonObj.lastValidStep;
  },
  lastAllowedStep: (state, getters) => getters.lastCompleteStep > state.lastVisitedStep
    ? state.lastVisitedStep
    : getters.lastCompleteStep,
  lastCompleteStep: (state, getters) => {
    const comparisonObj = Object.entries(getters.orgApplicationStepsValidityMap).reduce((acc, step, i, arr) => {
      const allSubStepsValid = (subStep) => subStep && subStep instanceof Object && !(Object.values(subStep).some((sub) => sub == false));
      if (i > 0) {
        if (acc.allPrevStepsValid && allSubStepsValid(step[1]) && allSubStepsValid(arr[i - 1][1])) {
          acc.lastValidStep = i + 1;
        } else {
          acc.allPrevStepsValid = false;
        }
      }
      return acc;
    }, {
      lastValidStep: 1,
      allPrevStepsValid: true,
    });
    return comparisonObj.lastValidStep;
  },
  claimsData: (state) => state.claimsData,
  athletesAgeBySports: (context, getters, rootState, rootGetters) => context.athletesAgeBySports,
  athletesAgeBySportsValid: (context) => Object.values(context.athletesAgeBySports).reduce((acc, obj) => {
    if (!objectHasAllValues(obj)) {
      acc = false;
    }
    return acc;
  }, true),
  otherBackgroundProvider: (context) => context.otherBackgroundProvider,
  otherRegProvider: (context) => context.otherProvider,
  selectedRegProvider: (context) => context.registrationProvider,
  selectedSports: (context) => Object.keys(context.athletesAgeBySports),
  sexualAbuseLiabilityQuestionsNeeded: (state, getters) => getters.chosenSubCoverages.some((subCov) => subCov.type === COVERAGE_TYPE_ALIASES.sexualAbuseLiability),
  swimmingQuestionsNeeded: (state, getters, rootState, rootGetters) => checkAnySelectedSportsRequireMinTwoLifeguards(getters.athletesAgeBySports, rootGetters['glossary/sportsMap']),
  tackleFootballQuestionsNeeded: (state, getters, rootState, rootGetters) => checkAnySelectedSportsShouldBeRestrictedByAge(getters.athletesAgeBySports, rootGetters['glossary/sportsMap']),
  orgApplicationStepKeys: (state, getters) => Object.keys(getters.orgApplicationSteps),
  orgApplicationSteps: (state, getters, rootState, rootGetters) => filterOutUnnecessarySubSteps(state.orgApplicationSteps, state, rootGetters),
  orgApplicationStepsArray: (state, getters) => Object.entries(getters.orgApplicationSteps).map((st, i) => ({
    name: st[0],
    label: STEP_NAMES_MAP[st[0]] || st[0],
    children: st[1],
  })),
  totalStepsNum: (state, getters) => Object.keys(getters.orgApplicationSteps).length,
  bgCheckStep: (context) => context.bgCheckStep,
  bgChecks: (context) => context.bgChecks,
  paymentOption: (context) => context.paymentOption,
  bgCheckProvider: (context) => context.bgCheckProvider,
  orgPersonnelForm: (context) => context.personnel,
  protocolsQuestionsNeeded: (context, getters) => getters.chosenCoverage.type !== COVERAGE_TYPE_ALIASES.generalLiabilityAndParticipantLiability,
  injuryProtocol: (context) => context.protocols.injuryProtocol,
  injuryProtocolTemplate: (context) => context.protocols.injuryProtocolTemplate,
  concussionProtocol: (context) => context.protocols.concussionProtocol,
  concussionProtocolTemplate: (context) => context.protocols.concussionProtocolTemplate,
  concussionProtocolQuestionsNeeded: (context, getters, rootState, rootGetters) => checkAnySelectedSportsAreConcussionCausing(getters.athletesAgeBySports, rootGetters['glossary/sportsMap']),
  waiverProtocol: (context) => context.protocols.waiverProtocol,
  waiverProtocolTemplate: (context) => context.protocols.waiverProtocolTemplate,
  cardInfoForm: (context) => context.cardInfo,
  eCheckInfoForm: (context) => context.eCheckInfo,
  organizationToComplete: (context) => context.organizationToComplete,
  organizationToCompleteType: (context) => context.organizationToComplete.type,
  organizationToCompleteTypeIsSeasonal: (state, getters) => checkOrgTypeHasEventDates(getters.organizationToCompleteType),
  hasNoQuoteOffers: (state, getters) => (!!getters.submission
    && getters.submission.offers instanceof Array
    && getters.submission.offers.length === 0),
  hasPendingQuoteOffers: (state, getters) => !!getters.submission
    && getters.submission.offers instanceof Array
    && getters.submission.offers.some((of) => of.quotes instanceof Array && of.quotes.length === 0),
  coverageParamsMap: (state) => state.coverageParamsMap,
  chosenCoverageId: (context) => context.chosenCoverageId,
  havePolicy: (context) => context.havePolicy,
  coveragesWithParams: (state, getters, rootState, rootGetters) => getCoveragesMappedWithParams(state, getters, rootGetters),
  coveragesWithParamsMapByType: (state, getters) => getters.coveragesWithParams.reduce((acc, cvg) => {
    if (!acc[cvg.type]) {
      acc[cvg.type] = cvg;
    }
    return acc;
  }, {}),
  chosenCoverage: (state, getters) => {
    const coverage = getters.coveragesWithParams.find((cvg) => cvg.id === getters.chosenCoverageId);
    if (coverage) {
      return {
        ...coverage,
        subcoverages: coverage.subcoverages
          ? getSubCoveragesWithOverriddenLimits(coverage.subcoverages)
            .sort(({ name: a }, { name: b }) => a.localeCompare(b))
          : [],
      };
    }
    return {};
  },
  chosenCoverageType: (state, getters) => getters.chosenCoverage.type,
  chosenSubCoverages: (state, getters) => getters.chosenCoverage.subcoverages instanceof Array
    ? getters.chosenCoverage.subcoverages.filter((subCov) => subCov.chosen)
    : [],
  chosenSubCoverageIds: (state, getters) => getters.chosenSubCoverages.map((sub) => sub.id),
  protocolsFormValid: (state) => {
    const templatesToCheck = [];
    if (state.protocols.injuryProtocol) {
      templatesToCheck.push(state.protocols.injuryProtocolTemplate);
    }
    if (state.protocols.concussionProtocol) {
      templatesToCheck.push(state.protocols.concussionProtocolTemplate);
    }
    if (state.protocols.waiverProtocol) {
      templatesToCheck.push(state.protocols.waiverProtocolTemplate);
    }
    return templatesToCheck.every((tmpl) => ((tmpl instanceof File) || (typeof tmpl === 'string' && tmpl.length)));
  },
  externalCoveragesForm: (context) => context.externalCoverages,
  totalNumberOfAthletes: (state) => getTotalNumberOfAthletesFromAthletesAge(state.athletesAgeBySports),
  minTotalNumberOfAthletes: (state) => {
    if (state.organizationToComplete
      && state.organizationToComplete.address
      && state.organizationToComplete.address.state
      && typeof state.organizationToComplete.address.state === 'string') {
      const lowerCaseState = state.organizationToComplete.address.state.toLowerCase();
      const stateMapMatch = AIG_MIN_ATHLETES_PER_STATE_MAP[lowerCaseState];
      if (stateMapMatch) {
        return stateMapMatch;
      }
    }
    return 0;
  },
  submission: (state, getters, rootState, rootGetters) => rootGetters['submission/firstSubmission'] || {},
};
