import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueFacebookPixel from 'vue-analytics-facebook-pixel';
import router from '@/router';

if (process.env.GA_KEY) {
  // Vue.use(VueAnalytics, {
  //   id: process.env.GA_KEY,
  //   router,
  //   // debug: {
  //   //   sendHitTask: process.env.NODE_ENV === 'production'
  //   // }
  // });
}

// eslint-disable-next-line import/no-mutable-exports
let fbqInstance;
if (process.env.FBQ_KEY) {
  Vue.use(VueFacebookPixel);
  Vue.analytics.fbq.init(process.env.FBQ_KEY);
  fbqInstance = Vue.analytics && Vue.analytics.fbq;
}

export {
  fbqInstance,
};
