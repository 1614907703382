export const COUNTRY_PHONE_CODE = `+${process.env.COUNTRY_PHONE_CODE}` || '+1';
export const COVERAGE_SUMMARY_CHECKBOXES = {
  rules: false,
  privacy_policy: false,
  send_risk: false,
  read_coverage_details: false,
};
export const PHONE_MASK = process.env.PHONE_MASK || '+1 (###) ### - ####';
export const DEFAULT_ATHLETES_NUMBER = 0;
export const DEFAULT_COVERAGE_DURATION_YEARS = 1;
export const DEFAULT_PERSONNEL_NUMBER = 0;
export const DEFAULT_HEADER_COLOR = 'white';
export const DEFAULT_PAYMENT_CYCLE_VALUE = process.env.DEFAULT_POLICY_PAYMENT_CYCLE || 'annually';
export const DEFAULT_BG_CHECK_VALUE = 'none';
export const DEFAULT_EXPORT_FORMAT = process.env.DEFAULT_EXPORT_FORMAT || 'xls';
export const TOURNAMENT_ORG_TYPE_ID = process.env.TOURNAMENT_ORG_TYPE_ID || '5ad9d520afc44f00644b34e1';
export const LEAGUE_ORG_TYPE_ID = process.env.LEAGUE_ORG_TYPE_ID || '5ad9d525afc44f00644b34e3';
export const CAMP_ORG_TYPE_ID = process.env.CAMP_ORG_TYPE_ID || '5c8ff1b315ebce005e34465e';
export const HUMAN_DATE_FORMAT = 'MMMM DD, YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const ALLOWED_ROLE = process.env.ALLOWED_ROLE || 'customer';
export const DEFAULT_PAYMENT_METHOD = 'Card';
export const ACH_AVAILABLE_COUNTRIES = ['US'];
export const ACH_SUPPORT = process.env.ACH_SUPPORT === 'true';
export const REFUSAL_TO_REQUEST_ACCESS_ERROR_MESSAGE = 'refusalToRequestAccess';
export const REFUSAL_TO_UPDATE_ORG_ERROR_MESSAGE = 'refusalToUpdateOrg';
export const GRECAPTCHA_FRAUD_ERROR_MESSAGE = 'gReCaptchaFraudError';
export const AIG_MIN_ATHLETES_PER_STATE_MAP = {
  colorado: 10,
};
export const GAIG_MIN_ATHLETES_PER_STATE_MAP = {
  FL: 51,
};
export const MULTI_LANG_SUPPORT = process.env.MULTI_LANG_SUPPORT === 'true';
export const PRIVACY_POLICY_LINK = 'https://playershealth.com/privacy';
export const { RECAPTCHA_SITEKEY } = process.env;
export const STRIPE_KEY = process.env.STRIPE_KEY || 'pk_test_7KPAE1Vzcrz3lMzAD1MGH90i';
export const SOCIAL_FACEBOOK = 'https://www.facebook.com/playershealth';
export const SOCIAL_INSTAGRAM = 'https://www.instagram.com/playershealth';
export const SOCIAL_TWITTER = 'https://twitter.com/playershealth';
export const SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/team-interval-powered-by-ao1-solutions-inc-/';
export const SUPPORT_EMAIL = 'support@playershealth.com';
export const SALES_EMAIL = 'sales@playershealth.com';
export const TERMS_OF_SERVICE_LINK = 'https://playershealth.com/terms-conditions/';
export const TOAST_TIMEOUT_MS = 5000;
export const COVERAGE_TYPE_ALIASES = {
  participantAccident: 'participantAccident',
  generalLiabilityAndParticipantLiability: 'generalLiabilityAndParticipantLiability',
  sexualAbuseLiability: 'sexualAbuseLiability',
  hnoaLiability: 'hnoaLiability',
};
export const SUBCOVERAGE_TYPE_ALIASES = {
  [COVERAGE_TYPE_ALIASES.generalLiabilityAndParticipantLiability]: {
    accidentMedical: 'accidentMedical',
    hnoaLiability: 'hnoaLiability',
    sexualAbuseLiability: 'sexualAbuseLiability',
  },
};
export const ORGANIZATION_STATUS_ALIASES = {
  completed: 'Completed',
  incompleted: 'InCompleted',
  archived: 'Archived',
};
export const CHAINED_QUESTION_TYPES = {
  RADIO: 'radio',
  NONE: 'none',
};
export const DIALOG_TYPES = {
  INFO: 'info',
  CONFIRM: 'confirm',
};
export const TOAST_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
};

export const ADDRESS_RECORD_FIELDS = {
  CITY: 'city',
  COUNTRY: 'country',
  STATE: 'state',
  STREET: 'address',
  SECONDARY: 'address2',
  ZIP: 'zip',
};

export const ORG_PARAMS_RECORD_FIELDS = {
  EVENT_END_DATE: 'eventEndDate',
  EVENT_LOCATION: 'eventLocation',
  EVENT_START_DATE: 'eventStartDate',
  IS_OVERNIGHT: 'isOvernight',
  IS_PARTICIPANTS_TRANSPORTED: 'isParticipantsTransported',
  LEAGUE_NUMBER_OF_TEAMS: 'numberTeamsLeague',
};

export const CURRENCIES = {
  USD: 'USD',
};

export const CURRENCY_SYMBOLS = {
  [CURRENCIES.USD]: '$',
};

export const LOGO_TYPES = {
  WIDE: 'logoWide',
  APP_BAR: 'appBarLogo',
};

export const LOGO_NAMES = {
  [LOGO_TYPES.WIDE]: 'logo--wide.svg',
  [LOGO_TYPES.APP_BAR]: 'logo.svg',
};
