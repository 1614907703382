export default {
  numberTeamsLeague: `{
               params(filters:"numberTeamsLeague"){
                 name,
                 values{
                   id,
                   value,
                   values,
                 }
               }
             }`,
  sports: `{
               params(filters:"sport"){
                 field,
                 name,
                 values{
                   id,
                   name,
                   value,
                   values,
                 }
               }
             }`,
  regProviders: `{
                      params(filters:["regProvider"]){
                        field,
                        name
                        values {
                          id,
                          name,
                          value,
                          icon
                        }
                      }
                   }`,
  bgCheckProviders: `{
                      params(filters:["backgroundProvider"]){
                        field,
                        name,
                        values {
                          id,
                          name,
                          value,
                          icon
                        }
                      }
                   }`,
  orgTypes: `{
                params(filters:["orgType"]){
                    field,
                    name,
                    values {
                      id,
                      name,
                      value,
                      values,
                      icon
                    }
                   }
                }`,
  states: `{
              params(filters:["state"]){
                  field,
                  name,
                  values {
                    id,
                    name,
                    value,
                    values
                  }
                 }
            }`,
  countries: `{
              params(filters:["country"]){
                  field,
                  name,
                  values {
                    id,
                    name,
                    value,
                    values,
                    disabled
                  }
                 }
            }`,
  general: `query ($filters: [String]!){
               params(filters:$filters){
                 field,
                 values {
                   id,
                   name,
                   value,
                 }
               }
             }`,
};
