import { DEFAULT_PERSONNEL_NUMBER } from '@/utils/constants';
import {
  PERSONNEL_OBJECT,
  SIGN_UP_STEPS,
} from '@/store/modules/signUp/constants';
import { jsonClone } from '@/utils/helpers';

const getSignUpSteps = () => jsonClone(SIGN_UP_STEPS);

const orgSearch = {
  selectedOrg: null,
  searchOrgQuery: '',
};

const step = 1;
const subStep = 1;
const lastVisitedStep = 1;
const lastVisitedSubStep = 1;

const enableOrgSearchBtn = false;

const orgType = {
  selectedOrgType: '',
};

const orgCreation = {
  createNewOrgShown: false,
  createNewOrgForm: {
    name: '',
    site: '',
    phone: '',
    email: '',
    icon: null,
    address: {
      address: '',
      city: null,
      state: null,
      country: null,
      zip: '',
    },
    mailingAddress: {
      address: '',
      city: null,
      state: null,
      country: null,
      zip: '',
    },
    noSeparateMailingAddress: true,
    valid: false,
  },
};

const params = {
  isOvernight: null,
  eventLocation: '',
  eventStartDate: null,
  eventEndDate: null,
  numberTeamsLeague: null,
};

const userInfo = {
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  phone: '',
  soaRole: '',
};

export default () => jsonClone({
  athletesAgeBySports: {},
  brokerId: '',
  enableOrgSearchBtn,
  lastVisitedStep,
  lastVisitedSubStep,
  loadingSession: false,
  ...orgSearch,
  ...orgCreation,
  ...orgType,
  personnel: { ...PERSONNEL_OBJECT },
  session: {},
  params,
  signUpSteps: getSignUpSteps(),
  step,
  subStep,
  userCreated: false,
  userInfo: { ...userInfo },
  visibilityMap: {
    createNewOrg: true,
  },
});
