import moment from '@/plugins/moment';

export default (date, yearIncrement = 1) => {
  if (typeof yearIncrement === 'number' && !Number.isNaN(yearIncrement)) {
    let momentDate;
    if (date instanceof Object && date._isAMomentObject && date.isValid()) {
      momentDate = date;
    } else if (typeof date === 'string' || (typeof date === 'number' && !Number.isNaN(date)) || date instanceof Date) {
      const attemptedMoment = moment(date);
      if (attemptedMoment.isValid()) {
        momentDate = attemptedMoment;
      }
    } else {
      throw new Error('Invalid date argument');
    }
    const day = momentDate.date();
    const month = momentDate.month();
    const year = momentDate.year();
    const outputMoment = moment();
    outputMoment.date(day);
    outputMoment.month(month);
    outputMoment.year(year + yearIncrement);
    return outputMoment;
  }
  throw new Error('Invalid yearIncremennt argument');
};
