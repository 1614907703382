import getDefaultState from '@/store/modules/organization/helpers/getDefaultState';

export default {
  ADD_APPROVED_SOA_TO_ORG(context, data) {
    const orgToAddSOA = context.organizations.find((org) => org.id === data.organization.id);
    const outdatedRequest = orgToAddSOA.requests.find((req) => req.id === data.id);
    orgToAddSOA.users.push(data.user);
    orgToAddSOA.requests.splice(orgToAddSOA.requests.indexOf(outdatedRequest), 1);
  },
  ADD_INVITE_TO_ORG(context, data) {
    const orgToAddInvite = context.organizations.find((org) => org.id === data.orgId);
    const duplicateInvite = orgToAddInvite.invitations.find((inv) => inv.id === data.invite.id);
    if (duplicateInvite) {
      orgToAddInvite.invitations.splice(orgToAddInvite.invitations.indexOf(duplicateInvite), 1, data.invite);
    } else {
      orgToAddInvite.invitations.push(data.invite);
    }
  },
  PUSH_TO_ORGS(context, newOrg) {
    context.organizations.push(newOrg);
  },
  PUSH_TO_PENDING_ORGS(context, newOrg) {
    context.pendingOrganizations.push(newOrg);
  },
  REMOVE_EXCLUDED_SOA_FROM_ORG(context, data) {
    const orgToRemoveSOA = context.organizations.find((org) => org.id === data.orgId);
    const adminToRemove = orgToRemoveSOA.users.find((adm) => adm.id === data.userId);
    orgToRemoveSOA.users.splice(orgToRemoveSOA.users.indexOf(adminToRemove), 1);
  },
  REMOVE_REJECTED_SOA_FROM_ORG(context, data) {
    const orgToRemoveSOA = context.organizations.find((org) => org.id === data.organization.id);
    const requestToRemove = orgToRemoveSOA.requests.find((req) => req.id === data.id);
    orgToRemoveSOA.requests.splice(orgToRemoveSOA.requests.indexOf(requestToRemove), 1);
  },
  REPLACE_ORG(context, newOrg) {
    const index = context.organizations.indexOf(context.organizations.find((org) => org.id === newOrg.id));
    context.organizations.splice(index, 1, newOrg);
  },
  RESET_SEARCH_DATA(context) {
    const { search } = getDefaultState();
    context.search = { ...search };
  },
  UPDATE_ORGANIZATIONS(context, orgs) {
    context.organizations = orgs;
  },
  UPDATE_PENDING_ORGANIZATIONS(context, pendingOrgs) {
    context.pendingOrganizations = pendingOrgs;
  },
  UPDATE_SEARCH_QUERY(context, query) {
    context.search.query = query;
  },
  UPDATE_SEARCH_RESULTS(context, results) {
    context.search.results = results;
  },
  UPDATE_SEARCH_LOADING_STATE(context, loading) {
    context.search.loading = !!loading;
  },
};
