import { DEFAULT_EXPORT_FORMAT } from '@/utils/constants';
import ServiceProvider from '../provider';

import QUERIES from './queries';
import MUTATIONS from './mutations';

class FNOLProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  fnolsQuery(filters = {}) {
    return this.query(this.queries.fnols, filters);
  }

  getFnolQuery(fnolId) {
    return this.query(this.queries.fnol, { id: fnolId });
  }

  updateFnolsMutation(updateIds, input) {
    return this.mutate(this.mutations.updateMultipleFnols, { input, updateList: updateIds });
  }

  updateSingleFnolMutation(updateId, input) {
    return this.mutate(this.mutations.updateSingleFnol, { input, updateId });
  }

  createFnolMutation(input) {
    return this.mutate(this.mutations.createFnol, { input });
  }

  exportFnolsMutation(fnolIds, format = DEFAULT_EXPORT_FORMAT) {
    return this.mutate(this.mutations.exportFnols, { format, filters: { isFNOL: true }, records: fnolIds });
  }

  exportFilteredFnolsMutation(filterObj, format = DEFAULT_EXPORT_FORMAT) {
    return this.mutate(this.mutations.exportFnols, { format, filters: { isFNOL: true, ...filterObj } });
  }
}

export default new FNOLProvider(QUERIES, MUTATIONS);
