<template>
  <ShoppingCartDropdown
    @checkout="handleCheckout"
    @input="handleDropdownInput"
    @itemGroupDelete="handleItemGroupDelete"
    :value="value"
    :coverages="coverages"
    :items="shoppingCartItems"
    :mobile="isMobile"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ShoppingCartDropdown from './index';

export default {
  name: 'OrgShoppingCartDropdown',
  components: {
    ShoppingCartDropdown,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters('insurance', ['coverages']),
    ...mapGetters('shoppingCart', ['shoppingCartItems']),
    ...mapGetters('layout', ['isMobile']),
  },
  methods: {
    ...mapActions('shoppingCart', ['removeItemsOfCoverageTypeFromCart']),
    ...mapActions('insurance', ['fetchCoverages']),
    handleCheckout() {
      this.$router.push({ name: 'soa.checkout' });
    },
    handleDropdownInput(shown) {
      this.$emit('input', shown);
    },
    async handleItemGroupDelete(item) {
      try {
        await this.removeItemsOfCoverageTypeFromCart({ coverageType: item.coverageType });
      } catch (error) {
        this.$dialog.info({
          title: this.$t('response.error.error'),
          description: this.$t('response.error.smthWrong'),
        });
      }
    },
  },
  mounted() {
    if (!this.coverages || !this.coverages.length) {
      this.fetchCoverages();
    }
  },
};
</script>
