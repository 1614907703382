import { VRadio } from 'vuetify/lib';

const BLOCK_CLASS = 'v-radio';
const getModifierClasses = (props) => ({
  [`${BLOCK_CLASS}--ph`]: props.custom,
});

export default ((component) => ({
  functional: true,
  name: 'VRadio',
  model: {
    prop: 'input-value',
    event: 'change',
  },
  props: {
    custom: {
      type: Boolean,
      default: true,
    },
  },
  render(h, context) {
    const newProps = {
      ...context.props,
    };
    return h(
      component,
      {
        ...context.data,
        class: [
          context.data.class,
          getModifierClasses(newProps),
        ],
        props: newProps,
      },
      context.children,
    );
  },
}))(VRadio);
