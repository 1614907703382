import Home from '@/pages/pub/Home';
import store from '@/store';

const route = {
  name: 'home',
  path: '/',
  redirect: store.getters['user/homeRoute'],
  meta: {
    requiresAuth: true,
  },
  component: Home,
};

export default route;
