<template>
  <ClaimAction @handleSubmit="handleSubmit" />
</template>

<script>
import ClaimAction from '@/components/claim/action/ClaimAction';
import { mapActions } from 'vuex';

export default {
  name: 'FnolAdd',
  block: 'claimActionWrapper',
  components: {
    ClaimAction,
  },
  methods: {
    ...mapActions('fnol', ['createFnol']),
    handleSubmit(payload) {
      this.createFnol(payload).then(() => {
        this.$dialog.info({
          title: 'Success',
          description: 'Fnol successfully created',
        });
        this.$router.push({ name: 'soa.fnols' });
      }).catch((error) => {
        if (error.graphQLErrors) {
          let errorMsg = '';
          error.graphQLErrors.forEach((err) => {
            errorMsg += `${err.message}\n`;
          });
          this.$dialog.info({
            title: this.$t('response.error.error'),
            description: errorMsg,
          });
        }
      });
    },
  },
};
</script>
