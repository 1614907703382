import { event } from 'vue-analytics';
import { SOA } from '@/data/analyticsEvents';
import AnalyticsProvider from '@/graphql/providers/analytics';
import { getAnalyticsDataBySubStep } from '@/utils/helpers';

const eventNames = Object.values(SOA);

const state = {
  eventHistory: [],
};

const mutations = {
  PUSH_TO_HISTORY(ctx, evt) {
    if (evt && evt instanceof Object) {
      ctx.eventHistory.push(evt);
    }
  },
  UPDATE_EVENT_IN_HISTORY(ctx, payload) {
    if (payload.id && payload.data) {
      const index = ctx.eventHistory.findIndex((evt) => evt.id === payload.id);
      if (index !== -1) {
        ctx.eventHistory.splice(index, 1, { ...ctx.eventHistory[index], ...payload.data });
      }
    }
  },
};

const actions = {
  registerEvent(ctx, payload) {
    return new Promise(async (resolve, reject) => {
      if (payload.name && eventNames.find((evt) => payload.name.includes(evt))) {
        const eventId = `${payload.name}_${(+((ctx.state.eventHistory.length + 1) + Date.now())).toString(36)}`;
        try {
          const eventObject = {
            id: eventId,
            type: payload.type || 'inApp',
            ...payload,
          };
          if (!payload.resolvable) {
            eventObject.date = Date.now();
            if (payload.type === 'googleAnalytics') {
              event({
                eventCategory: eventObject.name,
                eventAction: eventObject.action,
              });
            } else {
              await AnalyticsProvider.sendEventMutation(eventObject.name, JSON.stringify(eventObject));
            }
          } else {
            eventObject.start = Date.now();
          }
          ctx.commit('PUSH_TO_HISTORY', eventObject);
          resolve(eventObject);
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The event name is invalid'));
      }
    });
  },
  resolveEvent(ctx, payload) {
    return new Promise(async (resolve, reject) => {
      if (payload.id) {
        try {
          const eventIndex = ctx.state.eventHistory.findIndex((evt) => evt.id === payload.id);
          const eventObject = eventIndex >= 0 ? { ...ctx.state.eventHistory[eventIndex] } : null;

          if (eventObject) {
            if (payload.resolvable && payload.start) {
              eventObject.end = Date.now();
              eventObject.duration = eventObject.end - payload.start;
              if (payload.type === 'googleAnalytics') {
                event({
                  eventCategory: eventObject.name,
                  eventAction: eventObject.action,
                });
              } else {
                await AnalyticsProvider.sendEventMutation(eventObject.name, JSON.stringify(eventObject));
              }
              ctx.state.commit('UPDATE_EVENT_IN_HISTORY', eventObject);
              resolve(eventObject);
            } else {
              throw new Error('The event is not of resolvable type or doesn\'t have the start timestamp');
            }
          } else {
            throw new Error('The event wasn\'t found by id');
          }
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The event id is invalid'));
      }
    });
  },
  trackOrgCoverageSelection({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.organizationId && payload.coverages instanceof Array) {
        const eventObj = {
          name: SOA.COVERAGE_CHOICE,
          type: 'inApp',
          organizationId: payload.organizationId,
          coverages: payload.coverages,
        };
        if (payload.requesterEmail) {
          eventObj.requesterEmail = payload.requesterEmail;
        }
        if (payload.requesterName) {
          eventObj.requesterName = payload.requesterName;
        }
        if (payload.requesterPhone) {
          eventObj.requesterPhone = payload.requesterPhone;
        }
        Promise.all([
          dispatch('registerEvent', eventObj),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackOrgCoverageSelection action was incomplete or invalid'));
      }
    });
  },
  trackPreliminaryShoppingCartUpdate({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.organizationId && payload.quoteIds instanceof Array) {
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.SIGNUP_CART_UPDATE,
            type: 'inApp',
            organizationId: payload.organizationId,
            quotes: payload.quoteIds,
            requesterEmail: payload.requesterEmail,
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackPreliminaryShoppingCartUpdate action was incomplete or invalid'));
      }
    });
  },
  trackSignUpStepEnter({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.pageName && typeof payload.pageName === 'string') {
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.REGISTRATION_STEP_ENTER,
            action: payload.pageName,
            type: 'googleAnalytics',
          }),
          dispatch('registerEvent', {
            name: payload.pageName ? `${SOA.REGISTRATION_STEP_ENTER}.${payload.pageName}` : SOA.REGISTRATION_STEP_ENTER,
            action: payload.pageName,
            type: 'inApp',
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackRegistrationStepEnter action was incomplete or invalid'));
      }
    });
  },
  trackSubStep({ dispatch, rootGetters }, { subStep }) {
    const evt = getAnalyticsDataBySubStep({ subStep, rootGetters });
    if (evt) { window.dataLayer.push(evt); }
  },
  trackSignUpSubStepLeave({ dispatch, rootGetters }, { subStep }) {
    dispatch('trackSubStep', { subStep });
  },
  trackOrgAppSubStepLeave({ dispatch }, { subStep }) {
    dispatch('trackSubStep', { subStep });
  },
  trackSignUpStepLeave({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.pageName && typeof payload.pageName === 'string') {
        const eventObj = {};
        const {
          organizationId, requesterName, requesterPhone, requesterEmail,
        } = payload;
        if (organizationId) {
          eventObj.organizationId = organizationId;
        }
        if (requesterName) {
          eventObj.requesterName = requesterName;
        }
        if (requesterPhone) {
          eventObj.requesterPhone = requesterPhone;
        }
        if (requesterEmail) {
          eventObj.requesterEmail = requesterEmail;
        }
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.REGISTRATION_STEP_LEAVE,
            action: payload.pageName,
            type: 'googleAnalytics',
            ...eventObj,
          }),
          dispatch('registerEvent', {
            name: payload.pageName ? `${SOA.REGISTRATION_STEP_LEAVE}.${payload.pageName}` : SOA.REGISTRATION_STEP_LEAVE,
            action: payload.pageName,
            type: 'inApp',
            ...eventObj,
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackRegistrationStepEnter action was incomplete or invalid'));
      }
    });
  },
  trackOrgApplicationStepEnter({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.pageName && typeof payload.pageName === 'string') {
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.ORG_APPLICATION_STEP_ENTER,
            action: payload.pageName,
            type: 'googleAnalytics',
          }),
          dispatch('registerEvent', {
            name: payload.pageName ? `${SOA.ORG_APPLICATION_STEP_ENTER}.${payload.pageName}` : SOA.ORG_APPLICATION_STEP_ENTER,
            action: payload.pageName,
            organizationId: payload.organizationId,
            type: 'inApp',
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackOrgApplicationStepEnter action was incomplete or invalid'));
      }
    });
  },
  trackOrgApplicationStepLeave({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.pageName && typeof payload.pageName === 'string') {
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.ORG_APPLICATION_STEP_LEAVE,
            action: payload.pageName,
            type: 'googleAnalytics',
          }),
          dispatch('registerEvent', {
            name: payload.pageName ? `${SOA.ORG_APPLICATION_STEP_LEAVE}.${payload.pageName}` : SOA.ORG_APPLICATION_STEP_LEAVE,
            action: payload.pageName,
            organizationId: payload.organizationId,
            type: 'inApp',
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackOrgApplicationStepLeave action was incomplete or invalid'));
      }
    });
  },
  trackUserEmailConfirmation({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.pageName && typeof payload.pageName === 'string') {
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.EMAIL_CONFIRMED,
            action: payload.pageName,
            type: 'inApp',
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackUserEmailConfirmation action was incomplete or invalid'));
      }
    });
  },
  trackUserProfileCreation({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && payload.pageName && typeof payload.pageName === 'string') {
        Promise.all([
          dispatch('registerEvent', {
            name: SOA.PROFILE_STARTED,
            action: payload.pageName,
            type: 'inApp',
          }),
        ]).then(resolve).catch(reject);
      } else {
        reject(new Error('The data passed into the trackUserProfileCreation action was incomplete or invalid'));
      }
    });
  },
  registerFullStoryEvent(_, { name, properties }) {
    window.FS.event(name, properties || {});
  },
};

const getters = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
