import moment from '@/plugins/moment';
import { HUMAN_DATE_FORMAT } from '@/utils/constants';

export default (message, type) => {
  if (message instanceof Object && Object.keys(message).length) {
    switch (type) {
      case 'RequestSOA':
        return (router) => {
          router.push({
            name: 'soa.organizations',
            query: {
              toggle_target: message.organization.id,
              toggle_type: 'admin_list',
            },
          });
        };
      case 'QuoteExpirationSoon':
        return (router) => {
          router.push({
            name: 'soa.quotes',
            query: {
              toggle_target: encodeURIComponent(JSON.stringify({
                coverage: message.quotes[0].coverage,
                org: message.quotes[0].organization.id || message.quotes[0].organization._id,
              })),
              toggle_type: 'filters',
            },
          });
        };
      case 'QuoteExpirationJust':
        return (router) => {
          router.push({
            name: 'soa.organizations',
            query: {
              toggle_target: encodeURIComponent(JSON.stringify({
                orgName: message.quotes[0].organization.name,
              })),
              toggle_type: 'filters',
            },
          });
        };
      case 'RequestApproved':
        return (router) => {
          if (router.currentRoute.name == 'soa.organizations') {
            router.go();
          } else {
            router.push({
              name: 'soa.organizations',
            });
          }
        };
      case 'PolicyExpired':
      case 'PolicyProlonged':
      case 'PolicyCanceled':
      case 'PolicyPurchased':
      case 'PolicyPDF':
        return (router) => {
          router.push({
            name: 'soa.policies',
            query: {
              toggle_target: message.policy.id || message.policy._id,
              toggle_type: 'policy_modal',
            },
          });
        };
      case 'ProofPDF':
        return (router) => {
          router.push({
            name: 'soa.proofOfInsurance',
          });
        };
      case 'ProtocolFilesMissing':
        return (router) => {
          router.push({
            name: 'soa.orgApplication',
            params: { orgId: message.organization.id },
          });
        };
      case 'FnolCreated':
      case 'FnolUpdated':
        return (router) => {
          router.push({
            name: 'soa.fnols',
          });
        };
      case 'FnolEscalated':
        return (router) => {
          router.push({
            name: 'soa.claims',
          });
        };
      case 'Hello':
        return 'Hello';
      default:
        return '';
    }
  }
  return '';
};

// let a = ['message', {
//   id: '5df7958b40ed73005e958c79',
//   createdAt: '2019-12-16T14:32:43.975Z',
//   type: 'QuoteExpirationSoon',
//   quotes: [{
//     isManual: true,
//     notifications: [{
//       offset: 1440,
//       letter: 'quotes/quote_expiration_soon',
//       socket: 'QuoteExpirationSoon',
//       createdAt: '2019-12-16T14:32:43.795Z',
//     }],
//     isRejected: false,
//     isInCart: false,
//     currency: 'usd',
//     _id: '5df7948f40ed73005e958c71',
//     __v: 1,
//     annualCost: 400,
//     carrier: '5d9b9c38437ca76c81d721da',
//     coverage: '5d9b9d61437ca76c81d72210',
//     coverageName: 'General Liability and Participant Liability',
//     coverageType: 'generalLiabilityAndParticipantLiability',
//     createdAt: '2019-12-16T14:28:31.606Z',
//     expiredAt: '2019-12-16T23:59:59.000Z',
//     limit: 100000,
//     organization: {
//       address: {
//         address: '218 West Broadway Avenue',
//         city: 'Bismarck',
//         state: '5ad9dc3ef14751b3327852bf',
//         country: '5bf56d2f3753aef4c07e9fdd',
//         zip: '58501',
//       },
//       mailing: {
//         address: '218 West Broadway Avenue',
//         city: 'Bismarck',
//         state: '5ad9dc3ef14751b3327852bf',
//         country: '5bf56d2f3753aef4c07e9fdd',
//         zip: '58501',
//       },
//       personnel: {
//         numAthletes: 400,
//         numCoaches: 2,
//         numVolunteers: 0,
//         numOfficials: 42,
//       },
//       sport: [],
//       backgroundCheck: 'none',
//       insurance: [],
//       isCompleted: true,
//       isTemporary: false,
//       isQuoteRequested: true,
//       _id: '5df1053d2f3b22005e19c3f0',
//       __v: 0,
//       code: 'ABU0YV0',
//       createdAt: '2019-12-11T15:03:25.299Z',
//       externalCoverages: [],
//       name: 'Abused Adult Resource Center',
//       params: {
//         isOvernight: false,
//         eventStartDate: null,
//         eventEndDate: null,
//         isGuestParticipants: true,
//         isOvernightPlanned: true,
//         isMinorsControlled: true,
//         isMaintainRecordSigned: true,
//         isMaintainPlayingSurfaces: false,
//         isProcessCheckPlayingSurfaces: true,
//         isNonSportRelatedActivities: true,
//         isAllegationSexualAbuse: true,
//         isInsuredCriminalInvestigations: true,
//         isWrittenStandardsSexualAbuse: true,
//       },
//       phone: '+1 701-222-8370',
//       placeId: 'ChIJ-_ymZGuD11IRXNCFtw56Px0',
//       previousClaims: [],
//       site: 'http://www.abusedadultresourcecenter.com/',
//       sports: [{
//         _id: '5df105492f3b22005e19c3f7',
//         sport: '5c93af686434f5005e741521',
//         ageGroup1: 100,
//         ageGroup2: 100,
//         ageGroup3: 200,
//         monthsInSeason: 1,
//       }],
//       type: '5ad9d513afc44f00644b34dd',
//       updatedAt: '2019-12-16T12:26:26.561Z',
//       creator: '5d8b4d222ca24d005e98dd22',
//       completedAt: '2019-12-16T12:26:26.521Z',
//     },
//     paymentCycle: 'annually',
//     policyExpiredAt: '2020-12-14T00:00:00.000Z',
//     policyStartedAt: '2019-12-17T00:00:00.000Z',
//     requester: {
//       stripeBankObjects: [],
//       stripeBankVerified: false,
//       organizations: ['5d8b4d002ca24d005e98dd1d', '5d8de93cbbcc1e005e992e9d', '5d932492effcd6005e945dc9', '5defb4498353b1005e394ae7', '5df1053d2f3b22005e19c3f0'],
//       isEmailConfirmed: true,
//       role: 'customer',
//       brokerOrganizations: [],
//       brokerCommission: 0,
//       isBroker: false,
//       isArchived: false,
//       _id: '5d8b4d222ca24d005e98dd22',
//       __v: 5,
//       createdAt: '2019-09-25T11:18:58.935Z',
//       email: 'blxnsh+322228@gmail.com',
//       name: 'Vladislav',
//       password: '$2b$10$5kz19mVohTeW3.TpBdmz8eVSTyBJ2KNf3koKdqZr4BChbPUovJ4ry',
//       phone: '380730421488',
//       soaRole: 'King of crusade',
//       updatedAt: '2019-12-11T15:03:25.328Z',
//       activeAt: '2019-12-16T14:31:41.562Z',
//       locale: 'en-US',
//     },
//     requesterEmail: 'blxnsh+322228@gmail.com',
//     sports: [],
//     subcoverages: [],
//     updatedAt: '2019-12-16T14:28:31.606Z',
//   }],
// }]; http://localhost:8080/soa/quotes?toggle_target=%257B%2522orgName%2522%253A%2522Toyota%2520Center%2522%257D&toggle_type=filters
