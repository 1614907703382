export default {
  createFnol: `mutation CreateClaim ($input: ClaimInput) {
                    claim (input: $input) {
                      name,
                      organization{
                         id,
                         name,
                       },
                       status,
                       birthdate,
                       gender,
                       socialSecurity,
                       address{
                         address,
                         address2,
                         city,
                         state,
                         country,
                         zip,
                         phone,
                       },
                       mailing{
                         address,
                         address2,
                         city,
                         state,
                         country,
                         zip,
                         phone,
                       },
                       isPersonDependent,
                       employerName,
                       injuryLocation,
                       injuryDate,
                       isPolicyholderJurisdiction,
                       isWitness,
                       isFNOL,
                       supervisorName,
                       activityType,
                       incidentDescription,
                       injuryReport,
                       policyNumber,
                       certificateNumber,
                       otherInsuranceName,
                       isInjuredPersonCovered,
                       physicalName,
                       reporterName,
                       admittanceDate,
                       hospitalName,
                       bodyPart
                     }
                 }`,
  exportFnols: `mutation ($format: String, $records: [ID], $filters: ClaimsFilterInput) {
                export(format: $format, records: $records, filters: $filters) {
                  code,
                  message,
                  token,
                  url,
                }
               }`,
  updateMultipleFnols: `mutation ($input:ClaimInput, $updateList: [ID]){
                         claims(input:$input, updateList:$updateList){
                           code,
                           message,
                         }
                       }`,
  updateSingleFnol: `mutation ($input:ClaimInput, $updateId: ID){
                      claim(input:$input, updateId:$updateId){
                          id,
                          organization {
                            id,
                            name
                          },
                          status,
                          name,
                          birthdate,
                          gender
                        }
                     }`,
};
