import http from '@/plugins/axios';
import { GraphQLError } from '@/utils/exceptions';
import { EventEmitter } from '@/utils/events';
import { getGraphQLErrorMessage } from '@/utils/helpers/graphql';

class ServiceProvider extends EventEmitter {
  static authToken = null;

  static requestLocale = null;

  constructor() {
    if (new.target === ServiceProvider) throw new TypeError('Cannot construct ServiceProvider instances directly');
    super();
    this.http = http;
    this.apiURL = process.env.API_URL || 'https://api-stage.playershealthcover.com/graphql';
    this.stripeURL = process.env.STRIPE_URL || 'https://api-stage.playershealthcover.com/stripe';
  }

  post(url, data, config) {
    return new Promise((resolve, reject) => {
      const requestConfig = ServiceProvider.getRequestConfig(config);
      this.http.post(url, data, requestConfig).then((response) => {
        ServiceProvider.dispatch('beforeHandlePostResponse', response);
        resolve(response);
      }).catch((err) => {
        ServiceProvider.dispatch('errorHandlerPost', err);
        reject(err);
      });
    });
  }

  query(query, variables, config = {}) {
    let queryObj = { query, variables };
    return new Promise((resolve, reject) => {
      this.post(this.apiURL, queryObj, { ...config, ...(ServiceProvider.authToken && !config.noAuth ? { headers: ServiceProvider.getAuthBearerHeader() } : {}) }).then((response) => {
        if (response.data.errors && response.data.errors.length) {
          throw new GraphQLError(response.data.errors[0].message, response.data.errors);
        } else {
          resolve(response);
        }
      }).catch((error) => {
        reject(ServiceProvider.wrapErrorrWithExtractedMessage(error));
      });
    });
  }

  mutate(mutation, variables, config = {}) {
    let mutationObj = { query: mutation, variables };
    return new Promise((resolve, reject) => {
      this.post(this.apiURL, mutationObj, { ...config, ...(ServiceProvider.authToken && !config.noAuth ? { headers: ServiceProvider.getAuthBearerHeader() } : {}) })
        .then((response) => {
          if (response.data.errors && response.data.errors.length) {
            throw new GraphQLError('GraphQL Mutation Error', response.data.errors);
          } else {
            resolve(response);
          }
        }).catch((error) => {
          reject(ServiceProvider.wrapErrorrWithExtractedMessage(error));
        });
    });
  }

  formDataMutate(formData, config = {}) {
    return new Promise((resolve, reject) => {
      this.post(
        this.apiURL, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data', ...(ServiceProvider.authToken ? ServiceProvider.getAuthBearerHeader() : {}) } },
      )
        .then((response) => {
          if (response.data.errors && response.data.errors.length) {
            throw new GraphQLError('GraphQL Mutation Error', response.data.errors);
          } else {
            resolve(response);
          }
        }).catch((error) => {
          reject(ServiceProvider.wrapErrorrWithExtractedMessage(error));
        });
    });
  }

  static getAuthBearerHeader(token) {
    return { Authorization: `Bearer ${token || ServiceProvider.authToken}` };
  }

  static setAuthToken(token) {
    ServiceProvider.authToken = token;
  }

  static setRequestLocale(localeCode) {
    ServiceProvider.requestLocale = localeCode;
  }

  static extendRequestConfigWithHeaders(config, headersObject) {
    if (!(config instanceof Object)) {
      throw new Error('The required parameter "config" is not provided or invalid');
    }
    if (!(headersObject instanceof Object)) {
      throw new Error('The required parameter "headersObject" is not provided or invalid');
    }
    return { ...config, headers: { ...config.headers, ...headersObject } };
  }

  static getRequestConfig(config = {}) {
    if (typeof ServiceProvider.requestLocale === 'string' && ServiceProvider.requestLocale.length) {
      return ServiceProvider.extendRequestConfigWithHeaders(config, {
        'Accept-Language': ServiceProvider.requestLocale,
      });
    }
    return config;
  }

  static wrapErrorrWithExtractedMessage(error) {
    if (error.graphQLErrors instanceof Array) {
      return new GraphQLError(getGraphQLErrorMessage(error), error.graphQLErrors);
    } if (error.response instanceof Object
        && error.response.data instanceof Object
        && error.response.data.errors instanceof Array) {
      return new GraphQLError(getGraphQLErrorMessage(error), error.response.data.errors);
    }
    return error;
  }
}

export default ServiceProvider;
