<template lang="html">
  <v-navigation-drawer
    :value="drawer"
    :width="screenWidth"
    :class="b()"
    app
    absolute
    stateless
    temporary
    scroll-off-screen
    te
  >
    <v-toolbar :class="b('toolbar')">
      <div v-if="localeInitialized" :class="b('langWrapper')">
        <LangSwitcher
          :available-locales="availableLocales"
          :current-locale="currentLocale"
          :is-mobile="isMobile"
          @setNewLocale="handleUpdateLocale"
        />
      </div>
      <v-spacer/>
      <transition name="fade">
        <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon text @click.stop="hideMenu">
          <img src="~@/assets/images/cross-icon.svg" alt="cross">
        </v-btn>
      </transition>
    </v-toolbar>
    <v-toolbar-title v-if="!authed">
      <router-link class="logo-link" :to="homeRoute">
        <Logo class="logo" alt="PH Cover" />
      </router-link>
    </v-toolbar-title>
    <div class="" v-if="authed">
      <div class="avatarContainer">
        <div class="avatarContainer__holder">
          <router-link :to="{name: 'soa.profile'}">
            <img class="avatarContainer__icon" :src="getProfileAvatar()" alt="user avatar" />
          </router-link>
        </div>
        <div class="avatarContainer__name">
          {{profile.name}}
        </div>
      </div>
    </div>
    <v-list id="appSidebarMenuList">
      <template v-for="(item, index) in items">
        <v-layout v-if="item.heading"
                  row
                  align-center
                  :key="`${item.to.name}_${index}`">
          <template v-if="item.heading">
            <v-subheader>
               {{ item.heading }}
            </v-subheader>
            <v-divider/>
          </template>
        </v-layout>
        <v-list-item
          v-else
          :key="item.text"
          :to="item.to"
          :class="item.to.name === 'auth.logout' ? b('logoutBtn') : ''"
          active-class="accent--text"
          ripple
          exact
        >
          <v-list-item-content>
            <v-list-item-title>
              <img
                v-if="item.to.name === 'auth.logout'"
                :class="b('logoutBtnIcon')"
                src="~@/assets/images/logout-5.svg"
                alt="logout"
              >
              <span :class="b('menuItemText')">{{ item.text }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import LangSwitcher from '@/components/controls/LangSwitcher';
import { mapActions, mapGetters } from 'vuex';
import { getMenuItemsByRoles } from '@/utils/helpers';
import Logo from '@/components/layout/Logo';

const MENU_ITEMS = getMenuItemsByRoles();
const USER_PLACEHOLDER_IMG = require('@/assets/images/user-placeholder.jpg');

export default {
  name: 'AppSidebarMenu',
  block: 'appSidebarMenu',
  components: {
    LangSwitcher,
    Logo,
  },
  data() {
    return {
      confirmSignOut: false,
      soa: {
        items: [
          ...MENU_ITEMS.soa.items,
          {
            materialIcon: 'power_settings_new',
            i18nKey: 'ui.logOut',
            to: {
              name: 'auth.logout',
            },
          },
        ],
      },
      guest: MENU_ITEMS.guest,
    };
  },
  computed: {
    ...mapGetters('user', ['homeRoute', 'userIsSoa', 'authed', 'profile']),
    ...mapGetters('layout', ['isMobile', 'screenWidth']),
    ...mapGetters('locale', [
      'availableLocales',
      'currentLocale',
      'localeInitialized',
    ]),
    clipped() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    drawer: {
      get() {
        return this.$store.state.layout.showingMenu;
      },
      set(val) {
        // eslint-disable-next-line
        this.$store.commit(val ? 'layout/SHOW_MENU' : 'layout/HIDE_MENU');
      },
    },
    items() {
      let items = this.mapItemsWithTranslation(this.guest.items);
      if (this.userIsSoa) {
        items = this.mapItemsWithTranslation(this.soa.items);
      }
      return items;
    },
    routePath() {
      return this.$store.state.route.path;
    },
  },
  watch: {
    routePath() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$store.commit('layout/HIDE_MENU');
      }
    },
  },
  methods: {
    ...mapActions('layout', ['hideMenu']),
    ...mapActions('locale', ['setLocale']),
    handleUpdateLocale(value) {
      this.setLocale(value);
    },
    getProfileAvatar() {
      if (this.profile && this.profile.icon) {
        return this.profile.icon;
      }
      return USER_PLACEHOLDER_IMG;
    },
    mapItemsWithTranslation(items) {
      if (items instanceof Array) {
        return items.map((itm) => ({
          ...itm,
          text: itm.i18nKey ? this.$t(itm.i18nKey) : '',
        }));
      }
      return items;
    },
  },
  mounted() {
    this.hideMenu();
  },
};
</script>

<style lang="scss">
$block: 'appSidebarMenu';

.#{$block} {
  padding: 0;
  &__toolbar {
    &.v-toolbar {
      &.theme--light {
        box-shadow: none;
        background: $white;
      }
    }
  }
  .v-toolbar__title {
    margin: 0px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ECEFF6;
  }
  &.v-navigation-drawer {
    .v-list {
      background: none;
      position: relative;
      height: calc(100% - 152px);
      min-height: 450px;
      &-item {
        padding: 0 20px;
        height: 62px;
        .v-list-item__title {
          font-weight: 300;
          font-size: 24px;
        }
        &--active {
          .v-list-item__title {
            font-weight: 400;
          }
        }
        &.#{$block}__logoutBtn {
          padding-left: 36px;
        }
      }
    }
    .logo-link {
      display: flex;
    }
  }
  &__langWrapper {
    margin-left: 15px !important;
  }
  &__logoutBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #ECEFF6;
    .#{$block}__logoutBtnIcon {
      margin-right: 45px;
      vertical-align: middle;
    }
    .#{$block}__menuItemText {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
      color: #272727;
      vertical-align: middle;
    }
  }
}

.avatarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 20px;
  border-bottom: 1px solid #ECEFF6;
}

.avatarContainer__holder {
  flex: 0 1 58px;
  margin-right: 14px;
  margin-left: 16px;
}

.avatarContainer__icon {
  width: 58px;
  height: 58px;
  border-radius: 50%;
}

.avatarContainer__name {
  font-size: 20px;
  line-height: 24px;
  color: #272727;
  font-weight: 500;
}
</style>
