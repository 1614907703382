import { ParamProvider, InsuranceProvider, SearchProvider } from '@/graphql/providers';

import { sortOptionsAlphabetically } from '@/utils/helpers';

const mapSelectOptions = (item) => ({
  text: item.value,
  value: item.value,
  values: item.values,
  id: item.id,
});

const mapSelectOptionsWithIdValues = (item) => ({
  text: item.value,
  value: item.id,
  values: item.values,
  id: item.id,
});

const reduceToIdMap = (acc, currVal) => {
  if (currVal && currVal instanceof Object && currVal.id && typeof currVal.id === 'string') {
    acc[currVal.id] = { ...currVal };
  }
  return acc;
};

const getKeysOfFetchedGlossaries = (state) => {
  if (state && state instanceof Object) {
    const filterKeysMap = {
      activityTypes: 'activityType',
      cities: 'city',
      states: 'state',
      countries: 'country',
      bodyParts: 'bodyPart',
      injuryTypes: 'injuryType',
      organizationTypes: 'orgType',
      sports: 'sport',
      regProviders: 'regProvider',
      bgCheckProviders: 'backgroundProvider',
    };
    return Object.keys(state).reduce((acc, key) => {
      if (state[key] instanceof Array && state[key].length && filterKeysMap[key]) {
        acc.push(filterKeysMap[key]);
      }
      return acc;
    }, []);
  }
  return [];
};

const getStateAliasForParamName = (name) => {
  const aliasKeysMap = {
    activityType: 'activityTypes',
    backgroundProvider: 'bgCheckProviders',
    bodyPart: 'bodyParts',
    city: 'cities',
    country: 'countries',
    injuryType: 'injuryTypes',
    orgType: 'organizationTypes',
    regProvider: 'regProviders',
    sport: 'sports',
    state: 'states',
  };
  return aliasKeysMap[name] || '';
};

const state = {
  numberTeamsLeague: [],
  activityTypes: [],
  cities: [],
  states: [],
  countries: [],
  bodyParts: [],
  injuryTypes: [],
  organizationTypes: [],
  sports: [],
  regProviders: [],
  bgCheckProviders: [],
};

const mutations = {
  SET_GLOSSARY(ctx, data) {
    ctx[data.name] = data.values;
  },
};

const actions = {
  searchLocation(ctx, query) {
    return new Promise((resolve, reject) => {
      SearchProvider.searchLocationQuery(query).then(resolve).catch(reject);
    });
  },
  fetchActivityTypes(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.activityTypesQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'activityTypes',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchBgCheckProviders(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.bgCheckProvidersQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'bgCheckProviders',
            values: response.data.data.params[0].values,
          });
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchCities(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.citiesQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'cities',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchStates(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.statesQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'states',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchCountries(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.countriesQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'countries',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchBodyParts(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.bodyPartsQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'bodyParts',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchInjuryTypes(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.injuryTypesQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'injuryTypes',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchOrganizationTypes(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.orgTypesQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'organizationTypes',
            values: response.data.data.params[0].values,
          });
          resolve(response);
        }
      }).catch(reject);
    });
  },
  fetchRegProviders(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.regProvidersQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'regProviders',
            values: response.data.data.params[0].values,
          });
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchSports(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.sportsQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'sports',
            values: response.data.data.params[0].values,
          });
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchNumberTeamsLeague(ctx) {
    return new Promise((resolve, reject) => {
      ParamProvider.numberTeamsLeagueQuery().then((response) => {
        if (response.data.data.params
          && response.data.data.params.length
          && response.data.data.params[0].values instanceof Array) {
          ctx.commit('SET_GLOSSARY', {
            name: 'numberTeamsLeague',
            values: response.data.data.params[0].values,
          });
        }
        resolve(response);
      }).catch(reject);
    });
  },
  reFetchPresentData(ctx) {
    return new Promise((resolve, reject) => {
      const paramFilters = getKeysOfFetchedGlossaries(ctx.state);
      if (paramFilters.length) {
        ParamProvider.paramsQuery(paramFilters).then((response) => {
          if (response.data.data.params
            && response.data.data.params.length) {
            response.data.data.params.forEach((paramRes) => {
              const stateAlias = getStateAliasForParamName(paramRes.field);
              if (stateAlias) {
                ctx.commit('SET_GLOSSARY', {
                  name: stateAlias,
                  values: paramRes.values,
                });
              }
            });
          }
          resolve(response);
        }).catch(reject);
      } else {
        resolve({});
      }
    });
  },
};

const getters = {
  // options
  activityTypes: (ctx) => ctx.activityTypes.map(mapSelectOptionsWithIdValues),
  numberTeamsLeagueOptions: (ctx) => ctx.numberTeamsLeague.map(mapSelectOptionsWithIdValues).sort((a, b) => a.values - b.values),
  bgCheckProviders: (ctx) => ctx.bgCheckProviders.map(mapSelectOptionsWithIdValues),
  cities: (ctx) => ctx.cities.map(mapSelectOptionsWithIdValues),
  // TODO return back with ids
  countries: (ctx) => ctx.countries.map(mapSelectOptionsWithIdValues),
  countriesSelectable: (ctx) => ctx.countries.filter((country) => !country.disabled).map(mapSelectOptionsWithIdValues),
  states: (ctx) => ctx.states.map(mapSelectOptionsWithIdValues),
  bodyParts: (ctx) => ctx.bodyParts.map(mapSelectOptionsWithIdValues),
  injuryTypes: (ctx) => ctx.injuryTypes.map(mapSelectOptionsWithIdValues),
  regProviders: (ctx) => ctx.regProviders.map(mapSelectOptionsWithIdValues),
  sports: (ctx) => ctx.sports.map(mapSelectOptionsWithIdValues),
  // glossary
  activityTypesMap: (ctx) => ctx.activityTypes.reduce(reduceToIdMap, {}),
  numberTeamsLeagueOptionsMap: (ctx) => ctx.numberTeamsLeague.reduce(reduceToIdMap, {}),
  bgCheckProvidersMap: (ctx) => ctx.bgCheckProviders.reduce(reduceToIdMap, {}),
  citiesMap: (ctx) => ctx.cities.reduce(reduceToIdMap, {}),
  statesMap: (ctx) => ctx.states.reduce(reduceToIdMap, {}),
  countriesMap: (ctx) => ctx.countries.reduce(reduceToIdMap, {}),
  bodyPartsMap: (ctx) => ctx.bodyParts.reduce(reduceToIdMap, {}),
  injuryTypesMap: (ctx) => ctx.injuryTypes.reduce(reduceToIdMap, {}),
  regProvidersMap: (ctx) => ctx.regProviders.reduce(reduceToIdMap, {}),
  sportsMap: (ctx) => ctx.sports.reduce(reduceToIdMap, {}),
  organizationTypeMap: (ctx) => ctx.organizationTypes.reduce(reduceToIdMap, {}),
  carriersMap: (ctx, ctxGetters, rootState, rootGetters) => rootGetters['insurance/carriers'].reduce(reduceToIdMap, {}),
  // TODO: refactor
  sportsMapByValue: (context) => context.sports.reduce((acc, sport) => {
    acc[sport.id] = sport.value;
    return acc;
  }, {}),
  organizationTypes: (ctx) => ctx.organizationTypes,
  organizationTypeOptions: (ctx) => sortOptionsAlphabetically(ctx.organizationTypes.map(mapSelectOptionsWithIdValues), 'text'),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
