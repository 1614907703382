import { COVERAGE_TYPE_ALIASES, SUBCOVERAGE_TYPE_ALIASES } from '@/utils/constants';
import { CHAINABLE_SUB_STEP_NAMES, CHAINABLE_SUB_STEP_QUESTION_NAMES } from '@/store/modules/orgApplication/constants';

export default (questionKey, chosenCoverage, chosenSubCoverages) => {
  // process "generalLiabilityAndParticipantLiability" cases
  if (questionKey
    && chosenCoverage instanceof Object
    && chosenCoverage.type === COVERAGE_TYPE_ALIASES.generalLiabilityAndParticipantLiability
    && Array.isArray(chosenSubCoverages)
  ) {
    const subcoverageAliases = SUBCOVERAGE_TYPE_ALIASES[chosenCoverage.type];
    const generalCommonQuestionNames = CHAINABLE_SUB_STEP_QUESTION_NAMES[CHAINABLE_SUB_STEP_NAMES.generalCommonQuestions];
    if ([generalCommonQuestionNames.isParticipantsTransported, 'doesTheInsuredTransportParticipants'].includes(questionKey)
      && subcoverageAliases.hnoaLiability !== undefined
    ) {
      const hasChosenHNOALiability = chosenSubCoverages.some((sub) => sub.type === subcoverageAliases.hnoaLiability);
      return hasChosenHNOALiability;
    }
  }
  return true;
};
