import { VBtn } from 'vuetify/lib';

const BTN_CLASS = 'v-btn';
const getModifierClasses = (props) => ({
  [`${BTN_CLASS}--ph`]: props.custom,
  [`${BTN_CLASS}--wide`]: props.wide,
  [`${BTN_CLASS}--uppercase`]: props.uppercase,
  [`${BTN_CLASS}--black`]: props.black,
  [`${BTN_CLASS}--narrow`]: props.narrow,
  [`${BTN_CLASS}--small-text`]: props.smallText,
  [`${BTN_CLASS}--borderless`]: props.borderless,
  [`${BTN_CLASS}--fill-width`]: props.fillWidth,
});

export default ((component) => ({
  functional: true,
  name: 'VBtn',
  props: {
    custom: {
      type: Boolean,
      default: true,
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
    black: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    smallText: {
      type: Boolean,
      default: false,
    },
    fillWidth: {
      type: Boolean,
      default: false,
    },
    wide: Boolean,
  },
  render(h, context) {
    const newProps = {
      ...context.props,
      rounded: context.props.rounded !== false,
      uppercase: context.props.uppercase,
      wide: context.props.wide,
      black: context.props.black,
      narrow: context.props.narrow,
      smallText: context.props.smallText,
      borderless: context.props.borderless,
      fillWidth: context.props.fillWidth,
    };
    return h(
      component,
      {
        ...context.data,
        class: [
          context.data.class,
          getModifierClasses(newProps),
        ],
        props: newProps,
      },
      context.children,
    );
  },
}))(VBtn);
