export default (num, locale = 'en') => {
  try {
    num = num.toString();
    const { format } = new Intl.NumberFormat(locale);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
      .replace(decimalSign, '.');
  } catch (e) {
    return num;
  }
};
