<template>
  <img v-if="inverted" :class="b()" src="~@/assets/images/logo--white.svg" alt="Player's Health">
  <img v-else :class="b(wide ? 'wide': '')" :src="logoSrc" alt="Player's Health">
</template>

<script>
import { LOGO_NAMES, LOGO_TYPES } from '@/utils/constants/index';

export default {
  name: 'Logo',
  block: 'phLogo',
  props: {
    inverted: Boolean,
    wide: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getLogoUrl(logoType) {
      return require(`../../assets/images/${LOGO_NAMES[logoType]}`);
    },
  },
  computed: {
    logoType() {
      return this.wide
        ? LOGO_TYPES.WIDE
        : LOGO_TYPES.APP_BAR;
    },
    logoSrc() {
      return this.getLogoUrl(this.logoType);
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'phLogo';

.#{$block} {
  max-width: 132px;
  vertical-align: middle;
  @include mappy-bp(xs){
    max-width: 151px;
  }
  &__wide {
    max-width: 222px;
    @include mappy-bp(xs) {
      max-width: 262px;
    }
  }
}
</style>
