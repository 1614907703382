const route = {
  name: 'login',
  path: 'login',
  component: () => import('@/pages/auth/Login'),
  meta: {
    disableDrawer: true,
  },
};

export default route;
