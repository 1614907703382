import i18n from '@/i18n';
import { RECAPTCHA_SITEKEY, SUPPORT_EMAIL } from '@/utils/constants';
import { CaptchaProvider } from '@/graphql/providers';

const state = {
  mounted: false,
  actions: {
    signIn: {
      validated: false,
      success: false,
      score: 0,
    },
    signUp: {
      validated: false,
      success: false,
      score: 0,
    },
  },
};

const mutations = {
  SET_MOUNTED(context, value) {
    context.mounted = !!value;
  },
  UPDATE_ACTION_STATE(context, data) {
    const entries = Object.entries(data);
    if (entries.length && context.actions[entries[0][0]]) {
      context.actions[entries[0][0]] = {
        ...context.actions[entries[0][0]],
        ...entries[0][1],
      };
    }
  },
};

const actions = {
  mountCaptcha(context) {
    return new Promise((resolve, reject) => {
      if (window && document) {
        try {
          const body = document.querySelector('body');
          if (body) {
            if (RECAPTCHA_SITEKEY) {
              const onloadCallback = () => {
                context.commit('SET_MOUNTED', true);
                resolve(true);
              };
              // set onloadCallback to window, so that reCAPTCHA could find & execute it
              window.onloadCallback = onloadCallback;
              // create & configure the script tag
              const script = document.createElement('script');
              script.setAttribute('src', `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=${RECAPTCHA_SITEKEY}`);
              script.async = true;
              script.defer = true;
              // append script tag to the end of the <body>
              body.appendChild(script);
            } else {
              throw new Error('RECAPTCHA_SITEKEY wasn\'t present in the app environment variables');
            }
          }
        } catch (err) {
          reject(err);
        }
      } else {
        reject(new Error('It appears the app is not running in a browser environment'));
      }
    });
  },
  executeAction(context, action) {
    return new Promise(async (resolve, reject) => {
      try {
        if (context.state.mounted && window.grecaptcha) {
          if (action && Object.keys(context.state.actions).includes(action)) {
            const validateVerifyRecaptchaResponse = (response) => Boolean(response
              && response.data
              && response.data.data
              && response.data.data.verifyRecaptcha
              && response.data.data.verifyRecaptcha instanceof Object);
            window.grecaptcha.execute(RECAPTCHA_SITEKEY, { action })
              .then((token) => {
                context.dispatch('validateToken', token)
                  .then((response) => {
                    if (validateVerifyRecaptchaResponse(response)) {
                      resolve(response.data.data.verifyRecaptcha);
                    }
                    resolve(response);
                  }).catch(reject);
              }, (reason) => {
                reject(new Error(reason));
              });
          } else {
            throw new Error('The provided action was invalid');
          }
        } else {
          resolve();
        }
      } catch (err) {
        reject(err);
      }
    });
  },
  executeLoginCaptcha(context) {
    return context.dispatch('executeAction', 'signIn');
  },
  executeSignUpCaptcha(context) {
    return context.dispatch('executeAction', 'signUp');
  },
  showFraudErrorMessage({ dispatch }) {
    return dispatch('dialog/showInfoDialog', {
      title: i18n.t('message.warning'),
      description: i18n.t('response.error.gReCaptchaFraud', { email: SUPPORT_EMAIL }),
    }, {
      root: true,
    });
  },
  validateToken(context, token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await CaptchaProvider.validateCaptchaByTokenMutation(token);
        if (response && response instanceof Object && response.data && response.data.data && response.data.data.verifyRecaptcha) {
          if (context.state.actions[response.action]) {
            context.commit('UPDATE_ACTION_STATE', {
              [response.action]: {
                validated: true,
                success: response.success,
                score: response.score,
              },
            });
          }
          resolve(response);
        } else {
          reject(new Error('Wrong reCAPTCHA validation response'));
        }
      } catch (err) {
        reject(err);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
