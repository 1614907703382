export default {
  getShoppingCartItems: `query {
    cart {
      id,
      isValid,
      carrier {
        id,
        icon,
        name
      },
      coverage {
        carrierParams
      },
      quoteId,
      quote {
        id,
        isInCart,
        terrorismAmount,
        coverageType,
        coverageName,
        subcoverages {
          coverage {
            name,
          },
          limit
        },
        expiredAt,
        limit,
        annualCost,
        monthlyCost,
        barePremiumAmount,
        taxAmount,
        policyAdminAmount,
        rpgAmount,
        stampingAmount,
        policyStartedAt,
        policyExpiredAt,
      },
      organization {
        id,
        name,
        address {
          country,
        },
      },
      paymentCycle,
      startDate,
      annualCost,
      monthlyCost,
      type,
      createdAt
    }
  }`,
};
