export default (athletesAgeBySports, sportsMap) => {
  if (athletesAgeBySports && athletesAgeBySports instanceof Object && sportsMap && sportsMap instanceof Object) {
    return Object.entries(athletesAgeBySports).some((entry) => entry[1].ageGroup2 != 0
      && entry[1].ageGroup3 == 0
      && typeof entry[0] === 'string'
      && entry[0].length
      && sportsMap[entry[0]] instanceof Object
      && sportsMap[entry[0]].values
      && sportsMap[entry[0]].values instanceof Object
      && sportsMap[entry[0]].values.restrictAthletesOlderThanFourteen);
  }
  return false;
};
