const route = {
  name: 'profile',
  path: 'profile',
  component: () => import('@/pages/soa/Profile'),
  meta: {
    requiresAuth: true,
  },
};

export default route;
