export default {
  requestPOI: `mutation RequestPOI ($input: ProofInput!) {
                    proof (input: $input) {
                      id,
                      policies {
                        id,
                        policyFile,
                        policyPreview,
                        quote {
                          id,
                          coverageType,
                          coverageName
                        },
                      },
                      organization {
                        id,
                        name
                      },
                      coverageType,
                      clubName,
                      clubAddress,
                      contactName,
                      contactEmail,
                      contactPhone,
                      eventName,
                      eventLocation,
                      eventStartDate,
                      eventEndDate,
                      additionalInsured,
                      isArchived,
                      documentUrl,
                      documentPreviewMini,
                      documentPreviewMidi,
                    }
                }`,
};
