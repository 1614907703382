var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({ left: _vm.handleSwipeLeft, right: _vm.handleSwipeRight }),expression:"{ left: handleSwipeLeft, right: handleSwipeRight }"}],class:_vm.b('wrapper', { mobile: _vm.mobile })},[_c('div',{class:_vm.b('scroll', { mobile: _vm.mobile })},[_c('div',{class:_vm.b('scrollContent', { mobile: _vm.mobile }),style:(_vm.scrollContentTransformStyle)},[_c('ul',{class:_vm.b({
            shadow: _vm.hasShadow,
            mobile: _vm.mobile,
          })},_vm._l((_vm.steps),function(step,index){return _c('li',{key:index,class:_vm.b('item', {
            shadow: true,
            mobile: _vm.mobile,
            active: (index + 1) === _vm.value,
            complete: (index + 1) <= _vm.lastCompleteStep,
            disabled: (index + 1) > _vm.lastCompleteStep,
          }),on:{"click":function($event){return _vm.handleItemClick(index)}}},[_c('div',{},[_c('span',{directives:[{name:"show",rawName:"v-show",value:((index + 1) <= _vm.lastCompleteStep && (index + 1) !== _vm.value),expression:"(index + 1) <= lastCompleteStep && (index + 1) !== value"}],class:_vm.b('itemIconWrapper', { mobile: _vm.mobile })},[_c('img',{class:_vm.b('itemIcon'),attrs:{"alt":"Check icon"}})]),_vm._v(" "),_vm._t("step",[_vm._v("\n              "+_vm._s(step)+"\n            ")],{"step":step,"index":index})],2)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }