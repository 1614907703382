<template lang="html">
  <v-form :class="b('form')" ref="claim-step-form">
    <v-row :class="b('formOrgNameBlock')">
      <v-col :class="b('radioTitle')" cols="12" md="12">
        {{ $t('message.isInjuredPersonCovered') }}
      </v-col>
      <v-col cols="12" md="6">
        <v-radio-group
          @change="updateForm('person_covered', arguments[0])"
          :value="form.person_covered"
          :class="b('radioGroup')"
          row
        >
          <div :class="b('radioBlockRow')" align-center>
            <v-radio
              :value="true"
              :label="$t('ui.yes')"
            />
          </div>
          <div :class="b('radioBlockRow')" align-center>
            <v-radio
              :value="false"
              :label="$t('ui.no')"
            />
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row :class="b('formOrgNameBlock')">
      <v-col cols="12" md="6" v-if="form.person_covered">
        <v-text-field
          :value="form.company_name"
          @input="updateForm('company_name', arguments[0])"
          autocomplete="new-password"
          :rules="[...inputRules.required]"
          required
          :label="$t('fields.otherInsuranceName')"
          name="company_name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-if="form.person_covered"
          @input="updateForm('policy_number', arguments[0])"
          :value="form.policy_number"
          :rules="inputRules.required"
          :label="$t('items.policyNumber')"
          name="policy_number"
          required
        />
        <v-tooltip v-else :disabled="!!validPolicyNumbers.length" top>
          <template v-slot:activator="{ on }">
            <SearchableSelect
              v-on="on"
              @input="updateForm('policy_number', arguments[0])"
              :items="validPolicyNumbers"
              :value="form.policy_number"
              :rules="inputRules.required"
              :label="$t('items.policyNumber')"
              :disabled="!validPolicyNumbers.length"
              name="policy_number"
              slot="activator"
              required
              single-line
            />
          </template>
          <span>{{ $t('message.noPoliciesAvailableForClaims') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row :class="b('formOrgNameBlock')">
      <v-col cols="12" md="12">
        <div :class="b('hereby')">{{ $t('message.iConfirmInfoIsCorrect') }}.</div>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @input="updateForm('claimant_name', arguments[0])"
          :value="form.claimant_name"
          :rules="[...inputRules.required]"
          :label="$t('fields.reporterName')"
          autocomplete="new-password"
          name="claimant_name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <div :class="b('todayDate')">
          <span :class="b('todayDateLabel')">
            {{ $t('ui.date') }}
          </span>
          <span :class="b('todayDateValue')">
            {{ todayDate }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import inputRules from '@/utils/helpers/inputRules';

export default {
  name: 'ClaimActionInsuranceStep',
  block: 'claimActionInsuranceStep',
  props: {
    insuranceInfo: Object,
    selectedOrg: {
      default: () => false,
    },
    accidentDate: {
      type: [String, Number],
      required: true,
    },
    accidentTime: {
      type: [String, Number],
      required: true,
    },
    form: {
      type: Object,
    },
    currentStep: {
      type: [String, Number],
    },
  },
  data() {
    return {
      inputRules,
    };
  },
  computed: {
    validPolicyNumbers() {
      if (this.accidentDate && this.selectedOrg && this.selectedOrg.policies instanceof Array) {
        const momentAccidentDate = this.$lib.moment(`${this.accidentDate}${this.accidentTime ? ` ${this.accidentTime}` : ''}`);
        return this.selectedOrg.policies.reduce((acc, pol) => {
          if (momentAccidentDate.isBetween(pol.startedAt, pol.expiredAt, null, '[)')) {
            acc.push(pol.codeCarrier);
          }
          return acc;
        }, []);
      }
      return [];
    },
    todayDate() {
      return this.$lib.moment().format('DD MMMM YYYY');
    },
  },
  methods: {
    updateForm(key, value) {
      let reqObj = {};
      if (key.split('.').length == 2) {
        reqObj[[key.split('.')[0]]] = { ...this.form[key.split('.')[0]], [key.split('.')[1]]: value };
      } else {
        reqObj = { [key]: value };
      }
      let form = { ...this.form, ...reqObj };
      this.$emit('updateStepForm', { number: this.currentStep, form });
    },
    validateForm() {
      const isValid = this.$refs['claim-step-form'].validate();
      if (isValid) {
        this.$emit('nextStep', this.currentStep + 1);
      }
      return isValid;
    },
    stepBack() {
      this.$emit('handleToPrevStep');
    },
  },
  created() {
    if (this.form.claimant_name.length == 0) {
      this.updateForm('claimant_name', this.$store.getters['user/profile'].name);
    }
  },
};
</script>

<style lang="scss" scoped>
$block: 'claimActionInsuranceStep';

.#{$block} {
  max-width: 800px;
  margin: 0 auto;
  &__radioBlockRow {
    width: 80px;
  }
  &__formOrgNameBlock {
    > div {
      align-items: flex-start;
    }
  }
  &__formOrgInfoBlock {
    width: 100%;
  }
  &__radioTitle {
    margin-bottom: 0px;
  }
  &__todayDate {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  &__todayDateLabel {
    font-size: 16px;
    color: #868686;
    letter-spacing: 0.57px;
    font-weight: 400;
    margin-right: 8px;
  }
  &__todayDateValue {
    font-size: 16px;
    letter-spacing: 0.57px;
    font-weight: 400;
  }
}
</style>
