export default (sourceParams, updatedParams, definedOnly = false, ignoreNull = false) => {
  if (sourceParams instanceof Object && updatedParams instanceof Object) {
    return Object.entries(updatedParams).reduce((acc, paramEnt) => {
      if ((!definedOnly || sourceParams[paramEnt[0]] !== undefined) && (!ignoreNull || paramEnt[1] !== null)) {
        if (sourceParams[paramEnt[0]] !== paramEnt[1]) {
          acc[paramEnt[0]] = paramEnt[1];
        }
      }
      return acc;
    }, {});
  }
  return {};
};
