import { REFUSAL_TO_UPDATE_ORG_ERROR_MESSAGE } from '@/utils/constants';
import { OrganizationProvider, SoaProvider, UserProvider } from '@/graphql/providers';
import i18n from '@/i18n';

export default {
  addApprovedSOAToLocalOrg(context, data) {
    if (data && Object.keys(data).length) {
      context.commit('ADD_APPROVED_SOA_TO_ORG', data);
    }
  },
  addInviteToLocalOrg(context, data) {
    if (data.orgId && data.invite) {
      context.commit('ADD_INVITE_TO_ORG', data);
    }
    return Promise.resolve(data);
  },
  approveAdminIntoOrganization(context, payload) {
    try {
      if (payload && payload instanceof Object && payload.id) {
        if (payload.organization && payload.organization instanceof Object && payload.organization.id) {
          return SoaProvider.approveSOAMutation(payload.id)
            .then((response) => {
              if (response.data.data.request.code === 200) {
                context.dispatch('addApprovedSOAToLocalOrg', payload);
                return Promise.resolve(response);
              }
            });
        }
        throw new Error('The organization object wasn\'t provided or was invalid');
      } else {
        throw new Error('The action payload wasn\'t provided or was invalid');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async checkOrgUpdatesWillAffectSubmissionsOfOrg(context, { orgId, updateObj }) {
    if (typeof orgId === 'string' && orgId.length && updateObj && updateObj instanceof Object && Object.keys(updateObj).length) {
      try {
        const { data } = await OrganizationProvider.checkOrgUpdatesAffectSubmissionsMutation(orgId, updateObj);
        if (data && data instanceof Object && data.data && data.data instanceof Object && data.data.regenerateQuotes && data.data.regenerateQuotes instanceof Object) {
          return data.data.regenerateQuotes.code !== 200;
        }
        return false;
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      return Promise.reject(new Error('arguments not provided or invalid'));
    }
  },
  clearOrganizations({ commit }) {
    try {
      commit('UPDATE_ORGANIZATIONS', []);
      commit('UPDATE_PENDING_ORGANIZATIONS', []);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  },
  createOrganization({ dispatch, rootGetters }, createOrgObj) {
    const noAuth = !rootGetters['user/authed'];
    return OrganizationProvider.createOrgMutation(createOrgObj, {
      noAuth,
    }).then(async (response) => {
      if (response.data.data.organization && !noAuth) {
        await dispatch('pushToLocalOrgs', response.data.data.organization);
        return Promise.resolve(response);
      }
    });
  },
  excludeAdminFromOrganization(context, { orgId, userId }) {
    try {
      if (typeof orgId === 'string' && orgId.length) {
        if (typeof userId === 'string' && userId.length) {
          return SoaProvider.excludeSOAMutation(userId, orgId)
            .then((response) => {
              if (response.data.data.user.code === 200) {
                context.dispatch('removeExcludedSOAFromLocalOrg', {
                  orgId,
                  userId,
                });
                return Promise.resolve(response);
              }
            });
        }
        throw new Error('The user ID wasn\'t provided or was invalid');
      } else {
        throw new Error('The organization ID wasn\'t provided or was invalid');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },
  fetchApprovedOrganizations({ commit }) {
    return new Promise((resolve, reject) => {
      UserProvider.userOrganizationsQuery()
        .then((response) => {
          if (response.data && response.data.data && response.data.data.me && response.data.data.me.organizations instanceof Array) {
            commit('UPDATE_ORGANIZATIONS', response.data.data.me.organizations);
          }
          resolve(response);
        })
        .catch(reject);
    });
  },
  fetchOrganization(ctx, id) {
    return new Promise((resolve, reject) => {
      OrganizationProvider.orgDetailsQuery(id)
        .then(resolve)
        .catch(reject);
    });
  },
  fetchOrganizations({ dispatch }) {
    return Promise.all([dispatch('fetchApprovedOrganizations'), dispatch('fetchPendingOrganizations')]);
  },
  fetchPendingOrganizations({ commit }) {
    return new Promise((resolve, reject) => {
      UserProvider.userPendingOrganizationsQuery()
        .then((response) => {
          if (response.data && response.data.data && response.data.data.me && response.data.data.me.pending instanceof Array) {
            commit('UPDATE_PENDING_ORGANIZATIONS', response.data.data.me.pending);
          }
          resolve(response);
        })
        .catch(reject);
    });
  },
  getOrganizationDetailsByPlaceId(context, placeId) {
    return OrganizationProvider.orgDetailsQuery(placeId);
  },
  inviteNewAdminToOrganization(context, invitationObject) {
    if (invitationObject && invitationObject instanceof Object) {
      return SoaProvider.inviteSOAMutation(invitationObject, context.rootGetters['user/accessToken'])
        .then(async (response) => {
          if (response.data.data.invite && Object.keys(response.data.data.invite).length) {
            await context.dispatch('addInviteToLocalOrg', {
              orgId: invitationObject.organizationId,
              invite: response.data.data.invite,
            });
            return Promise.resolve(response);
          }
        }).catch((err) => Promise.reject(err));
    }
    return Promise.reject(new Error('The invitation object was invalid or empty'));
  },
  pushToLocalOrgs(context, newOrg) {
    if (newOrg instanceof Object && Object.keys(newOrg).length) {
      context.commit('PUSH_TO_ORGS', newOrg);
      return Promise.resolve(newOrg);
    }
    return Promise.reject(new Error('The provided newOrg object was invalid'));
  },
  pushToLocalPendingOrgs(context, newOrg) {
    if (newOrg instanceof Object && Object.keys(newOrg).length) {
      context.commit('PUSH_TO_PENDING_ORGS', newOrg);
      return Promise.resolve(newOrg);
    }
    return Promise.reject(new Error('The provided newOrg object was invalid'));
  },
  replaceLocalOrg(context, newOrg) {
    if (newOrg instanceof Object && Object.keys(newOrg).length) {
      context.commit('REPLACE_ORG', newOrg);
      return Promise.resolve(newOrg);
    }
    return Promise.reject(new Error('The provided organization object seems to be invalid or empty'));
  },
  rejectAdminFromOrganization(context, payload) {
    try {
      if (payload && payload instanceof Object && payload.id) {
        if (payload.organization && payload.organization instanceof Object && payload.organization.id) {
          return SoaProvider.rejectSOAMutation(payload.id)
            .then((response) => {
              if (response.data.data.request.code === 200) {
                context.dispatch('removeRejectedSOAFromLocalOrg', payload);
                return Promise.resolve(response);
              }
            });
        }
        throw new Error('The organization object wasn\'t provided or was invalid');
      } else {
        throw new Error('The action payload wasn\'t provided or was invalid');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },
  removeExcludedSOAFromLocalOrg(context, data) {
    if (data && Object.keys(data).length) {
      context.commit('REMOVE_EXCLUDED_SOA_FROM_ORG', data);
      return Promise.resolve(data);
    }
    return Promise.reject(new Error('The data object was invalid or absent'));
  },
  removeRejectedSOAFromLocalOrg(context, data) {
    if (data && Object.keys(data).length) {
      context.commit('REMOVE_REJECTED_SOA_FROM_ORG', data);
    }
  },
  resetOrganizationSearch(context) {
    context.commit('RESET_SEARCH_DATA');
    return Promise.resolve(context.state.search);
  },
  searchOrganizations(context) {
    context.commit('UPDATE_SEARCH_LOADING_STATE', true);
    return OrganizationProvider.orgSearchQuery(context.state.search.query)
      .then((result) => {
        if (result.data.data && result.data.data.organizations instanceof Array) {
          context.commit('UPDATE_SEARCH_RESULTS', result.data.data.organizations);
        }
        return result;
      })
      .finally(() => {
        context.commit('UPDATE_SEARCH_LOADING_STATE', false);
      });
  },
  searchOrg(ctx, { queryString, our = false }) {
    return new Promise((resolve, reject) => {
      OrganizationProvider.orgSearchQuery(queryString, our).then(resolve).catch(reject);
    });
  },
  searchOrganizationsByQuery(context, query) {
    context.commit('UPDATE_SEARCH_LOADING_STATE', true);
    return OrganizationProvider.orgSearchQuery(query)
      .then((result) => {
        if (result.data.data && result.data.data.organizations instanceof Array) {
          context.commit('UPDATE_SEARCH_RESULTS', result.data.data.organizations);
        }
        return result;
      })
      .finally(() => {
        context.commit('UPDATE_SEARCH_LOADING_STATE', false);
      });
  },
  async updateOrganization({ dispatch, getters }, { orgId, orgUpdateObject, checkAffectsSubmissions = true }) {
    try {
      if (typeof orgId === 'string' && orgId.length) {
        if (orgUpdateObject && orgUpdateObject instanceof Object && Object.values(orgUpdateObject).length) {
          const userBelongsToOrg = getters.organizations.some((org) => org.id === orgId);
          if (userBelongsToOrg) {
            if (checkAffectsSubmissions) {
              const willAffectSubmissionsOfOrg = await dispatch('organization/checkOrgUpdatesWillAffectSubmissionsOfOrg',
                {
                  orgId,
                  updateObj: orgUpdateObject,
                }, {
                  root: true,
                });
              if (willAffectSubmissionsOfOrg) {
                const consent = await dispatch('dialog/showConfirmDialog', {
                  title: i18n.t('message.warning'),
                  description: i18n.t('message.quoteAffectingChanges'),
                }, {
                  root: true,
                });
                if (!consent) {
                  throw new Error(REFUSAL_TO_UPDATE_ORG_ERROR_MESSAGE);
                }
              }
            }
            const response = await OrganizationProvider.updateOrgMutation(orgId, orgUpdateObject);
            if (response && response.data && response.data.data && response.data.data.organization) {
              await dispatch('replaceLocalOrg', response.data.data.organization);
              return Promise.resolve(response.data.data.organization);
            }
            throw new Error('Invalid response');
          } else {
            throw new Error('The organization you\'re trying to update doesn\'t belong to your account');
          }
        } else {
          throw new Error('The orgUpdateObject seems to be empty or invalid');
        }
      } else {
        throw new Error('orgId wasn\'t provided or was invalid');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateOrganizationIsQuoteRequested({ dispatch, getters }, { orgId, isQuoteRequested }) {
    try {
      if (typeof orgId === 'string' && orgId.length) {
        const orgMatch = getters.organizations.find((org) => org.id === orgId);
        if (orgMatch && orgMatch instanceof Object) {
          const updatedOrg = {
            ...orgMatch,
            isQuoteRequested,
          };
          await dispatch('replaceLocalOrg', updatedOrg);
          return Promise.resolve(updatedOrg);
        }
        throw new Error('no organization match found for the passed orgId');
      } else {
        throw new Error('orgId wasn\'t provided or was invalid');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },
  updateLocalOrg(context, newOrg) {
    if (newOrg instanceof Object && Object.keys(newOrg).length) {
      context.commit('REPLACE_ORG', newOrg);
      return Promise.resolve(newOrg);
    }
    return Promise.reject(new Error('The provided organization object seems to be invalid or empty'));
  },
  updateSearchOrganizationQuery(context, query) {
    context.commit('UPDATE_SEARCH_QUERY', query);
    return Promise.resolve(context.state.search);
  },
  updateSearchOrganizationResults(context, results) {
    context.commit('UPDATE_SEARCH_RESULTS', results);
    return Promise.resolve(context.state.search);
  },
};
