import moment from '@/plugins/moment';
import { ServiceProvider } from '@/graphql/providers';
import { MULTI_LANG_SUPPORT } from '@/utils/constants';

const convertNavigatorLanguageToLocaleCode = (payload) => {
  switch (payload) {
    case 'en-US':
    case 'en':
    case 'uk':
    case 'la':
    case 'en-AU':
    case 'en-GB':
    case 'en-IN':
    case 'en-CA':
    case 'en-NZ':
    case 'en-ZA':
      return 'EN_US';
    case 'fr':
    case 'fr-CA':
    case 'fr-FR':
    case 'fr-CH':
      return 'FR_CA';
    default:
      return 'EN_US';
  }
};

const findLocaleByi18nKey = (locales, i18nKey) => locales.find((loc) => loc.i18n === i18nKey);

const localStorageCopy = {
  state: {
    locale: null,
  },
  // methods
  checkLocaleIsSame(loc) {
    this.checkAndUpdateState();
    return loc === this.state.locale;
  },
  checkAndUpdateState() {
    if (!this.state.locale) {
      this.state.locale = localStorage.getItem('locale');
    }
  },
  // getters
  get locale() {
    this.checkAndUpdateState();
    return this.state.locale;
  },
  // setters
  set locale(loc) {
    this.state.locale = loc;
  },
};

export default {
  checkAndSetLocale({ dispatch, rootGetters, state }, overrideCurrentLocale = false) {
    if (MULTI_LANG_SUPPORT) {
      if (!state.currentLocale || overrideCurrentLocale) {
        if (rootGetters['user/authed']) {
          dispatch('setLocaleFromUser');
        }
        if (!state.currentLocale) {
          dispatch('setLocaleFromLocalStorage');
          if (!localStorageCopy.locale) {
            dispatch('setLocaleFromNavigator');
          }
        }
      }
    }
  },
  persistLocaleToLocalStorage({ getters }, localeCode) {
    if (!localStorageCopy.checkLocaleIsSame(getters.currentLocale.i18n)) {
      localStorage.setItem('locale', localeCode);
      localStorageCopy.locale = localeCode;
    }
  },
  reFetchLocaleSensitiveAppData({ dispatch, rootGetters }) {
    return Promise.all([
      dispatch('glossary/reFetchPresentData', null, { root: true }),
      rootGetters['insurance/coverages'].length
        ? dispatch('insurance/fetchCoverages', null, { root: true })
        : null,
    ]);
  },
  saveCurrentLocaleToUser({ getters, dispatch }, saveTimezone = false) {
    if (getters.currentLocale instanceof Object && getters.currentLocale.i18n) {
      return dispatch('user/updateUserLocale', getters.currentLocale.i18n, {
        root: true,
      });
    }
    return Promise.reject(new Error('The currentLocale data is invalid'));
  },
  async setLocale({
    commit, dispatch, getters, rootGetters, state,
  }, localeCode) {
    if (typeof localeCode === 'string' && state.languages[localeCode]) {
      if (state.currentLocale !== localeCode) {
        commit('SET_CURRENT_LOCALE', localeCode);
        commit('SET_I18N_LOCALE', localeCode);
        dispatch('persistLocaleToLocalStorage', getters.currentLocale.i18n);
        if (rootGetters['user/authed'] && rootGetters['user/preferredLocale'] !== getters.currentLocale.i18n) {
          await dispatch('saveCurrentLocaleToUser');
        }
      } else if (!localStorageCopy.checkLocaleIsSame(getters.currentLocale.i18n)) {
        // persist localStorage in case it's the same as localeCode
        // but is not present in localStorage
        dispatch('persistLocaleToLocalStorage', getters.currentLocale.i18n);
      }
      // set moment.js locale as current locale
      dispatch('syncMomentLocale', getters.currentLocale.moment);
      // set selected locale i18n key to ServiceProvider
      // to fetch data from the server with a specified locale
      dispatch('syncProviderRequestLocale', getters.currentLocale.i18n);
      dispatch('reFetchLocaleSensitiveAppData');
    } else {
      throw new Error('The provided localeCode is not valid or absent');
    }
  },
  setLocaleFromUser({ dispatch, getters, rootGetters }) {
    if (rootGetters['user/authed']) {
      const targetLocale = findLocaleByi18nKey(getters.availableLocales, rootGetters['user/preferredLocale']);
      if (targetLocale instanceof Object && targetLocale.value) {
        dispatch('setLocale', targetLocale.value);
      }
    }
  },
  setLocaleFromLocalStorage({ dispatch, getters }) {
    const targetLocale = findLocaleByi18nKey(getters.availableLocales, localStorageCopy.locale);
    if (targetLocale instanceof Object && targetLocale.value) {
      dispatch('setLocale', targetLocale.value);
    }
  },
  setLocaleFromNavigator({ dispatch }) {
    if (navigator instanceof Object) {
      const localeCode = convertNavigatorLanguageToLocaleCode(navigator.language);
      if (localeCode) {
        dispatch('setLocale', localeCode);
      }
    }
  },
  syncMomentLocale(context, momentLocaleKey) {
    moment.locale(momentLocaleKey);
  },
  syncProviderRequestLocale(context, i18nKey) {
    ServiceProvider.setRequestLocale(i18nKey);
  },
};
