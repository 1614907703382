import flatten from 'lodash/flatten';
import Vue from 'vue';
import Meta from 'vue-meta';
import Router from 'vue-router';

import authCheck from './helpers/authCheck';

// Routes
import auth from './auth';
import pub from './pub';
import soa from './soa';

Vue.use(Router);
Vue.use(Meta);

const routes = flatten([
  auth,
  pub,
  soa,
]);

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
});

router.beforeEach(authCheck);
export default router;
