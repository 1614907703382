import { ORG_APPLICATION_STEP_NAMES, ORG_APPLICATION_SUB_STEP_NAMES } from '@/store/modules/orgApplication/constants';
import { COVERAGE_TYPE_ALIASES } from '@/utils/constants';
import { inputRules } from '@/utils/helpers';

export default (state, getters, rootState, rootGetters) => Object.entries(getters.orgApplicationSteps).reduce((acc, stepEntry) => {
  if (stepEntry[0] === ORG_APPLICATION_STEP_NAMES.BASIC) {
    acc[stepEntry[0]] = stepEntry[1].reduce((subAcc, subStep) => {
      subAcc[subStep] = false;
      if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.BASIC.ORG_SPORTS) {
        subAcc[subStep] = !!getters.selectedSports.length;
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.BASIC.ORG_PERSONNEL) {
        subAcc[subStep] = (getters.orgPersonnelForm.athletes !== ''
          && getters.orgPersonnelForm.coaches !== ''
          && getters.orgPersonnelForm.volunteers !== ''
          && getters.orgPersonnelForm.officials !== '');
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.BASIC.ORG_AGE_GROUPS) {
        subAcc[subStep] = getters.athletesAgeBySportsValid;
      }
      return subAcc;
    }, {});
  } else if (stepEntry[0] === ORG_APPLICATION_STEP_NAMES.QUOTE) {
    acc[stepEntry[0]] = stepEntry[1].reduce((subAcc, subStep) => {
      subAcc[subStep] = false;
      if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREFERRED_COVERAGE) {
        subAcc[subStep] = !!(getters.chosenCoverage instanceof Object
          && typeof getters.chosenCoverage.id === 'string'
          && getters.chosenCoverage.id.length);
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREFERRED_COVERAGE_SETTINGS) {
        const selectedCoverageParams = state.coverageParamsMap[getters.chosenCoverage.type];
        subAcc[subStep] = Boolean(selectedCoverageParams
          && selectedCoverageParams instanceof Object
          && !Number.isNaN(selectedCoverageParams.selectedLimit));
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PRELIMINARY_QUOTES) {
        subAcc[subStep] = true;
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREFERRED_COVERAGE_DATES) {
        const selectedCoverageParams = state.coverageParamsMap[getters.chosenCoverage.type];
        subAcc[subStep] = Boolean(selectedCoverageParams
          && selectedCoverageParams instanceof Object
          && typeof selectedCoverageParams.startDate === 'string'
          && typeof selectedCoverageParams.startDate.length);
      // } else if (subStep == ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREVIOUS_CLAIMS) {
      //   subAcc[subStep] = !!(!getters.claimsData.hadClaims || getters.claimsData.claims.length) || getters.chosenCoverage.type == COVERAGE_TYPE_ALIASES.generalLiabilityAndParticipantLiability;
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_CURRENT_COVERAGE) {
        subAcc[subStep] = !!(state.havePolicy && Object.keys(state.externalCoverages).length) || !state.havePolicy;
      }
      return subAcc;
    }, {});
  } else if (stepEntry[0] === ORG_APPLICATION_STEP_NAMES.PERSONALIZE) {
    acc[stepEntry[0]] = stepEntry[1].reduce((subAcc, subStep) => {
      subAcc[subStep] = false;
      if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_REGISTRATION_PROVIDER) {
        let stepValid = false;
        if (getters.selectedRegProvider && getters.selectedRegProvider.id === 'other') {
          stepValid = getters.selectedRegProvider.name && inputRules.providerName[0](getters.selectedRegProvider.name) === true;
        } else {
          stepValid = !!(getters.selectedRegProvider && getters.selectedRegProvider.name);
        }
        subAcc[subStep] = stepValid;
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_BACKGROUND_CHECK) {
        if (getters.bgCheckStep == 2) {
          if (getters.bgCheckProvider && getters.bgCheckProvider instanceof Object) {
            subAcc[subStep] = (getters.bgCheckProvider.id !== 'other') || (getters.bgCheckProvider.name && (inputRules.providerName[0](getters.bgCheckProvider.name) === true));
          }
        } else {
          subAcc[subStep] = true;
        }
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_PROTOCOL_TEMPLATES) {
        subAcc[subStep] = getters.protocolsFormValid;
      } else if (rootGetters['orgApplication/chainedQuestionsSteps'].includes(subStep)) {
        subAcc[subStep] = getters.chainableSubStepsQuestionChainsValidityMap[subStep];
      } else if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_MONTHS_IN_SEASON) {
        subAcc[subStep] = true;
      }
      return subAcc;
    }, {});
  } else if (stepEntry[0] === ORG_APPLICATION_STEP_NAMES.POLICY) {
    acc[stepEntry[0]] = stepEntry[1].reduce((subAcc, subStep) => {
      subAcc[subStep] = false;
      if (subStep === ORG_APPLICATION_SUB_STEP_NAMES.POLICY.POLICY_CHOICE) {
        subAcc[subStep] = getters.hasNoQuoteOffers || getters.hasPendingQuoteOffers || rootGetters['shoppingCart/shoppingCartItems'].some((itm) => state.organizationToComplete && itm && itm.organization && itm.organization.id === state.organizationToComplete.id);
      }
      return subAcc;
    }, {});
  } else if (stepEntry[0] === ORG_APPLICATION_STEP_NAMES.APPLY) {
    acc[stepEntry[0]] = stepEntry[1].reduce((subAcc, subStep) => {
      subAcc[subStep] = true;
      return subAcc;
    }, {});
  }
  return acc;
}, {});
