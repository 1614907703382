import cloneDeep from 'lodash/cloneDeep';
import { TOURNAMENT_ORG_TYPE_ID } from '@/utils/constants';

// IMPORTANT: questions with dependencies must have a default value (like 'false') since they may appear
// conditionally in cases when neighboring questions in the chain are already filled out,
// and thus cause issues with sub-step validity checking

const CHAINED_QUESTIONS_FOR_CAMPS = {
  generalCommonQuestions: {
    current: 0,
    i18nTitle: 'ui.general',
    questions: {
      isGuestParticipants: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.areYouHostingCampsForGuests',
        type: 'radio',
      },
      isOvernightPlanned: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.areOvernightCampsPlanned',
        type: 'radio',
      },
      isMinorsControlled: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.areAllMinorsUnderCustody',
        type: 'radio',
      },
      isAthletesPlayingFootballOlder: {
        value: false,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouHaveTackleFootballPlayers',
        type: 'radio',
        dependencies: [{
          getter: 'orgApplication/tackleFootballQuestionsNeeded',
          value: true,
        }],
      },
      isOvernightExposure: {
        value: false,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.isOvernightExposure',
        type: 'radio',
        dependencies: [{
          getter: 'orgApplication/organizationToCompleteType',
          value: TOURNAMENT_ORG_TYPE_ID,
        }],
      },
    },
  },
  riskManagementQuestions: {
    current: 0,
    i18nTitle: 'ui.riskManagement',
    questions: {
      isMaintainRecordSigned: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouMainatainRecordOfWaivers',
        type: 'radio',
      },
      isProcessCheckPlayingSurfaces: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.isProcessForSurfaceChecks',
        type: 'radio',
      },
      isNonSportRelatedActivities: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.areNonSportActivitiesPlanned',
        type: 'radio',
      },
      isHavingLifeguardsWhenRequired: {
        value: false,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.areLifeguardsPresentAccordingToRedCross',
        type: 'radio',
        dependencies: [{
          getter: 'orgApplication/swimmingQuestionsNeeded',
          value: true,
        }],
      },
    },
  },
  concussionProtocolQuestions: {
    current: 0,
    i18nTitle: 'ui.concussionProtocol',
    questions: {
      isConcussionManagementPolicy: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouHaveConcussionManagementPolicy',
        type: 'radio',
      },
      isDistributeWrittenPolicy: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouDistributeConcussionManagementPolicy',
        type: 'radio',
      },
      isTrainingConcussionRecognition: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouRequireConcussionRecognitionTraining',
        type: 'radio',
      },
    },
  },
  sexualAbuseLiabilityQuestions: {
    current: 0,
    i18nTitle: 'ui.sexualAbuseLiability',
    questions: {
      isAllegationSexualAbuse: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.everHadSexualAbuseIncidents',
        type: 'radio',
      },
      isInsuredCriminalInvestigations: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouRequireStaffBackgroundChecks',
        type: 'radio',
      },
      isWrittenStandardsSexualAbuse: {
        value: null,
        validation: 'boolean',
        i18nLabel: 'message.chainedQuestions.doYouHaveAbusePreventionStandards',
        type: 'radio',
      },
    },
  },
};

export default () => cloneDeep(CHAINED_QUESTIONS_FOR_CAMPS);
