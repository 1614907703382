import ServiceProvider from '../provider';
import QUERIES from './queries';
import MUTATIONS from './mutations';

class SearchProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  searchLocationQuery(query) {
    return this.query(this.queries.locations, { query });
  }
}

export default new SearchProvider(QUERIES, MUTATIONS);
