export default {
  accountHolderOptions: [
    {
      i18nKey: 'items.company',
      value: 'Company',
    }, {
      i18nKey: 'items.individual',
      value: 'Individual',
    }],
  completenessFilterOptions: [
    {
      i18nKey: 'items.allStatuses',
      value: 'all',
    },
    {
      i18nKey: 'items.completeStatus',
      value: 'complete',
    },
    {
      i18nKey: 'items.incompleteStatus',
      value: 'incomplete',
    },
    {
      i18nKey: 'items.archiveStatus',
      value: 'archived',
    },
  ],
  claimTypeOptions: [
    {
      i18nKey: 'items.allClaims',
      value: 'all',
    },
    {
      i18nKey: 'items.archivedClaims',
      value: 'archived',
    },
    {
      i18nKey: 'items.activeClaims',
      value: 'active',
    },
  ],
  fnolTypeOptions: [
    {
      i18nKey: 'items.allFnols',
      value: 'all',
    },
    {
      i18nKey: 'items.archivedFnols',
      value: 'archived',
    },
    {
      i18nKey: 'items.activeFnols',
      value: 'active',
    },
  ],
  paymentCycleOptions: [
    {
      i18nKey: 'items.annually',
      value: 'annually',
    },
    {
      i18nKey: 'items.monthly',
      value: 'monthly',
    },
  ],
};
