<template>
  <div :class="b({ hasHeader, shield: true })">
    <div :class="b('titleWrapper')">
      <h3 :class="b('title')">
      <span v-if="mobile" :class="b('decorationShapeWrapper')">
        <img src="@/assets/icons/confetti.svg" alt="Confetti icon" :class="b('decorationShape')">
      </span>
      <slot name="title">
        <span :class="b('titleContent')">{{ title }}</span>
      </slot>
      <span v-if="mobile" :class="b('decorationShapeWrapper', { right: true })">
        <img src="@/assets/icons/confetti--right.svg" alt="Confetti icon" :class="b('decorationShape')">
      </span>
      </h3>
    </div>
    <div v-if="subtitle || $slots.subtitle" :class="b('subtitleWrapper')">
      <slot name="subtitle">
        <h4 :class="b('subtitle')">
          <span v-if="!mobile" :class="b('decorationShapeWrapper')">
            <img src="@/assets/icons/confetti.svg" alt="Confetti icon" :class="b('decorationShape')">
          </span>
          <span :class="b('subtitleContent')">{{ subtitle }}</span>
          <span v-if="!mobile" :class="b('decorationShapeWrapper', { right: true })">
            <img src="@/assets/icons/confetti--right.svg" alt="Confetti icon" :class="b('decorationShape')">
          </span>
        </h4>
      </slot>
    </div>
    <div v-if="bottomDecoration" :class="b('bottomDecoration')"></div>
  </div>
</template>

<script>
export default {
  name: 'Congrats',
  block: 'congrats',
  props: {
    mobile: Boolean,
    subtitle: String,
    title: String,
    bottomDecoration: Boolean,
    hasHeader: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$block: 'congrats';

.#{$block} {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: contain;
    background-position: center;
  &--hasHeader {
    height: calc(100vh - #{$header-height});
    padding-bottom: $header-height;
  }
  &--shield {
    background-image: url('~@/assets/images/shield-left-bg.svg');
    background-position: bottom;
  }
  &__decorationShapeWrapper {
    display: block;
    width: 60px;
    height: 61px;
    margin-right: 26px;
    &--right {
      margin-right: 0px;
      margin-left: 21px;
      width: 74px;
    }
    @include mappy-bp(md) {
      margin-right: 48px;
      width: 83px;
      height: 82px;
      &--right {
        width: 100px;
        margin-left: 33px;
        margin-right: 0px;
      }
    }
  }
  &__decorationShape {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: $diff-black;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.39px;
    line-height: 1.6666;
    margin-bottom: 0px;
    @include mappy-bp(md) {
      font-size: 2.875rem;
      font-weight: 300;
    }
  }
  &__titleContent,
  &__subtitleContent {
    display: block;
  }
  &__subtitleWrapper {
    margin-top: 24px;
  }
  &__subtitle {
    font-size: 1rem;
    font-weight: 500;
    color: #25D690;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0px;
  }
  &__bottomDecoration {
    margin: 22px auto 0px auto;
    height: 26px;
    width: 100%;
    max-width: 250px;
    background: url('~@/assets/icons/confetti--horizontal.svg') 0 0 / contain no-repeat;
    @include mappy-bp(md) {
      margin-top: 33px;
      height: 36px;
      max-width: 346px;
    }
  }
}
</style>
