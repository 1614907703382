import { FILTER_DATA } from '@/store/modules/submission/constants';

export default {
  RESET_DATA(context) {
    context.filters = { ...FILTER_DATA };
    context.submissions = [];
  },
  SET_SUBMISSIONS(context, submissions) {
    context.submissions = submissions;
  },
  REMOVE_QUOTE_OFFER_FROM_SUBMISSION(context, offerId) {
    if (typeof offerId === 'string' && offerId.length) {
      let offerInSubmissionIndex = -1;
      const submissionWithOfferIndex = context.submissions.findIndex((sub) => {
        if (sub && sub instanceof Object && sub.offers instanceof Array && sub.offers.length) {
          let offerInCurrentSubIndex = sub.offers.findIndex((off) => off.id === offerId);
          if (offerInCurrentSubIndex !== offerInSubmissionIndex) {
            offerInSubmissionIndex = offerInCurrentSubIndex;
          }
          return offerInCurrentSubIndex !== -1;
        }
        return false;
      });
      if (submissionWithOfferIndex !== -1 && offerInSubmissionIndex !== -1) {
        const submissionToAmend = context.submissions[submissionWithOfferIndex];
        submissionToAmend.offers.splice(offerInSubmissionIndex, 1);
      }
    }
  },
};
