export default (questions, questionKey) => {
  if (questions && questions instanceof Object && typeof questionKey === 'string' && questionKey.length) {
    let questionMatch = Object.values(questions).find((queVal) => Boolean(queVal
      && queVal instanceof Object
      && queVal.questions
      && queVal.questions instanceof Object
      && queVal.questions[questionKey]
      && queVal.questions[questionKey] instanceof Object));
    if (questionMatch && questionMatch instanceof Object) {
      return questionMatch.questions[questionKey];
    }
  }
  return null;
};
