import compact from 'lodash/compact';
import store from '@/store/index';
import { fbqInstance } from '@/plugins/analytics';

const FBPixelHandler = ({ name, path, params }) => {
  if (fbqInstance) fbqInstance.event('PageView', { name, path, params });
};

const fetchUserOnRouteChange = (() => {
  let userFetched = false;
  return () => new Promise(async (resolve) => {
    if (!userFetched) {
      await store.dispatch('user/fetchUser').then((response) => {
        if (response.data.data && response.data.data.me) {
          userFetched = true;
        }
      }).catch(() => {
        store.dispatch('user/logout');
      });
    }
    resolve(store.getters['user/authed']);
  });
})();

const redirectToLogin = (to, from, next) => {
  next({
    name: 'auth.login',
    query: {
      redirect: to.fullPath,
    },
  });
};

const redirectToLogout = (to, from, next) => {
  next({
    name: 'auth.logout',
  });
};

const redirectToRoot = (to, from, next) => {
  next(store.getters['user/homeRoute']);
};

const setAppLocale = () => {
  store.dispatch('locale/checkAndSetLocale');
};

export default async (to, from, next) => {
  FBPixelHandler(to);
  const tokenPresent = !!(store.getters['user/accessToken'] || localStorage.getItem('auth_token'));
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUnauth = to.matched.some((record) => record.meta.requiresAuth === false);
  const requiresSkipUserFetch = to.matched.some((record) => record.meta.skipUserFetch);
  // skip all the subsequent checkups if the route is non auth and requires to skip user fetching
  if (tokenPresent && requiresUnauth && requiresSkipUserFetch) {
    next();
    return;
  }

  // fetch user if token is present in order to determine further redirects
  const authed = tokenPresent && !store.getters['user/authed'] ? await fetchUserOnRouteChange() : store.getters['user/authed'];
  // set app locale either from user or localStorage (the action will check user authorization itself)
  setAppLocale();

  // after async user fetching (or not fetching) - proceed to determine further redirects
  if (requiresAuth) {
    if (authed) {
      // check if authed user can access the 'to' route by role
      const requiredRoles = compact(to.matched.map((record) => record.meta.requiresRole));
      const requiresRole = requiredRoles.length > 0;
      if (requiresRole) {
        const authedByRole = requiredRoles && to.matched.some(() => requiredRoles.includes(store.state.user.role));
        if (!authedByRole) {
          // logout the user if his role doesn't belong to this route
          redirectToLogout(to, from, next);
        }
      }
    } else {
      // redirect to login if requiresAuth & didn't manage to authorize the user
      redirectToLogin(to, from, next);
    }
  } else if (requiresUnauth) {
    if (authed) {
      redirectToRoot(to, from, next);
    }
  }
  next();
};
