export default (query, variables) => {
  const formData = new FormData();
  if (typeof query === 'string' && query.length && variables && variables instanceof Object) {
    const clearedVariables = {};
    formData.append('query', query);
    Object.entries(variables).forEach((varEntry) => {
      if (varEntry[1] instanceof File) {
        formData.append(varEntry[0], varEntry[1]);
      } else {
        clearedVariables[varEntry[0]] = varEntry[1];
      }
    });
    if (Object.keys(clearedVariables).length) {
      formData.append('variables', JSON.stringify(clearedVariables));
    }
  }
  return formData;
};
