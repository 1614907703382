import moment from '@/plugins/moment';
import { FNOLProvider } from '@/graphql/providers';

const FILTER_GLOSSARY = {
  name: 'name',
  organizationName: 'organizationName',
  injuryType: 'injuryType',
  status: 'status',
  policyNumber: 'policyNumber',
};

const SORTING_GLOSSARY = {
  organizationName: 'organization.name',
};

const state = {
  fnols: [],
  pagination: {
    currentPage: 1,
    nextPage: null,
    totalPages: 1,
  },
  filters: {},
  sorting: {
    field: '',
    order: '',
  },
  selectedFnol: {},
};

const mutations = {
  ARCHIVE_FNOLS(context, archivedFnolIds) {
    const archivedFnols = context.fnols.filter((clm) => archivedFnolIds.includes(clm.id));
    archivedFnols.map((clm) => ({ ...clm, isArchived: true }));
  },
  ESCALATE_FNOLS(context, escalatedFnolsIds) {
    context.fnols = context.fnols.filter((el) => !escalatedFnolsIds.includes(el.id));
  },
  UPDATE_FNOL(context, updatedFnol) {
    const fnolToUpdateIndex = context.fnols.findIndex((clm) => clm.id === updatedFnol.id);
    if (fnolToUpdateIndex) {
      context.fnols.splice(fnolToUpdateIndex, 1, updatedFnol);
    }
  },
  SET_FNOL_PAGINATION(context, pagination) {
    context.pagination = pagination;
  },
  UPDATE_FNOL_PAGINATION(context, pagination) {
    context.pagination = Object.assign(context.pagination, pagination);
  },
  SET_FNOL_FILTERS(context, filters) {
    context.filters = filters;
  },
  UPDATE_FNOL_FILTERS(context, filters) {
    context.filters = { ...context.filters, ...filters };
  },
  UPDATE_FNOL_SORTING(context, sorting) {
    context.sorting = Object.assign(context.sorting, sorting);
  },
  UPDATE_FNOLS(context, fnols) {
    context.fnols = fnols;
  },
  PUSH_ONE_TO_FNOLS(context, fnol) {
    context.fnols.push(fnol);
  },
  PUSH_BATCH_TO_FNOLS(context, fnolsBatch) {
    if (fnolsBatch instanceof Array) {
      context.fnols = [...context.fnols, ...fnolsBatch];
    }
  },
  UNSHIFT_FNOL(context, fnol) {
    context.fnols.unshift(fnol);
  },
};

const actions = {
  archiveFnols(context, payload) {
    return new Promise((resolve, reject) => {
      FNOLProvider.updateFnolsMutation(payload.selectedIds, {
        isArchived: true,
        archivingReason: context.archivingReason,
      }).then((response) => {
        if (response.data.data.claims && response.data.data.claims.code === 200) {
          context.commit('ARCHIVE_FNOLS', payload.selectedIds);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  escalateFnols(context, payload) {
    return new Promise((resolve, reject) => {
      FNOLProvider.updateFnolsMutation(payload.selectedIds, {
        isFNOL: false,
      }).then((response) => {
        if (response.data.data.claims && response.data.data.claims.code === 200) {
          context.commit('ESCALATE_FNOLS', payload.selectedIds);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  exportFilteredFnols(context) {
    return new Promise((resolve, reject) => {
      FNOLProvider.exportFilteredFnolsMutation(context.state.filters)
        .then((response) => {
          if (response.data && response.data.data && response.data.data.export) {
            resolve(response.data.data.export);
          } else {
            resolve(response);
          }
        }).catch(reject);
    });
  },
  exportSelectedFnols(context, ids) {
    return new Promise((resolve, reject) => {
      if (ids instanceof Array && ids.length) {
        FNOLProvider.exportFnolsMutation(ids).then((response) => {
          if (response.data && response.data.data && response.data.data.export) {
            resolve(response.data.data.export);
          } else {
            resolve(response);
          }
        }).catch(reject);
      } else {
        reject(new Error('There were no FNOL ids provided into the exportSelectedFnols action'));
      }
    });
  },
  clearFnols(context) {
    context.commit('UPDATE_FNOLS', []);
  },
  createFnol(context, fnol) {
    return FNOLProvider.createFnolMutation({ ...fnol, isFNOL: true });
  },
  fetchSingleFnol(context, id) {
    return FNOLProvider.getFnolQuery(id);
  },
  editFnol(context, payload) {
    return new Promise((resolve, reject) => {
      FNOLProvider.updateFnolsMutation(payload.claimId, payload.claim)
        .then((response) => {
          if (response.data.data.claim && response.data.data.claims.code === 200) {
            context.commit('UPDATE_FNOL', payload.claim);
          }
          resolve(response);
        }).catch(reject);
    });
  },
  fetchFnol(context, id) {
    return FNOLProvider.getFnolQuery(id);
  },
  fetchFnols(context, update = true) {
    return new Promise((resolve, reject) => {
      let filterObj = {
        ...context.state.filters,
        pageNumber: context.state.pagination.nextPage || 1,
        sortField: context.getters.requestReadyColumnSorting.field,
        sortOrder: context.state.sorting.order === 'asc' ? 1 : -1,
        isFNOL: true,
      };
      FNOLProvider.fnolsQuery({
        filters: filterObj,
      }).then((response) => {
        if (update) {
          context.dispatch('updateFnols', response);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchNextFnolsByPagination(context) {
    return new Promise((resolve, reject) => {
      context.dispatch('fetchFnols', false).then((response) => {
        context.dispatch('pushToFnols', response);
        resolve(response);
      }).catch(reject);
    });
  },
  filterFnolsByField(context, config) {
    return new Promise((resolve, reject) => {
      context.dispatch('resetPagination');
      let headerName = FILTER_GLOSSARY[config.column] || config.column;
      if (config.value) {
        context.commit('UPDATE_FNOL_FILTERS', { [headerName]: config.value });
      } else {
        const { [headerName]: oldFilter, ...newFilters } = context.state.filters;
        context.commit('SET_FNOL_FILTERS', newFilters);
      }
      context.dispatch('fetchFnols')
        .then(resolve)
        .catch(reject);
    });
  },
  filterFnolsByArchivedValue(context, type) {
    return new Promise((resolve, reject) => {
      if (type === 'active') {
        context.commit('UPDATE_FNOL_FILTERS', { isArchived: false });
      } else if (type === 'archived') {
        context.commit('UPDATE_FNOL_FILTERS', { isArchived: true });
      } else if (type === 'all') {
        const { isArchived: oldFilter, ...newFilters } = context.state.filters;
        context.commit('SET_FNOL_FILTERS', newFilters);
      }
      context.dispatch('resetPagination');
      context.dispatch('fetchFnols')
        .then(resolve)
        .catch(reject);
    });
  },
  filterFnolsByDate(context, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && typeof payload.createdAtFrom === 'string') {
        if (payload.createdAtFrom) {
          context.commit('UPDATE_FNOL_FILTERS', { createdAtFrom: payload.createdAtFrom });
        } else {
          const {
            createdAtFrom: oldCreatedAtFromFilter,
            ...newFilters
          } = context.state.filters;
          context.commit('SET_FNOL_FILTERS', newFilters);
        }
      } else if (payload && payload instanceof Object && typeof payload.createdAtTo === 'string') {
        if (payload.createdAtTo) {
          context.commit('UPDATE_FNOL_FILTERS', { createdAtTo: payload.createdAtTo });
        } else {
          const {
            createdAtTo: oldCreatedAtToFilter,
            ...newFilters
          } = context.state.filters;
          context.commit('SET_FNOL_FILTERS', newFilters);
        }
      } else if (!payload || !(payload instanceof Object) || !Object.keys(payload).length) {
        const {
          createdAtFrom: oldCreatedAtFromFilter,
          createdAtTo: oldCreatedAtToFilter,
          ...newFilters
        } = context.state.filters;
        context.commit('SET_FNOL_FILTERS', newFilters);
      }
      context.dispatch('resetPagination');
      context.dispatch('fetchFnols')
        .then(resolve)
        .catch(reject);
    });
  },
  resetPagination(context) {
    context.commit('SET_FNOL_PAGINATION', {
      currentPage: 1,
      nextPage: null,
      totalPages: 1,
    });
  },
  sortFnolsByField(context, config) {
    return new Promise((resolve, reject) => {
      context.dispatch('resetPagination');
      if (config instanceof Object && Object.keys(config).length) {
        context.commit('UPDATE_FNOL_SORTING', config);
        context.dispatch('fetchFnols')
          .then(resolve)
          .catch(reject);
      }
    });
  },
  updateFnols(context, response) {
    if (response.data && response.data.data && response.data.data.claims && response.data.data.claims.edges instanceof Array) {
      context.commit('UPDATE_FNOLS', response.data.data.claims.edges);
      context.commit('UPDATE_FNOL_PAGINATION', response.data.data.claims.pagination);
    }
  },
  pushToFnols(context, response) {
    if (response.data.data.claims && response.data.data.claims.edges) {
      context.commit('PUSH_BATCH_TO_FNOLS', response.data.data.claims.edges);
      context.commit('UPDATE_FNOL_PAGINATION', response.data.data.claims.pagination);
    }
  },
  pushFnol(context, fnol) {
    context.commit('UNSHIFT_FNOL', fnol);
  },
};

const getters = {
  fnols: (context) => context.fnols,
  fnolFilters: (context) => context.filters,
  fnolPagination: (context) => context.pagination,
  fnolSorting: (context) => context.sorting,
  formattedFnols: (context) => context.fnols.map((fnol) => {
    const fnolObj = {
      ...fnol,
      createdAt: moment(fnol.createdAt).format('YYYY-MM-DD'),
      organizationName: fnol.organization ? fnol.organization.name : '',
    };
    return fnolObj;
  }),
  requestReadyColumnSorting: (context) => {
    if (SORTING_GLOSSARY[context.sorting.field]) {
      return {
        ...context.sorting,
        field: SORTING_GLOSSARY[context.sorting.field],
      };
    }
    return { ...context.sorting };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
