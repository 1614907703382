/* eslint-disable */
import i18n from '@/i18n';

const cityReg = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
// const entityNameReg = /^([0-9]+|[a-zA-Z])+[0-9]?[^#&<>\/\\~;$^%{}?]{1,255}[^\s]$/;
const emailReg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const streetReg = /^[\w(\s)?]{3,30}/;
// zipReg explaned: 1st group - Canadian Postal codes, 2nd group - US Zip codes
const zipReg = /(^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$)|(^\d{5}(?:[-\s]\d{4})?$)/;
const phoneReg = /^(?=.*[0-9+])[ +0-9\-]+$/;
const passwordReg = /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>\?,\.\/])(?=.{8,})/;
const realisticNumberReg = /^[0-9]{1,10}$/;
const moneyReg = /^[0-9]+(\.[0-9]{0,2})?$/;
// this credit card regexp passed test with all main types of credit cards generated on http://www.getcreditcardnumbers.com/
const creditCardReg = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
const creditExpDateReg = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/;
const creditCvvReg = /^[0-9]{3,4}$/;
const letterReg = /^[a-zA-Z]*$/;
const siteUrlReg= /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
const whiteSpaceReg = /\s+/;

const city = [
  v => v ? (cityReg.test(v) || i18n.t('validation.cityBeAValid')) : true,
];

const email = [
  v => v ? (emailReg.test(v) || i18n.t('validation.mustBeValidEmail')) : true,
];

const password = [
  v => v ? (whiteSpaceReg.test(v) ? i18n.t('validation.passwordMustNotContainSpaces') : (passwordReg.test(v) || i18n.t('validation.passwordRules'))) : true,
];

const phone = [
  v => v ? ((phoneReg.test(v) && v.split(' ').join('').split('+').join('').length <= 13) || i18n.t('validation.phoneMustHaveFormat')) : true
];

const firstName = [
  v => v ? (v.length >= 2 || i18n.t('validation.mustContainAtLeast', {
  name: 'Name',
  num: 2
})) : true,
  v => v ? (v.length <= 256 || i18n.t('validation.mustBeLess', {
  name: 'Name',
  num: 256
})) : true,
];

const lastName = [
  v => v ? (v.length >= 2 || i18n.t('validation.mustContainAtLeast', {
  name: 'Name',
  num: 2
})) : true,
  v => v ? (v.length <= 256 || i18n.t('validation.mustBeLess', {
  name: 'Name',
  num: 256
})) : true,
];

const name = [
  v => v ? (v.length >= 2 || i18n.t('validation.mustContainAtLeast', {
    name: 'Name',
    num: 2
  })) : true,
  v => v ? (v.length <= 256 || i18n.t('validation.mustBeLess', {
    name: 'Name',
    num: 256
  })) : true,
];

const providerName = [
  v => v ? (!!v && v.length >= 2 || i18n.t('validation.mustContainAtLeast', {
    name: 'Provider',
    num: 2
  })) : true,
  v => v ? (!!v && v.length <= 256 || i18n.t('validation.mustBeLess', {
    name: 'provider',
    num: 256
  })) : true,
];

const role = [
  v => v ? (v.length >= 2 || i18n.t('validation.mustContainAtLeast', {
    name: 'Role',
    num: 2
  })
  ) : true,
  v => v ? (v.length <= 256 || i18n.t('validation.mustBeLess', {
    name: 'Role',
    num: 256
  })) : true,
];

const street = [
  v => v ? (streetReg.test(v) || i18n.t('validation.mustBeCharAndLonger', {
    name: 'Street',
    char: 3
  })) : true,
];

const zip = [
  v => v ? (zipReg.test(v) || i18n.t('validation.mustBeValidZipOrPostalCode')) : true,
];

const required = [
  v => !!v || i18n.t('validation.fieldRequired'),
];

const requiredString = [
  v => (!!v && !!v.trim().length) || i18n.t('validation.fieldRequired'),
];

const requiredNumeric = [
  v => !isNaN(parseInt(v)) || i18n.t('validation.fieldRequired'),
];

const requiredBoolRadio = [
  v => typeof v === 'boolean' || i18n.t('validation.fieldRequired'),
];

const realisticNumber = [
  v => v ? (realisticNumberReg.test(v) || i18n.t('validation.realisticNumber')) : true,
];

const positiveNumber = [
  (v) => {
    if (v !== undefined) {
      if (typeof v === 'number') {
        return v > 0;
      } else if (typeof v === 'string') {
        try {
          const parsedV = Number.parseFloat(v, 10);
          if (!Number.isNaN(parsedV)) {
            return parsedV >= 0;
          }
        } catch (err) {
          console.error(err);
        }
      }
      return false
    }
    return true;
  },
];

const numberGreaterThanZero = [
  (v) => {
    if (v !== undefined) {
      if (typeof v === 'number') {
        return v > 0;
      } else if (typeof v === 'string') {
        try {
          const parsedV = Number.parseFloat(v, 10);
          if (!Number.isNaN(parsedV)) {
            return parsedV > 0;
          }
        } catch (err) {
          console.error(err);
        }
      }
      return false
    }
    return true;
  },
];

const moneyValue = [
  v => {
    if(v != null) {
      v = v.toString();
      if(v.includes('.,') || v.includes('.,')) {
        return i18n.t('validation.mustHaveMoneyFormat');
      }
      if(v.split(',').filter(el => !el.length).length > 1
      || v.split('.').filter(el => !el.length).length > 1) {
        return i18n.t('validation.mustHaveMoneyFormat');
      }
      if(v) v = v.replace(/,/g, '');
    }
    return v ? ((moneyReg.test(v) && v.split('.').length < 3) || i18n.t('validation.mustHaveMoneyFormat')) : true
  },
];

const creditCard = [
  v => v ? (creditCardReg.test(v) || i18n.t('validation.slugIsNotValid', {
    slug: 'Credit card number',
  })) : true,
];

const creditExpDate = [
  v => v ? (creditExpDateReg.test(v) || i18n.t('validation.slugIsNotValid', {
    slug: 'Expiration date',
  })) : true,
];

const creditCvv = [
  v => v ? (creditCvvReg.test(v) || i18n.t('validation.slugIsNotValid', {
    slug: 'CVV code',
  })) : true,
];

const letters = [
  v => v ? (letterReg.test(v) || i18n.t('validation.fieldNotValid')) : true,
];
const siteUrl = [
  v => v ? (siteUrlReg.test(v) || i18n.t('validation.fieldNotValid')) : true,
];

const onlyInteger = [
  v => v ? Number.isInteger(Number(v)) || i18n.t('validation.integerOnly') : true
];

export default {
  zip,
  role,
  city,
  email,
  phone,
  street,
  siteUrl,
  letters,
  password,
  lastName,
  name,
  positiveNumber,
  required,
  requiredNumeric,
  requiredBoolRadio,
  creditCvv,
  firstName,
  creditCard,
  providerName,
  creditExpDate,
  realisticNumber,
  moneyValue,
  numberGreaterThanZero,
  onlyInteger,
  requiredString
};

export { phoneReg as phoneReg }



