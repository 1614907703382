export default {
  createOrganization: `mutation CreateOrg ($input: OrganizationInput) {
                    organization (input: $input) {
                      id,
                      name,
                      placeId,
                      icon,
                      type,
                      sports {
                        sport {
                          id,
                          name,
                          value
                        },
                        ageGroup1,
                        ageGroup2,
                        ageGroup3,
                        ageGroup4,
                        monthsInSeason
                      },
                      backgroundCheck,
                      backgroundProvider,
                      otherBackgroundProvider,
                      provider,
                      otherProvider,
                      address {
                        address,
                        address2,
                        city,
                        state,
                        country,
                        zip
                      },
                      mailing {
                        address,
                        address2,
                        city,
                        state,
                        country,
                        zip
                      },
                      personnel {
                        numAthletes,
                        numCoaches,
                        numVolunteers,
                        numOfficials
                      },
                      users {
                        id,
                        name,
                        phone,
                        email,
                        isEmailConfirmed,
                        soaRole
                      },
                      requests {
                        id,
                        user {
                          id,
                          name,
                          phone,
                          email,
                          isEmailConfirmed,
                          soaRole
                        },
                        organization {
                          id,
                        }
                      },
                      invitations {
                        id,
                        name,
                        email,
                        isFinished,
                        organization {
                          id,
                        }
                      },
                      externalCoverages {
                        carrier {
                          icon,
                          id,
                          name
                        },
                        coverageType,
                        otherCarrier,
                        premium,
                        paymentCycle,
                        renewalDate,
                      },
                      previousClaims {
                        amountPaid,
                        carrier {
                          icon,
                          id,
                          name
                        },
                        policyStartDate,
                        policyEndDate,
                        totalPremium,
                        numberOfClaims,
                      },
                      phone,
                      site,
                      email,
                      injuryProtocol,
                      concussionProtocol,
                      waiverProtocol,
                      isCompleted,
                      isQuoteRequested
                    }
                 }`,
  preUpdateOrganization: `mutation ($userId:ID!, $input: OrganizationInput!, $organizationId: ID!) {
                        updateObject(userId: $userId, input: $input, organizationId: $organizationId) {
                          code,
                          message
                        }
  }`,
  updateOrganization: `mutation ($updateId:ID!, $input:OrganizationInput) {
                    organization(updateId:$updateId, input:$input) {
                      id,
                      name,
                      placeId,
                      icon,
                      type,
                      sports {
                        sport {
                          id,
                          name,
                          value
                        },
                        ageGroup1,
                        ageGroup2,
                        ageGroup3,
                        ageGroup4,
                        monthsInSeason
                      },
                      params,
                      backgroundCheck,
                      backgroundProvider,
                      otherBackgroundProvider,
                      provider,
                      otherProvider,
                      address {
                        address,
                        address2,
                        city,
                        state,
                        country,
                        zip
                      },
                      mailing {
                        address,
                        address2,
                        city,
                        state,
                        country,
                        zip
                      },
                      personnel {
                        numAthletes,
                        numCoaches,
                        numVolunteers,
                        numOfficials
                      },
                      users {
                        id,
                        name,
                        phone,
                        email,
                        isEmailConfirmed,
                        soaRole
                      },
                      requests {
                        id,
                        user {
                          id,
                          name,
                          phone,
                          email,
                          isEmailConfirmed,
                          soaRole
                        },
                        organization {
                          id,
                        }
                      },
                      invitations {
                        id,
                        name,
                        email,
                        isFinished,
                        organization {
                          id,
                        }
                      },
                      externalCoverages {
                        carrier {
                          icon,
                          id,
                          name
                        },
                        coverageType,
                        otherCarrier,
                        premium,
                        paymentCycle,
                        renewalDate,
                      },
                      previousClaims {
                        amountPaid,
                        carrier {
                          icon,
                          id,
                          name
                        },
                        policyStartDate,
                        policyEndDate,
                        totalPremium,
                        numberOfClaims,
                      },
                      phone,
                      site,
                      email,
                      injuryProtocol,
                      concussionProtocol,
                      waiverProtocol,
                      isCompleted,
                      isQuoteRequested
                    }
                 }`,
  // serves to check if Organization updates will cause
  // its existing submissions for quote to close and get re-generated
  regenerateQuotes: `mutation CheckOrgUpdatesWillAffectQuotes($input: OrganizationInput!, $organizationId: ID!) {
                      regenerateQuotes(input: $input, organizationId: $organizationId) {
                        code,
                        message,
                      }
                    }`,
};
