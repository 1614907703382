export default (error, callbackMap) => {
  const callbackMapKeys = (callbackMap
    && callbackMap instanceof Object && Object.keys(callbackMap)) || [];
  if (callbackMapKeys.length) {
    let specificCallbackExecuted = false;
    if (callbackMapKeys.filter((key) => key !== 'all').length > 0
      && error
      && error instanceof Object
      && error.graphQLErrors instanceof Array && callbackMap) {
      error.graphQLErrors.forEach((err) => {
        if (err.code && typeof callbackMap[err.code] === 'function') {
          specificCallbackExecuted = true;
          callbackMap[err.code](err);
        }
      });
      if (!specificCallbackExecuted) {
        callbackMap.all(error);
      }
    } else if (typeof callbackMap.all === 'function') {
      callbackMap.all(error);
    }
  }
  return error;
};
