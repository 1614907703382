<template>
  <v-container
    :class="b('item', {toast: isToast})"
    @click="$emit('click', toast)"
  >
    <v-row no-gutters>
      <v-col cols="2">
        <div :class="b('itemDisplay', { [toast.type || TOAST_TYPES.INFO]: true })">
          <div :class="b('ItemDisplayIconWrapper')">
            <img
              v-if="toast.icon && !isToast"
              :class="b('ItemDisplayIcon')"
              :src="toast.icon"
              alt="Info icon"
            />
            <img
              v-else-if="toast.type === TOAST_TYPES.SUCCESS"
              :class="b('ItemDisplayIcon')"
              src="~@/assets/icons/check-circle--black.svg"
              alt="Success icon"
            />
            <img
              v-else-if="toast.type === TOAST_TYPES.WARNING"
              :class="b('ItemDisplayIcon')"
              src="~@/assets/icons/exclamation-circle--black.svg"
              alt="Warning icon"
            />
            <img
              v-else-if="toast.type === TOAST_TYPES.DANGER"
              :class="b('ItemDisplayIcon')"
              src="~@/assets/icons/warning-triangle--black.svg"
              alt="Danger icon"
            />
            <img
              v-else
              :class="b('ItemDisplayIcon')"
              src="~@/assets/icons/info-circle--black.svg"
              alt="Info icon"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="10" :class="b('itemContentWrapper pa-2')">
        <v-row :class="b('itemHeaderWrapper')" no-gutters>
          <v-col cols="10">
            <div :class="b('itemTitle')">
              {{toast.type}}
            </div>
          </v-col>
          <v-col cols="2" :class="b('itemCloseContainer')">
            <button
              v-if="toast.dismissible && isToast"
              :class="b('itemCloseButton')"
              @click.stop="$emit('delete', toast.id)"
            />
            <div
              v-else
              :class="b('itemDate')"
            >
              {{formatDate(toast.createdAt)}}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div :class="b('itemContent')" v-html="toast.content"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { TOAST_TYPES } from '@/utils/constants';

export default {
  name: 'Notification',
  block: 'notification',
  props: {
    toast: {
      type: Object,
      required: true,
    },
    isToast: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    TOAST_TYPES,
  }),
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('L');
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'notification';

.#{$block} {
  &__item {
    background-color: $ph-grey-fa;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    transition: all .5s;
    display: flex;
    cursor: pointer;
    &--toast {
      max-width: calc(100vw - 20px - 20px);
      margin: 5px 0;
      @include mappy-bp(sm) {
        max-width: 370px;
      }
    }
  }
  &__itemDisplay {
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-top: 8px;
    // &--info {
    //   background: linear-gradient(45deg, #11A4D5, #2AFFDA);
    // }
    // &--danger {
    //   background: linear-gradient(45deg, #DF3C32, #FFA95A);
    // }
    // &--warning {
    //   background: linear-gradient(45deg, #F6E94C, #F9BA71);
    // }
    &--success,
    &--danger,
    &--warning,
    &--info {
      background: linear-gradient(89.96deg, #CCBCFA 0.05%, #CDD2FB 41.25%, #C7E2F7 68.8%, #BBF1EF 101.79%);
      .#{$block}__item:hover & {
        background: linear-gradient(89.96deg, #844DDC 0.05%, #594FF0 26.94%, #549FD5 69.33%, #74D2C0 101.79%);
        .#{$block}__ItemDisplayIcon {
          filter: invert(100%) sepia(2%) saturate(4448%) hue-rotate(231deg) brightness(120%) contrast(99%);
        }
      }
    }
    @include mappy-bp(md) {
      margin-left: 4px;
    }
  }
  &__ItemDisplayIconWrapper {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: $ph-dark;
  }
  &__ItemDisplayIcon {
    height: 100%;
  }
  &__itemTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
  }
  &__itemDate {
    font-size: 12px;
    line-height: 24px;
    color: $ph-grey-dark;
  }
  &__itemContentWrapper {
    max-width: 330px;
    padding: 20px;
  }
  &__itemContent {
    font-size: 14px;
    line-height: 20px;
    color: $ph-dark;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 40px;
  }
  &__itemCloseContainer {
    display: flex;
    justify-content: end;
  }
  &__itemCloseButton {
    width: 17px;
    height: 17px;
    padding: 1px;
    background: url('~@/assets/icons/cross.svg') 1px 1px/16px 16px no-repeat;
    &:active,
    &:focus {
      outline: none;
    }
  }
}
</style>
