const base = {
  primary: '#5b4ff9',
  secondary: '#11284F',
  accent: '#5b4ff9',
  error: '#FF5252',
  lime: '#93EF66',
  lime_green: '#4ADB62',
  dark_blue: '#0C1B2D',
  red: '#FF5252',
  pink: '#DF426A',
  glue_blue: '#50E3C2',
};

export default { base };
