export default {
  questions: `query getQuestions($organizationId: ID!) {
            questions(organizationId: $organizationId) 
         }`,
  search: `query getOrganizations($name: String!) {
            organizations(name: $name) {
              id,
              creator {
                id,
              },
              placeId,
              name,
              icon,
              address {
                address,
                address2,
                city,
                state,
                country,
                zip
              },
              sport,
              sports {
                sport {
                  id,
                  name,
                  value,
                  values
                },
                ageGroup1,
                ageGroup2,
                ageGroup3,
                ageGroup4,
                monthsInSeason,
              },
              provider,
              otherProvider,
              personnel {
                numAthletes,
                numCoaches,
                numVolunteers,
                numOfficials,
              },
              injuryProtocol,
              waiverProtocol,
              concussionProtocol,
              backgroundProvider,
              backgroundCheck,
              otherBackgroundProvider,
              phone,
              site,
              email,
              type,
              isCompleted
            }
         }`,
  organizations: `{
      organizations {
        id,
        name,
        address {
          address,
          address2,
          city,
          state,
          country,
          zip
        },
        site,
        email,
        isCompleted,
        quoteOffersTotal,
      }
   }`,
  organization: `query getOrganization($placeId:String) {
                  organization(placeId:$placeId) {
                    id,
                    placeId,
                    name,
                    site,
                    phone,
                    address {
                      address,
                      address2,
                      city,
                      state,
                      country,
                      zip
                    },
                    params,
                    sports {
                      ageGroup1,
                      ageGroup2,
                      ageGroup3,
                      ageGroup4,
                      monthsInSeason,
                    },
                    type,
                    previousClaims {
                      amountPaid,
                      carrier {
                        id,
                        name,
                      },
                      policyStartDate,
                      policyEndDate,
                      totalPremium,
                      numberOfClaims,
                    },
                    site,
                    email,
                    isCompleted,
                    quoteOffersTotal,
                  }
                }`,
};
