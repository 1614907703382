/* eslint no-param-reassign: 0 */
import { isValidString } from '@/utils/helpers/string';

export default (error) => {
  if (error instanceof Object) {
    if (error.graphQLErrors instanceof Array) {
      return error.graphQLErrors.reduce((acc, err) => {
        if (err.message instanceof Object) {
          const { message } = err;
          if (isValidString(message.message)) {
            acc += `${err.message.message} \n`;
          } else if (isValidString(message.error)) {
            acc += `${err.message.error} \n`;
          }
        } else if (isValidString(err.message)) {
          acc += `${err.message} \n`;
        }
        return acc;
      }, '');
    } if (error.response instanceof Object
      && error.response.data instanceof Object
      && error.response.data.errors instanceof Array) {
      return error.response.data.errors.reduce((acc, err) => {
        if (isValidString(err.message)) {
          const errorGroups = err.message.split(';');
          if (errorGroups.length > 0 && isValidString(errorGroups[errorGroups.length - 1])) {
            acc += `${errorGroups[errorGroups.length - 1]} \n`;
          } else {
            acc += `${err.message} \n`;
          }
        }
        return acc;
      }, '');
    }
  }
  return error.message;
};
