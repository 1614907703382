import moment from 'moment';

export default (({ subStep, rootGetters }) => {
  switch (subStep) {
    case 'orgPreferredCoverage': {
      const chosenCoverage = rootGetters['orgApplication/chosenCoverage'];
      return {
        event: 'checkoutForm',
        step: subStep,
        coverageName: chosenCoverage.name,
      };
    }
    case 'orgPreferredCoverageSettings': {
      const chosenCoverage = rootGetters['orgApplication/chosenCoverage'];
      let coveragesAcc = {};
      if (chosenCoverage.carrierParams) {
        chosenCoverage.carrierParams.map((obj) => {
          if (obj.segments) {
            obj.segments.map((coverage) => {
              if (coverage.limit) {
                coveragesAcc = {
                  ...coveragesAcc,
                  [coverage.name]: coverage.limit,
                };
              }
              if (coverage.limits) {
                Object.entries(coverage.limits).map(({ 0: key, 1: value }) => {
                  coveragesAcc = {
                    ...coveragesAcc,
                    [`${coverage.name} | ${key}`]: value,
                  };
                  return true;
                });
              }
              return true;
            });
          }
          return true;
        });
      }
      if (chosenCoverage.type == 'participantAccident') {
        coveragesAcc = {
          ...coveragesAcc,
          [chosenCoverage.name]: chosenCoverage.selectedLimit,
        };
      }
      const coverages = {
        ...coveragesAcc,
        ...(() => chosenCoverage.subcoverages.reduce((acc, val) => {
          if (val.chosen) {
            acc[val.name] = val.selectedLimit;
          }
          return acc;
        }, {})
        )(),
      };
      return {
        event: 'checkoutForm',
        step: subStep,
        coverages,
      };
    }
    case 'orgPreferredCoverageDates': {
      const chosenCoverage = rootGetters['orgApplication/chosenCoverage'];
      return {
        event: 'checkoutForm',
        step: subStep,
        startDate: moment(chosenCoverage.startDate).format('MM-DD-YYYY'),
      };
    }
    case 'orgPreviousClaims': {
      const { hadClaims, claims } = rootGetters['orgApplication/claimsData'];
      const carriersMap = rootGetters['glossary/carriersMap'];
      return {
        event: 'checkoutForm',
        step: subStep,
        hadClaims: hadClaims ? 'Yes' : 'No',
        claims: claims.map((el) => `${carriersMap[el.carrier].name}, ${moment(el.policyStartDate).format('MM-DD-YYYY')} to ${moment(el.policyEndDate).format('MM-DD-YYYY')}, paid ${el.amountPaid}$, num of claims ${el.numberOfClaims}, premium ${el.totalPremium}$`).join(' | '),
      };
    }
    case 'orgCurrentCoverage': {
      const havePolicy = rootGetters['orgApplication/havePolicy'];
      const currentCoverage = rootGetters['orgApplication/externalCoveragesForm'];
      const carriersMap = rootGetters['glossary/carriersMap'];
      const coveragesMap = rootGetters['insurance/coveragesMapByType'];

      return {
        event: 'checkoutForm',
        step: subStep,
        havePolicy: havePolicy ? 'Yes' : 'No',
        currentCoverages: havePolicy ? Object.entries(currentCoverage)
          .map(({ 0: key, 1: value }) => `${coveragesMap[key].name}, ${carriersMap[value.carrier].name}, premium ${value.premium}$, ${value.renewalDate || 'No date'}`).join(' | ') : '',
      };
    }
    case 'concussionProtocolQuestions':
    case 'sexualAbuseLiabilityQuestions':
    case 'riskManagementQuestions':
    case 'generalCommonQuestions': {
      const chainedSubSteps = rootGetters['orgApplication/chainableSubStepsQuestionChains'];
      return {
        event: 'checkoutForm',
        step: subStep,
        ...(() => chainedSubSteps[subStep].reduce((acc, question) => {
          if (question.valid) {
            // TBD: helper for parsing question by type
            if (question.type == 'radio') {
              acc[question.field] = `${question.value ? 'Yes' : 'No'}`;
            }
          }
          return acc;
        }, {}))(),
      };
    }
    case 'orgProtocolTemplates': {
      return {
        event: 'checkoutForm',
        step: subStep,
        injuryManagementProtocol: rootGetters['orgApplication/injuryProtocol'] ? 'Yes' : 'No',
        concussionProtocol: rootGetters['orgApplication/concussionProtocol'] ? 'Yes' : 'No',
        signWaivers: rootGetters['orgApplication/waiverProtocol'] ? 'Yes' : 'No',
      };
    }
    case 'policyChoice': {
      return {
        event: 'checkoutForm',
        step: 'Quote',
      };
    }
    case 'coverageSummary': {
      return {
        event: 'checkoutForm',
        step: 'Submit for Quote',
      };
    }
    case 'orgType': {
      const { value } = rootGetters['glossary/organizationTypeMap'][rootGetters['signUp/selectedOrgType']];
      return {
        event: 'signupForm', step: '1. Organization Type', organizationType: value,
      };
    }
    case 'orgChoice': {
      const { name } = rootGetters['signUp/selectedOrg'];
      return { event: 'signupForm', step: '2. Organization Info', organizationName: name };
    }
    case 'orgSports': {
      const values = rootGetters['signUp/selectedSports'].map((el) => rootGetters['glossary/sportsMap'][el].value).join(' | ');
      return { event: 'signupForm', step: '3. Sports Offered', sportsOffered: values };
    }
    case 'orgMonthsInSeason': {
      const values = Object.entries(rootGetters['signUp/athletesAgeBySports']).map(({ 0: key, 1: value }) => `${rootGetters['glossary/sportsMap'][key].value} - ${value.monthsInSeason}`).join(' | ');
      return { event: 'signupForm', step: '4. Months in Season', seasonMonths: values };
    }
    case 'orgAgeGroups': {
      const ag1 = Object.entries(rootGetters['signUp/athletesAgeBySports']).map(({ 0: key, 1: value }) => `${rootGetters['glossary/sportsMap'][key].value} - ${value.ageGroup1}`).join(' | ');
      const ag2 = Object.entries(rootGetters['signUp/athletesAgeBySports']).map(({ 0: key, 1: value }) => `${rootGetters['glossary/sportsMap'][key].value} - ${value.ageGroup2}`).join(' | ');
      const ag3 = Object.entries(rootGetters['signUp/athletesAgeBySports']).map(({ 0: key, 1: value }) => `${rootGetters['glossary/sportsMap'][key].value} - ${value.ageGroup3}`).join(' | ');
      return {
        event: 'signupForm', step: '5. Athletes per Age Group', '12 and below': ag1, '13-15': ag2, '16-18': ag3,
      };
    }
    case 'orgPersonnel': {
      const { athletes, coaches, officials } = rootGetters['signUp/orgPersonnelForm'];
      return {
        event: 'signupForm', step: '6. Organization Members', athletes, coaches, trainersManagers: officials,
      };
    }
    case 'userInfo': {
      return { event: 'signupForm', step: '7. Contact Info Successful Submit' };
    }
    default:
      return null;
  }
});
