import { GAIG_MIN_ATHLETES_PER_STATE_MAP } from '@/utils/constants';

export default function (getOrgFn) {
  return function ({
    state,
    getters,
    rootGetters,
    dispatch,
  }) {
    // User for GAIG for Florida
    const org = getOrgFn({ state, getters });
    const stateId = org && org.address && org.address.state;

    if (!stateId) {
      return;
    }

    const stateMap = rootGetters['glossary/statesMap'];
    if (!stateMap) {
      return;
    }

    const orgState = stateMap[stateId];

    if (!orgState) {
      return;
    }

    const minNumberOfAthletes = GAIG_MIN_ATHLETES_PER_STATE_MAP[orgState.values.code];

    if (!minNumberOfAthletes) {
      return;
    }

    const totalNumberOfAthletes = getters.totalNumberOfAthletes;

    if (totalNumberOfAthletes < minNumberOfAthletes) {
      const diff = minNumberOfAthletes - totalNumberOfAthletes;
      const field = 'ageGroup1';
      const sport = getters.selectedSports[0];
      const currentValue = getters.athletesAgeBySports[sport][field]
        ? parseInt(getters.athletesAgeBySports[sport][field], 10)
        : 0;
      const value = currentValue + diff;

      dispatch('updateAthletesAgeField', { value, field, sport });
    }
  };
}
