export default (steps, subStepName) => {
  const stepValues = steps && steps instanceof Object ? Object.values(steps) : [];
  return stepValues.reduce((acc, step, i) => {
    if (step instanceof Array && step.length) {
      let subStepIndex = step.indexOf(subStepName);
      if (subStepIndex !== -1) {
        acc.step = i;
        acc.subStep = subStepIndex;
      }
    }
    return acc;
  }, {
    step: -1,
    subStep: -1,
  });
};
