import { InsuranceProvider } from '@/graphql/providers';
import getSubmissionMutationPayload from '@/utils/api/parsers/out/getSubmissionMutationPayload';

const checkSubmissionsQueryResponseValid = (res) => Boolean(res
  && res instanceof Object
  && res.data
  && res.data instanceof Object
  && res.data.data
  && res.data.data.submissions instanceof Array);

const checkSubmissionMutationResponseValid = (res) => Boolean(res
  && res instanceof Object
  && res.data
  && res.data instanceof Object
  && res.data.data
  && res.data.data.submission
  && res.data.data.submission instanceof Object);

const checkOfferCancellationMutationResponseValid = (res) => Boolean(res
  && res instanceof Object
  && res.data
  && res.data instanceof Object
  && res.data.data
  && res.data.data.quoteOfferCancel
  && res.data.data.quoteOfferCancel instanceof Object
  && res.data.data.quoteOfferCancel.code === 200);

const checkOfferRejectionMutationResponseValid = (res) => Boolean(res
  && res instanceof Object
  && res.data
  && res.data instanceof Object
  && res.data.data
  && res.data.data.quoteOfferReject
  && res.data.data.quoteOfferReject instanceof Object
  && res.data.data.quoteOfferReject.code === 200);

export default {
  resetData({ commit }) {
    commit('RESET_DATA');
  },
  setSelectedSubmissionQuoteLimit({ commit }, limit) {
    commit('SET_SELECTED_LIMIT', limit);
  },
  fetchSubmissions({ commit, state }, filters = {}) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.submissionsQuery(filters)
        .then((response) => {
          if (checkSubmissionsQueryResponseValid(response)) {
            const validSubmissions = response.data.data.submissions.filter((sub) => sub.coverage instanceof Object);
            commit('SET_SUBMISSIONS', validSubmissions);
          }
          resolve(response);
        })
        .catch(reject);
    });
  },
  createSubmission({ commit }, input) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.submissionMutation(input)
        .then((response) => {
          if (checkSubmissionMutationResponseValid(response)) {
            commit('SET_SUBMISSIONS', [response.data.data.submission]);
          } else {
            throw new Error('Invalid submission creation response');
          }
          resolve(response);
        }).catch(reject);
    });
  },
  rejectQuoteOffer({ commit }, { id, reason, remove = false }) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.rejectQuoteOfferMutation({
        id,
        reason,
      }).then((response) => {
        if (remove && checkOfferRejectionMutationResponseValid(response)) {
          commit('REMOVE_QUOTE_OFFER_FROM_SUBMISSION', id);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  cancelQuoteOffer({ commit }, { id, reason, remove = false }) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.cancelQuoteOfferMutation({
        id,
        reason,
      }).then((response) => {
        if (remove && checkOfferCancellationMutationResponseValid(response)) {
          commit('REMOVE_QUOTE_OFFER_FROM_SUBMISSION', id);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  async fetchSubmissionForOrgByCoverage({ dispatch, state }, {
    organizationId, coverage, limit, startDate, endDate, subCoverages,
  }) {
    if (!organizationId || typeof organizationId !== 'string') {
      throw new Error('organizationId filter is absent or invalid');
    }
    if (!coverage || typeof coverage !== 'string') {
      throw new Error('coverage filter is absent or invalid');
    }
    try {
      await dispatch('createSubmission', getSubmissionMutationPayload(organizationId, coverage, limit, subCoverages, startDate, endDate));
    } catch (err) {
      return Promise.reject(err);
    }
    return state.submissions;
  },
  resetSubmissionsData({ commit }) {
    commit('RESET_DATA');
  },
};
