import moment from '@/plugins/moment';
import { getMomentPolicyYear } from '@/utils/helpers';
import { checkOrgTypeHasEventDates } from '@/store/modules/orgApplication/helpers';
import {
  API_DATE_FORMAT, DEFAULT_COVERAGE_DURATION_YEARS, CAMP_ORG_TYPE_ID, TOURNAMENT_ORG_TYPE_ID,
} from '@/utils/constants';

const checkOrgParamsValid = (params) => params instanceof Object
  && typeof params.eventStartDate === 'string'
  && params.eventStartDate.length
  && typeof params.eventEndDate === 'string'
  && params.eventEndDate.length;

export default ({ type, params }) => {
  if (checkOrgTypeHasEventDates(type)
    && checkOrgParamsValid(params)) {
    return {
      startDate: params.eventStartDate,
      endDate: params.eventEndDate,
    };
  }
  const startDate = moment().add(1, 'days').format(API_DATE_FORMAT);
  const endDate = getMomentPolicyYear(startDate, DEFAULT_COVERAGE_DURATION_YEARS).format(API_DATE_FORMAT);
  return {
    startDate,
    endDate,
  };
};
