import analytics from './modules/analytics';
import proof from './modules/proof';
import captcha from './modules/captcha';
import claim from './modules/claim';
import dialog from './modules/dialog';
import fnol from './modules/fnol';
import insurance from './modules/insurance';
import layout from './modules/layout';
import locale from './modules/locale';
import glossary from './modules/glossary';
import user from './modules/user/index';
import organization from './modules/organization/index';
import orgApplication from './modules/orgApplication/index';
import shoppingCart from './modules/shoppingCart/index';
import signUp from './modules/signUp/index';
import socket from './modules/socket';
import stripe from './modules/stripe/index';
import submission from './modules/submission/index';
import policy from './modules/policy';
import notification from './modules/notification';

const getters = {
  route: (context) => context.route,
  routeRequiresAuth: (context) => Boolean(context.route && context.route.meta.requiresAuth) || false,
  routeRequiresMenuBar: (context) => Boolean(context.route && context.route.meta.requiresMenuBar) || false,
};

const state = {
  modules: {
    analytics,
    proof,
    captcha,
    claim,
    dialog,
    fnol,
    insurance,
    layout,
    locale,
    glossary,
    user,
    organization,
    orgApplication,
    shoppingCart,
    signUp,
    socket,
    stripe,
    submission,
    notification,
    policy,
  },
  getters,
};

export default state;
