export default (config = {}) => {
  const {
    autoInit = true,
    scrollableElement = '.scroll-container',
    containedElement,
    errorSelector = '.input-group--error',
    scrollDebounceMs = 200,
  } = config;
  const EASING = 'smooth';
  const elScrollTo = (yCoord, el, easing = EASING) => {
    if (yCoord >= 0 && yCoord <= el.scrollHeight) {
      el.scrollTo({
        top: yCoord,
        behavior: easing,
      });
    }
  };
  const isDOMElement = (obj) => {
    try {
      // Using W3 DOM2 (works for FF, Opera and Chrome)
      return obj instanceof HTMLElement;
    } catch (e) {
      // Browsers not supporting W3 DOM2 don't have HTMLElement and
      // an exception is thrown and we end up here. Testing some
      // properties that all elements have (works on IE7)
      return (typeof obj === 'object')
        && (obj.nodeType === 1) && (typeof obj.style === 'object')
        && (typeof obj.ownerDocument === 'object');
    }
  };
  const isVueRef = (obj) => obj instanceof Object && obj.$el instanceof Object && isDOMElement(obj.$el);
  return {
    data() {
      return {
        scrollUtils: {
          el: null,
          initialized: false,
        },
        scrolledToBottom: true,
        scrollHandlerTimeout: null,
      };
    },
    methods: {
      handleScroll() {
        if (this.scrollUtils.initialized) {
          const winScroll = this.scrollUtils.el.scrollTop;
          const height = this.scrollUtils.el.scrollHeight - this.scrollUtils.el.clientHeight;
          this.scrolledToBottom = (winScroll - height) >= 0;
        }
      },
      handleScrollDebounced() {
        clearTimeout(this.scrollHandlerTimeout);
        setTimeout(this.handleScroll(), this.scrollDebounceMs);
      },
      scrollToBottom() {
        if (this.scrollUtils.initialized) {
          const el = this.scrollUtils.el;
          el.scrollBy(0, el.scrollHeight);
        }
      },
      initScrollUtils() {
        this.initScrollUtilsWithArgs(scrollableElement, containedElement);
      },
      initScrollUtilsWithArgs(scrollableEl, childEl) {
        let el = null;
        if (typeof scrollableEl === 'string') {
          if (this.$refs[scrollableEl]) {
            if (isDOMElement(this.$refs[scrollableEl])) {
              el = this.$refs[scrollableEl];
            } else if (isVueRef(this.$refs[scrollableEl])) {
              el = this.$refs[scrollableEl].$el;
            }
          } else if (childEl) {
            if (isDOMElement(childEl)) {
              el = childEl.closest(scrollableEl);
            } else if (isVueRef(childEl)) {
              el = childEl.$el.closest(scrollableEl);
            } else if (typeof childEl === 'string' && childEl.length) {
              const child = this.$refs[childEl];
              if (isDOMElement(child)) {
                el = child.closest(scrollableEl);
              } else if (isVueRef(child)) {
                el = child.$el.closest(scrollableEl);
              }
            }
          } else {
            el = document.querySelector(scrollableEl);
          }
        } else if (isDOMElement(scrollableEl)) {
          el = scrollableEl;
        } else if (isVueRef(scrollableEl)) {
          el = scrollableEl.$el;
        } else {
          throw new Error('The required arguments are absent');
        }
        if (el) {
          this.scrollUtils.el = el;
          this.scrollUtils.el.addEventListener('scroll', this.handleScrollDebounced);
          this.handleScroll();
          this.scrollUtils.initialized = true;
        }
        return this.scrollUtils.initialized;
      },
      unInitScrollUtils() {
        if (this.scrollUtils.initialized) {
          this.scrollUtils.el.removeEventListener('scroll', this.handleScrollDebounced);
          this.scrollUtils.el = null;
          this.scrollUtils.initialized = false;
        }
      },
      scrollToFirstError() {
        if (this.scrollUtils.initialized && typeof errorSelector === 'string' && errorSelector.length) {
          this.$nextTick(() => {
            const firstErrorInput = this.scrollUtils.el.querySelector(errorSelector);
            if (firstErrorInput) {
              const targetY = firstErrorInput.offsetTop;
              this.scrollTo(targetY);
            }
          });
        }
        return this.scrollUtils.initialized;
      },
      scrollToTop() {
        if (this.scrollUtils.initialized) {
          elScrollTo(0, this.scrollUtils.el);
        }
        return this.scrollUtils.initialized;
      },
      scrollTo(yCoord) {
        if (this.scrollUtils.initialized
          && typeof yCoord === 'number'
          && !Number.isNaN(yCoord)) {
          elScrollTo(yCoord, this.scrollUtils.el);
        }
        return this.scrollUtils.initialized;
      },
    },
    mounted() {
      if (autoInit) {
        this.initScrollUtils();
      }
    },
    updated() {
      this.handleScroll();
    },
    beforeDestroy() {
      this.unInitScrollUtils();
    },
  };
};
