import { ACH_SUPPORT, ACH_AVAILABLE_COUNTRIES, ALLOWED_ROLE } from '@/utils/constants';
import { ACH_VERIFICATION_ALLOWED_ATTEMPTS } from '@/store/modules/user/constants';
import { checkOrgHasAllPolicies, getPoliciesOfOrg } from '@/utils/helpers';
import { hasUsaOrgs, hasUnverifiedTemporaryAccounts, findTemporaryAchToApprove } from '@/store/modules/user/helpers';

export default {
  authed: (ctx, ctxGetters) => ctx.accessToken && ctx.role === ALLOWED_ROLE,
  accessToken: (ctx) => ctx.accessToken,
  userDebitCard: (ctx) => ctx.debitCard,
  userBankAccount: (ctx) => ctx.bankAccount,
  userOrganizations: (ctx, ctxGetters, rootState, rootGetters) => rootGetters['organization/organizations'].map((org) => ({
    ...org,
    hasAllPolicies: checkOrgHasAllPolicies(org, rootGetters['policy/policies'], rootGetters['insurance/coverages']),
    policies: getPoliciesOfOrg(org, rootGetters['policy/policies']),
  })),
  userOrganizationsOptions: (ctx, ctxGetters, rootState, rootGetters) => rootGetters['organization/organizations'].map((org) => ({ text: org.name, value: org.id })),
  userOrganizationsClaimsOptions: (ctx, ctxGetters, rootState, rootGetters) => rootGetters['organization/organizations'].map((org) => ({ text: org.name, value: org.name })),
  userPendingOrganizations: (ctx, ctxGetters, rootState, rootGetters) => rootGetters['organization/pendingOrganizations'].map((org) => { org.pending = true; return org; }),
  preferredLocale: (ctx) => ctx.locale,
  profile: (ctx) => ctx.profile,
  setNewIcon: (ctx) => ctx.setNewIcon,
  signature: (ctx) => (ctx.signature && ctx.signature instanceof Object && ctx.signature.value) || '',
  signatureValid: (ctx, ctxGetters) => !!ctxGetters.signature.length,
  userIsGuest: (ctx) => ctx.role === 'guest',
  userIsAdmin: (ctx) => ctx.role === 'admin',
  userIsSoa: (ctx) => ctx.role === 'customer',
  userCanUseACH: (ctx, ctxGetters, rootState, rootGetters) => ACH_SUPPORT
    && ((hasUsaOrgs(rootGetters['organization/organizations'])) || (ctx.locationCountryCode && ACH_AVAILABLE_COUNTRIES instanceof Array && ACH_AVAILABLE_COUNTRIES.includes(ctx.locationCountryCode))),
  showCardDetails: (ctx) => !!ctx.profile.stripeCardObject,
  showBankDetails: (ctx) => !!ctx.profile.stripeBankObject,
  parsedCardObject: (ctx) => ctx.profile.stripeCardObject,
  parsedBankObject: (ctx) => ctx.profile.stripeBankObject,
  permanentAchVerified: (ctx) => Boolean(ctx.profile.stripeBankObject && ctx.profile.stripeBankObject instanceof Object && Boolean(Object.values(ctx.profile.stripeBankObject).length) && ctx.profile.stripeBankObject.verified),
  homeRoute: (context, contextGetters, rootContext, rootGetters) => {
    const ctx = context;
    let route = {
      name: 'auth.login',
    };
    if (ctx.role === 'customer' || ctx.role === 'admin') {
      let itemsInCart = rootGetters['shoppingCart/shoppingCartItems'];
      if (itemsInCart && itemsInCart.length) {
        return {
          name: 'soa.checkout',
        };
      }
      return {
        name: 'soa.organizations',
      };
    }
    return route;
  },
  defaultPaymentMethod: (ctx) => ctx.profile.defaultPaymentMethod,
  showAchDialog: (ctx) => ctx.showAchDialog,
  achToVerify: (ctx) => {
    const source = ctx.shownAchDialogSource;
    if (ctx.profile.stripeBankObject && ctx.profile.stripeBankObject.source === source) {
      return ctx.profile.stripeBankObject;
    }
    const matchInTemporaryAccounts = (ctx.profile.stripeBankObjects instanceof Array && ctx.profile.stripeBankObjects.length && ctx.profile.stripeBankObjects.find((acc) => acc.source === source));
    return matchInTemporaryAccounts || {};
  },
  userHasUnverifiedTemporaryAch: (ctx) => hasUnverifiedTemporaryAccounts(ctx.profile.stripeBankObjects),
  userHasVerificationAttempts: (ctx) => (ctx.profile.stripeBankObject && ctx.profile.stripeBankObject instanceof Object && Boolean(Object.values(ctx.profile.stripeBankObject).length)) && ctx.profile.stripeBankObject.attempts > 0,
};
