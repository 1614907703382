import { SIGN_UP_STEPS } from '@/store/modules/signUp/constants';

export default (session) => {
  const signUpStepEntries = Object.entries(SIGN_UP_STEPS);
  if (session && session instanceof Object && Object.keys(session).length) {
    return Object.keys(session).reduce((acc, stepName) => {
      const stepNamePosition = signUpStepEntries.reduce((signUpStepsEntriesAcc, signUpStepEntry, signUpStepEntryIndex) => {
        if (signUpStepEntry[1] instanceof Array) {
          const targetStepName = stepName;
          const stepNameMatchIndex = signUpStepEntry[1].findIndex((ent) => ent === targetStepName);
          if (stepNameMatchIndex !== -1) {
            signUpStepsEntriesAcc.step = signUpStepEntryIndex + 1;
            signUpStepsEntriesAcc.subStep = stepNameMatchIndex + 1;
          }
        }
        return signUpStepsEntriesAcc;
      }, { step: -1, subStep: -1 });
      if (stepNamePosition.step >= acc.step || stepNamePosition.subStep > acc.subStep) {
        acc.step = stepNamePosition.step;
        acc.subStep = stepNamePosition.subStep;
      }
      return acc;
    }, { step: 1, subStep: 1 });
  }
  throw new Error('The provided session object was invalid or empty');
};
