import connectSocketClient from '@/plugins/socket';
import getNotificationMessage from '@/utils/helpers/getNotificationMessage';
import getNotificationClickHandler from '@/utils/helpers/getNotificationClickHandler';
import getNotificationIcon from '@/utils/helpers/getNotificationIcon';
import getToastColorByNotificationType from '@/utils/helpers/getToastColorByNotificationType';
import handleSocketDataUpdate from '@/utils/helpers/handleSocketDataUpdate';
import { TOAST_TIMEOUT_MS, TOAST_TYPES } from '@/utils/constants';

const state = {
  socketInstance: {},
  connected: false,
  firstConnection: null,
};

const mutations = {
  CLIENT_CONNECT(context, token) {
    context.socketInstance = connectSocketClient({
      auth_token: token,
      Cookie: '',
    });
    context.socketInstance.open();
  },
  CLIENT_DISCONNECT(context) {
    context.socketInstance.onclose = function () {};
    if (context.socketInstance.close) context.socketInstance.close();
  },
  CLIENT_UPDATE_CONNECTION_STATUS(context, status) {
    context.connected = status;
  },
  CLIENT_SET_FIRST_CONNECTION_TIME(context, time) {
    context.firstConnection = time;
  },
};

const actions = {
  openSocket(context, token) {
    if (!context.state.socketInstance.disconnected && context.state.socketInstance.connected) {
      context.commit('CLIENT_DISCONNECT');
    }
    return new Promise((resolve) => {
      context.commit('CLIENT_CONNECT', token);
      context.state.socketInstance.on('error', (error) => {});
      context.state.socketInstance.on('connect', () => {
        context.dispatch('socket_connect');
        resolve({
          connected: true,
          message: 'Socket connection established',
        });
      });
      context.state.socketInstance.onevent = (payload) => {
        if (payload.data[0] == 'message') {
          context.dispatch('socket_message', payload.data[1]);
        }
      };
    });
  },
  closeSocket(context) {
    context.commit('CLIENT_DISCONNECT');
  },
  updateNotificationsSeenStatus(context, ids) {
    if (context.state.socketInstance && context.state.socketInstance.emit) {
      context.state.socketInstance.emit('seen', {
        ids,
      });
    }
  },
  socket_connect(context) {
    context.commit('CLIENT_UPDATE_CONNECTION_STATUS', true);
    if (!context.state.firstConnection) {
      context.commit('CLIENT_SET_FIRST_CONNECTION_TIME', Date.now());
    }
  },
  socket_disconnect(context) {
    context.commit('CLIENT_UPDATE_CONNECTION_STATUS', false);
  },
  socket_message(context, payload) {
    handleSocketDataUpdate({ payload, ctx: context });
    const baseToastConfig = {
      systemId: payload.id,
      content: getNotificationMessage(payload, payload.type),
      clickHandler: getNotificationClickHandler(payload, payload.type),
      type: getToastColorByNotificationType(payload.type),
      systemType: payload.type,
      timeout: TOAST_TIMEOUT_MS || false,
      dismissible: true,
    };
    if (payload.type === 'RequestSOA') {
      context.dispatch('notification/createToast', {
        ...baseToastConfig,
        icon: getNotificationIcon(payload),
      }, { root: true });
      context.dispatch('user/fetchUser', null, { root: true });
    } else if (payload.type === 'Hello') {
      context.dispatch('notification/createToast', {
        clickHandler: baseToastConfig.clickHandler,
        systemId: payload.id,
        content: 'Hello',
        type: TOAST_TYPES.WARNING,
        timeout: false,
        dismissible: true,
      }, { root: true });
    } else if (payload.type == 'QuoteExpirationJust') {
      if (context.rootGetters['shoppingCart/shoppingCartItems'].some(({ quoteId }) => payload.quotes[0].id == quoteId || payload.quotes[0].id == quoteId)) {
        context.dispatch('shoppingCart/fetchShoppingCartItems', null, { root: true });
      }
      if (payload.quotes.map((quote) => context.rootGetters['submission/submissions'].some((el0) => el0.offers.some((el1) => el1.quotes.some((el2) => el2.id == quote.id || el2.id == quote._id)))).includes(true)) {
        context.dispatch('submission/fetchSubmissions', null, { root: true });
      }

      context.dispatch('notification/createToast', {
        ...baseToastConfig,
      }, { root: true });
    } else {
      context.dispatch('notification/createToast', {
        ...baseToastConfig,
      }, { root: true });
    }
    // side WS actions
    if (payload.type === 'PolicyPDF') {
      context.dispatch('policy/updatePolicyLinks', payload.policy, { root: true });
    }
    if (payload.type === 'ProofPDF') {
      context.dispatch('proof/updateProofLinks', payload.proof, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
