import map from 'lodash/map';
import claimAdd from './claimAdd';
import claimEdit from './claimEdit';
import claims from './claims';
import profile from './profile';
import proofOfInsurance from './proofOfInsurance';
import policies from './policies';
import orgApplication from './orgApplication';
import orgCreate from './orgCreate';
import orgEdit from './orgEdit';
import claimView from './claimView';
import organizations from './organizations';
import checkout from './checkout';
import fnols from './fnols';
import fnolAdd from './fnolAdd';
import fnolEdit from './fnolEdit';
import fnolView from './fnolView';
import quotes from './quotes';

const name = 'soa';

const children = map([
  claimAdd,
  claimEdit,
  claims,
  profile,
  proofOfInsurance,
  policies,
  orgApplication,
  orgCreate,
  orgEdit,
  claimView,
  organizations,
  checkout,
  fnols,
  fnolAdd,
  fnolEdit,
  fnolView,
  quotes,
], (c) => {
  const child = c;
  child.name = `${name}.${child.name}`;
  return child;
});

const routes = [
  {
    path: '/soa',
    component: () => import('@/pages/soa/Soa'),
    children,
    meta: {
      requiresAuth: true,
      requiresRole: 'customer',
    },
  },
];

export default routes;
