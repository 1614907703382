import i18n from '@/i18n';
import {
  DEFAULT_PERSONNEL_NUMBER,
  GRECAPTCHA_FRAUD_ERROR_MESSAGE,
  REFUSAL_TO_REQUEST_ACCESS_ERROR_MESSAGE,
  SUPPORT_EMAIL,
} from '@/utils/constants';
import {
  ATHLETES_AGE_OBJECT,
  ONBOARDING_SESSION_STEPS,
  ONBOARDING_SESSION_CLIENT_STATE_KEY,
  SIGN_UP_STEPS,
} from '@/store/modules/signUp/constants';
import {
  SessionProvider,
} from '@/graphql/providers';
import {
  formatPhone,
  getStepAndSubStepIndexesBySubStepName,
  retrieveGraphQLErrors,
  jsonClone,
  setMinimumNumberOfAthletesAction,
} from '@/utils/helpers';
import {
  checkOrgSelectionHasConflicts,
  getBiggestPassedStepAndSubStepOfSession,
  getEmptyOrganizationObject,
  getOrgChoicePayload,
  getOrgPersonnelInput,
  getOrgTypePayload,
  getPopulateSessionFromExistingOrgPayload,
  getSessionResolvePayload,
  getSportsPayload,
  getUserInfoPayload,
} from '@/store/modules/signUp/helpers';

const getDefaultSignUpSteps = () => jsonClone(SIGN_UP_STEPS);

export default {
  calculateAthletesFromAgeGroups({ state, getters, dispatch }) {
    return new Promise((resolve) => {
      const FIELD = 'athletes';
      const DEFAULT_VALUE = 0;
      const value = Object.values(getters.athletesAgeBySports).reduce((acc, item) => {
        let valuePerSport = 0;
        [item.ageGroup1, item.ageGroup2, item.ageGroup3, item.ageGroup4].forEach((num) => {
          valuePerSport += Number.parseInt(num, 10);
        });
        if (valuePerSport && !Number.isNaN(valuePerSport)) {
          acc += valuePerSport;
        }
        return acc;
      }, 0);
      dispatch('updateOrgPersonnelField', {
        value: !Number.isNaN(value) ? value : DEFAULT_VALUE,
        field: FIELD,
      });
      resolve(state.personnel[FIELD]);
    });
  },
  setMinimumNumberOfAthletes: setMinimumNumberOfAthletesAction(({ getters }) => getters.selectedOrg),
  checkOrgSelectionWillHaveConflictsWithTheSession(context, org) {
    return checkOrgSelectionHasConflicts(context, org);
  },
  checkSelectedOrgCanBeCreated({ getters, state }) {
    if (getters.selectedOrg && getters.selectedOrg instanceof Object) {
      return Boolean((getters.selectedOrg.name && typeof getters.selectedOrg.name === 'string')
        && (state.selectedOrgType && typeof state.selectedOrgType === 'string')
        && (getters.selectedOrg.address && getters.selectedOrg.address instanceof Object
          && getters.selectedOrg.address.address && typeof getters.selectedOrg.address.address === 'string'
          && getters.selectedOrg.address.city && typeof getters.selectedOrg.address.city === 'string'
          && getters.selectedOrg.address.state && typeof getters.selectedOrg.address.state === 'string'
          && getters.selectedOrg.address.zip && typeof getters.selectedOrg.address.zip === 'string'));
    }
    return false;
  },
  showCreateNewOrg(context, populateNameFromSearch = true) {
    if (populateNameFromSearch && typeof context.state.searchOrgQuery === 'string' && context.state.searchOrgQuery.length) {
      context.dispatch('updateCreateNewOrgName', context.state.searchOrgQuery);
    }
    context.commit('UPDATE_VISIBILITY_MAP', { createNewOrg: true });
  },
  hideCreateNewOrg(context) {
    context.commit('UPDATE_VISIBILITY_MAP', { createNewOrg: false });
  },
  async populateSessionFromOrganization({ dispatch, state, commit }, organization) {
    // this function will populate org-related signUp data from the organization object
    try {
      let payload = getPopulateSessionFromExistingOrgPayload(organization, {} || state.session);
      if (payload instanceof Object && Object.keys(payload).length) {
        const response = await dispatch('updateMutipleStepsInOnboardingSession', payload);
        if (response.data && response.data.data && response.data.data.session) {
          commit('SET_SESSION', response.data.data.session);
        }
        dispatch('populateDataFromOnboardingSession', state.session);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async selectOrganization(context, org) {
    if (org && org.phone) {
      Object.assign(org, {
        phone: formatPhone(org.phone),
      });
    }
    if (context.getters.selectedOrg) {
      const selectionWillHaveConflicts = await context.dispatch('checkOrgSelectionWillHaveConflictsWithTheSession', org);
      if (selectionWillHaveConflicts) {
        context.dispatch('resetOrgSelectionOnOrgChoice');
      }
    }
    context.commit('SET_SELECTED_ORG', org);
  },
  selectAndResetExistingOrganization(context, org) {
    const {
      name, address, email, site, phone, id,
    } = org;
    const resetOrg = {
      ...jsonClone(getEmptyOrganizationObject()),
      address: jsonClone(address),
      id,
      name,
      email,
      site,
      phone: phone ? formatPhone(phone) : '',
    };
    context.commit('SET_SELECTED_ORG', resetOrg);
  },
  resetOrgSelection(context) {
    context.commit('SET_SELECTED_ORG', null);
  },
  resetOrgSelectionOnCreationFormSubmission(context) {
    if (context.state.selectedOrg) {
      context.dispatch('resetOrgSelection');
    }
  },
  resetOrgSelectionOnOrgChoice(context) {
    context.dispatch('resetCreateNewOrg');
  },
  setSelectedOrgType(context, type) {
    return new Promise(async (resolve, reject) => {
      if (type) {
        context.commit('SET_SELECTED_ORG_TYPE', type);
        resolve(type);
      } else {
        reject(new Error('No organization type was provided'));
      }
    });
  },
  changeOrgParam(context, { payload, field }) {
    context.commit('UPDATE_ORG_PARAM', {
      payload,
      field,
    });
  },
  updateSearchOrgQuery(context, query) {
    context.commit('UPDATE_SEARCH_ORG_QUERY', query);
  },
  updateEventTime(context, { value, field }) {
    if (field && Object.keys(context.state.params).includes(field)) {
      context.commit('UPDATE_ORG_PARAM', { field, payload: value });
    }
  },
  setUserCreated(context, val) {
    context.commit('SET_USER_CREATED', !!val);
  },
  updateCreateNewOrgName(context, value) {
    context.commit('UPDATE_CREATE_NEW_ORG_NAME', value);
  },
  updateCreateNewOrgPhone(context, value) {
    context.commit('UPDATE_CREATE_NEW_ORG_PHONE', value);
  },
  updateCreateNewOrgSite(context, value) {
    context.commit('UPDATE_CREATE_NEW_ORG_SITE', value);
  },
  updateCreateNewOrgIcon(context, file) {
    context.commit('UPDATE_CREATE_NEW_ORG_ICON', file);
  },
  updateCreateNewOrgAddressField(context, data) {
    if (data.field && data.value && Object.keys(context.state.createNewOrgForm.address).includes(data.field)) {
      context.commit('UPDATE_CREATE_NEW_ORG_ADDRESS_FIELD', data);
    }
  },
  updateCreateNewOrgMailingAddressField(context, data) {
    if (data.field && data.value && Object.keys(context.state.createNewOrgForm.mailingAddress).includes(data.field)) {
      context.commit('UPDATE_CREATE_NEW_ORG_MAILING_ADDRESS_FIELD', data);
    }
  },
  updateCreateNewOrgNoSeparateMailingAddress(context, value) {
    context.commit('UPDATE_CREATE_NEW_ORG_NO_SEPARATE_MAILING_ADDRESS', value);
  },
  updateCreateNewOrgValid(context, value) {
    context.commit('UPDATE_CREATE_NEW_ORG_VALID', Boolean(value));
  },
  resetCreateNewOrg(context) {
    context.commit('FORCE_UPDATE_CREATE_NEW_ORG', {
      name: '',
      site: '',
      phone: '',
      email: '',
      icon: null,
      address: {
        address: '',
        city: null,
        state: null,
        country: null,
        zip: '',
      },
      mailingAddress: {
        address: '',
        city: null,
        state: null,
        country: null,
        zip: '',
      },
      noSeparateMailingAddress: true,
      valid: false,
      placeId: '',
      id: '',
      formatted_address: '',
    });
  },
  flushSignUpData(context, data) {
    context.commit('RESET_SIGNUP_DATA', data);
  },
  updateEnableOrgSearchBtn(context, value) {
    context.commit('UPDATE_ENABLE_ORG_SEARCH_BTN', value);
  },
  updateUserInfoField(context, data) {
    if (data.field && data.value !== undefined && Object.keys(context.state.userInfo).includes(data.field)) {
      context.commit('UPDATE_USER_INFO_FIELD', data);
    }
  },
  saveSignUpBrokerId(context, id) {
    context.commit('SAVE_SIGNUP_BROKER_ID', id);
  },
  async goForward({
    state, getters, commit, dispatch,
  }, forceSubStep) {
    let currStep = Object.keys(getters.signUpSteps)[state.step - 1];

    if (state.step <= getters.totalStepsNum) {
      try {
        await dispatch('updateOnboardingSessionBySubStep', getters.currentSubStep);
        if (forceSubStep) {
          dispatch('toSpecificSubStepByName', forceSubStep);
        } else if (state.subStep < getters.signUpSteps[currStep].length) {
          commit('INCREMENT_SUB_STEP');
        } else if (state.step < getters.totalStepsNum) {
          commit('RESET_SUB_STEP');
          commit('INCREMENT_STEP');
        }
        if (state.step > state.lastVisitedStep || (state.step === state.lastVisitedStep && state.subStep > state.lastVisitedSubStep)) {
          // setting last visited step to track how far the user managed to get
          dispatch('setLastVisitedStepAndSubStep', { step: state.step, subStep: state.subStep });
        }
      } catch (err) {
        if (err.message === REFUSAL_TO_REQUEST_ACCESS_ERROR_MESSAGE) {
          return Promise.resolve();
        }
        return Promise.reject(err);
      }
    }
    return Promise.resolve();
  },
  goBackward({ state, commit, dispatch }) {
    if (state.step > 1 && state.subStep === 1) {
      commit('DECREMENT_STEP');
      dispatch('goToLastSubStep');
    } else if (state.subStep !== 1) {
      commit('DECREMENT_SUB_STEP');
    }
  },
  goToLastSubStep({ state, getters, commit }) {
    const prevStep = Object.keys(getters.signUpSteps)[state.step - 1];
    const stepArr = getters.signUpSteps[prevStep];
    commit('FORCE_SUB_STEP', Object.keys(stepArr).length);
  },
  toPrevSubStep(context) {
    context.commit('DECREMENT_SUB_STEP');
  },
  toSpecificStep({
    state, getters, commit, dispatch,
  }, step) {
    if (step >= 1 && step <= getters.totalStepsNum) {
      const goingBackwards = step < state.step;
      commit('FORCE_STEP', step);
      let subStep = goingBackwards ? getters.currentStepLastCompleteSubStep : 1;
      if (state.step === state.lastVisitedStep) {
        subStep = state.lastVisitedSubStep;
      }
      commit('FORCE_SUB_STEP', subStep);
      if (state.step > state.lastVisitedStep || (state.step === state.lastVisitedStep && state.subStep > state.lastVisitedSubStep)) {
        // setting last visited step to track how far the user managed to get
        dispatch('setLastVisitedStepAndSubStep', { step: state.step, subStep: state.subStep });
      }
    }
  },
  toSpecificSubStep({
    commit, dispatch, getters, state,
  }, subStep) {
    if (subStep >= 1 && subStep <= getters.currentStepSubSteps.length) {
      commit('FORCE_SUB_STEP', subStep);
      if (state.subStep > state.lastVisitedSubStep) {
        dispatch('setLastVisitedSubStep', state.subStep);
      }
    }
  },
  toSpecificStepAndSubStep({
    commit, dispatch, getters, state,
  }, { step, subStep }) {
    if (step >= 1 && step <= getters.totalStepsNum) {
      commit('FORCE_STEP', step);
      const stepValues = Object.values(getters.signUpSteps);
      const stepMatch = stepValues[step - 1] instanceof Array
        ? stepValues[step - 1]
        : [];
      if (subStep <= stepMatch.length) {
        commit('FORCE_SUB_STEP', subStep);
      }
      if (state.step > state.lastVisitedStep || (state.step === state.lastVisitedStep && state.subStep > state.lastVisitedSubStep)) {
        // setting last visited step to track how far the user managed to get
        dispatch('setLastVisitedStepAndSubStep', { step: state.step, subStep: state.subStep });
      }
    }
  },
  toSpecificSubStepByName({ getters, dispatch }, subStepName) {
    if (typeof subStepName === 'string' && subStepName.length) {
      const stepValues = Object.values(getters.signUpSteps);
      const subStepMatchIndexes = getStepAndSubStepIndexesBySubStepName(getters.signUpSteps, subStepName);
      if (subStepMatchIndexes.step !== -1 && subStepMatchIndexes.subStep !== -1) {
        const stepMatch = stepValues[subStepMatchIndexes.step] instanceof Array
          ? stepValues[subStepMatchIndexes.step]
          : [];
        const nextStep = subStepMatchIndexes.step + 1;
        const nextSubStep = subStepMatchIndexes.subStep + 1;
        // check if step is valid and if needs to be updated
        if (nextStep <= getters.totalStepsNum) {
          if (nextSubStep !== getters.currentSubStepNumber && stepMatch[subStepMatchIndexes.subStep] === subStepName) {
            dispatch('toSpecificStepAndSubStep', {
              step: nextStep,
              subStep: nextSubStep,
            });
          } else if (nextStep !== getters.currentStepNum) {
            dispatch('toSpecificStep', nextStep);
          }
        }
      }
    }
  },
  setSelectedSports(context, selSports) {
    if (selSports && selSports instanceof Array) {
      context.commit('SET_AGE_GROUPS_BY_SPORTS_ARRAY', selSports);
    }
  },
  updateSelectedSportsByIds(context, sportIds) {
    if (sportIds && sportIds instanceof Array) {
      const ageBySports = sportIds.reduce((acc, sportId) => {
        if (sportId && typeof sportId === 'string') {
          const org = context.getters.selectedOrg;
          const sportObjMatch = org.sports
            && org.sports.find((sp) => sp.sport && sp.sport.id === sportId);
          if (sportObjMatch) {
            const { sport, ...ages } = sportObjMatch;
            acc[sportId] = ages;
          } else {
            acc[sportId] = { ...ATHLETES_AGE_OBJECT };
          }
        }
        return acc;
      }, {});
      context.commit('SET_AGE_GROUPS_BY_SPORTS', ageBySports);
    }
  },
  async setLastVisitedStep({ dispatch, commit }, step) {
    if (typeof step === 'number' && !Number.isNaN(step)) {
      try {
        commit('SET_LAST_VISITED_STEP', step);
        await dispatch('updateOnboardingSessionClientState', { lastVisitedStep: step });
        return Promise.resolve(step);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject('The provided step argument was absent or invalid');
  },
  async setLastVisitedSubStep({ dispatch, commit }, subStep) {
    if (typeof subStep === 'number' && !Number.isNaN(subStep)) {
      try {
        commit('SET_LAST_VISITED_SUB_STEP', subStep);
        const payload = { lastVisitedSubStep: subStep };
        await dispatch('updateOnboardingSessionClientState', payload);
        return Promise.resolve(subStep);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject('The provided subStep argument was absent or invalid');
  },
  async setLastVisitedStepAndSubStep({ dispatch, commit, state }, { step, subStep }) {
    if (typeof step === 'number' && !Number.isNaN(step) && typeof subStep === 'number' && !Number.isNaN(subStep)) {
      try {
        commit('SET_LAST_VISITED_STEP', step);
        commit('SET_LAST_VISITED_SUB_STEP', subStep);
        const payload = {
          lastVisitedStep: step,
          lastVisitedSubStep: subStep,
        };
        await dispatch('updateOnboardingSessionClientState', payload);
        return Promise.resolve({ step, subStep });
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject('The provided subStep argument was absent or invalid');
  },
  fetchNecessaryData({ getters, dispatch, rootGetters }) {
    return Promise.all([
      !rootGetters['glossary/organizationTypes'].length ? dispatch('glossary/fetchOrganizationTypes', null, { root: true }) : null,
      !rootGetters['glossary/sports'].length ? dispatch('glossary/fetchSports', null, { root: true }) : null,
      !rootGetters['glossary/states'].length ? dispatch('glossary/fetchStates', null, { root: true }) : null,
    ]);
  },
  // session actions start
  initOnboardingSession({ dispatch, commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch('fetchOnboardingSession');
        if (data.data && data.data instanceof Object && data.data.session && data.data.session instanceof Object) {
          const { cookie, ...session } = data.data.session;
          if (Object.keys(session).length) {
            commit('SET_SESSION', session);
            dispatch('populateDataFromOnboardingSession', session);
            dispatch('setStepFromOnboardingSession', session);
          }
          resolve(session);
        }
        resolve({});
      } catch (err) {
        reject(err);
      }
    });
  },
  setStepFromOnboardingSession({ state, dispatch, getters }, session) {
    if (session && session instanceof Object && Object.keys(session).length) {
      try {
        const steps = SIGN_UP_STEPS;
        const stepValues = Object.values(steps);
        const biggestStepAndSubStep = getBiggestPassedStepAndSubStepOfSession(session);
        const signUpStepName = Object.keys(steps)[biggestStepAndSubStep.step - 1];
        let signUpStepMatch = steps[signUpStepName];
        const lastVisitedStep = (session.clientState
          && typeof session.clientState.lastVisitedStep === 'number'
          && !Number.isNaN(session.clientState.lastVisitedStep)
          && session.clientState.lastVisitedStep) || 1;
        const lastVisitedSubStep = (session.clientState
          && typeof session.clientState.lastVisitedSubStep === 'number'
          && !Number.isNaN(session.clientState.lastVisitedSubStep)
          && session.clientState.lastVisitedSubStep) || 1;
        if (biggestStepAndSubStep.step >= state.step) {
          const nextStep = signUpStepMatch instanceof Array && biggestStepAndSubStep.subStep === signUpStepMatch.length
            ? biggestStepAndSubStep.step + 1
            : biggestStepAndSubStep.step;
          if (nextStep !== state.step) {
            const selectedNextStep = nextStep <= getters.totalStepsNum ? nextStep : nextStep - 1;
            const finalNextStep = lastVisitedStep >= selectedNextStep ? selectedNextStep : lastVisitedStep;
            dispatch('toSpecificStep', finalNextStep);
            signUpStepMatch = stepValues[finalNextStep - 1];
          }
        }
        if (biggestStepAndSubStep.subStep >= state.subStep) {
          const nextSubStep = signUpStepMatch instanceof Array && biggestStepAndSubStep.subStep === signUpStepMatch.length
            ? biggestStepAndSubStep.subStep
            : biggestStepAndSubStep.subStep + 1;
          // lastVisitedStep is relevant only if our current step is the same as the last visited step
          // otherwise we don't care if lastVisitedSubStep is bigger if nextSubStep
          const lastVisitedStepIsRelevant = lastVisitedStep === state.step;
          dispatch('toSpecificSubStep', lastVisitedStepIsRelevant && lastVisitedSubStep >= nextSubStep ? nextSubStep : lastVisitedSubStep);
        }
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(new Error('The provided session object was invalid or empty'));
  },
  populateDataFromOnboardingSession({ commit, dispatch, state }, session) {
    if (session && session instanceof Object && Object.keys(session).length) {
      const isObjectAndHasKey = (obj, key) => obj && obj instanceof Object && Object.keys(obj).includes(key);
      const isArrayAndHasItems = (arr) => arr && arr instanceof Array && arr.length;
      // orgType step data
      if (isObjectAndHasKey(session.orgType, 'type')) {
        dispatch('setSelectedOrgType', session.orgType.type);
        if (isObjectAndHasKey(session.orgType.params, 'isOvernight')) {
          dispatch('changeOrgParam', {
            payload: session.orgType.params.isOvernight,
            field: 'isOvernight',
          });
        }
        if (isObjectAndHasKey(session.orgType.params, 'eventLocation')) {
          dispatch('changeOrgParam', {
            payload: session.orgType.params.eventLocation,
            field: 'eventLocation',
          });
        }
        if (isObjectAndHasKey(session.orgType.params, 'eventStartDate')
          && isObjectAndHasKey(session.orgType.params, 'eventEndDate')) {
          dispatch('updateEventTime', {
            value: session.orgType.params.eventStartDate,
            field: 'eventStartDate',
          });
          dispatch('updateEventTime', {
            value: session.orgType.params.eventStartDate,
            field: 'eventEndDate',
          });
        }
      }
      // orgChoice step data
      if (isObjectAndHasKey(session.orgChoice, 'organizationId') || isObjectAndHasKey(session.orgChoice, 'name')) {
        dispatch('selectOrganization', session.orgChoice);
      }
      // populate SportsInput values if orgSports payload is present, but orgAgeGroups or orgMonthsInSeason payload is absent
      if (isObjectAndHasKey(session.orgSports, 'sports')
        && (!isObjectAndHasKey(session.orgAgeGroups, 'sports') || isObjectAndHasKey(session.orgMonthsInSeason, 'sports'))) {
        if (isArrayAndHasItems(session.orgSports.sports)) {
          dispatch('setSelectedSports', session.orgSports.sports);
        }
      }
      // populate SportsInput values from orgAgeGroups sub-step payload
      if (isObjectAndHasKey(session.orgAgeGroups, 'sports')) {
        if (isArrayAndHasItems(session.orgAgeGroups.sports)) {
          dispatch('setSelectedSports', session.orgAgeGroups.sports);
        }
      }
      // SportInput from the "months" step may override the initial sports selection
      if (isObjectAndHasKey(session.orgMonthsInSeason, 'sports')) {
        if (isArrayAndHasItems(session.orgMonthsInSeason.sports)) {
          dispatch('setSelectedSports', session.orgMonthsInSeason.sports);
        }
      }
      // SportInput from the "age groups" step may override the initial sports selection
      if (isObjectAndHasKey(session.orgAgeGroups, 'sports')) {
        if (isArrayAndHasItems(session.orgAgeGroups.sports)) {
          dispatch('setSelectedSports', session.orgAgeGroups.sports);
        }
      }
      // populate personnel info
      if (isObjectAndHasKey(session.orgPersonnel, 'personnel')) {
        dispatch('populatePersonnelFromSessionData', session.orgPersonnel.personnel);
      }
      // populate user info
      if (isObjectAndHasKey(session.userInfo, 'name')) {
        dispatch('populateUserInfoFromSessionData', session.userInfo);
      }
      // populate module state from clientState data
      if (session.clientState && session.clientState instanceof Object) {
        if (state.lastVisitedStep && typeof session.clientState.lastVisitedStep === 'number' && !Number.isNaN(session.clientState.lastVisitedStep)) {
          commit('SET_LAST_VISITED_STEP', session.clientState.lastVisitedStep);
        }
        if (state.lastVisitedSubStep && typeof session.clientState.lastVisitedSubStep === 'number' && !Number.isNaN(session.clientState.lastVisitedSubStep)) {
          commit('SET_LAST_VISITED_SUB_STEP', session.clientState.lastVisitedSubStep);
        }
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error('The provided session object was invalid or empty'));
  },
  populatePersonnelFromSessionData({ dispatch }, personnel) {
    if (personnel instanceof Object) {
      const FIELD_NAME_MAP = {
        numAthletes: 'athletes',
        numCoaches: 'coaches',
        numVolunteers: 'volunteers',
        numOfficials: 'officials',
      };
      Object.entries(personnel).forEach((ent) => {
        if (FIELD_NAME_MAP[ent[0]]) {
          dispatch('updateOrgPersonnelField', {
            field: FIELD_NAME_MAP[ent[0]],
            value: ent[1],
          });
        }
      });
    }
  },
  populateUserInfoFromSessionData({ dispatch }, userInfo) {
    Object.entries(userInfo).forEach((ent) => {
      dispatch('updateUserInfoField', {
        field: ent[0],
        value: ent[1],
      });
      if (ent[0] === 'password') {
        dispatch('updateUserInfoField', {
          field: 'password_confirmation',
          value: ent[1],
        });
      }
    });
  },
  fetchOnboardingSession({ commit }) {
    commit('SET_LOADING_SESSION', true);
    return SessionProvider.fetchSessionQuery()
      .finally(() => {
        commit('SET_LOADING_SESSION', false);
      });
  },
  updateOnboardingSessionBySubStep({
    dispatch, commit, state, rootGetters,
  }, subStep) {
    return new Promise(async (resolve, reject) => {
      if (typeof subStep === 'string' && subStep.length) {
        if (ONBOARDING_SESSION_STEPS.includes(subStep)) {
          try {
            let response;
            let updateArgs = {
              stepName: subStep,
              payload: null,
            };
            if (subStep === 'orgType') {
              updateArgs.payload = getOrgTypePayload(state);
            } else if (subStep === 'orgChoice') {
              updateArgs.payload = getOrgChoicePayload(state);
            } else if (subStep === 'orgSports' || subStep === 'orgMonthsInSeason' || subStep === 'orgAgeGroups') {
              updateArgs.payload = getSportsPayload(state, subStep);
            } else if (subStep === 'orgPersonnel') {
              updateArgs.payload = getOrgPersonnelInput(state);
            } else if (subStep === 'userInfo') {
              updateArgs.payload = getUserInfoPayload(state, rootGetters);
            }
            if (updateArgs.payload) {
              response = await dispatch('updateOnboardingSession', updateArgs);
              if (response.data && response.data.data && response.data.data.session) {
                commit('SET_SESSION', response.data.data.session);
              }
              if (subStep === 'orgChoice') {
                if (state.session.orgChoice
                  && state.session.orgChoice.organizationId
                  && state.session.organization && typeof state.session.organization.isCompleted === 'boolean') {
                  await dispatch('populateSessionFromOrganization', state.session.organization);
                }
              }
            }
            resolve(response);
          } catch (err) {
            // process duplicate org creation error
            if (err.graphQLErrors instanceof Array) {
              retrieveGraphQLErrors(err, {
                409: async (duplicateErr) => {
                  if (subStep === 'orgChoice' && duplicateErr.organizationId) {
                    const userWantsToRequestAccessToOrg = await dispatch('dialog/showConfirmDialog', {
                      title: i18n.t('response.error.error'),
                      description: `${i18n.t('response.error.orgAlreadyExists')}. ${i18n.t('message.requestAccessToThisOrg')}`,
                    }, { root: true });
                    if (userWantsToRequestAccessToOrg) {
                      await dispatch('selectOrganization', {
                        organizationId: duplicateErr.organizationId,
                      });
                      try {
                        await dispatch('updateOnboardingSessionBySubStep', 'orgChoice');
                        resolve();
                      } catch (requestOrgAccessError) {
                        reject(requestOrgAccessError);
                      }
                    } else {
                      reject(new Error(REFUSAL_TO_REQUEST_ACCESS_ERROR_MESSAGE));
                    }
                  } else {
                    reject(duplicateErr);
                  }
                },
                all: reject,
              });
            } else {
              reject(err);
            }
          }
        } else {
          resolve();
        }
      } else {
        reject(new Error('the subStep argument wasn\'t provided or was invalid'));
      }
    });
  },
  updateOnboardingSession(context, { stepName, payload }) {
    context.commit('SET_LOADING_SESSION', true);
    return SessionProvider.updateSessionValueMutation(stepName, payload)
      .finally(() => {
        context.commit('SET_LOADING_SESSION', false);
      });
  },
  updateMutipleStepsInOnboardingSession(context, payload) {
    context.commit('SET_LOADING_SESSION', true);
    return SessionProvider.updateSessionValuesMutation(payload)
      .finally(() => {
        context.commit('SET_LOADING_SESSION', false);
      });
  },
  updateOnboardingSessionClientState({ state, dispatch }, stateFragmentObj) {
    if (stateFragmentObj && stateFragmentObj instanceof Object && Object.keys(stateFragmentObj).length) {
      const currentSessionClientState = state.session.clientState || {};
      return dispatch('updateOnboardingSession', {
        stepName: ONBOARDING_SESSION_CLIENT_STATE_KEY,
        payload: { ...currentSessionClientState, ...stateFragmentObj },
      });
    }
    return Promise.reject('The payload provided to the action was invalid or incomplete');
  },
  // session actions end
  registerUser({ dispatch }, flushDataOnSuccess = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const captchaValidationResponse = await dispatch('captcha/executeSignUpCaptcha', null, { root: true })
          .catch((captchaErr) => {
            console.error('registerUser.js gReCaptcha Error: ', captchaErr);
          });
        if (captchaValidationResponse && captchaValidationResponse instanceof Object && captchaValidationResponse.fraud) {
          throw new Error(GRECAPTCHA_FRAUD_ERROR_MESSAGE);
        }
        const {
          data: {
            data: {
              session: {
                token,
              },
            },
          },
        } = await dispatch('resolveOnboardingSession');
        let fetchedUser = null;
        if (token) {
          dispatch('user/forceAuthToken', token, { root: true });
          const fetchUserResponse = await dispatch('user/fetchUser', token, { root: true });
          if (fetchUserResponse instanceof Object
            && fetchUserResponse.data instanceof Object
            && fetchUserResponse.data.data instanceof Object
            && fetchUserResponse.data.data.me instanceof Object) {
            fetchedUser = fetchUserResponse.data.data.me;
          }
        }
        dispatch('setUserCreated', true);
        if (flushDataOnSuccess) {
          dispatch('flushSignUpData');
        }
        resolve(fetchedUser);
      } catch (err) {
        // process ReCaptcha fraud suspicion differently
        if (err.message === GRECAPTCHA_FRAUD_ERROR_MESSAGE) {
          dispatch('dialog/showInfoDialog', {
            title: i18n.t('message.warning'),
            description: i18n.t('response.error.gReCaptchaFraud', { email: SUPPORT_EMAIL }),
          }, {
            root: true,
          });
          resolve();
        } else {
          retrieveGraphQLErrors(err, {
            // this error usually happens when an org has all the possible policies already
            400: async () => {
              const userWantsToSignUpWithoutQuote = await dispatch('dialog/showConfirmDialog', {
                title: i18n.t('response.error.error'),
                description: i18n.t('message.registerWithoutAQuote'),
              }, {
                root: true,
              });
              if (userWantsToSignUpWithoutQuote) {
                dispatch('registerUser', 'registration');
              }
              resolve();
            },
            all: reject,
          });
        }
      }
    });
  },
  resolveOnboardingSession({ dispatch }) {
    return dispatch('updateOnboardingSession', getSessionResolvePayload());
  },
  toNextSubStep(context) {
    context.commit('INCREMENT_SUB_STEP');
  },
  updateOrgPersonnelField(context, data) {
    if (data.field && Object.keys(context.state.personnel).includes(data.field)) {
      context.commit('UPDATE_ORG_PERSONNEL_FIELD', data);
    }
  },
  updateAthletesAgeField(context, data) {
    if (data.field
      && data.sport
      && context.state.athletesAgeBySports[data.sport]
      && Object.keys(context.state.athletesAgeBySports[data.sport]).includes(data.field)) {
      context.commit('UPDATE_ATHLETES_AGE_FIELD', data);
    }
  },
};
