export default {
  addItemToShoppingCart: `mutation AddItemToShoppingCart ($input: CartInput) {
    cart (input: $input) {
      id,
      isValid,
      carrier {
        id,
        icon,
        name
      },
      coverage {
        carrierParams
      },
      quoteId,
      quote {
        id,
        isInCart,
        coverageType,
        coverageName,
        annualCost,
        monthlyCost,
        barePremiumAmount,
        taxAmount,
        policyAdminAmount,
        stampingAmount,
        policyStartedAt,
        policyExpiredAt,
      },
      organization {
        id,
        name,
        address {
          address,
          address2,
          state,
          country,
        },
      },
      paymentCycle,
      startDate,
      annualCost,
      monthlyCost,
      type,
      createdAt
    }
  }`,
  addItemsToShoppingCart: `mutation AddItemsToShoppingCart ($inputs: [CartInput]) {
    carts (inputs: $inputs) {
      id,
      isValid,
      carrier {
        id,
        icon,
        name
      },
      coverage {
        carrierParams
      },
      quoteId,
      quote {
        id,
        isInCart,
        coverageType,
        coverageName,
        annualCost,
        monthlyCost,
        policyStartedAt,
        policyExpiredAt,
      },
      organization {
        id,
        name,
      },
      paymentCycle,
      startDate,
      annualCost,
      monthlyCost,
      type,
      createdAt
    }
  }`,
  checkout: `mutation CheckoutCart ($input: CheckoutInput!) {
    checkout (input: $input) {
      code,
      message,
      user {
        id,
        name
      },
      policies {
        id,
        isCanceled,
        carrier {
          id,
        },
        createdAt,
        creator {
          id,
          name
        },
        coverage {
          carrierParams,
        },
        policyFile,
        policyPreview,
        expiredAt,
        organization {
          id,
          name,
          type,
          address {
            address,
            address2,
            city,
            state,
            country,
            zip
          }
        },
        paymentCycle,
        quote {
          id,
          carrier {
            id,
          },
          coverageType,
          coverageName,
          annualCost,
          monthlyCost
        },
        startedAt
      },
      url
    }
  }`,
  removeItemFromShoppingCart: `mutation RemoveItemFromShoppingCart ($deleteId: ID) {
    cart (deleteId: $deleteId) {
      id,
      organization {
        id
      },
      quoteId
    }
  }`,
  updateItemInShoppingCart: `mutation UpdateItemInShoppingCart ($updateId: ID, $input: CartInput) {
    cart (updateId: $updateId, input: $input) {
      id,
      isValid,
      carrier {
        id,
        icon,
        name
      },
      coverage {
        carrierParams
      },
      quoteId,
      quote {
        id,
        isInCart,
        coverageType,
        coverageName,
        annualCost,
        monthlyCost,
        policyStartedAt,
        policyExpiredAt,
      },
      organization {
        id,
        name,
      },
      paymentCycle,
      startDate,
      annualCost,
      monthlyCost,
      type,
      createdAt
    }
  }`,
};
