const route = {
  name: 'signUp',
  path: 'sign-up',
  component: () => import('@/pages/auth/SignUp'),
  meta: {
    requiresAuth: false,
    disableDrawer: true,
  },
};

export default route;
