<template>
  <div :class="b()">
    <HalfCircleSpinner :class="b('spinner')" color="#01831D" />
  </div>
</template>

<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner';

export default {
  name: 'PreloaderFullscreen',
  block: 'preloaderFullscreen',
  components: {
    HalfCircleSpinner,
  },
};
</script>

<style lang="scss">
$block: 'preloaderFullscreen';

.#{$block} {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: $white;
  z-index: 999;
  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
