import { VSelect } from 'vuetify/lib';
import { getDefaultSelectProps, getRequiredInputProps } from './utils/helpers';

export default ((component) => ({
  functional: true,
  name: 'VSelect',
  props: {
    ...getDefaultSelectProps(),
  },
  render(h, context) {
    const newProps = {
      ...context.props,
      ...getRequiredInputProps(context.props),
    };
    return h(
      component,
      {
        ...context.data,
        props: newProps,
      },
      context.children,
    );
  },
}))(VSelect);
