import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { langs } from '@/data/langs';
import messages from '@/i18n/messages';

const defaultLocale = langs.EN_US.i18n;
const fallbackLocale = langs.EN_US.i18n;

// I18n
// @see http://kazupon.github.io/vue-i18n
Vue.use(VueI18n);

const dateTimeFormats = {
  [langs.EN_US.i18n]: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

const numberFormats = {
  [langs.EN_US.i18n]: {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  [langs.FR_CA.i18n]: {
    currency: {
      style: 'currency',
      currency: 'CAD',
    },
  },
};

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale,
  dateTimeFormats,
  messages,
  numberFormats,
});

export default i18n;
