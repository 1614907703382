/* eslint no-shadow: 0 */
import { UserProvider, SoaProvider } from '@/graphql/providers';
import { ALLOWED_ROLE, GRECAPTCHA_FRAUD_ERROR_MESSAGE } from '@/utils/constants';
import { TOKEN_TIMEOUT } from '@/store/modules/user/constants';
import {
  concatGraphQLErrors, concatResponseErrors, retrieveGraphQLErrors,
} from '@/utils/helpers';
import {
  checkIfUserHasAchBySource,
} from '@/store/modules/user/helpers';
import i18n from '@/i18n';
import UserActivityTracker from '@/utils/services/UserActivityTracker';

const UserActivityTrackingInstance = new UserActivityTracker(TOKEN_TIMEOUT);

const handleAchVerifyError = (err, dispatch) => {
  dispatch('refreshAchToVerify', { attempts: err.attempts });
  if (err.attempts == 0) {
    dispatch('hideAchApprovalDialog');
    // re-fetch user & policies to get rid of a removed policy & payment method
    // as they will be removed on the backend when there are no verification attempts left
    dispatch('fetchUser');
    dispatch('policy/fetchPolicies', null, { root: true });
  }
};

export default {
  logout: (context) => new Promise((resolve) => {
    context.dispatch('socket/closeSocket', null, { root: true });
    context.dispatch('notification/clearAllNotifications', null, { root: true });
    context.commit('USER_CLEAR');
    context.commit('TOKEN_CLEAR');
    // stop idle timer & token refresh timeout
    UserActivityTrackingInstance.stopTracking();
    // resetting orgs
    context.dispatch('organization/clearOrganizations', null, { root: true });
    // resetting policies
    context.dispatch('policy/updatePolicies', [], { root: true });
    // resetting proofs of insurance
    context.dispatch('proof/updateProofs', [], { root: true });
    // resetting local shopping cart items
    context.dispatch('shoppingCart/flushLocalItems', null, { root: true });
    resolve();
  }),
  getUserInvitationByToken(context, token) {
    if (token && typeof token === 'string' && token.length) {
      return SoaProvider.getInviteByToken(token);
    }
    return Promise.reject(new Error(i18n.t('response.error.invitationLinkInvalid')));
  },
  fetchUser: (context) => new Promise((actionResolve, actionReject) => {
    if (!context.state.accessToken) {
      context.commit('TOKEN_SET', localStorage.getItem('auth_token'));
    }
    const currentToken = context.state.accessToken;
    if (currentToken) {
      UserProvider.fetchUserQuery(currentToken).then(async (response) => {
        if (response.data.data.me) {
          const {
            role,
            locale,
            timezone,
            token,
            id,
            name,
            soaRole,
            email,
            phone,
            icon,
            signature,
            stripeBankObject,
            stripeCardObject,
            stripeBankObjects,
            defaultPaymentMethod,
          } = response.data.data.me;
          if (role === ALLOWED_ROLE) {
            context.commit('USER_UPDATE', {
              role,
              profile: {
                id,
                name,
                soaRole,
                email,
                phone,
                icon,
                stripeBankObject,
                stripeCardObject,
                stripeBankObjects,
                defaultPaymentMethod,
              },
              signature,
            });
            // locale & timezone
            context.commit('USER_UPDATE_LOCALE_TIMEZONE', { locale, timezone });
            context.dispatch('syncUserLocale');
            // token
            context.commit('TOKEN_SET', token);
            context.dispatch('initActivityTracking');
            if (process.env.NODE_ENV !== 'test' && token) {
              context.dispatch('socket/openSocket', token, { root: true });
            }
            context.dispatch('notification/setUnseenNotifications', response.data.data.me.notifications, { root: true });
            await context.dispatch('shoppingCart/fetchShoppingCartItems', null, { root: true });
            await context.dispatch('organization/fetchOrganizations', null, { root: true });
            actionResolve(response);
          } else {
            actionReject(new Error(i18n.t('response.error.loginError')));
          }
        }
      }).catch((err) => {
        console.error(err);
        actionReject(err);
      });
    } else {
      actionReject(new Error('The authorization token isn\'t present'));
    }
  }),
  fetchLocationCountryCode(context) {
    return UserProvider.locationCountryCodeQuery().then((response) => {
      if (response.data
        && response.data.data
        && response.data.data.countryCode
        && response.data.data.countryCode.code == 200
        && response.data.data.countryCode.message) {
        context.commit('UPDATE_LOCATION_COUNTRY_CODE', response.data.data.countryCode.message);
      }
    });
  },
  async loginUser({ commit, dispatch }, payload) {
    try {
      const captchaValidationResponse = await dispatch('captcha/executeLoginCaptcha', null, { root: true })
        .catch((captchaErr) => {
          console.error('loginUser action gReCaptcha Error: ', captchaErr);
        });
      if (captchaValidationResponse && captchaValidationResponse instanceof Object && captchaValidationResponse.fraud) {
        throw new Error(GRECAPTCHA_FRAUD_ERROR_MESSAGE);
      }
      const { data } = await UserProvider.authUserQuery(payload);
      if (data.data.auth && !data.errors) {
        commit('TOKEN_SET', data.data.auth.token);
        commit('USER_UPDATE', {
          profile: data.data.auth,
        });
        return data;
      }
      throw new Error(concatGraphQLErrors(data));
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  forceAuthToken: (context, token) => new Promise((resolve, reject) => {
    if (typeof token === 'string' && token.length) {
      context.commit('TOKEN_SET', token);
      resolve();
    } else {
      reject(new Error('The token wasn\'t present or was invalid'));
    }
  }),
  updateUserFields(context, data) {
    context.commit('USER_UPDATE_PROFILE', data);
  },
  updateUserAvatar(context) {
    context.commit('USER_SET_NEW_ICON');
  },
  unsetUserAvatar(context) {
    context.commit('USER_UNSET_NEW_ICON');
  },
  checkUserShouldVerifyAchAndShowDialog({ dispatch, getters, state }) {
    if (getters.userCanUseACH) {
      const checkUserHasPermanentAch = (profile) => Boolean(profile.stripeBankObject instanceof Object && Object.keys(profile.stripeBankObject).length);
      if (checkUserHasPermanentAch(state.profile) && !state.profile.stripeBankObject.verified) {
        dispatch('showAchApprovalDialog', 'default');
      } else if (getters.userHasUnverifiedTemporaryAch) {
        dispatch('showAchApprovalDialog', 'temporary');
      }
    }
  },
  refreshAchToVerify({ commit, state }, data) {
    commit('UPDATE_PAYMENT_SOURCE_BY_SOURCE_TOKEN', { source: state.shownAchDialogSource, data });
  },
  showAchApprovalDialog(context, source) {
    if (source === 'default' && !context.getters.permanentAchVerified && !context.getters.userHasVerificationAttempts) {
      context.dispatch('dialog/showInfoDialog', {
        title: i18n.t('response.error.error'),
        description: i18n.t('message.toContinueToVerifyAch'),
      }, { root: true });
      return Promise.reject(new Error('No verification attempts left'));
    } if (source === 'temporary' && !context.getters.userHasUnverifiedTemporaryAch) {
      return Promise.reject(new Error('No temporary ACH to verify'));
    } if (typeof source !== 'string' && !source.length) {
      return Promise.reject(new Error('No valid source argument provided'));
    }
    context.commit('SHOW_ACH_APPROVAL_DIALOG', source);
  },
  hideAchApprovalDialog(context) {
    context.commit('HIDE_ACH_APPROVAL_DIALOG');
  },
  registerUser(context, userInput) {
    return new Promise((resolve, reject) => {
      UserProvider.registerUserMutation(userInput)
        .then(async (response) => {
          if (response.data.data && response.data.data.user && response.data.data.user.code === 200) {
            if (response.data.data.user.token) {
              context.dispatch('forceAuthToken', response.data.data.user.token);
              const fetchedUser = await context.dispatch('fetchUser', response.data.data.user.token);
              resolve(fetchedUser);
            } else {
              resolve(response);
            }
          }
          reject(new Error('The user wasn\'t created'));
        })
        .catch(reject);
    });
  },
  requestAccessToOrg(context, organizationId) {
    if (typeof organizationId === 'string' && organizationId.length) {
      return UserProvider.updateUserInfoMutation(context.state.profile.id, { organizationId });
    }
    return Promise.reject(new Error('organizationId was invalid or absent'));
  },
  setTokenChangeListener(context) {
    if (!context.state.storageListenerAttached) {
      const onStorage = (data) => {
        if (data.key === 'auth_token') {
          if (!data.newValue && context.getters.authed) {
            context.dispatch('forceLogout');
          } else if (data.newValue && !context.getters.authed) {
            // reload the page so that the user would be fetched on next app startup
            window.location.reload();
          }
        }
      };

      if (window.addEventListener) {
        window.addEventListener('storage', onStorage, false);
      } else {
        window.attachEvent('onstorage', onStorage);
      }
      context.commit('SET_TOKEN_CHANGE_LISTENER_STATUS', true);
    }
  },
  resendConfirmationToEmail(context, email) {
    if (typeof email === 'string' && email.length) {
      return UserProvider.resendConfirmationMutation(email);
    }
    return Promise.reject(new Error('The email was invalid or absent'));
  },
  initActivityTracking(context) {
    const tokenTimeoutCallback = (shouldLogout) => new Promise((resolve, reject) => {
      if (shouldLogout) {
        context.dispatch('forceLogout');
        reject();
      } else {
        context.dispatch('forceAuthToken', context.state.accessToken);
        context.dispatch('fetchUser').catch(() => {
          context.dispatch('forceLogout');
          reject();
        });
        resolve();
      }
    });
    UserActivityTrackingInstance.startTracking(tokenTimeoutCallback);
  },
  syncUserLocale({ dispatch, rootGetters, state }) {
    if (state.locale && rootGetters['locale/currentLocale'] instanceof Object && state.locale !== rootGetters['locale/currentLocale'].i18n) {
      return dispatch('locale/setLocaleFromUser', null, { root: true });
    }
    return Promise.resolve();
  },
  updateUserDebitCard(context, data) {
    if (data.field && Object.keys(context.state.debitCard).includes(data.field)) {
      context.commit('UPDATE_USER_DEBIT_CARD', data);
    }
  },
  updateUserBankAccount(context, data) {
    if (data.field && Object.keys(context.state.bankAccount).includes(data.field)) {
      context.commit('UPDATE_USER_BANK_ACCOUNT', data);
    }
  },
  updateUserProfile({ state, dispatch }, object) {
    return new Promise((resolve, reject) => {
      UserProvider.updateUserInfoMutation(state.profile.id, object.form)
        .then((response) => {
          if (response.data && response.data.data && response.data.data.user && response.data.data.user.user && response.data.data.user.user instanceof Object) {
            const emailChanged = response.data.data.user.user.email !== object.form.email;
            dispatch('dialog/showInfoDialog', {
              title: emailChanged ? i18n.t('message.emailChangeVerification') : i18n.t('response.success.success'),
              description: emailChanged
                ? `<div>${i18n.t('response.success.profileUpdateSuccessWithEmailConfirmation', { email: object.form.email })}</div>`
                : i18n.t('response.success.profileUpdateSuccess'),
            }, {
              root: true,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch('dialog/showErrorDialog', { error }, { root: true });
          reject(error);
        });
    });
  },
  updateUserLocale({ commit, dispatch, state }, locale) {
    return new Promise((resolve, reject) => {
      UserProvider.updateUserInfoMutation(state.profile.id, { locale })
        .then((response) => {
          if (response.data && response.data.data && response.data.data.user && response.data.data.user.code === 200) {
            commit('USER_UPDATE_LOCALE_TIMEZONE', { locale });
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch('dialog/showErrorDialog', { error }, { root: true });
          reject(error);
        });
    });
  },
  updateUserSignature({ dispatch, state }, signature) {
    return new Promise((resolve, reject) => {
      UserProvider.updateUserInfoMutation(state.profile.id, {
        signature,
      }).then(resolve)
        .catch((error) => {
          dispatch('dialog/showErrorDialog', { error }, { root: true });
          reject(error);
        });
    });
  },
  confirmUserEmailChange({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (data.email && data.token) {
        UserProvider.confirmEmailChangeMutation({ email: data.email, token: data.token })
          .then(async (response) => {
            if (response.data
              && response.data.data
              && response.data.data.emailChange
              && response.data.data.emailChange.code === 200) {
              try {
                dispatch('analytics/trackUserEmailConfirmation', {
                  pageName: 'SignUpConfirmation',
                }, {
                  root: true,
                });
              } catch (err) {
                console.error(err);
              }
              // force token & fetch user by it
              dispatch('forceAuthToken', response.data.data.emailChange.token)
                .then(() => {
                  dispatch('fetchUser');
                });
            }
            // resolve the confirmation promise before the user is fetched
            resolve(response);
          })
          .catch(reject);
      } else {
        reject(new Error('Email or token wasn\'t present in the URL query'));
      }
    });
  },
  confirmUserEmail({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (data.email && data.token) {
        UserProvider.confirmEmailMutation({ email: data.email, token: data.token })
          .then(async (response) => {
            if (response.data
              && response.data.data
              && response.data.data.confirm
              && response.data.data.confirm.code === 200) {
              try {
                dispatch('analytics/trackUserEmailConfirmation', {
                  pageName: 'SignUpConfirmation',
                }, {
                  root: true,
                });
              } catch (err) {
                console.error(err);
              }
              // force token & fetch user by it
              dispatch('forceAuthToken', response.data.data.confirm.token)
                .then(() => {
                  dispatch('fetchUser');
                });
              // resolve the confirmation promise before the user is fetched
              resolve(response);
            }
          })
          .catch(reject);
      } else {
        reject(new Error('Email or token wasn\'t present in the URL query'));
      }
    });
  },
  recoverUserPassword({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      UserProvider.recoverPasswordMutation(data.userId, data.password, data.token)
        .then(resolve)
        .catch((error) => {
          let errorMessage = i18n.t('response.error.passwordReset');
          if (error.graphQLErrors) {
            errorMessage = '';
            error.graphQLErrors.forEach((err) => {
              errorMessage += `${err.message}\n`;
            });
          }
          dispatch('dialog/showInfoDialog', {
            title: i18n.t('response.error.error'),
            description: errorMessage,
          }, {
            root: true,
          });
          reject(error);
        });
    });
  },
  resendUserEmailConfirmation({ dispatch }, email) {
    return new Promise((resolve, reject) => {
      if (email) {
        UserProvider.resendConfirmationMutation(email).then((response) => {
          if (response.data.data.resendConfirmation && response.data.data.resendConfirmation.code === 202) {
            dispatch('dialog/showInfoDialog', {
              title: i18n.t('ui.confirmation'),
              description: i18n.t('response.success.emailConfirmationResend', { email }),
            }, {
              root: true,
            });
          }
          resolve(response);
        }).catch((error) => {
          if (error.graphQLErrors) {
            error.graphQLErrors.forEach((err) => {
              dispatch('dialog/showInfoDialog', {
                title: i18n.t('response.error.error'),
                description: err.message,
              }, {
                root: true,
              });
            });
          }
          reject(error);
        });
      } else {
        const err = new Error('The email to resend confirmation to wasn\'t provided or was incorrect');
        dispatch('dialog/showInfoDialog', {
          title: i18n.t('response.error.error'),
          description: err.message,
        }, {
          root: true,
        });
        reject(err);
      }
    });
  },
  resetUserPassword({ dispatch }, email) {
    return new Promise((resolve, reject) => {
      UserProvider.resetPasswordMutation(email).then((response) => {
        if (response.data.data.resetPassword.code === 202) {
          resolve(response);
        }
      }).catch((error) => {
        let errorMessage = `${i18n.t('response.error.passwordReset')}`;
        if (error.graphQLErrors) {
          errorMessage = '';
          error.graphQLErrors.forEach((err) => {
            errorMessage += `${err.message}\n`;
          });
        }
        dispatch('dialog/showInfoDialog', {
          title: i18n.t('response.error.error'),
          description: errorMessage,
        }, {
          root: true,
        });
        reject(error);
      });
    });
  },
  verifyAchAccount({ dispatch }, amounts) {
    return new Promise((resolve, reject) => {
      UserProvider.userVerifyAchAccount(amounts).then(async (response) => {
        dispatch('dialog/showInfoDialog', {
          title: i18n.t('response.success.success'),
          description: response.data.data.paymentMethod.message,
        }, {
          root: true,
        });
        dispatch('fetchUser');
        await dispatch('policy/fetchPolicies', null, { root: true });
        resolve(response);
      }).catch(async (error) => {
        dispatch('dialog/showErrorDialog', { error }, { root: true });
        retrieveGraphQLErrors(error, {
          400: (err) => {
            handleAchVerifyError(err, dispatch);
          },
        });
        dispatch('refreshAchToVerify');
        reject(error);
      });
    });
  },
  verifyTemporaryAchAccount({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      UserProvider.userVerifyTemporaryAchAccount(data.amounts, true, data.token).then(async (response) => {
        dispatch('dialog/showInfoDialog', {
          title: i18n.t('response.success.success'),
          description: response.data.data.paymentMethod.message,
        }, {
          root: true,
        });
        dispatch('fetchUser');
        await dispatch('policy/fetchPolicies', null, { root: true });
        resolve(response);
      }).catch(async (error) => {
        dispatch('dialog/showErrorDialog', { error }, { root: true });
        retrieveGraphQLErrors(error, {
          400: (err) => {
            handleAchVerifyError(err, dispatch);
          },
        });
        reject(error);
      });
    });
  },
  async findAchAndShowApprovalDialog({ dispatch, state }, source) {
    try {
      if (typeof source === 'string' && source.length) {
        let presentInPaymentSources = (state.profile.stripeBankObject && state.profile.stripeBankObject instanceof Object && state.profile.stripeBankObject.source === source)
          || checkIfUserHasAchBySource(state.profile.stripeBankObjects, source);
        if (!presentInPaymentSources) {
          await dispatch('fetchUser');
          presentInPaymentSources = (state.profile.stripeBankObject && state.profile.stripeBankObject instanceof Object && state.profile.stripeBankObject.source === source)
            || checkIfUserHasAchBySource(state.profile.stripeBankObjects, source);
          if (!presentInPaymentSources) {
            throw new Error('The specified payment method was not found');
          }
        }
        dispatch('showAchApprovalDialog', source);
        return Promise.resolve(true);
      }
      throw new Error('No valid token was provided');
    } catch (err) {
      return Promise.reject(err);
    }
  },
  updatePaymentMethods({ dispatch }, object) {
    return new Promise((resolve, reject) => {
      UserProvider.updateUserPaymentMethodMutation(object.token, object.method, object.defaultMethod).then((response) => {
        resolve(response);
      }).catch((err) => {
        if (err) {
          let { message } = err;
          if (err.graphQLErrors instanceof Array) {
            message = concatGraphQLErrors(err);
          } else if (err.errors instanceof Array) {
            message = concatResponseErrors(err);
          }
          if (typeof message === 'string' && message.length) {
            dispatch('dialog/showInfoDialog', {
              title: i18n.t('response.error.error'),
              description: message,
            }, {
              root: true,
            });
          }
        }
        reject(err);
      });
    });
  },
  updateDefaultPaymentMethod(context, method) {
    return new Promise((resolve, reject) => {
      UserProvider.updateDefaultPaymentMethodMutation(method).then((response) => {
        resolve(response);
      }).catch(reject);
    });
  },
  removePaymentMethod(context, object) {
    return new Promise((resolve, reject) => {
      UserProvider.removeUserPaymentMethodMutation(object.method, object.isDelete).then((response) => {
        if (object.method === 'ACH') {
          context.commit('CLEAR_BANK_ACCOUNT');
        }
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  forceLogout(context) {
    context.dispatch('logout')
      .then(() => {
        window.location.reload();
      });
  },
};
