import ServiceProvider from '../provider';

import QUERIES from './queries';
import MUTATIONS from './mutations';

class OrganizationProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  orgQuestionsQuery(organizationId) {
    return this.query(this.queries.questions, { organizationId });
  }

  orgSearchQuery(name) {
    return this.query(this.queries.search, { name }, { noAuth: true });
  }

  orgDetailsQuery(placeId) {
    return this.query(this.queries.organization, { placeId }, { noAuth: true });
  }

  createOrgMutation(input, config) {
    if (input.icon || input.injuryProtocol || input.concussionProtocol || input.waiverProtocol) {
      let variableObj = {};
      const fileFields = ['icon', 'injuryProtocol', 'concussionProtocol', 'waiverProtocol'];
      Object.entries(input).forEach((entry) => {
        if (entry[1] && !fileFields.includes(entry[0])) {
          variableObj[entry[0]] = entry[1];
        }
      });

      let formData = new FormData();
      formData.append('query', this.mutations.createOrganization);
      formData.append('variables', JSON.stringify({ input: variableObj }));
      if (input.icon) {
        formData.append('icon', input.icon);
      }
      if (input.injuryProtocol) {
        formData.append('injuryProtocol', input.injuryProtocol);
      }
      if (input.concussionProtocol) {
        formData.append('concussionProtocol', input.concussionProtocol);
      }
      if (input.waiverProtocol) {
        formData.append('waiverProtocol', input.waiverProtocol);
      }
      return this.formDataMutate(formData);
    }
    return this.mutate(this.mutations.createOrganization, { input }, config);
  }

  checkOrgUpdatesAffectSubmissionsMutation(organizationId, input) {
    return this.mutate(this.mutations.regenerateQuotes, { organizationId, input });
  }

  updateOrgMutation(updateId, input) {
    if (input.icon || input.injuryProtocol || input.concussionProtocol || input.waiverProtocol) {
      let variableObj = {};
      const fileFields = ['icon', 'injuryProtocol', 'concussionProtocol', 'waiverProtocol'];
      Object.entries(input).forEach((entry) => {
        if (entry[1] && !fileFields.includes(entry[0])) {
          variableObj[entry[0]] = entry[1];
        }
      });

      let formData = new FormData();
      formData.append('query', this.mutations.updateOrganization);
      formData.append('variables', JSON.stringify({ updateId, input: variableObj }));
      if (input.icon) {
        formData.append('icon', input.icon);
      }
      if (input.injuryProtocol) {
        formData.append('injuryProtocol', input.injuryProtocol);
      }
      if (input.concussionProtocol) {
        formData.append('concussionProtocol', input.concussionProtocol);
      }
      if (input.waiverProtocol) {
        formData.append('waiverProtocol', input.waiverProtocol);
      }
      return this.formDataMutate(formData);
    }
    return this.mutate(this.mutations.updateOrganization, { updateId, input });
  }

  preUpdateOrgMutation(organizationId, userId, input) {
    if (input.icon instanceof File
      || input.injuryProtocol instanceof File
      || input.concussionProtocol instanceof File
      || input.waiverProtocol instanceof File) {
      let variableObj = {};
      const fileFields = ['icon', 'injuryProtocol', 'concussionProtocol', 'waiverProtocol'];
      const stringCompatibleFileFields = ['injuryProtocol', 'concussionProtocol', 'waiverProtocol'];
      Object.entries(input).forEach((entry) => {
        if (entry[1] && (!fileFields.includes(entry[0]) || (typeof entry[1] === 'string' && stringCompatibleFileFields.includes(entry[0])))) {
          variableObj[entry[0]] = entry[1];
        }
      });

      let formData = new FormData();
      formData.append('query', this.mutations.preUpdateOrganization);
      formData.append('variables', JSON.stringify({ organizationId, userId, input: variableObj }));
      if (input.icon) {
        formData.append('icon', input.icon);
      }
      if (input.injuryProtocol && input.injuryProtocol instanceof File) {
        formData.append('injuryProtocol', input.injuryProtocol);
      }
      if (input.concussionProtocol && input.concussionProtocol instanceof File) {
        formData.append('concussionProtocol', input.concussionProtocol);
      }
      if (input.waiverProtocol && input.waiverProtocol instanceof File) {
        formData.append('waiverProtocol', input.waiverProtocol);
      }
      return this.formDataMutate(formData);
    }
    return this.mutate(this.mutations.preUpdateOrganization, { organizationId, userId, input });
  }
}
export default new OrganizationProvider(QUERIES, MUTATIONS);
