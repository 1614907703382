import actions from '@/store/modules/organization/actions';
import mutations from '@/store/modules/organization/mutations';
import getters from '@/store/modules/organization/getters';
import getDefaultState from '@/store/modules/organization/helpers/getDefaultState';

const state = { ...getDefaultState() };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
