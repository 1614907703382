import organizationStep from './organizationStep';
import claimantStep from './claimantStep';
import injuryStep from './injuryStep';
import insuranceStep from './insuranceStep';

export default {
  1: organizationStep,
  2: claimantStep,
  3: injuryStep,
  4: insuranceStep,
};
