<template>
    <div :class="b({ fnolEdit: true })">
      <v-container>
        <ClaimAction
          :id="routeParams.id"
          :fnol="routeParams.fnol"
          @handleSubmit="handleSubmit"
        />
      </v-container>
    </div>
</template>

<script>
import ClaimAction from '@/components/claim/action/ClaimAction';
import { mapActions } from 'vuex';

export default {
  name: 'FnolEdit',
  block: 'claimActionWrapper',
  components: {
    ClaimAction,
  },
  computed: {
    routeParams() {
      return this.$route.params;
    },
  },
  methods: {
    ...mapActions('fnol', ['editFnol']),
    handleSubmit(payload) {
      this.editFnol({
        claimId: this.routeParams.id,
        claim: payload,
      }).then(() => {
        this.$dialog.info({
          title: 'Success',
          description: 'Fnol successfully updated',
        });
        this.$router.push({ name: 'soa.fnols' });
      }).catch((error) => {
        if (error.graphQLErrors) {
          let errorMsg = '';
          error.graphQLErrors.forEach((err) => {
            errorMsg += `${err.message}\n`;
          });
          this.$dialog.info({
            title: this.$t('response.error.error'),
            description: errorMsg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
  @import "~@/assets/scss/components/claimActionWrapper";
</style>
