const getSubCoverageParams = (subCoverages) => {
  if (subCoverages instanceof Array && subCoverages.length) {
    return subCoverages.reduce((acc, subCov) => {
      if (subCov instanceof Object && typeof subCov.id === 'string' && !Number.isNaN(subCov.selectedLimit)) {
        acc.push({
          coverage: subCov.id,
          limit: subCov.selectedLimit,
        });
      }
      return acc;
    }, []);
  }
  return [];
};

export default (state, getters) => {
  let submissionParams = {
    organizationId: state.organizationToComplete.id,
    coverage: state.chosenCoverageId,
  };
  // if (getters.coverageParamsMap instanceof Object && )
  if (getters.chosenCoverage && getters.chosenCoverage instanceof Object) {
    if (!Number.isNaN(getters.chosenCoverage.selectedLimit)) {
      submissionParams.limit = getters.chosenCoverage.selectedLimit;
    }
    if (typeof getters.chosenCoverage.startDate === 'string' && getters.chosenCoverage.startDate.length) {
      submissionParams.startDate = getters.chosenCoverage.startDate;
    }
    if (typeof getters.chosenCoverage.endDate === 'string' && getters.chosenCoverage.endDate.length) {
      submissionParams.endDate = getters.chosenCoverage.endDate;
    }
  }
  if (getters.chosenCoverage.id === state.chosenCoverageId
    && getters.chosenSubCoverages instanceof Array
    && getters.chosenSubCoverages.length) {
    const subCoverageParams = getSubCoverageParams(getters.chosenSubCoverages);
    if (subCoverageParams.length) {
      Object.assign(submissionParams, {
        subCoverages: subCoverageParams,
      });
    }
  }
  return submissionParams;
};
