import differenceBy from 'lodash/differenceBy';
import moment from 'moment';

export default (org, policies, coverages, activeOnly = true) => {
  if (org && org instanceof Object && org.id) {
    if (policies instanceof Array && policies.length && coverages instanceof Array && coverages.length) {
      const momentNow = activeOnly ? moment() : null;
      const policiesOfOrg = policies.reduce((acc, pol) => {
        if (pol.organization
          && pol.organization.id === org.id
          && (!momentNow || momentNow.isBefore(pol.expiredAt))) {
          acc.push({
            ...pol,
            type: (pol.quote && pol.quote.coverageType) || '',
          });
        }
        return acc;
      }, []);
      if (!policiesOfOrg.length || (policiesOfOrg.length !== coverages.length)) {
        return false;
      }
      return differenceBy(policiesOfOrg, coverages, 'type').length === 0;
    }
  }
  return false;
};
