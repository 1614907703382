export default {
  carriers: `query getCarriers {
               carriers{
                 id,
                 name,
                 icon
               }
             }`,
  coverages: `query ($filters: CoveragesFilterInput) {
                coverages(filters:$filters){
                  id,
                  type,
                  name,
                  icon,
                  cases{
                    id,
                    name,
                    value,
                    icon
                  },
                  carrierParams,
                  description,
                  descriptionFile,
                  shortDescription,
                  subcoverages{
                    isOptional,
                    description,
                    descriptionFile,
                    id,
                    limits,
                    name,
                    shortDescription,
                    type,
                  },
                  limits,
                  videoUrl,
                  proposal {
                    excludes,
                    includes
                  }
                }
              }`,
  previousClaimsLossRatio: `query ($previousClaims: [PreviousClaimInput]!) {
                              calcClaimsRatio(previousClaims: $previousClaims) {
                                  kickFlow
                                  avgRatio
                              }
  }`,
  quotes: `query($filters:QuotesFilterInput!){
              quotes(filters:$filters){
                id,
                annualCost,
                monthlyCost,
                currency,
                discount,
                limit,
                carrier {
                  id,
                  name,
                  icon
                },
                coverage {
                  id,
                  type,
                  name,
                },
              }
            }`,
  submissions: `query ($filters: SubmissionsFilterInput) {
                  submissions(filters: $filters) {
                    id,
                    limits,
                    startedAt,
                    expiredAt,
                    organization {
                      id,
                      name,
                    },
                    coverage {
                      id,
                      name,
                      type,
                      carrierParams,
                      shortDescription,
                      description,
                      descriptionFile,
                      videoUrl,
                      proposal {
                        includes,
                        excludes,
                      },
                    },
                    subcoverages {
                      coverage {
                        id
                        name
                        limits
                        isOptional
                      }
                      limit
                    }
                    limit
                    offers {
                      id,
                      carrier {
                        id,
                        name
                      },
                      coverage {
                        id,
                        name,
                        type,
                        carrierParams,
                      },
                      organization {
                        id,
                        name,
                      },
                      isRejected,
                      currency,
                      quotes {
                        coverageType,
                        id,
                        limit,
                        annualCost,
                        monthlyCost,
                        barePremiumAmount,
                        rpgAmount,
                        stampingAmount,
                        taxAmount,
                        discount,
                        expiredAt,
                        isRejected,
                        isInCart,
                        policyStartedAt,
                        policyExpiredAt,
                      }
                    }
                  }
                }`,
};
