export default {
  INIT_CHECKOUT_PARAMS(context, { organizations }) {
    if (organizations && organizations instanceof Array) {
      context.checkoutParams.organizations = organizations;
    }
    context.checkoutParams.initialized = true;
  },
  UPDATE_CHECKOUT_PARAM(context, { param, value }) {
    context.checkoutParams = {
      ...context.checkoutParams,
      [param]: value,
    };
  },
  SET_ITEMS(context, items) {
    context.items = items;
  },
  PUSH_TO_ITEMS(context, item) {
    context.items.push(item);
  },
  REMOVE_FROM_ITEMS(context, itemId) {
    const foundItemIndex = context.items.findIndex((it) => it.id === itemId);
    if (foundItemIndex !== -1) {
      context.items.splice(foundItemIndex, 1);
    }
  },
  REPLACE_IN_ITEMS(context, { replaceIndex, item }) {
    if (replaceIndex >= 0 && replaceIndex <= context.items.length && item && item instanceof Object && Object.keys(item).length) {
      context.items.splice(replaceIndex, 1, item);
    }
  },
  UPDATE_ITEM(context, { itemId, updateFragment }) {
    const itemIndex = context.items.findIndex((it) => it.id === itemId);
    if (itemIndex !== -1) {
      context.items.splice(itemIndex, 1, {
        ...context.items[itemIndex],
        ...updateFragment,
      });
    }
  },
};
