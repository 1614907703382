import { getCarrierParamsByCarrierMap } from '@/utils/helpers/app/carrierParams';

export default {
  firstSubmission: (state, getters) => getters.submissions instanceof Array && getters.submissions.length && getters.submissions[0] instanceof Object
    ? getters.submissions[0]
    : null,
  submissions: (state, ctxGetters, rootState, rootGetters) => state.submissions.map((sbm) => {
    const coverages = rootGetters['insurance/coveragesMapByType'];
    const paramsMap = coverages[sbm.coverage.type]
      ? getCarrierParamsByCarrierMap(coverages[sbm.coverage.type].carrierParams)
      : {};
    const offers = sbm.offers.map((offer) => ({
      ...offer,
      carrierParamsMap: paramsMap[offer.carrier.id] || {},
    }));
    return {
      ...sbm,
      offers,
    };
  }),
};
