import { jsonClone } from '@/utils/helpers';
import { COVERAGE_SUMMARY_CHECKBOXES, DEFAULT_PERSONNEL_NUMBER, STRIPE_KEY } from '@/utils/constants';
import {
  getDefaultOrgApplicationSteps,
} from '@/store/modules/orgApplication/helpers';

const backgroundCheck = {
  bgCheckStep: 0,
  bgChecks: 'not_sure',
  bgCheckProvider: null,
  otherBackgroundProvider: null,
};

const personnel = {
  athletes: DEFAULT_PERSONNEL_NUMBER,
  coaches: DEFAULT_PERSONNEL_NUMBER,
  volunteers: DEFAULT_PERSONNEL_NUMBER,
  officials: DEFAULT_PERSONNEL_NUMBER,
};

const step = 1;
const subStep = 1;
const lastVisitedStep = 1;
const lastVisitedSubStep = 1;

const havePolicy = false;

const protocols = {
  injuryProtocol: null,
  injuryProtocolTemplate: null,
  concussionProtocol: null,
  concussionProtocolTemplate: null,
  waiverProtocol: null,
  waiverProtocolTemplate: null,
};

const cardInfo = {
  cvv: '',
  city: '',
  state: '',
  address: '',
  zipcode: '',
  exp_date: '',
  card_name: '',
  card_number: '',
};

const eCheckInfo = {
  bank_name: '',
  acc_number: '',
  bank_acc_name: '',
  routing_number: '',
};

export default () => jsonClone({
  coverages: [],
  manualReportCheckboxes: { ...COVERAGE_SUMMARY_CHECKBOXES },
  athletesAgeBySports: {},
  externalCoverages: {},
  havePolicy,
  step,
  subStep,
  lastVisitedStep,
  lastVisitedSubStep,
  orgApplicationSteps: getDefaultOrgApplicationSteps(),
  otherProvider: null,
  registrationProvider: null,
  registrationProviders: [],
  organizationToComplete: {},
  ...backgroundCheck,
  paymentOption: {},
  claimsData: {
    hadClaims: false,
    claims: [],
  },
  chosenCoverageId: '',
  chosenSubCoverageIds: [],
  coverageParamsMap: {},
  personnel: { ...personnel },
  protocols: { ...protocols },
  cardInfo: { ...cardInfo },
  eCheckInfo: { ...eCheckInfo },
  chainedQuestions: {},
  preliminaryQuotes: {
    items: [],
    lastUpdatedAtDate: null,
  },
});
