// Vuex module components
import mutations from '@/store/modules/stripe/mutations';
import actions from '@/store/modules/stripe/actions';
import getters from '@/store/modules/stripe/getters';
import getDefaultState from '@/store/modules/stripe/helpers/getDefaultState';

const state = { ...getDefaultState() };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
