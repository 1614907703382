import getDefaultState from '@/store/modules/insurance/helpers/getDefaultState';
import actions from '@/store/modules/insurance/actions';
import getters from '@/store/modules/insurance/getters';
import mutations from '@/store/modules/insurance/mutations';

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getters,
};
