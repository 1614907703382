import Vue from 'vue';
// import { Expo } from 'gsap/EasePack';
// import TweenLite from 'gsap/TweenLite';
import convertFromLocale from '@/utils/helpers/convertFromLocale';

Vue.directive('handleScroll', {
  inserted(el, binding) {
    // eslint-disable-next-line
    let f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f);
      }
    };
    el.addEventListener('scroll', f);
  },
});

/**
  'type' property is used to configure workflow of directive
  @param expression: Object {
    type: String (scroll | to),
    target: [Number, String]
  }
*/
const scrollDownListenerFN = function (el, binding, vnode) {
  // const SCROLL_DURATION = 0.5;
  // const EXPRESSION = binding.value;
  // const SCROLL_TYPE = EXPRESSION ? (EXPRESSION.type || 'scroll') : 'scroll';
  // const SCROLL_TARGET = EXPRESSION ? (EXPRESSION.target || 40) : 40;
  //
  // const ScrollTo = (element) => {
  //   let scrollObj = {
  //     ease: Expo.easeOut,
  //   };
  //   switch (SCROLL_TYPE) {
  //     case 'scroll':
  //       Object.assign(scrollObj, { scrollTop: element.scrollTop + SCROLL_TARGET });
  //       break;
  //     case 'to':
  //       Object.assign(scrollObj, { scrollTop: SCROLL_TARGET });
  //       break;
  //     default:
  //       break;
  //   }
  //   TweenLite.to([element], SCROLL_DURATION, scrollObj);
  // };
  //
  // let ViewModel = vnode.context;
  // ScrollTo(ViewModel.$refs[Object.keys(binding.modifiers)[0]].querySelector('.vb-content'));
};

Vue.directive('bar-scrolldown', {
  bind(el, binding, vnode) {
    el.addEventListener('click', () => scrollDownListenerFN(...arguments));
  },
  unbind(el) {
    el.removeEventListener('click', () => scrollDownListenerFN(...arguments));
  },
});

const formatMoney = (el, binding) => {
  if (binding.value == '' || binding.value == null) {
    return '';
  }
  const triggerEvent = (payloadElemnt, eventName) => {
    let evt = new CustomEvent(eventName, {});
    payloadElemnt.dispatchEvent(evt);
  };
  const element = el.querySelector('input');
  const elNum = convertFromLocale(element.value, binding.arg || 'en');
  if (elNum) {
    if (Number.isNaN(Number(element.value.substring(element.value.length - 1)))) {
      el.querySelector('input').value = element.value;
      triggerEvent(element, 'input');
    } else {
      el.querySelector('input').value = elNum.toLocaleString(binding.arg || 'en', {
        minimumFractionDigits: elNum.toString().split('.')[1] ? elNum.toString().split('.')[1].length : 0,
      });
      triggerEvent(element, 'input');
    }
  }
};
Vue.directive('money', {
  inserted(el, binding) {
    formatMoney(el, binding);
  },
  update(el, binding) {
    formatMoney(el, binding);
  },
});
