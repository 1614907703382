import { PolicyProvider, UserProvider } from '@/graphql/providers';

const state = {
  policies: [],
};

const mutations = {
  SET_POLICIES(context, pols) {
    if (pols && pols instanceof Array) {
      context.policies = pols;
    }
  },
  PUSH_TO_POLICIES(context, pol) {
    if (pol && pol instanceof Object && Object.keys(pol).length) {
      context.policies.push(pol);
    }
  },
  UPDATE_POLICY(context, { policyId, updateFragment }) {
    if (policyId && typeof policyId === 'string' && updateFragment && updateFragment instanceof Object) {
      const policyIndex = context.policies.findIndex((pol) => pol.id === policyId);
      if (policyIndex !== -1) {
        context.policies.splice(policyIndex, 1, { ...context.policies[policyIndex], ...updateFragment });
      }
    }
  },
  REPLACE_POLICY(context, policy) {
    const index = context.policies.indexOf(context.policies.find((el) => el.id === policy.id));
    context.policies.splice(index, 1, policy);
  },
};

const actions = {
  checkAndUpdateDataForUnverifiedACHPolicies(context) {
    return new Promise((resolve, reject) => {
      // check if there are policies that require ACH verification
      const checkUserHasUnpaidPolicies = (pols) => pols instanceof Array && pols.length && pols.some((pol) => !pol.isPaid);
      const checkUserHasAllPaymentMethods = (profile) => profile
        && (Boolean(profile instanceof Object
        && profile.stripeBankObject
        && profile.stripeBankObject instanceof Object
        && Object.keys(profile.stripeBankObject).length)
          || Boolean(profile.stripeBankObjects instanceof Array && profile.stripeBankObjects.length));
      if (checkUserHasUnpaidPolicies(context.state.policies) || !checkUserHasAllPaymentMethods(context.rootState.user.profile)) {
        context.dispatch('user/fetchUser', null, { root: true })
          .then(resolve)
          .catch(reject);
      } else {
        resolve();
      }
    });
  },
  fetchPolicies(context) {
    return UserProvider.userPoliciesQuery().then((r) => {
      if (r.data.data && r.data.data.me && r.data.data.me.policies) {
        context.dispatch('updatePolicies', r.data.data.me.policies);
      }
    });
  },
  pushToPolicies(context, pols) {
    return new Promise((resolve, reject) => {
      if (pols instanceof Array) {
        context.commit('SET_POLICIES', [...context.state.policies, ...pols]);
        resolve();
      } else {
        reject(new Error('Policies array was empty'));
      }
    });
  },
  updateLocalPolicy(context, policy) {
    if (policy instanceof Object && Object.keys(policy).length) {
      context.commit('REPLACE_POLICY', policy);
      return Promise.resolve(policy);
    }
    return Promise.reject(new Error('The provided policy object seems to be invalid or empty'));
  },
  updatePolicies(context, pols) {
    return new Promise((resolve, reject) => {
      if (pols instanceof Array) {
        context.commit('SET_POLICIES', pols);
        resolve();
      } else {
        reject(new Error('Policies was not an array'));
      }
    });
  },
  updatePolicyLinks(context, policy) {
    return new Promise((resolve, reject) => {
      if (policy && policy instanceof Object && (policy._id || policy.id) && policy.policyFile && policy.policyPreview) {
        context.commit('UPDATE_POLICY', {
          policyId: policy._id || policy.id,
          updateFragment: {
            policyPreview: policy.policyPreview,
            policyFile: policy.policyFile,
          },
        });
        resolve();
      } else {
        reject(new Error('The action payload was incomplete or invalid'));
      }
    });
  },
  cancelPolicy(context, { policyId, cancellationReason }) {
    return new Promise((resolve, reject) => {
      if (policyId && typeof policyId === 'string') {
        PolicyProvider.cancelPolicyMutation({
          id: policyId,
          reason: cancellationReason,
        }).then((response) => {
          if (response
            && response.data
            && response.data.data
            && response.data.data.cancelPolicy
            && response.data.data.cancelPolicy.code === 200) {
            context.commit('UPDATE_POLICY', {
              policyId,
              updateFragment: {
                isCanceled: true,
              },
            });
          }
        }).catch(reject);
      } else {
        reject(new Error('The required policyId parameter was missing or invalid'));
      }
    });
  },
};

const getters = {
  policies: (context) => context.policies,
  hasPolicies: (context) => !!context.policies.length,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
