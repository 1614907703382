<template>
  <PhDialog
    :value="value"
    :class="b()"
    content-text-align="center"
    dismissible
    fullscreen
    narrow-content
    @close="handleClose"
  >
    <div :class="b('primaryContent')" slot="content">
      <div :class="b('content')">
        <v-container grid-list-md :class="b('container')">
          <OrgApplicationStepTitle :class="b('title')">
            {{ $capitalize($t('ui.verifyYourBankAccount')) }} <span v-if="achType !== 'none'">({{ prettyAchType }} account)</span>
          </OrgApplicationStepTitle>
          <div v-if="achType === 'temporary'">
            {{ $t('message.yourRoutingNumberIs') }}: {{ achToVerify.routing_number }}
          </div>
          <div v-html="$t('message.verifyAchFirstSentence')" :class="b('text', { top: true })"></div>
          <div v-html="$t('message.verifyAchSecondSentence')" :class="b('text')"></div>
          <v-form :class="b('form')" ref="approvalForm">
            <v-text-field
              @input="handleInputAmount(arguments[0], 'amount_one')"
              v-money:en="form.amount_one"
              :placeholder="$t('ui.amountNum', { currency: '$', number: 1 })+'*'"
              :min="0"
              :rules="[...inputRules.required, ...inputRules.moneyValue]"
              :value="form.amount_one"
              autocomplete="new-password"
              required
            />
            <v-text-field
              @input="handleInputAmount(arguments[0], 'amount_two')"
              v-money:en="form.amount_two"
              :placeholder="$t('ui.amountNum', { currency: '$', number: 2 })+'*'"
              :min="0"
              :rules="[...inputRules.required, ...inputRules.moneyValue]"
              :value="form.amount_two"
              autocomplete="new-password"
              required
            />
          </v-form>
        </v-container>
      </div>
    </div>
    <div slot="footer" :class="b('footer')">
      <v-btn :class="b('button')" @click="validateForm" wide uppercase>{{ $t('ui.verify') }}</v-btn>
      <div :class="b('subtext')">
        <span>{{ $t('ui.numberOfRemainingAttempts') }}: </span><span :class="b('subtext', { number: true })">{{ achToVerify.attempts }}</span>
      </div>
    </div>
  </PhDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OrgApplicationStepTitle } from '@/components/orgApplication/OrgApplicationStep';
import PhDialog from '@/components/dialogs/PhDialog';
import inputRules from '@/utils/helpers/inputRules';

export default {
  name: 'AchApprovalDialog',
  block: 'achApprovalDialog',
  components: {
    OrgApplicationStepTitle,
    PhDialog,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      inputRules,
      form: {
        amount_one: '',
        amount_two: '',
      },
    };
  },
  watch: {
    value(shown) {
      if (!shown) {
        this.clearForm();
      }
    },
  },
  computed: {
    ...mapGetters('user', [
      'profile',
      'achToVerify',
    ]),
    achType() {
      if (this.achToVerify && this.achToVerify instanceof Object) {
        const achToVerifyIsPermanent = Boolean(this.profile.stripeBankObject
          && this.profile.stripeBankObject instanceof Object
          && this.profile.stripeBankObject.token === this.achToVerify.token);
        return achToVerifyIsPermanent ? 'default' : 'temporary';
      }
      return 'none';
    },
    prettyAchType() {
      return this.$t(`ui.${this.achType}`);
    },
  },
  methods: {
    ...mapActions('user', [
      'verifyAchAccount',
      'verifyTemporaryAchAccount',
      'hideAchApprovalDialog',
    ]),
    clearForm() {
      if (this.$refs.approvalForm) {
        this.$refs.approvalForm.reset();
      }
    },
    handleInputAmount(value, field) {
      this.form[field] = value;
    },
    handleClose() {
      this.hideAchApprovalDialog();
    },
    handleVerify() {
      let amounts = [];
      amounts.push(parseInt(this.form.amount_one, 10));
      amounts.push(parseInt(this.form.amount_two, 10));
      if (this.achType && this.achType === 'temporary') {
        this.verifyTemporaryAchAccount({
          amounts,
          token: this.achToVerify.token,
        }).then(() => {
          this.hideAchApprovalDialog();
        }).catch((err) => {
          console.error(err);
        });
      } else {
        this.verifyAchAccount(amounts).then(() => {
          this.hideAchApprovalDialog();
        }).catch((err) => {
          console.error(err);
        });
      }
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        let valid = this.$refs.approvalForm.validate();
        if (valid) {
          this.handleVerify();
        }
        resolve(valid);
      });
    },
  },
};

</script>

<style lang="scss" scoped>
$block: 'achApprovalDialog';

.#{$block} {
  &__container {
    padding: 4px 0px;
    @include mappy-bp(md) {
      padding: 8px;
    }
  }
  &__title {
    color: #17191D;
    margin-bottom: 36px;
    font-size:  18px;
    letter-spacing: 0.39px;
    line-height: 30px;
    @include mappy-bp(md) {
      font-size: 32px;
      letter-spacing: 1.14px;
      line-height: 54px;
    }
  }
  &__text {
    font-size: 16px;
    color: #5C636C;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 24px;
    &--top {
      margin-bottom: 20px;
    }
  }
  &__subtext {
    color: $text-light-grey;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-top: 37px;
    &--number {
      color: #111;
    }
  }
  &__form {
    margin: 37px auto 0;
    max-width: 342px;
    @include mappy-bp(md) {
      margin: 68px auto 0;
      max-width: 358px;
    }
  }
}
</style>
