import { TOAST_TYPES } from '@/utils/constants';

export default (type) => {
  if (typeof type === 'string' && type.length) {
    const typeMap = {
      [TOAST_TYPES.INFO]: ['RequestSOA', 'PolicyPDF', 'ProofPDF'],
      [TOAST_TYPES.WARNING]: ['PolicyCanceled', 'FnolCreated', 'FnolUpdated', 'FnolEscalated'],
      [TOAST_TYPES.DANGER]: ['RequestRejected', 'PolicyExpired', 'ProtocolFilesMissing'],
      [TOAST_TYPES.SUCCESS]: ['RequestApproved', 'PolicyProlonged', 'PolicyPurchased'],
    };
    const typeEntryMatch = Object.entries(typeMap).find((entry) => entry[1].find((val) => val === type));
    if (typeEntryMatch && typeof typeEntryMatch[0] === 'string' && typeEntryMatch[0].length) {
      return typeEntryMatch[0];
    }
  }
  return TOAST_TYPES.INFO;
};
