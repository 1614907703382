import ServiceProvider from '../provider';

import QUERIES from './queries';
import MUTATIONS from './mutations';

class PolicyProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  cancelPolicyMutation({ id, reason }) {
    return this.mutate(this.mutations.cancelPolicy, { id, reason });
  }
}
export default new PolicyProvider(QUERIES, MUTATIONS);
