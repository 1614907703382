export default () => ({
  soa: {
    items: [
      {
        materialIcon: 'account_balance',
        i18nKey: 'ui.organizations',
        exact: true,
        to: {
          name: 'soa.organizations',
        },
      },
      {
        materialIcon: 'receipt',
        i18nKey: 'ui.quotes',
        exact: false,
        to: {
          name: 'soa.quotes',
        },
      },
      {
        materialIcon: 'receipt',
        i18nKey: 'ui.policies',
        exact: true,
        to: {
          name: 'soa.policies',
        },
      },
      {
        materialIcon: 'check_circle',
        i18nKey: 'ui.certificates',
        exact: true,
        to: {
          name: 'soa.proofOfInsurance',
        },
      },
      {
        materialIcon: 'notification_important',
        i18nKey: 'ui.fnols',
        exact: true,
        to: {
          name: 'soa.fnols',
        },
      },
      {
        materialIcon: 'attach_money',
        i18nKey: 'ui.claims',
        exact: true,
        to: {
          name: 'soa.claims',
        },
      },
    ],
  },
  guest: {
    items: [
      {
        materialIcon: 'exit_to_app',
        i18nKey: 'ui.logIn',
        exact: true,
        to: {
          name: 'auth.login',
        },
      },
      {
        materialIcon: 'person_add',
        i18nKey: 'ui.signUp',
        exact: true,
        to: {
          name: 'auth.signUp',
        },
      },
    ],
  },
});
