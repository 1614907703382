<template>
  <div :class="b({ [position]: true })">
    <transition-group :class="b('wrapper')" name="slide-left" mode="out-in" tag="div">
      <Notification
        v-for="toast in toasts"
        :key="toast.id"
        :toast="toast"
        @click="onToastClick"
        @delete="deleteToast"
        isToast
        />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '@/components/Notification';

export default {
  name: 'Toasts',
  block: 'toasts',
  components: {
    Notification,
  },
  props: {
    position: {
      type: String,
      default: 'left-bottom',
    },
  },
  computed: {
    ...mapGetters('notification', ['toasts']),
  },
  methods: {
    ...mapActions('notification', ['deleteToast']),
    onToastClick(toast) {
      this.$emit('toastClick', toast);
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'toasts';

.#{$block} {
  position: fixed;
  z-index: 9999999;
  filter: drop-shadow(0px 4px 12px rgba(39, 41, 37, 0.18));
  /* Positions */
  &--left-bottom {
    bottom: 120px;
    left: 20px;
  }
  &--left-top {
    top: 20px;
    left: 20px;
  }
  &--right-bottom {
    bottom: 20px;
    right: 20px;
  }
  &--right-top {
    top: 20px;
    right: 20px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}
/* Animation */
.slide-left-enter-active, .slide-left-leave-active {
  transition: all .5s;
  transform: translateX(0);
}
.slide-left-leave-active {
  position: absolute;
}
.slide-left-enter, .slide-left-leave-to {
  opacity: 0;
  transition: all .5s;
  transform: translateX(-100%);
}
.slide-left-move {
  transition: all 0.5s;
}
</style>
