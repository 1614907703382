const route = {
  name: 'logout',
  path: 'logout',
  component: () => import('@/pages/auth/Logout'),
  meta: {
    requiresAuth: true,
  },
};

export default route;
