const route = {
  name: 'password-recovery',
  path: 'password-recovery',
  component: () => import('@/pages/auth/PasswordRecovery'),
  meta: {
    disableDrawer: true,
  },
};

export default route;
