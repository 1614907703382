<template>
    <v-layout :class="b({ fullscreen: !isMobile })" column pa-0>
      <div :class="b('wrapper')">
        <div :class="b('header')">
          <NavStepper
            :value="current"
            :steps="tabNames"
            :last-complete-step="lastCompleteStep"
            :mobile="isMobile"
            @input="(number) => handleTabClick({number})"
          >
            <span slot="step" slot-scope="{ step }">{{ step }}</span>
          </NavStepper>
        </div>
        <div :class="b('stepWrapper')">
          <div :class="b('step')">
            <OrgApplicationStep :class="b('orgStepFacade')">
              <OrgApplicationStepTitle v-if="title" slot="title" :class="b('title')" center>
                {{ title }}
              </OrgApplicationStepTitle>
                <div slot="primary" :class="b('content')">
                  <transition name="fade">
                      <component
                        :form="currentForm"
                        :selectedOrg="selectedOrg"
                        :accidentDate="claimAccidentDate"
                        :accidentTime="claimAccidentTime"
                        :currentStep="current"
                        :is="currentComponent"
                        ref="step"
                        @updateStepForm="updateForm"
                        @nextStep="handleNextStep"
                        @goBack="handleBackClick"
                      />
                  </transition>
                </div>
            </OrgApplicationStep>
          </div>
        </div>
        <div :class="b('footer')" v-if="hasPolicies">
            <v-container px-4 py-0>
              <div :class="b('footerBtnRow')">
                <div :class="b('footerBtnWrapper', { prev: true })">
                  <v-btn outlined wide @click="handleBackClick">{{ $t('ui.back') }}</v-btn>
                </div>
                <div :class="b('footerBtnWrapper', { next: true })">
                  <v-btn @click="handleNextClick" wide>
                    {{ $t('ui.next') }}
                  </v-btn>
                </div>
              </div>
            </v-container>
        </div>
      </div>
    </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import actionStepsComponents from '@/components/claim/action/steps';
import scrollUtils from '@/utils/mixins/scrollUtils';
import NavStepper from '@/components/steppers/NavStepper';
import { OrgApplicationStep, OrgApplicationStepTitle } from '@/components/orgApplication/OrgApplicationStep';

const ClaimActionStepper = () => import('@/components/claim/action/ClaimActionStepper');
const ArrowBtn = () => import('@/components/buttons/ArrowBtn');
const NoPoliciesToProceed = () => import('@/components/policy/NoPoliciesToProceed');

export default {
  name: 'ClaimAction',
  block: 'claimAction',
  mixins: [scrollUtils({
    scrollableElement: 'scrollableContent',
  })],
  components: {
    ClaimActionStepper,
    ArrowBtn,
    NavStepper,
    OrgApplicationStep,
    OrgApplicationStepTitle,
  },
  props: {
    id: {
      type: [String, Number],
    },
    claim: {
      type: Object,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'Organization',
          title: this.$t('ui.orgName'),
          id: 'org',
          valid: false,
          number: 1,
        },
        {
          name: 'Claimant',
          id: 'claimant',
          valid: false,
          number: 2,
        },
        {
          name: 'Injury',
          id: 'inj',
          valid: false,
          number: 3,
        },
        {
          name: 'Insurance',
          id: 'ins',
          valid: false,
          number: 4,
        },
      ],
      forms: this.buildForms(),
      current: 1,
    };
  },
  computed: {
    ...mapGetters('policy', ['hasPolicies']),
    ...mapGetters('layout', ['isMobile']),
    claimAccidentDate() {
      const form = this.forms.find((el) => el.number == 3);
      if (form instanceof Object && form.data instanceof Object) {
        return form.data.accident_date;
      }
      return '';
    },
    claimAccidentTime() {
      const form = this.forms.find((el) => el.number == 3);
      if (form instanceof Object && form.data instanceof Object) {
        return form.data.accident_time;
      }
      return '';
    },
    currentComponent() {
      return this.hasPolicies ? actionStepsComponents[this.current] : NoPoliciesToProceed;
    },
    currentForm() {
      return this.forms[this.forms.findIndex((el) => el.number == this.current)].data;
    },
    lastCompleteStep() {
      return this.tabs.filter((tab) => tab.valid).length;
    },
    selectedOrg() {
      return this.forms.find((el) => el.number == 1).data;
    },
    title() {
      switch (this.current) {
        case 1:
          return this.tabs[0].title;
        default:
          return null;
      }
    },
    tabNames() {
      return this.tabs.map((tab) => tab.name);
    },
  },
  watch: {
    currentComponent() {
      // scroll to the top of the claim action's
      // scrollable el when the step changes
      this.scrollToTop();
    },
  },
  async created() {
    await Promise.all([
      this.fetchOrganizations(),
      this.fetchOrganizationTypes(),
      this.fetchStates(),
      this.fetchCountries(),
      this.fetchPolicies(),
    ]);
    if (this.claim) {
      this.populateForm(this.claim);
    } else if (this.id) {
      try {
        this.populateForm((await this.fetchClaim(this.id)).data.data.claim);
      } catch (e) {
        console.error(e);
      }
    }
  },
  methods: {
    ...mapActions('claim', [
      'fetchClaim',
    ]),
    ...mapActions('organization', [
      'fetchOrganizations',
    ]),
    ...mapActions('policy', [
      'fetchPolicies',
    ]),
    ...mapActions('glossary', [
      'fetchOrganizationTypes',
      'fetchStates',
      'fetchCountries',
    ]),
    handleNextClick() {
      if (this.$refs.step && typeof this.$refs.step.validateForm === 'function') {
        const valid = this.$refs.step.validateForm();
        if (!valid) {
          this.scrollToFirstError();
        }
      }
    },
    handleTabClick(tab) {
      this.goToStep(tab.number);
    },
    handleNextStep(payload) {
      Object.assign(this.tabs[this.tabs.findIndex((el) => el.number == this.current)], {
        valid: true,
      });
      if (this.forms.findIndex((el) => el.number == payload) > -1) {
        this.goToStep(payload);
      } else {
        this.$emit('handleSubmit', this.buildClaimRequestObject(this.forms));
      }
    },
    goToStep(payload) {
      this.current = payload;
    },
    handleBackClick() {
      if (this.current < 2) {
        this.$router.go(-1);
      } else {
        this.current--;
      }
    },
    updateForm({ number, form }) {
      let index = this.forms.findIndex((el) => el.number == number);
      Object.assign(this.forms[index].data, form);
    },
    populateForm(payload) {
      Object.assign(this.forms.find((el) => el.number == 1).data, this.populateCreateOrgForm(payload.organization));
      Object.assign(this.forms.find((el) => el.number == 2).data, this.populateClaimantForm(payload));
      Object.assign(this.forms.find((el) => el.number == 3).data, this.populateInjuryForm(payload));
      Object.assign(this.forms.find((el) => el.number == 4).data, this.populateInsuranceForm(payload));
    },
    populateCreateOrgForm(payload) {
      let orgForm = { ...this.forms.find((el) => el.number == 1).data };
      orgForm.orgId = payload.id;
      orgForm.icon = payload.icon;
      orgForm.type = payload.type;
      const generalFields = ['name', 'phone', 'site', 'placeId'];
      const addressFields = ['address', 'city', 'country', 'state', 'zip'];
      const address = { ...payload.address };

      Object.entries(payload).forEach((ent) => {
        if (generalFields.includes(ent[0]) && ent[1]) {
          Object.assign(orgForm, { [ent[0]]: ent[1] });
        }
      });
      Object.entries(address).forEach((ent) => {
        if (addressFields.includes(ent[0]) && ent[1]) {
          if (ent[0] === 'address') {
            Object.assign(orgForm.address, { street: ent[1] });
          } else {
            Object.assign(orgForm.address, { [ent[0]]: ent[1] });
          }
        }
      });
      return orgForm;
    },
    populateClaimantForm(payload) {
      return {
        name: payload.name,
        birthdate: payload.birthdate,
        // icon: icon,
        claimGender: payload.gender,
        visa: payload.socialSecurity,
        personAge: payload.isPersonDependent,
        employer: payload.employerName,
        guardian: payload.guardianName,
        //
        mailing: {
          street: payload.mailing.address,
          city: payload.mailing.city,
          state: payload.mailing.state,
          country: payload.mailing.country,
          zip: payload.mailing.zip,
          phone: payload.mailing.phone,
        },
        business: {
          street: payload.address.address,
          city: payload.address.city,
          state: payload.address.state,
          country: payload.address.country,
          zip: payload.address.zip,
          phone: payload.address.phone,
        },
      };
    },
    populateInjuryForm(payload) {
      let form = { ...this.forms.find((el) => el.number == 3).data };
      form.location = payload.injuryLocation;
      if (payload.injuryLocation.length) {
        this.searchLocationQuery = payload.injuryLocation;
        form.location = payload.injuryLocation;
      }
      form.witness = payload.isWitness;
      form.physician = payload.physicalName;
      form.activity = payload.supervisorName;
      form.accident = payload.incidentDescription;
      form.report = payload.injuryReport;
      form.body_part = payload.bodyPart;

      form.hospital = payload.hospitalName;

      form.type_sports = payload.activityType;
      form.injuryType = payload.injuryType;

      if (payload.injuryDate) {
        let momentInjuryDate = this.$lib.moment(payload.injuryDate);
        form.accident_date = (momentInjuryDate instanceof Object
          && momentInjuryDate._isAMomentObject
          && momentInjuryDate.utc().format('YYYY-MM-DD'))
          || null;
        form.accident_time = (momentInjuryDate instanceof Object
          && momentInjuryDate._isAMomentObject
          && momentInjuryDate.utc().format('HH:mm'))
          || null;
      }
      if (payload.admittanceDate) {
        let momentAdmittanceDate = this.$lib.moment(payload.admittanceDate);
        form.admittance_date = (momentAdmittanceDate instanceof Object
          && momentAdmittanceDate._isAMomentObject
          && momentAdmittanceDate.utc().format('YYYY-MM-DD'))
          || null;
        form.admittance_time = (momentAdmittanceDate instanceof Object
          && momentAdmittanceDate._isAMomentObject
          && momentAdmittanceDate.utc().format('HH:mm'))
          || null;
      }
      if (payload.treatmentDate) {
        let momentTreatmentDate = this.$lib.moment(payload.treatmentDate);
        form.treatment_date = (momentTreatmentDate instanceof Object
          && momentTreatmentDate._isAMomentObject
          && momentTreatmentDate.utc().format('YYYY-MM-DD'))
          || null;
        form.treatment_time = (momentTreatmentDate instanceof Object
          && momentTreatmentDate._isAMomentObject
          && momentTreatmentDate.utc().format('HH:mm'))
          || null;
      }
      return form;
    },
    populateInsuranceForm(payload) {
      return {
        company_name: payload.otherInsuranceName,
        policy_number: payload.policyNumber,
        // certificate_number: payload.certificateNumber,
        person_covered: payload.isInjuredPersonCovered,
        claimant_name: payload.reporterName,
      };
    },
    buildClaimRequestObject(forms) {
      let reqObj = {
        ...this.assembleOrgForm(forms.find((el) => el.number == 1).data),
        ...this.assembleClaimantForm(forms.find((el) => el.number == 2).data),
        ...this.assembleInjuryForm(forms.find((el) => el.number == 3).data),
        ...this.assembleInsuranceForm(forms.find((el) => el.number == 4).data),
      };
      const removeEmptyFields = (obj) => {
        let newObj = {};
        Object.keys(obj).forEach((prop) => {
          if (obj[prop] || obj[prop] === false) { newObj[prop] = obj[prop]; }
        });
        return newObj;
      };
      return removeEmptyFields(reqObj);
    },
    assembleOrgForm(orgForm) {
      return {
        status: 'Processing',
        organization: orgForm.id,
      };
    },
    assembleClaimantForm(claimInfo) {
      return {
        name: claimInfo.name,
        birthdate: claimInfo.birthdate,
        gender: claimInfo.claimGender,
        employerName: claimInfo.employer,
        guardianName: claimInfo.guardian,
        socialSecurity: claimInfo.visa,
        isPersonDependent: claimInfo.personAge,
        mailing: {
          address: claimInfo.mailing.street,
          state: claimInfo.mailing.state,
          city: claimInfo.mailing.city,
          country: claimInfo.mailing.country,
          zip: claimInfo.mailing.zip,
          phone: claimInfo.mailing.phone,
        },
        address: {
          address: claimInfo.business.street,
          state: claimInfo.business.state,
          city: claimInfo.business.city,
          country: claimInfo.business.country,
          zip: claimInfo.business.zip,
          phone: claimInfo.business.phone,
        },
      };
    },
    assembleInjuryForm(claimInjury) {
      return {
        injuryLocation: claimInjury.location,
        isPolicyholderJurisdiction: claimInjury.policy,
        injuryDate: claimInjury.accident_time ? `${claimInjury.accident_date} ${claimInjury.accident_time}` : claimInjury.accident_date,
        injuryType: claimInjury.injuryType,
        treatmentDate: claimInjury.treatment_date,
        admittanceDate: claimInjury.admittance_time ? `${claimInjury.admittance_date} ${claimInjury.admittance_time}` : claimInjury.admittance_date,
        isWitness: claimInjury.witness,
        bodyPart: claimInjury.body_part,
        incidentDescription: claimInjury.accident,
        supervisorName: claimInjury.activity,
        physicalName: claimInjury.physician,
        injuryReport: claimInjury.report,
        hospitalName: claimInjury.hospital,
        activityType: claimInjury.type_sports,
      };
    },
    assembleInsuranceForm(claimInsurance) {
      return {
        isInjuredPersonCovered: claimInsurance.person_covered,
        policyNumber: claimInsurance.policy_number,
        // certificateNumber: claimInsurance.certificate_number,
        otherInsuranceName: claimInsurance.company_name,
        reporterName: claimInsurance.claimant_name,
      };
    },
    buildForms: () => ([
      {
        data: {
          name: '',
          icon: '',
          email: '',
          site: '',
          phone: '',
          $control: {
            noSeparateMailingAddress: false,
          },
          address: {
            street: '',
            city: '',
            country: null,
            state: null,
            zip: '',
          },
          mailing: {
            street: '',
            city: '',
            country: null,
            state: null,
            zip: '',
          },
        },
        number: 1,
      },
      {
        data: {
          name: '',
          visa: '',
          birthdate: '',
          mailing: {
            street: '',
            city: '',
            state: null,
            country: null,
            zip: '',
            phone: '',
          },
          business: {
            street: '',
            city: '',
            state: null,
            country: null,
            zip: '',
            phone: '',
          },
          personAge: true,
          claimGender: 'Female',
          employer: '',
          guardian: '',
        },
        number: 2,
      },
      {
        data: {
          location: '',
          policy: true,
          witness: true,
          activity: '',
          type_sports: '',
          accident: '',
          report: '',
          body_part: '',
          hospital: '',
          injuryType: '',
          physician: '',
          accident_date: null,
          accident_time: null,
          admittance_date: null,
          admittance_time: null,
          treatment_date: null,
        },
        number: 3,
      },
      {
        data: {
          person_covered: true,
          company_name: '',
          policy_number: '',
          // certificate_number: '',
          claimant_name: '',
        },
        number: 4,
      },
    ]),
  },
};
</script>

<style lang="scss">
$block: 'claimAction';

.#{$block} {
  &--fullscreen {
    height: 100%;
    max-height: calc(100vh - #{$header-height});
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0px;
  }
  &__stepWrapper {
    max-width: 800px;
    width: 100%;
    height: 100%;
    margin: 0 auto 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
    min-height: 0px;
    padding: 0 16px;
    @include mappy-bp(xs) {
      z-index: 1;
      padding: 0;
    }
  }
  &__step {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0px;
    & > .orgApplicationStep {
      flex: 1;
    }
    @include mappy-bp(xs) {
      padding: 0;
    }
    & > .signUpPolicyConfigurator {
      margin-top: 24px;
    }
  }
  &__content {
    padding: 0;
    @include mappy-bp(md) {
      padding: 20px 0;
    }
  }
  &__header {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 24px;
  }
  &__footer {
    z-index: 2;
    height: 48px;
    padding-bottom: 150px;
    @include mappy-bp(xs) {
      height: 1px;
      position: fixed;
      left: 17px;
      right: 17px;
      bottom: 10px;
      width: calc(100% - 34px);
      padding-bottom: 0;
    }
    @include mappy-bp(md) {
      width: 100%;
      bottom: 0px;
      right: auto;
      left: 0px;
    }
  }
  &__footerBtnRow {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  &__footerBtnWrapper {
    position: relative;
    @include mappy-bp(xs) {
      position: absolute;
      bottom: 42px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      & > .v-btn.v-btn--ph {
        min-width: 115px;
        margin-left: 0;
        margin-right: 0;
        @include mappy-bp(md) {
          min-width: 175px;
        }
      }
      &--prev {
        flex: 1;
        left: 15px;
        bottom: 42px;
      }
      &--next {
        right: 20px;
      }
    }
    @include mappy-bp(md) {
      &--prev {
        left: 0px;
        bottom: 42px;
      }
      &--next {
        right: 0px;
      }
    }
  }
  &__buttonWrapper {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
