import { TOURNAMENT_ORG_TYPE_ID } from '@/utils/constants';

const getParamsPayload = (params) => Object.entries(params).reduce((acc, paramEnt) => {
  if (paramEnt[1] !== null && paramEnt[1] !== undefined) {
    acc[paramEnt[0]] = paramEnt[1];
  }
  return acc;
}, {});

export default (state) => {
  const payload = {
    type: state.selectedOrgType,
  };
  if (state.params instanceof Object) {
    const paramsPayload = getParamsPayload(state.params);
    if (Object.keys(paramsPayload).length) {
      payload.params = paramsPayload;
    }
  }
  return payload;
};
