import intersection from 'lodash/intersection';

const ALLOWED_COVERAGES = ['generalLiability'];

export default (steps, coverages, allowedCoverages = ALLOWED_COVERAGES) => {
  if ((steps && steps instanceof Object)
    && allowedCoverages instanceof Array
    && (coverages instanceof Array && coverages.length)) {
    const providerSubSteps = ['orgRegProvider', 'orgBackgroundCheck', 'registrationProvider', 'backgroundCheck'];
    const coveragesIntersection = intersection(coverages, allowedCoverages);
    if (!coveragesIntersection.length) {
      return Object.entries(steps).reduce((acc, entry) => {
        const step = entry[0];
        acc[step] = entry[1].filter((subStep) => !providerSubSteps.includes(subStep));
        return acc;
      }, {});
    }
  }
  return steps;
};
