import { DEFAULT_HEADER_COLOR } from '@/utils/constants';
import { langs } from '@/data/langs';
import i18n from '../../i18n';

const state = {
  $locale: langs.EN_US,
  headerColor: DEFAULT_HEADER_COLOR,
  anyInputFocused: false,
  showingFooter: false,
  showingHeader: true,
  showingMenu: false,
  showingNotify: false,
  showingToolbar: true,
  showingPreloader: false,
  screenHeight: 0,
  screenWidth: 0,
  showInsuranceDetails: false,
};

const mutations = {
  HIDE_FOOTER(context) {
    const ctx = context;
    ctx.showingFooter = false;
  },
  SHOW_FOOTER(context) {
    const ctx = context;
    ctx.showingFooter = true;
  },
  TOGGLE_FOOTER(context) {
    const ctx = context;
    ctx.showingFooter = !context.showingFooter;
  },
  HIDE_HEADER(context) {
    const ctx = context;
    ctx.showingHeader = false;
  },
  SHOW_HEADER(context) {
    const ctx = context;
    ctx.showingHeader = true;
  },
  TOGGLE_HEADER(context) {
    const ctx = context;
    ctx.showingHeader = !context.showingHeader;
  },
  HIDE_MENU(context) {
    const ctx = context;
    ctx.showingMenu = false;
  },
  SHOW_MENU(context) {
    const ctx = context;
    ctx.showingMenu = true;
  },
  SHOW_NOTIFY(context) {
    const ctx = context;
    ctx.showingNotify = true;
  },
  HIDE_NOTIFY(context) {
    const ctx = context;
    ctx.showingNotify = false;
  },
  TOGGLE_MENU(context) {
    const ctx = context;
    ctx.showingMenu = !context.showingMenu;
  },
  SHOW_PRELOADER(context) {
    context.showingPreloader = true;
  },
  HIDE_PRELOADER(context) {
    context.showingPreloader = false;
  },
  RESIZE_WINDOW(context, { width, height }) {
    context.screenWidth = width;
    context.screenHeight = height;
  },
  TOOGLE_INSURANCE_VIEWS(context) {
    const ctx = context;
    ctx.showInsuranceDetails = !context.showInsuranceDetails;
  },
  SET_HEADER_COLOR(context, color) {
    if (color && typeof color === 'string') {
      context.headerColor = color;
    }
  },
  SET_INPUT_FOCUSED(context, value) {
    context.anyInputFocused = !!value;
  },
  SET_LOCALE(ctx, payload) {
    ctx.$locale = payload;
  },
};

const actions = {
  changeLocale({ commit }, locale) {
    i18n.locale = langs[locale].i18n;
    commit('SET_LOCALE', langs[locale]);
  },
  updateFooter(context, value) {
    const ctx = context;
    ctx.commit(value ? 'SHOW_FOOTER' : 'HIDE_FOOTER');
  },
  toggleFooter(context) {
    const ctx = context;
    ctx.commit('TOGGLE_FOOTER');
  },
  updateHeader(context, value) {
    const ctx = context;
    ctx.commit(value ? 'SHOW_HEADER' : 'HIDE_HEADER');
    return Promise.resolve(true);
  },
  toggleHeader(context) {
    const ctx = context;
    ctx.commit('TOGGLE_HEADER');
  },
  updateMenu(context, value) {
    const ctx = context;
    ctx.commit(value ? 'SHOW_MENU' : 'HIDE_MENU');
  },
  hideMenu(context) {
    const ctx = context;
    ctx.commit('HIDE_MENU');
  },
  showMenu(context) {
    const ctx = context;
    ctx.commit('SHOW_MENU');
  },
  showNotify(context) {
    const ctx = context;
    ctx.commit('SHOW_NOTIFY');
  },
  hideNotify(context) {
    const ctx = context;
    ctx.commit('HIDE_NOTIFY');
  },
  toggleMenu(context) {
    const ctx = context;
    ctx.commit('TOGGLE_MENU');
  },
  showPreloader(context) {
    context.commit('SHOW_PRELOADER');
  },
  hidePreloader(context) {
    context.commit('HIDE_PRELOADER');
  },
  resizeWindow(context, { width, height }) {
    context.commit('RESIZE_WINDOW', { width, height });
  },
  setHeaderColor(context, value) {
    context.commit('SET_HEADER_COLOR', value || DEFAULT_HEADER_COLOR);
  },
  transparentizeHeader(context) {
    context.dispatch('setHeaderColor', 'transparent');
  },
  toggleInsuranceDetails(context) {
    context.commit('TOOGLE_INSURANCE_VIEWS');
  },
  listenToMobileKeyboardOpen(context, mobileOnly = true) {
    if (mobileOnly && !context.getters.isMobile) {
      return;
    }
    let focusInEventHandler = function (e) {
      if (e.target.tagName === 'INPUT') {
        this.commit('SET_INPUT_FOCUSED', true);
      }
    };
    let focusOutEventHandler = function (e) {
      if (e.target.tagName === 'INPUT') {
        this.commit('SET_INPUT_FOCUSED', false);
      }
    };
    if (document && document instanceof Object && typeof document.addEventListener === 'function') {
      focusInEventHandler = focusInEventHandler.bind(context);
      focusOutEventHandler = focusOutEventHandler.bind(context);
      document.addEventListener('focusin', focusInEventHandler);
      document.addEventListener('focusout', focusOutEventHandler);
    }
  },
};

const getters = {
  headerColor: (context) => context.headerColor,
  headerTransparent: (context) => context.headerColor === 'transparent',
  isMobile: (context) => context.screenWidth <= 920,
  mobileKeyboardOpen: (context, contextGetters) => contextGetters.isMobile && context.anyInputFocused,
  showingFooter: (context) => context.showingFooter,
  showingHeader: (context) => context.showingHeader,
  showingMenu: (context) => context.showingMenu,
  showingNotify: (context) => context.showingNotify,
  showingToolbar: (context) => context.showingToolbar,
  showingPreloader: (context) => context.showingPreloader,
  showInsuranceDetails: (context) => context.showInsuranceDetails,
  screenHeight: (context) => context.screenHeight,
  screenWidth: (context) => context.screenWidth,
  currentLocale: (context) => context.$locale,
  currentMomentLocale: (context) => context.$locale.moment,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
