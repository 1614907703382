import options from '@/data/options';
import moment from 'moment';
import { PROPOSAL } from '@/store/modules/orgApplication/constants';

export default (state, getters, rootGetters) => {
  const orgToComplete = state.organizationToComplete;
  const orgToCompleteShoppingCartItems = rootGetters['shoppingCart/shoppingCartItems']
    .filter((itm) => state.organizationToComplete
      && itm
      && itm.organization
      && itm.organization.id === state.organizationToComplete.id);
  const checkHasShoppingCartItemsOfCoverageTypeForOrgToComplete = (coverageType) => orgToCompleteShoppingCartItems
    .some((itm) => itm && itm.quote && itm.quote.coverageType === coverageType);
  const checkOrgAlreadyHasAPolicyOfThisType = (orgId, coverageType, policies) => {
    if (typeof orgId === 'string'
      && orgId.length
      && typeof coverageType === 'string'
      && coverageType.length
      && policies instanceof Array
      && policies.length) {
      return policies.some((pol) => pol.organization instanceof Object
        && pol.organization.id === orgId
        && pol.quote instanceof Object
        && pol.quote.coverageType === coverageType
        && moment().isBefore(pol.expiredAt));
    }
    return false;
  };
  return getters.coverages.map((cvg) => {
    const outputCoverage = {
      ...cvg,
      chosen: getters.chosenCoverageId === cvg.id,
      endDate: state.coverageParamsMap[cvg.type] ? state.coverageParamsMap[cvg.type].endDate : '',
      isAlreadyPurchased: checkOrgAlreadyHasAPolicyOfThisType(orgToComplete.id, cvg.type, rootGetters['policy/policies']),
      isInCart: orgToCompleteShoppingCartItems.length
        ? checkHasShoppingCartItemsOfCoverageTypeForOrgToComplete(cvg.type)
        : false,
      selectedPaymentCycle: (state.coverageParamsMap[cvg.type]
        && state.coverageParamsMap[cvg.type].selectedPaymentCycle
        && options.paymentCycleOptions.find((opt) => opt.value === state.coverageParamsMap[cvg.type].selectedPaymentCycle))
      || options.paymentCycleOptions[0],
      selectedLimit: state.coverageParamsMap[cvg.type] && state.coverageParamsMap[cvg.type].selectedLimit
        ? state.coverageParamsMap[cvg.type].selectedLimit
        : null,
      startDate: state.coverageParamsMap[cvg.type] ? state.coverageParamsMap[cvg.type].startDate : '',
      proposal: state.coverageParamsMap[cvg.type] ? state.coverageParamsMap[cvg.type].proposal : PROPOSAL,
    };
    if (cvg
      && cvg.subcoverages instanceof Array
      && cvg.subcoverages.length
      && state.coverageParamsMap[cvg.type]
      && state.coverageParamsMap[cvg.type] instanceof Object
      && state.coverageParamsMap[cvg.type].subCoverages instanceof Object) {
      outputCoverage.subcoverages = cvg.subcoverages.map((subCvg) => ({
        ...subCvg,
        chosen: state.chosenSubCoverageIds.includes(subCvg.id),
        selectedLimit: state.coverageParamsMap[cvg.type].subCoverages[subCvg.type] && state.coverageParamsMap[cvg.type].subCoverages[subCvg.type] instanceof Object && !Number.isNaN(state.coverageParamsMap[cvg.type].subCoverages[subCvg.type].selectedLimit)
          ? state.coverageParamsMap[cvg.type].subCoverages[subCvg.type].selectedLimit
          : null,
      }));
    }
    return outputCoverage;
  });
};
