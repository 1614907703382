<template>
  <div :class="b()">
    <v-menu
      ref="vuetifyMenu"
      :value="value"
      :nudge-bottom="5"
      :nudge-left="(minContentWidth - shadowMargin) / 2"
      :min-width="minContentWidth + (shadowMargin * 2)"
      :max-width="minContentWidth + (shadowMargin * 2)"
      :content-class="b('menu')"
      :close-on-content-click="false"
      origin="top"
      offset-y
      @input="onMenuInput"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :custom="false"
          :class="b('button')"
          text
          rounded
        >
          <div :class="b('buttonIconWrapper')">
            <div :class="b('buttonIconWrapperContent')">
              <img v-if="!value" :class="b('buttonIcon')" src="~@/assets/icons/shopping-cart.svg" />
              <img v-else :class="b('buttonIcon')" src="~@/assets/icons/shopping-cart--accent.svg" />
              <div v-if="totalCoverageCount > 0" :class="b('buttonCounter')">{{ totalCoverageCount }}</div>
            </div>
          </div>
        </v-btn>
      </template>
      <div :class="b('contentWrapper')">
        <div :class="b('content')" :style="`max-height: ${maxContentHeight}px;`">
            <div class="vuebar-element" v-bar>
              <div :class="b('vuebarContent')">
                <div v-if="cartEmpty" :class="b('coveragesAndItems', { empty: true })">
                  <div :class="b('placeholderWrapper')">
                    <div :class="b('placeholder')">{{ $t('ui.cartEmpty') }}</div>
                  </div>
                </div>
                <ul v-else :class="b('coveragesAndItems')">
                  <div v-for="(coverage, index) in coveragesAndItems"
                       :key="index"
                       tag="li"
                       :class="b('item')">
                    <div :class="b('itemContent')">
                      <div :class="b('itemContentFull')">
                        <div :class="b('itemHeader')">
                          <h5 :class="b('itemTitle')">{{ coverage.coverageName }}</h5>
                          <div :class="b('itemDeleteBtnWrapper')">
                            <button @click="handleItemGroupDelete(coverage)" :class="b('itemDeleteBtn')"></button>
                          </div>
                        </div>
                        <div :class="b('itemInfoGridWrapper')">
                          <div v-if="coverage.items && coverage.items.length" :class="b('itemInfoGrid')">
                            <div v-for="(item, index) in coverage.items" :key="index" :class="b('itemInfoGridRow')">
                              <div :class="b('itemInfoGridCell', { half: true, dominant: true, noTextWrap: true })">
                                <span v-if="item.premium" :class="b('itemInfoGridCellValue')">
                                  $ {{ item.premium | commifyNumber }}
                                </span>
                              </div>
                              <div :class="b('itemInfoGridCell', { half: true, borderLeft: true, textRight: true })">
                                <span :class="b('itemInfoGridCellLabel')">
                                  {{ (item.quote && item.quote.selectedPaymentCycle) ? item.quote.selectedPaymentCycle.label : $capitalize(item.paymentCycle) }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="!cartEmpty || $route.name != 'soa.organizations'" :class="b('footer')">
          <div :class="b('footerContent')">
            <v-btn
              v-if="cartEmpty && $route.name != 'soa.organizations'"
              :to="{ name: 'soa.organizations' }"
              :class="b('actionBtn')"
              narrow
              smallText
            >
              {{ $t('ui.getQuotes') }}
            </v-btn>
            <v-btn
              v-if="!cartEmpty"
              @click="handleCheckoutClick"
              :class="b('actionBtn')"
              small
              narrow
              smallText
            >
              {{ $t('ui.purchase') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
const maxContentHeight = 400;
const shadowMargin = 15;
const minContentWidth = 280;

export default {
  name: 'ShoppingCartDropdown',
  block: 'shoppingCartDropdown',
  props: {
    value: Boolean,
    coverages: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      maxContentHeight,
      minContentWidth,
      shadowMargin,
    };
  },
  computed: {
    cartEmpty() {
      return !this.items.length;
    },
    coveragesInCartMap() {
      return this.items.reduce((acc, currVal) => {
        const coverageType = (currVal.quote && currVal.quote.coverageType) || '';
        if (currVal.quote) {
          if (!acc[coverageType]) {
            const coverageMatch = this.coverages.find((cvg) => cvg.type === coverageType);
            if (coverageMatch) {
              acc[coverageType] = {
                ...coverageMatch,
                items: [currVal],
              };
            }
          } else {
            acc[coverageType].items.push(currVal);
          }
        }
        return acc;
      }, {});
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    coveragesAndItems() {
      const coveragesInCart = Object.values(this.coveragesInCartMap);
      return coveragesInCart.reduce((acc, currVal) => {
        acc.push({
          coverageName: currVal.name,
          coverageType: currVal.type,
          items: currVal.items.map((itm) => {
            let itemAmount = 0;
            const itemPaymentCycle = (itm.selectedPaymentCycle && itm.selectedPaymentCycle instanceof Object && itm.selectedPaymentCycle.value) || itm.paymentCycle;
            if (itemPaymentCycle) {
              const stringCost = itemPaymentCycle === 'annually' ? itm.annualCost : itm.monthlyCost;
              const parsedCost = Number.parseFloat(stringCost, 10);
              if (!Number.isNaN(parsedCost)) {
                itemAmount = parsedCost.toFixed(2);
              }
            }
            return {
              ...itm,
              premium: itemAmount,
            };
          }),
          itemNumber: currVal.items.length,
        });
        return acc;
      }, []);
    },
    totalCoverageCount() {
      return this.coveragesAndItems && this.coveragesAndItems.reduce((acc, currVal) => {
        acc += currVal.itemNumber;
        return acc;
      }, 0);
    },
  },
  methods: {
    formatDate(dateString) {
      return this.$lib.moment(dateString).format('MMMM Do YYYY, h:mm:ss a');
    },
    handleCheckoutClick() {
      this.$emit('checkout');
    },
    handleItemGroupDelete(item) {
      this.$dialog.confirm({
        description: this.$t('message.removeItemFromCartConfirm'),
      }).then((res) => {
        if (res) {
          this.$emit('itemGroupDelete', item);
        }
      });
    },
    onMenuInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
$block: 'shoppingCartDropdown';

.#{$block} {
  position: relative;
  height: 100%;
  .v-menu {
    height: 100%;
  }
  &__title {
    font-family: $antarctica;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  &__contentTail {
    width: 24px;
    height: 12px;
    background: white;
    position: relative;
    left: calc(50% - 12px);
  }
  &__contentWrapper {
    background: $background-gradient;
    position: relative;
    border-radius: 2px;
    &::before {
      content: '';
      display: block;
      width: 66px;
      background: url('~@/assets/icons/tooltip-tail.svg') 0% 0%/cover no-repeat;
      height: 35px;
      background-position: 50% 335%;
      background-size: 43px;
      position: absolute;
      top: -53px;
      transform: translateX(-20px) translateY(-22px);
      left: calc(50% - 12px);
    }
  }
  &__head {
    height: 50px;
    background-color: #FBFBFB;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &--empty {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  &__headTitle {
    font-weight: 600;
    color: $dark-blue;
  }
  &__content {
    overflow: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &__item {
    padding: 10px;
    display: flex;
    cursor: pointer;
    @include transition-all();
    &--seen {
      background: rgba($turquoise, 0.5);
      color: $white;
    }
  }
  &__itemContent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-width: 0;
  }
  &__itemContentFull {
    min-width: 0;
  }
  &__itemContentHalf {
    flex-basis: 50%;
    @include mappy-bp(sm) {
      &--actionButtonWrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 5px;
      }
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
  &__itemTitle {
    font-family: $antarctica;
    font-weight: 700;
    font-size: 14px;
    word-break: break-word;
    line-height: 1.3;
    color: $black;
    flex: 1;
    margin-bottom: 0px;
  }
  &__itemInfoGridWrapper {
    margin-top: 20px;
  }
  &__itemInfoGrid {
    display: flex;
    flex-direction: column;
  }
  &__itemInfoGridRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:nth-child(1n + 2) {
      padding-top: 10px;
    }
  }
  &__itemInfoGridCell {
    font-size: 12px;
    &--free {
      flex: 1;
    }
    &--dominant {
      flex-grow: 2;
      flex-shrink: 0;
    }
    &--half {
      flex-grow: 1;
      flex-basis: 50%;
    }
    &--textRight {
      text-align: right;
    }
    &--noTextWrap {
      white-space: nowrap;
    }
  }
  &__itemInfoGridCellLabel {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    color: $text-light-grey;
    padding-left: 25px;
    text-transform: capitalize;
  }
  &__itemInfoGridCellValue {
    font-family: $antarctica;
    font-weight: 700;
    color: $diff-black;
    font-size: 0.875rem;
    padding-right: 25px;
  }
  &__button {
    &.v-btn:not(.v-btn--round).v-size--default {
      width: 45px;
      height: 45px;
      min-width: 45px;
      padding: 8px;
      .v-btn__content {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__buttonIconWrapper {
    width: 100%;
    height: 100%;
  }
  &__buttonIconWrapperContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__buttonIcon {
    vertical-align: middle;
  }
  &__buttonCounter {
    min-height: 18px;
    min-width: 16px;
    font-size: 12px;
    line-height: 1.2;
    padding: 3px 4px;
    @include mappy-bp(md) {
      height: 24px;
      min-width: 24px;
      font-size: 15px;
      line-height: 16px;
      padding: 4px 6px;
    }
    background-color: $ph-cyan;
    color: $white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -13px;
  }
  &__actionBtn {
    &.v-btn {
      &--ph {
        &.v-size--small {
          min-width: 165px;
        }
        &--disabled {
          opacity: 0.5;
          pointer-events: none;
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
  &__itemHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid;
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
    border-image: $ph-purple-green 1;
    padding-bottom: 17px;
  }
  &__itemDeleteBtnWrapper {
    flex-shrink: 0;
    margin-left: 25px;
  }
  &__itemDeleteBtn {
    width: 15px;
    height: 15px;
    background: url('~@/assets/icons/delete--ph.svg') 0% 0%/contain no-repeat;
    cursor: pointer;
    border: none;
    outline: none;
  }
  &__itemContentFull {
    @extend .#{$block}__itemContentHalf;
    flex-basis: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  &__footerContent {
    padding: 15px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    &--non-padded {
      padding: 15px 0 0 0;
    }
  }
  &__placeholderWrapper {
    min-height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__placeholder {
    text-align: center;
    font-size: 16px;
    color: $text-light-grey;
    line-height: 1.875;
    padding: 10px 40px;
  }
  &__menu {
    box-shadow: 0px 0px 10px 0px rgb(52 45 71 / 14%);
  }
}
ul.#{$block}__coveragesAndItems {
  padding-top: 10px;
  padding-left: 10px;
  &--empty {
    padding-top: 15px;
  }
}
</style>
