<template>
  <transition name="menu-transition">
    <div :class="b()">
      <div :class="b('head')">
        <div :class="b('headImage')">
          <router-link :to="{ name: 'soa.profile' }">
            <img :class="b('avatar')" :src="getProfileAvatar()" alt="user avatar" />
          </router-link>
        </div>
        <div :class="b('headInfo')">
          <div :class="b('name')">{{ profile.name }}</div>
          <div :class="b('role')">{{ profile.soaRole }}</div>
        </div>
      </div>
      <div :class="b('content')">
        <ul :class="b('links')">
          <li :class="b('link')">
            <router-link :to="{ name: 'soa.profile' }">{{ $t('ui.profile') }}</router-link>
          </li>
          <li :class="b('link')">
            <router-link :to="{ name: 'auth.logout' }">{{ $t('ui.logOut') }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

const USER_PLACEHOLDER_IMG = require('@/assets/images/user-placeholder.jpg');

export default {
  name: 'UserPanel',
  block: 'userPanel',
  computed: {
    ...mapGetters('user', [
      'profile',
    ]),
  },
  methods: {
    getProfileAvatar() {
      if (this.profile.icon) {
        return this.profile.icon;
      }
      return USER_PLACEHOLDER_IMG;
    },
  },
};
</script>

<style lang="scss">
$block: 'userPanel';

.#{$block} {
  position: absolute;
  right: 0;
  z-index: 1000;
  top: 60px;
  box-shadow: 0px 0px 10px 0px rgba(#342D47, 0.14);
  width: 230px;
  border-radius: 2px;
  background: $background-gradient;
  &::before {
  content: '';
    display: block;
    width: 66px;
    background: url('~@/assets/icons/tooltip-tail.svg') 0% 0%/cover no-repeat;
    height: 35px;
    background-position: 50% 335%;
    background-size: 43px;
    position: absolute;
    top: -13px;
    transform: translateX(-20px) translateY(-22px);
    right: -34px;
  }
  &__head {
    background-color: $ph-dark;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__headTitle {
    font-weight: 700;
    color: $dark-blue;
  }
  &__headInfo {
    margin-left: 12px;
    font-size: 14px;
    max-width: 140px;
    color: $white;
    flex: 0 1 auto;
    font-weight: 300;
    .#{$block}__name {
      font-weight: 700;
    }
    div {
      text-overflow: ellipsis;
      text-transform: capitalize;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &__content {
    overflow: auto;
    max-height: 110px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &__links {
    font-size: 16px;
    padding: 0 15px;
    line-height: 28px;
    list-style: none;
    margin: 19px 0;
    li {
      cursor: pointer;
      &:hover {
        a {
          color: $ph-accent;
        }
      }
      a {
        color: #0C1B2D;
        @include transition-all();
        text-decoration: none;
        display: flex;
      }
    }
  }
  &__notificationText {
    font-size: 12px;
    color: #909090;
    line-height: 1.3;
    strong {
      font-weight: 500;
      color: $dark-blue;
    }
  }
  &__headImage {
    flex: 0 1 48px;
    max-width: 48px;
    min-width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
    .#{$block}__avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
