import ServiceProvider from '../provider';
import MUTATIONS from './mutations';

const QUERIES = {};

class AnalyticsProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  sendEventMutation(name, event) {
    return this.mutate(this.mutations.sendStat, { name, value: event });
  }
}
export default new AnalyticsProvider(QUERIES, MUTATIONS);
