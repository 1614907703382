export default (number) => {
  let k = '';
  const divideTillLteThousand = (val) => {
    if (val >= 1000) {
      const dividedVal = val / 1000;
      k += 'k';
      if (dividedVal >= 1000) {
        return divideTillLteThousand(dividedVal);
      }
      return dividedVal;
    }
    return val;
  };
  const parsedNumber = Number.parseInt(number, 10);
  if (!Number.isNaN(parsedNumber)) {
    const thousandAppendixMap = {
      kk: 'm',
      kkk: 'b',
      kkkk: 't',
    };
    const minifiedNumber = divideTillLteThousand(parsedNumber);
    const thousandAppendix = thousandAppendixMap[k] || k;
    return `${minifiedNumber}${thousandAppendix}`;
  }
  return number;
};
