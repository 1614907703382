/* eslint-disable */
export default (options, relevantEntities, disableOptions = true) => {
  if (options instanceof Array) {
    if (relevantEntities instanceof Array) {
      if (disableOptions) {
        return options.map(opt => relevantEntities.includes(opt.value) ? opt : { disabled: true, ...opt });
      }
      return options.filter(opt => relevantEntities.includes(opt.value));
    }
  }
  return options;
};
