<template>
  <div :class="b()">
    <div v-if="emailChangeConfirmed" :class="b('contentBlock')">
      <CongratsSimple>
        <div slot="title" :class="b('titleBlock')">
            <img src="@/assets/images/paperplane--cyan.svg" alt="Paper plane icon" :class="b('titleImage')">
            <span :class="b('titleText')">
              {{$t('ui.congratulations')}}
            </span>
        </div>
        <div slot="subtitle" :class="b('congratsContent')">
          <div :class="b('contentBlockTextWrapper')">
            <p :class="b('contentBlockText', { title: true })">
              {{ $t('response.success.emailConfirmation') }} {{ $t('particles.with') }}
            </p>
            <p :class="b('contentBlockText', { link: true })">
              <a :href="'mailto:' + email">{{ email }}</a>
            </p>
            <p :class="b('contentBlockText', { subtitle: true })">
              {{ $t('ui.clickToProceedToDashboard') }}!
            </p>
          </div>
          <div :class="b('buttonWrapper')">
            <v-btn :to="homeRoute" wide>{{ $t('ui.proceed') }}</v-btn>
          </div>
        </div>
      </CongratsSimple>
    </div>
    <div v-if="emailChangeConfirmationError && !emailChangeConfirmed" :class="b('contentBlock')">
      <CongratsSimple>
        <div slot="title" :class="b('titleBlock')">
          <span :class="b('titleText')">
            {{ $t('response.error.confirmationError') }}
          </span>
        </div>
        <div :class="b('contentBlockText')">
          <p>{{ $t('response.error.emailChangeConfirmationErrorDescription') }}.</p>
          <p>{{ $t('message.doubleCheckInbox') }}.</p>
        </div>
        <div slot="subtitle" :class="b('congratsContent')">
          <div :class="b('contentBlockTextWrapper')">
            <div :class="b('contentBlockText')">
              <p>{{ $t('response.error.emailChangeConfirmationErrorDescription') }}.</p>
              <p>{{ $t('message.doubleCheckInbox') }}.</p>
            </div>
          </div>
        </div>
      </CongratsSimple>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CongratsSimple from '@/components/CongratsSimple';

export default {
  name: 'SignUpConfirmationPage',
  block: 'emailChangeConfirmationPage',
  components: {
    CongratsSimple,
  },
  data() {
    return {
      emailChangeConfirmed: true,
      emailChangeConfirmationError: false,
      email: this.$route.query.email,
      token: this.$route.query.token,
    };
  },
  computed: {
    ...mapGetters('user', ['homeRoute', 'profile']),
  },
  methods: {
    ...mapActions('user', ['confirmUserEmailChange']),
    confirmEmail() {
      if (this.email && this.token) {
        this.confirmUserEmailChange({
          email: this.email,
          token: this.token,
        }).then((response) => {
          if (response.data
            && response.data.data
            && response.data.data.emailChange
            && response.data.data.emailChange.code === 200) {
            this.emailChangeConfirmed = true;
          }
        }).catch((err) => {
          this.emailChangeConfirmed = false;
          this.emailChangeConfirmationError = true;
        });
      } else {
        this.emailChangeConfirmed = false;
        this.emailChangeConfirmationError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'emailChangeConfirmationPage';

.#{$block} {
  height: 100%;
  width: 100%;
  position: relative;
  color: $diff-black;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  &__titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__titleText,
  &__heading {
    font-family: $antarctica;
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
  }
  &__contentBlock {
    text-align: center;
  }
  &__titleImage {
    max-width: 70px;
    margin-bottom: 16px;
  }
  &__contentBlockText {
    margin-top: 25px;
    a {
      text-decoration: none;
      color: $white;
      font-weight: 700;
    }
  }
  &__buttonWrapper {
    margin-top: 65px;
    display: flex;
    justify-content: center;
    .#{$block}__button {
      font-size: 16px;
      text-transform: none;
      max-width: 320px;
    }
  }
}
</style>
