const route = {
  name: 'signUpConfirmation',
  path: 'email-confirmation',
  component: () => import('@/pages/auth/SignUpConfirmation'),
  meta: {
    requiresAuth: false,
    disableDrawer: true,
  },
};

export default route;
