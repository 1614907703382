<template>
  <div :class="b()">
    <v-menu
      :value="value"
      :nudge-bottom="5"
      :max-width="maxContentWidth"
      :close-on-content-click="false"
      :content-class="b('menuContent')"
      offset-y
      ref="vuetifyMenu"
      @input="onShownChange"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :class="b('button')" :custom="false" text rounded>
          <div :class="b('buttonIconWrapper')">
            <img v-if='!value' :class="('buttonIcon')" src="~@/assets/icons/bell--dark.svg" />
            <img v-else :class="('buttonIcon')" src="~@/assets/icons/bell--active.svg" />
            <div v-if="notifications.length" :class="b('buttonCounter')">{{ notifications.length }}</div>
          </div>
        </v-btn>
      </template>
      <div :class="b('contentWrapper')">
        <div :class="b('head', { empty: !notifications.length })">
          <div :class="b('headTitle')">{{$t('ui.notifications')}}</div>
          <div :class="b('headCounter')">{{ notifications.length }}</div>
        </div>
        <div :class="b('content')" :style="`max-height: ${maxContentHeight}px;`">
          <div class="vuebar-element" v-bar>
            <div ref="scrollingContainer" :class="b('vuebarContent')" :style="`max-height: ${maxContentHeight}px`">
              <ul :class="b('notifications')">
                <Notification
                  v-for="(notification, index) in notifications"
                  :toast="notification"
                  :key="index"
                  :data-id="notification.id"
                  :class="b('notification')"
                  @click="onNotificationClick"
                  ref="notificationListItem"
                />
            </ul>
          </div>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '@/components/Notification';

const MAX_CONTENT_HEIGHT = 280;
const CONTENT_MARGIN_X = 15;
const MAX_CONTENT_WIDTH = 425 + (CONTENT_MARGIN_X * 2);

export default {
  name: 'NotificationsDropdown',
  block: 'notificationDropdown',
  components: {
    Notification,
  },
  props: {
    value: Boolean,
    visibilityThershold: {
      default: 0.85,
      validator: (val) => typeof val === 'number'
        && !Number.isNaN(val)
        && val >= 0
        && val <= 1,
    },
  },
  data() {
    return {
      seenNotificationIdsSet: new Set(),
      seenNotificationsIntersectionObserver: null,
      maxContentHeight: MAX_CONTENT_HEIGHT,
      maxContentWidth: MAX_CONTENT_WIDTH,
    };
  },
  watch: {
    value(shown) {
      if (!shown) {
        this.collectVisibleNotificationIdsAndMakeThemSeen();
        this.unsetVisibleNotificationsObservation();
        this.resetNotificationsScroll();
      } else {
        this.$nextTick(this.setVisibleNotificationsObservation);
      }
    },
  },
  computed: {
    ...mapGetters('notification', ['notifications']),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    ...mapActions('notification', ['makeNotificationsSeen']),
    collectVisibleNotificationIdsAndMakeThemSeen() {
      const ids = Array.from(this.seenNotificationIdsSet.values());
      if (ids.length) {
        return this.makeNotificationsSeen(ids);
      }
      return Promise.resolve(false);
    },
    onShownChange(shown) {
      this.$emit('input', shown);
    },
    onNotificationClick(notification) {
      this.$emit('notificationClick', notification);
    },
    resetNotificationsScroll() {
      this.$refs.scrollingContainer.scrollTop = 0;
    },
    setVisibleNotificationsObservation() {
      const options = {
        root: this.$refs.scrollingContainer,
        threshold: this.visibilityThershold,
      };
      const notificationSeenCallback = (intersections) => {
        if (intersections instanceof Array && intersections.length) {
          intersections.forEach((intersection) => {
            if (intersection.isIntersecting) {
              const notificationId = intersection.target.getAttribute('data-id');
              if (typeof notificationId === 'string' && notificationId.length && !this.seenNotificationIdsSet.has(notificationId)) {
                this.seenNotificationIdsSet.add(notificationId);
              }
            }
          });
        }
      };
      if (this.$refs.notificationListItem instanceof Array && this.$refs.notificationListItem.length) {
        this.seenNotificationsIntersectionObserver = new IntersectionObserver(notificationSeenCallback, options);
        this.$refs.notificationListItem.forEach((li) => {
          this.seenNotificationsIntersectionObserver.observe(li.$el);
        });
      }
    },
    unsetVisibleNotificationsObservation() {
      if (this.seenNotificationsIntersectionObserver instanceof Object && typeof this.seenNotificationsIntersectionObserver.disconnect === 'function') {
        this.seenNotificationsIntersectionObserver.disconnect();
      }
      this.seenNotificationIdsSet.clear();
    },
  },
};
</script>

<style lang="scss">
$notification-height: 50px;
$block: 'notificationDropdown';

.#{$block} {
  position: relative;
  &__menuContent {
    box-shadow: 20px 15px 35px rgba(0, 0, 0, 0.1);
  }
  &__contentTail {
    width: 24px;
    height: 12px;
    background: white;
    position: relative;
    left: calc(50% - 12px);
  }
  &__contentWrapper {
    box-shadow: 0px 0px 10px 0px rgba(#342D47, 0.14);
    background: $white;
    position: relative;
    border-radius: 5px;
    z-index: 1;
    width: 100%;
  }
  &__head {
    height: 50px;
    background-color: $ph-dark;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &--empty {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  &__headTitle {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    font-family: $antarctica;
    color: $white;
  }
  &__headCounter {
    min-width: 28px;
    height: 28px;
    padding: 6px;
    border-radius: 28px;
    background: $ph-accent;
    color: $white;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.2px;
  }
  &__content {
    overflow: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    ul.#{$block}__notifications {
      padding-left: 0px;
      @include mappy-bp(md) {
        margin-right: -17px;
      }
    }
  }
  &__notification {
    padding: 0px 15px;
    min-height: $notification-height;
    box-sizing: border-box;
    display: flex;
    @include transition-all();
    cursor: pointer;
    box-sizing: border-box;
    background-color: #FAFAFF;
    &:hover {
      background: linear-gradient(89.96deg, #F2E1FD 0.05%, #E3EAFD 54.88%, #CAFCFA 101.79%);
    }
    .list__tile {
      padding: 0;
    }
    &--seen {
      background: rgba($turquoise, 0.5);
      color: $white;
    }
  }
  &__button {
    &.v-btn:not(.v-btn--round).v-size--default {
      width: 40px;
      min-width: 40px;
      height: 40px;
      padding: 8px;
      .v-btn__content {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__buttonIconWrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__buttonIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
  &__buttonCounter {
    min-height: 18px;
    min-width: 16px;
    font-size: 12px;
    line-height: 1.2;
    padding: 3px 4px;
    @include mappy-bp(md) {
      height: 24px;
      min-width: 24px;
      font-size: 15px;
      line-height: 16px;
      padding: 4px 6px;
    }
    border-radius: 12px;
    background-color: $ph-cyan;
    color: $white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 9px;
    top: -13px;
  }
  &__notificationBlockText {
    padding-left: 10px;
    .#{$block}__notificationText {
      font-size: 16px;
      line-height: 20px;
      color: $text-light-grey;
      margin-bottom: 12px;
    }
  }
}
</style>
