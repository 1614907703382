import map from 'lodash/map';
import emailChangeConfirmation from './emailChangeConfirmation';
import forgotPassword from './forgotPassword';
import login from './login';
import logout from './logout';
import passwordRecovery from './passwordRecovery';
import signUp from './signUp';
import signUpConfirmation from './signUpConfirmation';
import invitationSignUp from './invitationSignUp';

const name = 'auth';

const children = map([
  emailChangeConfirmation,
  forgotPassword,
  login,
  logout,
  passwordRecovery,
  signUp,
  signUpConfirmation,
  invitationSignUp,
], (c) => {
  const child = c;
  child.name = `${name}.${child.name}`;
  return child;
});

const routes = [
  {
    path: '/auth',
    component: () => import('@/pages/auth/Auth'),
    meta: {
      requiresAuth: false,
    },
    children,
  },
];

export default routes;
