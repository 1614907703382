import { VTextField } from 'vuetify/lib';
import { getCustomIconProps, getRequiredInputProps } from './utils/helpers';

const BLOCK_CLASS = 'v-text-field';
const getModifierClasses = (props) => ({
  [`${BLOCK_CLASS}--ph`]: props.custom,
  [`${BLOCK_CLASS}--append-custom-icon--${props.appendCustomIcon}`]: !!props.appendCustomIcon,
});

export default ((component) => ({
  functional: true,
  name: 'VTextField',
  props: {
    appendCustomIcon: String,
    dense: Boolean,
    ignoreDefaultRules: Boolean,
    outlined: {
      type: Boolean,
      default: true,
    },
    label: String,
    placeholder: String,
    rules: {
      type: Array,
      default: () => [],
    },
    required: Boolean,
  },
  render(h, context) {
    const newProps = {
      ...context.props,
      ...getRequiredInputProps(context.props),
      ...getCustomIconProps(context.props),
    };
    return h(
      component,
      {
        ...context.data,
        class: [
          context.data.class,
          getModifierClasses(newProps),
        ],
        props: newProps,
      },
      context.children,
    );
  },
}))(VTextField);
