<template lang="html">
  <v-btn
  :block="block"
  :class="classes"
  :color="color"
  :dark="dark"
  :depressed="depressed"
  :flat="flat"
  :icon="icon"
  :large="large"
  :outline="outline"
  :round="round"
  :to="route"
  >
    <v-icon>arrow_back</v-icon>
    <span class="ml-2" v-if="!icon">{{text}}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'RouteBackBtn',
  block: 'routeBackBtn',
  props: {
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    dark: {
      type: Boolean,
      required: false,
      default: true,
    },
    depressed: {
      type: Boolean,
      required: false,
      default: false,
    },
    flat: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
    round: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      required: false,
      type: String,
      default: 'darken-4',
    },
  },
  computed: {
    hasMeta() {
      return this.$route.meta && (this.$route.meta.backToolbar);
    },
    route() {
      if (this.hasMeta) {
        return this.$route.meta.backToolbar.route;
      }
      return this.$route.parent;
    },
    text() {
      if (this.hasMeta && this.$route.meta.backToolbar.text) {
        return this.$route.meta.backToolbar.text;
      }
      return 'back';
    },
  },
};
</script>
