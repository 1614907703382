import { jsonClone } from '@/utils/helpers';

const defaultState = {
  organizations: [],
  pendingOrganizations: [],
  search: {
    results: [],
    query: '',
    loading: false,
  },
};

export default () => jsonClone(defaultState);
