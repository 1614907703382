import ServiceProvider from '../provider';
import QUERIES from './queries';
import MUTATIONS from './mutations';

class SoaProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  getInviteByToken(token) {
    return this.query(this.queries.getInviteByToken, { token }, { noAuth: true });
  }

  inviteSOAMutation(variables) {
    return this.mutate(this.mutations.inviteSOA, variables);
  }

  approveSOAMutation(requestId) {
    return this.mutate(this.mutations.handleSOARequest, { id: requestId, decision: 'approve' });
  }

  rejectSOAMutation(requestId) {
    return this.mutate(this.mutations.handleSOARequest, { id: requestId, decision: 'reject' });
  }

  excludeSOAMutation(adminId, orgId) {
    return this.mutate(this.mutations.excludeSOA, { updateId: adminId, input: { removeOrganizationId: orgId } });
  }
}

export default new SoaProvider(QUERIES, MUTATIONS);
