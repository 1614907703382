import { TOAST_TYPES } from '@/utils/constants';

class ToastCollection {
  constructor(toasts = []) {
    this._toasts = toasts;
  }

  get toasts() {
    return this._toasts;
  }

  createToast(config = {}) {
    let toast = {
      id: Math.random().toString(36).substr(2, 9),
      systemId: config.systemId || '',
      content: config.content || '',
      clickHandler: config.clickHandler || '',
      type: config.type || TOAST_TYPES.INFO,
      systemType: config.systemType || '',
      dismissible: config.dismissible || false,
      origin: config.origin || '',
    };
    this._toasts.push(toast);
    if (config.timeout !== false && typeof config.timeout === 'number' && !Number.isNaN(config.timeout)) {
      const timeout = config.timeout || 10 * 1000;
      setTimeout(() => {
        this.deleteToast(toast.id);
      }, timeout);
    }
  }

  deleteToast(id) {
    const index = this._toasts.indexOf(this._toasts.find((toast) => toast.id === id));
    if (index !== -1) {
      this._toasts.splice(index, 1);
    }
  }

  deleteSystemToast(systemId) {
    const index = this._toasts.indexOf(this._toasts.find((toast) => toast.systemId === systemId));
    if (index !== -1) {
      this._toasts.splice(index, 1);
    }
  }

  deleteAllToasts() {
    this._toasts = [];
  }
}

export default ToastCollection;
