const route = {
  name: 'checkout',
  path: 'checkout',
  component: () => import('@/pages/soa/checkout/Checkout'),
  redirect: 'checkout/list',
  children: [
    {
      name: 'checkoutList',
      path: 'list',
      component: () => import('@/pages/soa/checkout/CheckoutList'),
    },
    {
      name: 'checkoutPayment',
      path: 'payment',
      component: () => import('@/pages/soa/checkout/CheckoutPayment'),
    },
  ],
};

export default route;
