export default {
  methods: {
    onNotificationClick(notification) {
      const routes = {
        organizations: ['RequestSOA', 'RequestApproved', 'RequestRejected'],
        quotes: ['QuoteManual'],
      };
      if (routes.organizations.includes(notification.systemType)) {
        this.$router.push({ name: 'soa.organizations' });
      } else if (routes.quotes.includes(notification.systemType)) {
        this.$router.push({ name: 'soa.quotes' });
      }
    },
  },
};
