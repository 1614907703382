import Component from '@/pages/auth/EmailChangeConfirmation';

const route = {
  name: 'emailChangeConfirmation',
  path: 'email-change',
  component: Component,
  meta: {
    requiresAuth: false,
    skipUserFetch: true,
    disableDrawer: true,
  },
};

export default route;
