<template>
  <img v-if="iconPath" :src="iconPath" :alt="type + '_icon'" :class="b()">
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrgTypeIcon',
  block: 'orgTypeIcon',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('glossary', ['organizationTypeMap']),
    iconPath() {
      if (this.type && typeof this.type === 'string') {
        return this.organizationTypeMap
          && this.organizationTypeMap instanceof Object
          && this.organizationTypeMap[this.type]
          && this.organizationTypeMap[this.type] instanceof Object
          && this.organizationTypeMap[this.type].id
          ? `/static/images/orgType/${this.organizationTypeMap[this.type].id}.svg`
          : '';
      }
      return '';
    },
  },
};
</script>
