export default {
  createClaim: `mutation CreateClaim ($input: ClaimInput) {
                    claim (input: $input) {
                       name,
                       organization {
                         id,
                       },
                       status,
                       birthdate,
                       gender,
                       socialSecurity,
                       address{
                         address,
                         address2,
                         city,
                         state,
                         country,
                         zip,
                       },
                       mailing{
                         address,
                         address2,
                         city,
                         state,
                         country,
                         zip,
                       },
                       isPersonDependent,
                       employerName,
                       injuryLocation,
                       injuryDate,
                       isFNOL,
                       isPolicyholderJurisdiction,
                       isWitness,
                       supervisorName,
                       activityType,
                       incidentDescription,
                       injuryReport,
                       policyNumber,
                       certificateNumber,
                       otherInsuranceName,
                       isInjuredPersonCovered,
                       physicalName,
                       reporterName,
                       admittanceDate,
                       hospitalName,
                       bodyPart
                     }
                 }`,
  updateMultipleClaims: `mutation ($input:ClaimInput, $updateList: [ID]){
                         claims(input:$input, updateList:$updateList){
                           code,
                           message,
                         }
                       }`,
  updateSingleClaim: `mutation ($input:ClaimInput, $updateId: ID){
                      claim(input:$input, updateId:$updateId){
                          id,
                          organization {
                            id,
                            name
                          },
                          status,
                          name,
                          birthdate,
                          gender
                        }
                     }`,
};
