import { isValidString } from '@/utils/helpers/string';

export default (params) => {
  const paramsByCarrierMap = {};
  if (params instanceof Array && params.length) {
    params.forEach((param) => {
      const { carrier, ...carrierParams } = param;
      if (isValidString(carrier) && carrierParams instanceof Object) {
        paramsByCarrierMap[carrier] = carrierParams;
      }
    });
  }
  return paramsByCarrierMap;
};
