// import '@babel/polyfill';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

// prototype
import '@/plugins/prototype';
// filters
import '@/plugins/filters';
// directives
import '@/plugins/directives';
// analytics
import '@/plugins/analytics';
// global components
import '@/plugins/globalComponents';
// misc
import '@/plugins/misc';
// service provider additional configuration
import '@/plugins/provider';
// vuex-router-sync
import '@/plugins/routerSync';

// vuetify
import Vuetify from '@/plugins/vuetify';
// i18n
import i18n from '@/i18n';
// internal
import App from './App';
import store from './store';
import router from './router';

Vue.config.productionTip = false;

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: { App },
  computed: {
    isMobile() {
      const { getters } = store;
      return getters['layout/isMobile'] || false;
    },
  },
  vuetify: Vuetify,
});

export default app;
