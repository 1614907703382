export default (athletesAgeForm) => {
  if (athletesAgeForm && athletesAgeForm instanceof Object) {
    return Object.values(athletesAgeForm).reduce((acc, sportObj) => {
      if (sportObj && sportObj instanceof Object) {
        const fieldsToSum = ['ageGroup1', 'ageGroup2', 'ageGroup3', 'ageGroup4'];
        acc += Object.entries(sportObj).reduce((sportObjAcc, sportObjEnt) => {
          if (fieldsToSum.includes(sportObjEnt[0]) && sportObjEnt[1]) {
            const parsedEntryValue = Number.parseInt(sportObjEnt[1], 10);
            if (!Number.isNaN(parsedEntryValue)) {
              sportObjAcc += parsedEntryValue;
            }
          }
          return sportObjAcc;
        }, 0);
      }
      return acc;
    }, 0);
  }
  return 0;
};
