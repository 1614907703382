import i18n from '@/i18n';

export default {
  carriers: (context) => context.carriers,
  coverages: (context) => context.coverages,
  coverageTypesWithSubmissions: (context) => (context.coverages instanceof Array && context.coverages.length && context.coverages.reduce((acc, cov) => {
    acc.push({
      id: cov.id,
      text: cov.name,
    });
    return acc;
  }, [{
    id: 'All',
    text: i18n.t('items.allCoverages'),
  }])),
  coveragesMapByType: (context) => context.coverages.reduce((acc, cvg) => {
    if (!acc[cvg.type]) {
      acc[cvg.type] = cvg;
    }
    return acc;
  }, {}),
};
