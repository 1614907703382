export default (subCoverages) => {
  const limitOverridesBySubCoverageId = subCoverages.reduce((acc, subCov) => {
    // check if the subcoverage is selected && has some conditions to override the other subcoverages' limits
    if (
      subCov.chosen
      && subCov.limits instanceof Object
      && Array.isArray(subCov.limits.subcoverages)
    ) {
      // loop through subcoverage overrides & store them as per subcoverage to override id
      subCov.limits.subcoverages.forEach(({ id, ...override }) => {
        acc[id] = {
          ...acc[id],
          ...Object.freeze(override),
        };
      });
    }
    return acc;
  }, {});
  if (Object.keys(limitOverridesBySubCoverageId).length) {
    return subCoverages.map((sub) => ({
      ...sub,
      // add limit overrides to subcoverage by merging its original limits values & the ones from override
      limits: {
        ...sub.limits,
        ...limitOverridesBySubCoverageId[sub.id],
      },
    }));
  }
  return subCoverages;
};
