export default {
  inviteSOA: `mutation InviteSOA ($email: EmailAddress!, $name: Name!, $organizationId: ID!) {
      invite(name: $name, email: $email, organizationId: $organizationId){
        id,
        name,
        email,
        organization {
          id,
          placeId,
          name,
          email
        }
      }
    }`,
  handleSOARequest: `mutation ApproveSOA($id: ID!, $decision: String!) {
      request(id: $id, decision: $decision) {
        code,
        message
      }
    }`,
  excludeSOA: `mutation ExcludeSOAFromOrg($updateId: ID!, $input: UserInput) {
    user(updateId: $updateId, input: $input) {
        code,
        message,
        user {
          id,
          name,
          email,
          soaRole,
          phone,
          icon,
        }
      }
  }`,
};
