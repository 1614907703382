<template>
  <v-dialog
    v-model="showDialog"
    :class="b()"
    :content-class="bodyContentClasses"
    :fullscreen="fullscreenMode"
    max-width="500px"
  >
    <div v-if="dialog.title.length" :class="b('header', { fullscreen: fullscreenMode })">
      <slot name="header">
        <h2 :class="b('heading')">{{ $capitalize(dialog.title) }}</h2>
      </slot>
    </div>
    <div
      :class="b('content', {
        center: dialog.contentTextAlign === 'center',
        fullscreen: fullscreenMode,
        noHeader: !dialog.title,
      })"
    >
      <div v-html="dialog.description" :class="b('contentBody')"></div>
      <div :class="b('footer')">
        <v-btn
          v-if="isConfirm"
          :class="b('footerButton')"
          text
          outlined
          @click="closeDialog"
        >
          {{ $t('ui.cancel') }}
        </v-btn>
        <v-btn
          @click="resolveDialog(true)"
          :class="b('footerButton', { info: isInfo })"
        >
          {{ dialog.btnText }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DIALOG_TYPES } from '@/utils/constants';

const BLOCK = 'phGlobalDialog';

export default {
  name: 'PhGlobalDialog',
  block: BLOCK,
  props: {
    mobileFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('layout', ['isMobile']),
    ...mapGetters('dialog', ['dialog']),
    bodyContentClasses() {
      let baseClass = `${BLOCK}__body`;
      return this.fullscreenMode ? `${baseClass} ${baseClass}--fullscreen` : baseClass;
    },
    fullscreenMode() {
      return this.dialog.fullscreen || (this.mobileFullscreen && this.isMobile);
    },
    isConfirm() {
      return this.dialog.type === DIALOG_TYPES.CONFIRM;
    },
    isInfo() {
      return this.dialog.type === DIALOG_TYPES.INFO;
    },
    showDialog: {
      get() { return this.dialog.showDialog; },
      set(value) {
        value ? this.$store.commit('dialog/SHOW_DIALOG', value) : this.closeDialog();
      },
    },
  },
  methods: {
    ...mapActions('dialog', ['resolveDialog', 'closeDialog']),
    handleEscClick(e) {
      if (e.keyCode === 27) {
        this.closeDialog();
      }
    },
    setCloseOnEscListener() {
      document.addEventListener('keydown', this.handleEscClick);
    },
    removeCloseOnEscListener() {
      document.removeEventListener('keydown', this.handleEscClick);
    },
  },
  mounted() {
    this.setCloseOnEscListener();
  },
  beforeDestroy() {
    this.removeCloseOnEscListener();
  },
};
</script>

<style lang="scss">
$block: 'phGlobalDialog';

.#{$block} {
  &__body {
    overflow: auto;
    &--fullscreen {
      background: $white;
      border-radius: 0px;
      display: flex;
      flex-direction: column;
    }
  }
  &__header {
    text-align: center;
    padding: 30px 25px 0;
    background: $white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @include mappy-bp(md) {
      padding-top: 40px;
    }
    .#{$block}__heading {
      font-family: $antarctica;
      margin-bottom: 0;
      font-weight: 700;
      color: $ph-dark;
    }
    &--fullscreen {
      border-radius: 0px;
      padding: 20px;
      font-size: 1rem;
    }
  }
  &__content {
    background: $white;
    padding: 20px 30px 40px 30px;
    font-size: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
    &--center {
      text-align: center;
    }
    &--noHeader {
      border-radius: 8px;
      padding: 40px 30px;
      .#{$block}__footer {
        margin-bottom: 0px;
      }
    }
    &--fullscreen {
      padding: 20px;
      border-radius: 0px;
      flex: 1;
    }
  }
  &__contentBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  &__footer {
    margin: 30px 0 0;
    flex-direction: column-reverse;
    @include mappy-bp(md) {
      flex-direction: row;
      margin: 40px 0 0;
    }
    display: flex;
    justify-content: center;
    gap: 16px;
    .#{$block}__footerButton {
      align-self: center;
      width: 135px;
      margin: 0px 5px;
      &.#{$block}__footerButton--info {
        min-width: 230px;
      }
    }
  }
}
</style>
