/* eslint camelcase: 0 */
import { formatPhone } from '@/utils/helpers';

export default (state, rootGetters) => {
  const { password_confirmation, phone, ...user } = state.userInfo;
  const { brokerId } = state;
  const currentLocale = rootGetters['locale/currentLocale'];
  const payload = {
    ...user,
    phone: formatPhone(phone),
  };
  if (typeof brokerId === 'string' && brokerId.length) {
    payload.brokerId = brokerId;
  }
  if (currentLocale instanceof Object && currentLocale.i18n) {
    payload.locale = currentLocale.i18n;
  }
  return payload;
};
