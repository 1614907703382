import actions from '@/store/modules/user/actions';
import getters from '@/store/modules/user/getters';
import mutations from '@/store/modules/user/mutations';
import { getDefaultState } from '@/store/modules/user/helpers';

const state = { ...getDefaultState() };

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
