export default (questions, questionGroupKey, property) => {
  if (questions
    && questions instanceof Object
    && typeof questionGroupKey === 'string'
    && questionGroupKey.length) {
    if (typeof property === 'string'
      && property.length
      && questions[questionGroupKey]
      && questions[questionGroupKey] instanceof Object
      && questions[questionGroupKey][property] !== undefined) {
      return questions[questionGroupKey][property];
    }
  }
  return undefined;
};
