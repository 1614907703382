export default {
  organizationsToCheckout: (context) => context.checkoutParams.organizations,
  shoppingCartItems: (context) => context.items,
  shoppingCartItemsQuote: (context) => context.items.map((el) => ({
    ...el.quote,
    annualCost: el.annualCost,
    monthlyCost: el.monthlyCost,
    premiumPrice: el.paymentCycle == 'annually' ? el.annualCost : el.monthlyCost,
  })),
};
