let handler = {
  get: (target, name) => name in target
    ? target[name]
    : () => false,
};

const updateOrg = ({ payload, ctx }) => {
  ctx.dispatch('organization/updateLocalOrg', payload.organinization, { root: true });
};
const updatePolicy = ({ payload, ctx }) => {
  ctx.dispatch('policy/updateLocalPolicy', payload.policy, { root: true });
};
const pushFnol = ({ payload, ctx }) => {
  ctx.dispatch('fnol/pushFnol', payload.claim, { root: true });
};
const updateFnol = ({ payload, ctx }) => {
  ctx.commit('fnol/UPDATE_FNOL', payload.claim, { root: true });
};
const escalateFnol = ({ payload, ctx }) => {
  ctx.commit('fnol/ESCALATE_FNOLS', [payload.claim.id], { root: true });
};

const changeDataFunctions = {
  RequestSOA: updateOrg,
  RequestApproved: updateOrg,
  RequestRejected: updateOrg,
  PolicyExpired: updatePolicy,
  PolicyProlonged: updatePolicy,
  PolicyCanceled: updatePolicy,
  PolicyPurchased: updatePolicy,
  PolicyPDF: updatePolicy,
  // ProofPDF: updatePolicy,
  ProtocolFilesMissing: updateOrg,
  FnolCreated: pushFnol,
  FnolUpdated: updateFnol,
  FnolEscalated: escalateFnol,
};

const handleSocketDataUpdate = (arg) => new Proxy(changeDataFunctions, handler)[arg.payload && arg.payload.type](arg);

export default handleSocketDataUpdate;
