import ServiceProvider from '../provider';

import QUERIES from './queries';
import MUTATIONS from './mutations';

class InsuranceProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  // queries
  carriersQuery() {
    return this.query(this.queries.carriers);
  }

  coveragesQuery(filters) {
    return this.query(this.queries.coverages, { filters });
  }

  submissionsQuery(filters) {
    return this.query(this.queries.submissions, { filters });
  }

  previousClaimsLossRatioCheckQuery(claims) {
    return this.query(this.queries.previousClaimsLossRatio, claims);
  }

  quotesQuery(filters) {
    return this.query(this.queries.quotes, { filters });
  }

  // mutations
  submissionMutation(input) {
    return this.mutate(this.mutations.submission, { input });
  }

  rejectQuoteMutation(payload) {
    return this.mutate(this.mutations.rejectQuote, payload);
  }

  rejectQuoteOfferMutation(payload) {
    return this.mutate(this.mutations.rejectQuoteOffer, payload);
  }

  cancelQuoteOfferMutation(payload) {
    return this.mutate(this.mutations.cancelQuoteOffer, payload);
  }
}
export default new InsuranceProvider(QUERIES, MUTATIONS);
