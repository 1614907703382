import { API_DATE_FORMAT } from '@/utils/constants';
import moment from '@/plugins/moment';

export default {
  min: (val, maxDate, future = false) => {
    const now = moment().startOf('day');
    const momentVal = moment(val);
    const today = {
      year: now.year(),
      month: now.month() + 1,
      date: now.date(),
    };
    const start = {
      year: momentVal.year(),
      month: momentVal.month() + 1,
      date: momentVal.date(),
    };
    const shouldValidate = future ? momentVal >= now : momentVal <= now;
    if (shouldValidate) {
      const momentMaxDate = (maxDate && moment(maxDate)) || (!future && now);
      if (momentMaxDate) {
        const end = {
          year: momentMaxDate.year(),
          month: momentMaxDate.month() + 1,
          date: momentMaxDate.date(),
        };
        return (start.year < end.year)
        || (start.year === end.year && start.month < end.month)
        || (start.year === end.year && start.month === end.month && start.date <= end.date);
      }
      return start.year > today.year || (start.year === today.year && ((start.month > today.month) || (start.month === today.month && start.date >= today.date)));
    }
    return false;
  },
  max: (val, minDate, future = false) => {
    const now = moment().startOf('day');
    const momentVal = moment(val);
    const today = {
      year: now.year(),
      month: now.month() + 1,
      date: now.date(),
    };
    const start = {
      year: momentVal.year(),
      month: momentVal.month() + 1,
      date: momentVal.date(),
    };
    const shouldValidate = (future && momentVal >= now) || momentVal <= now;
    if (shouldValidate) {
      const momentMinDate = (minDate && moment(minDate)) || (future && now);
      if (momentMinDate) {
        const end = {
          year: momentMinDate.year(),
          month: momentMinDate.month() + 1,
          date: momentMinDate.date(),
        };
        return (start.year > end.year)
          || (start.year === end.year && start.month > end.month)
          || (start.year === end.year && start.month === end.month && start.date >= end.date);
      }
      return start.year < today.year || (start.year === today.year && ((start.month < today.month) || (start.month === today.month && start.date <= today.date)));
    }
    return false;
  },
  range(val, minDate, maxDate) {
    if (val && minDate && maxDate) {
      const momentVal = moment(val);
      const momentMinDate = moment(minDate);
      const momentMaxDate = moment(maxDate);
      return momentVal.isSameOrAfter(momentMinDate) && momentVal.isSameOrBefore(momentMaxDate);
    }
    return false;
  },
};
