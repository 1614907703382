<template>
  <button :class="b()" @click.stop="handleClick">
    <span :class="b('icon')"></span>
  </button>
</template>

<script>
export default {
  name: 'MenuToggle',
  block: 'menuToggle',
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'menuToggle';

.#{$block} {
  height: 38px;
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &__icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url('~@/assets/icons/menu-button.svg') 0 0/contain no-repeat;
  }
}
</style>
