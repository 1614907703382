export default (athletesAgeBySports, sportsMap) => {
  if (athletesAgeBySports && athletesAgeBySports instanceof Object && sportsMap && sportsMap instanceof Object) {
    return Object.entries(athletesAgeBySports).some((entry) => {
      const match = typeof entry[0] === 'string'
        && entry[0].length
        && sportsMap[entry[0]] instanceof Object
        && sportsMap[entry[0]].values
        && sportsMap[entry[0]].values instanceof Object
        && sportsMap[entry[0]].values.requiresMinTwoLifeguards;
      return match;
    });
  }
  return false;
};
