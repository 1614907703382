export default {
  SET_INITIALIZED(state, initialized) {
    state.initialized = !!initialized;
  },
  SET_INSTANCE(state, instance) {
    if (instance && instance instanceof Object) {
      state.instance = instance;
    }
  },
};
