import actions from '@/store/modules/locale/actions';
import mutations from '@/store/modules/locale/mutations';
import getters from '@/store/modules/locale/getters';
import getDefaultState from '@/store/modules/locale/helpers/getDefaultState';

const state = { ...getDefaultState() };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
