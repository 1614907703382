import { DEFAULT_ATHLETES_NUMBER, DEFAULT_PERSONNEL_NUMBER } from '@/utils/constants';

const ATHLETES_AGE_OBJECT = {
  ageGroup1: DEFAULT_ATHLETES_NUMBER || 0,
  ageGroup2: DEFAULT_ATHLETES_NUMBER || 0,
  ageGroup3: DEFAULT_ATHLETES_NUMBER || 0,
  ageGroup4: DEFAULT_ATHLETES_NUMBER || 0,
  monthsInSeason: 1,
};

const ONBOARDING_SESSION_STEPS = [
  'orgType',
  'orgChoice',
  'orgSports',
  'orgMonthsInSeason',
  'orgAgeGroups',
  'orgPersonnel',
  'userInfo',
  'orgPreferredCoverage',
  'orgPreviousClaims',
  'orgCurrentCoverage',
  'orgRegProvider',
  'orgBackgroundCheck',
  'orgProtocolTemplates',
  'policyChoice',
  'coverageSummary',
  'userPaymentMethod',
];

const ONBOARDING_SESSION_CLIENT_STATE_KEY = 'clientState';

const SIGN_UP_STEPS = {
  basic: ['userInfo', 'orgType', 'orgChoice', 'orgSports', 'orgAgeGroups', 'orgPersonnel'],
  quote: ['orgPreferredCoverage', 'orgPreviousClaims', 'orgCurrentCoverage'],
  personalize: ['orgMonthsInSeason', 'orgRegProvider', 'orgBackgroundCheck', 'orgProtocolTemplates'],
  policy: ['policyChoice'],
  apply: ['coverageSummary', 'userPaymentMethod'],
};

const STEP_NAMES_MAP = {
  quote: 'coverage',
  apply: 'checkout',
  policy: 'quote',
};

const PERSONNEL_OBJECT = {
  athletes: DEFAULT_PERSONNEL_NUMBER,
  coaches: DEFAULT_PERSONNEL_NUMBER,
  volunteers: DEFAULT_PERSONNEL_NUMBER,
  officials: DEFAULT_PERSONNEL_NUMBER,
};

export {
  ATHLETES_AGE_OBJECT,
  SIGN_UP_STEPS,
  STEP_NAMES_MAP,
  ONBOARDING_SESSION_STEPS,
  ONBOARDING_SESSION_CLIENT_STATE_KEY,
  PERSONNEL_OBJECT,
};
