import { formatPhone } from '@/utils/helpers';
import {
  findAchBySourceToken,
  findTemporaryAchToApprove,
  syncToken,
} from '@/store/modules/user/helpers';

export default {
  TOKEN_CLEAR(context) {
    const ctx = context;
    ctx.accessToken = '';
    syncToken(ctx.accessToken);
  },
  TOKEN_SET(context, payload) {
    const ctx = context;
    ctx.accessToken = payload;
    syncToken(ctx.accessToken);
  },
  SET_TOKEN_CHANGE_LISTENER_STATUS(context, payload) {
    context.storageListenerAttached = payload;
  },
  USER_SET_NEXT_TOKEN(context, token) {
    context.nextToken = token;
  },
  SHOW_ACH_APPROVAL_DIALOG(context, source) {
    if (typeof source === 'string' && source.length) {
      let sourceToSet;
      if (source === 'default'
        && context.profile.stripeBankObject
        && context.profile.stripeBankObject instanceof Object
        && typeof context.profile.stripeBankObject.source === 'string') {
        sourceToSet = context.profile.stripeBankObject.source;
      } else if (source === 'temporary') {
        const firstAchToApprove = findTemporaryAchToApprove(context.profile.stripeBankObjects);
        if (firstAchToApprove && firstAchToApprove instanceof Object && typeof firstAchToApprove.source === 'string') {
          sourceToSet = firstAchToApprove.source;
        }
      } else {
        sourceToSet = source;
      }
      if (typeof sourceToSet === 'string' && sourceToSet.length) {
        context.shownAchDialogSource = sourceToSet;
        context.showAchDialog = true;
      }
    }
  },
  HIDE_ACH_APPROVAL_DIALOG(context) {
    context.showAchDialog = false;
    context.shownAchDialogSource = '';
  },
  USER_CLEAR(context) {
    const ctx = context;
    ctx.billing = {};
    ctx.role = 'guest';
    ctx.profile = {};
  },
  USER_SET_NEW_ICON(context) {
    context.setNewIcon = true;
  },
  USER_UNSET_NEW_ICON(context) {
    context.setNewIcon = false;
  },
  USER_UPDATE(context, {
    role, profile, signature = {}, locale, timezone,
  }) {
    context.role = role;
    context.profile = profile;
    context.signature = signature;
  },
  USER_UPDATE_LOCALE_TIMEZONE(context, { locale, timezone }) {
    if (locale) {
      context.locale = locale;
    }
    if (timezone) {
      context.timezone = timezone;
    }
  },
  USER_UPDATE_PROFILE(context, data) {
    context.profile.name = data.name;
    context.profile.email = data.email;
    context.profile.phone = formatPhone(data.phone);
    context.profile.soaRole = data.soaRole;
    if (context.setNewIcon) {
      context.profile.icon = data.icon;
    }
  },
  UPDATE_PAYMENT_SOURCE_BY_SOURCE_TOKEN(context, { source, data }) {
    if (typeof source === 'string' && source.length && data && data instanceof Object) {
      if (context.profile.stripeBankObject
        && context.profile.stripeBankObject instanceof Object
        && context.profile.stripeBankObject.source === source) {
        context.profile.stripeBankObject = { ...context.profile.stripeBankObject, ...data };
      } else if (context.profile.stripeBankObjects instanceof Array && context.profile.stripeBankObjects.length) {
        const temporarySourceMatchIndex = context.profile.stripeBankObjects.findIndex((obj) => obj.source === source);
        if (temporarySourceMatchIndex !== -1) {
          context.profile.stripeBankObjects.splice(temporarySourceMatchIndex, 1, {
            ...context.profile.stripeBankObjects[temporarySourceMatchIndex],
            ...data,
          });
        }
      }
    }
  },
  UPDATE_USER_DEBIT_CARD(context, data) {
    context.debitCard[data.field] = data.value;
  },
  UPDATE_USER_BANK_ACCOUNT(context, data) {
    context.bankAccount[data.field] = data.value;
  },
  CLEAR_BANK_ACCOUNT(context) {
    context.bankAccount.routing_number = '';
    context.bankAccount.account_number = '';
    context.bankAccount.country = 'us';
    context.bankAccount.confirm_account = '';
    context.bankAccount.billing = '';
    context.bankAccount.account_holder_name = '';
    context.bankAccount.account_holder_type = '';
  },
  UPDATE_LOCATION_COUNTRY_CODE(context, countryCode) {
    context.locationCountryCode = countryCode;
  },
};
