export default (obj) => {
  if (obj && obj instanceof Object) {
    const objValues = Object.values(obj);
    if (!objValues.length) {
      return false;
    }
    return objValues.reduce((acc, currVal) => {
      if (currVal === undefined || currVal === '' || currVal === null) {
        acc = false;
      }
      return acc;
    }, true);
  }
  return false;
};
