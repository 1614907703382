<template lang="html">
  <v-form @submit.prevent :class="b('form')" ref="form">
    <v-row :no-gutters="isMobile" :class="b('')">
      <v-col cols="12" md="6">
          <PhDatepicker @input="updateForm('accident_date', arguments[0])"
            :value="form.accident_date"
            required
            :label="$t('fields.accidentOccuranceDate')"
            :hide-details="false"
          />
        </v-col>
        <v-col cols="12" md="6">
          <PhTimepicker
            @input="updateForm('accident_time', arguments[0])"
            :value="form.accident_time"
            :label="$t('fields.accidentTime')"
            required
          />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @input="updateClaimInjury(arguments[0], 'physician')"
          :value="form.physician"
          :label="$t('fields.nameOfPhysical')"
          name="physician"
          autocomplete="new-password"
        />
      </v-col>
      <v-col cols="12" md="6">
        <AutocompleteMaterialSearch
          @input="searchLocations"
          @resultSelect="selectLocationFromSearch"
          :query.sync="searchLocationQuery || form.location"
          :results.sync="searchLocationResults"
          :debounce="350"
          :rules="inputRules.required"
          :loading="searchLocationLoading"
          :label="$t('fields.injuryLocation')"
          id="smart-search-input"
          plainTextResults
          required
        />
      </v-col>
      <v-col :class="b('radioTitle')" cols="12" md="12">
        {{ $t('fields.isPolicyHolderJurisdiction') }}
      </v-col>
      <v-col cols="12" md="12">
        <v-radio-group
          @change="updateClaimInjuryPolicy"
          :value="form.policy"
          :class="b('radioGroup')"
          row
        >
          <div :class="b('radioBlockRow')">
            <v-radio
              :value="true"
              :label="$t('ui.yes')"
            />
          </div>
          <div :class="b('radioBlockRow')">
            <v-radio
              :value="false"
              :label="$t('ui.no')"
            />
          </div>
        </v-radio-group>
      </v-col>
      <v-col :class="b('radioTitle')" cols="12" md="12">
        {{ $t('fields.isWitness') }}
      </v-col>
      <v-col cols="12" md="12">
        <v-radio-group
          @change="updateClaimInjuryWitness"
          :value="form.witness"
          :class="b('radioGroup')"
          row
        >
          <div :class="b('radioBlockRow')">
            <v-radio
              :value="true"
              :label="$t('ui.yes')"
            />
          </div>
          <div :class="b('radioBlockRow')">
            <v-radio
              :value="false"
              :label="$t('ui.no')"
            />
          </div>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @input="updateClaimInjury(arguments[0], 'activity')"
          :value="form.activity"
          :counter="255"
          :rules="[(v) => !v || (v.length <= 255 || 'Must not exceed 255 characters')]"
          :label="$t('fields.supervisorName')"
          rows="1"
          name="activity"
          outlined
          auto-grow
          multi-line
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="updateClaimInjury(arguments[0], 'type_sports')"
          :items="activityTypes"
          :value="form.type_sports"
          :disabled="!activityTypes.length"
          :rules="[...inputRules.required]"
          :label="$t('fields.activityType')"
          name="type_sports"
          single-line
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @input="updateClaimInjury(arguments[0], 'accident')"
          :value="form.accident"
          :counter="1024"
          :rules="[(v) => !v || (v.length <= 1024 || $t('validation.charactersExceed', { number: 1024 } ))]"
          :label="$t('fields.accidentDescription')"
          name="accident"
          auto-grow
          multi-line
          outlined
          rows="1"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="updateClaimInjury(arguments[0], 'injuryType')"
          :value="form.injuryType"
          :items="injuryTypes"
          :label="$t('fields.injuryType')"
          :disabled="!injuryTypes.length"
          :rules="[...inputRules.required]"
          name="injuryType"
          required
          single-line
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field
          @input="updateClaimInjury(arguments[0], 'report')"
          :value="form.report"
          :label="$t('fields.injuryReport')"
          autocomplete="new-password"
          name="report"
          multi-line
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="updateClaimInjury(arguments[0], 'body_part')"
          :value="form.body_part"
          :label="$t('fields.bodyPart')"
          :items="bodyParts"
          :disabled="!bodyParts.length"
          :rules="[...inputRules.required]"
          name="body_part"
          required
          single-line
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @input="updateClaimInjury(arguments[0], 'hospital')"
          :value="form.hospital"
          :label="$t('fields.hospitalName')"
          autocomplete="new-password"
          name="hospital"
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhDatepicker
          @input="updateForm('treatment_date', arguments[0])"
          :value="form.treatment_date"
          :label="$t('fields.treatmentDate')"
          :hide-details="false"
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhDatepicker
          @input="updateForm('admittance_date', arguments[0])"
          :value="form.admittance_date"
          :label="$t('fields.admittanceDate')"
          :hide-details="false"
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhTimepicker
          @input="updateForm('admittance_time', arguments[0])"
          :value="form.admittance_time"
          :label="$t('fields.admittanceTime')"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import dateValidator from '@/utils/helpers/dateValidator';
import inputRules from '@/utils/helpers/inputRules';
import { mapActions, mapGetters } from 'vuex';

const AutocompleteMaterialSearch = () => import('@/components/controls/AutocompleteMaterialSearch');
const PhDatepicker = () => import('@/components/controls/PhDatepicker');
const PhTimepicker = () => import('@/components/controls/PhTimepicker');

export default {
  name: 'ClaimActionInjuryStep',
  block: 'claimActionInjuryStep',
  components: {
    AutocompleteMaterialSearch,
    PhDatepicker,
    PhTimepicker,
  },
  props: {
    form: {
      type: Object,
    },
    currentStep: {
      type: [String, Number],
    },
    injuryInfo: Object,
  },
  data() {
    return {
      inputRules,
      searchLocationQuery: '',
      searchLocationResults: [],
      searchLocationLoading: false,
      searchLocationError: null,
    };
  },
  computed: {
    ...mapGetters('glossary', ['activityTypes', 'bodyParts', 'injuryTypes']),
    ...mapGetters('layout', ['isMobile']),
  },
  methods: {
    ...mapActions('glossary', ['fetchBodyParts', 'fetchInjuryTypes', 'fetchActivityTypes', 'searchLocation']),
    updateClaimInjury(value, field) {
      if (field) {
        this.updateForm(field, value);
      }
    },
    getAllowedMinDates(val) {
      return dateValidator.min(val);
    },
    getAllowedMaxDates(val) {
      return dateValidator.max(val, this.dateFilters.createdAtFrom);
    },
    updateClaimInjuryPolicy(val) {
      this.updateForm('policy', val);
    },
    updateClaimInjuryWitness(val) {
      this.updateForm('witness', val);
    },
    updateForm(key, value) {
      let reqObj = {};
      if (key.split('.').length == 2) {
        reqObj[[key.split('.')[0]]] = { ...this.form[key.split('.')[0]], [key.split('.')[1]]: value };
      } else {
        reqObj = { [key]: value };
      }
      let form = { ...this.form, ...reqObj };
      this.$emit('updateStepForm', { number: this.currentStep, form });
    },
    validateForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$emit('nextStep', this.currentStep + 1);
      }
      return isValid;
    },
    searchLocations(query) {
      this.searchLocationLoading = true;
      this.searchLocation(query).then((response) => {
        if (response.data.data.google instanceof Array) {
          this.searchLocationLoading = false;
          this.searchLocationResults = response.data.data.google.map((addr) => ({
            text: addr.address,
            id: addr.id,
          }));
        }
      }).catch((error) => {
        this.$dialog.info({
          title: this.$t('response.error.error'),
          description: error.message,
        });
        this.searchLocationLoading = false;
      });
    },
    selectLocationFromSearch(location) {
      if (location instanceof Object && Object.keys(location).length) {
        this.searchLocationQuery = location.text;
        this.updateClaimInjury(location.text, 'location');
      }
    },
    stepBack() {
      this.$emit('handleToPrevStep');
    },
  },
  created() {
    if (!this.bodyParts.length) {
      this.fetchBodyParts();
    }
    if (!this.injuryTypes.length) {
      this.fetchInjuryTypes();
    }
    if (!this.activityTypes.length) {
      this.fetchActivityTypes();
    }
  },
};
</script>

<style lang="scss" scoped>
$block: 'claimActionInjuryStep';

.#{$block} {
  &__form {
    @include mappy-bp(md) {
      padding-bottom: 50px;
    }
  }
  &__radioBlockRow {
    width: 80px;
  }
  &__formOrgNameBlock {
    > div {
      align-items: flex-start;
    }
  }
  &__formOrgInfoBlock {
    width: 100%;
  }
  &__radioGroup {
    margin-bottom: 15px;
  }
}
</style>
