export const langs = {
  EN_US: {
    i18n: 'en-US',
    moment: 'en',
    label: 'EN',
  },
  FR_CA: {
    i18n: 'fr-CA',
    moment: 'fr',
    label: 'FR',
  },
};
