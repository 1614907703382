class ActivityTracker {
  constructor(tokenSlug) {
    this.token_slug = tokenSlug;
  }

  syncLastActivityTime(time) {
    localStorage.setItem(this.token_slug, time);
  }

  getLastActivityFromLocalStorageValue() {
    const value = localStorage.getItem(this.token_slug);
    if (value) {
      const parsedValue = Number.parseInt(value, 10);
      if (!Number.isNaN(parsedValue)) {
        return parsedValue;
      }
    }
    return null;
  }

  checkLastActivityDiffs(lastActivity) {
    const localStorageLastActivity = localStorage.getItem(this.token_slug);
    return localStorageLastActivity && localStorageLastActivity > lastActivity;
  }
}

export default ActivityTracker;
