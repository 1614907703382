import ServiceProvider from '../provider';

import QUERIES from './queries';
import MUTATIONS from './mutations';

class ClaimProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  claimsQuery(filters = {}) {
    return this.query(this.queries.claims, filters);
  }

  getClaimQuery(claimId) {
    return this.query(this.queries.claim, { id: claimId });
  }

  updateClaimsMutation(updateIds, input) {
    return this.mutate(this.mutations.updateMultipleClaims, { input, updateList: updateIds });
  }

  updateSingleClaimMutation(updateId, input) {
    return this.mutate(this.mutations.updateSingleClaim, { input, updateId });
  }

  createClaimMutation(input) {
    return this.mutate(this.mutations.createClaim, { input });
  }
}
export default new ClaimProvider(QUERIES, MUTATIONS);
