import { InsuranceProvider } from '@/graphql/providers';
import getQuotesQueryPayload from '@/utils/api/parsers/out/getQuotesQueryPayload';

export default {
  fetchCarriers(context) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.carriersQuery().then((response) => {
        if (response.data.data && response.data.data.carriers instanceof Array) {
          context.commit('SET_CARRIERS', response.data.data.carriers);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchCoverages(context) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.coveragesQuery().then((response) => {
        if (response.data.data && response.data.data.coverages) {
          context.commit('SET_COVERAGES', response.data.data.coverages);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchCoverageInfoForCarrier({ commit }, carrierId) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.coveragesQuery({ carrier: carrierId }).then((response) => {
        if (response.data.data && response.data.data.coverages) {
          commit('SET_CARRIER_COVERAGE_INFO', {
            carrierId,
            coverages: response.data.data.coverages,
          });
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchQuotes(context, {
    organizationId, coverage, limit, startDate, endDate, subCoverages,
  }) {
    if (!organizationId || typeof organizationId !== 'string') {
      throw new Error('organizationId filter is absent or invalid');
    }
    if (!coverage || typeof coverage !== 'string') {
      throw new Error('coverage filter is absent or invalid');
    }
    return new Promise((resolve, reject) => {
      InsuranceProvider.quotesQuery(
        getQuotesQueryPayload(
          organizationId, coverage, limit, subCoverages, startDate, endDate,
        ),
      ).then((response) => {
        if (response.data.data && Array.isArray(response.data.data.quotes)) {
          resolve(response.data.data.quotes);
        } else {
          throw new Error("Couln't fetch quotes for provided params");
        }
      }).catch(reject);
    });
  },
};
