import { ATHLETES_AGE_OBJECT } from '@/store/modules/signUp/constants';

const prepareSportsValues = (sports) => {
  const fieldsToInclude = [...Object.keys(ATHLETES_AGE_OBJECT), 'sport'];
  return sports.map((sp) => {
    const clearedSportObj = sp && sp instanceof Object && Object.entries(sp).reduce((acc, spEnt) => {
      if (fieldsToInclude.includes(spEnt[0])) {
        acc[spEnt[0]] = spEnt[1];
      }
      return acc;
    }, {});
    if (clearedSportObj && clearedSportObj instanceof Object) {
      return clearedSportObj;
    }
    return {};
  });
};

export default (organization, session) => {
  let payload = {};
  if (organization
    && organization instanceof Object
    && Object.keys(organization).length
    && session
    && session instanceof Object) {
    // sports
    if ((!session.orgSports || !session.orgMonthsInSeason || !session.orgAgeGroups)
      && organization.sports instanceof Array && organization.sports.length) {
      const preparedSports = prepareSportsValues(organization.sports);
      payload.orgSports = { sports: preparedSports };
      payload.orgMonthsInSeason = { sports: preparedSports };
      payload.orgAgeGroups = { sports: preparedSports };
    }
    // personnel
    if (!session.orgPersonnel && organization.personnel && organization.personnel instanceof Object && Object.keys(organization.personnel).length) {
      payload.orgPersonnel = {
        personnel: { ...organization.personnel },
      };
    }
    // registration provider
    if (!session.orgRegProvider) {
      if (organization.provider) {
        payload.orgRegProvider = {
          provider: organization.provider,
        };
      } else if (organization.otherProvider) {
        payload.orgRegProvider = {
          otherProvider: organization.otherProvider,
        };
      }
    }
    // background provider
    if (!session.orgBackgroundCheck && organization.backgroundCheck) {
      payload.orgBackgroundCheck = {
        backgroundCheck: organization.backgroundCheck,
      };
      if (organization.backgroundProvider) {
        payload.orgBackgroundCheck.backgroundProvider = organization.backgroundProvider;
      } else if (organization.otherBackgroundProvider) {
        payload.orgBackgroundCheck.otherBackgroundProvider = organization.otherBackgroundProvider;
      }
    }
  }
  return payload;
};
