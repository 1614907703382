import {
  checkChainedQuestionKeyBelongsToSelectedCoverage,
  getChainedQuestionMatchByQuestionKey,
} from '@/store/modules/orgApplication/helpers';
import { isValidString } from '@/utils/helpers/string';
import _get from 'lodash/get';
import _getArraysIntersection from 'lodash/intersection';
import _getArraysIntersectionBy from 'lodash/intersectionBy';

const CHAINED_QUESTION_VALIDATORS = {
  boolean: (val) => typeof val === 'boolean',
};

const checkMethodArgumentsValid = (questions, questionGroupKey) => Boolean((questions && questions instanceof Object && typeof questionGroupKey === 'string' && questionGroupKey.length));

const checkAllDependenciesFulfilled = (currentQuestionGroup, questions, rootGetters) => {
  if (currentQuestionGroup && currentQuestionGroup instanceof Object
    && currentQuestionGroup.dependencies instanceof Array
    && currentQuestionGroup.dependencies.length) {
    // return whether there are NO dependencies that are not fulfilled
    return !currentQuestionGroup.dependencies.some((dep) => {
      if (typeof dep.questionKey === 'string') {
        if (currentQuestionGroup[dep.questionKey]) {
          return currentQuestionGroup[dep.questionKey].value != dep.value;
        }
        // check for dependency match within the outer groups
        const outerQuestionMatch = getChainedQuestionMatchByQuestionKey(questions, dep.questionKey);
        if (outerQuestionMatch
          && outerQuestionMatch instanceof Object) {
          return outerQuestionMatch.value != dep.value;
        }
      } else if (typeof dep.getter === 'string'
        && rootGetters
        && rootGetters instanceof Object
        && rootGetters.hasOwnProperty(dep.getter)) {
        // check for dependency match within the global rootGetters object
        return rootGetters[dep.getter] != dep.value;
      }
      return false;
    });
  }
  return true;
};

const getCurrentQuestionGroupQuestions = (questions, questionGroupKey) => (questions[questionGroupKey]
  && questions[questionGroupKey] instanceof Object
  && questions[questionGroupKey].questions
  && questions[questionGroupKey].questions instanceof Object)
  ? questions[questionGroupKey].questions
  : null;

const checkValueAgainstExpression = (value, {
  eq, gt, gte, lt, lte, or, intersection,
}) => {
  // expression structure:
  // {
  //   eq: 1,
  //   gt: 1,
  //   gte: 2,
  //   lt: 5,
  //   lte: 6,
  //   or: [1, 2, 3],
  //   intersection: [4, 5, 6],
  //   // OR
  //   intersection: {
  //     by: 'x',
  //     value: [1, 2, 3]
  //   }
  // }
  let conditionMet = false;
  // gt check
  if (eq !== undefined) {
    conditionMet = value === eq;
  }
  if (gt !== undefined) {
    conditionMet = value > gt;
  }
  // gte
  if (gte !== undefined) {
    conditionMet = value >= gte;
  }
  if (lt !== undefined) {
    conditionMet = value < lt;
  }
  if (lte !== undefined) {
    conditionMet = value <= lte;
  }
  if (Array.isArray(or) && or.length) {
    conditionMet = or.includes(value);
  }
  if (Array.isArray(value) && value.length) {
    if (Array.isArray(intersection) && intersection.length) {
      conditionMet = !!(_getArraysIntersection(value, intersection).length);
    } else if (intersection instanceof Object
      && Array.isArray(intersection.value)
      && intersection.value.length
    ) {
      if (isValidString(intersection.by)) {
        conditionMet = !!(_getArraysIntersectionBy(value, intersection.value, intersection.by).length);
      }
    }
  }
  return conditionMet;
};

const checkAllConditionsAreMet = (conditions, rootGetters) => conditions.every((condition) => {
  const allConditionExpressionsAreMet = Array.isArray(condition.expressions)
    ? condition.expressions.every((expression) => {
      // mongo query like search
      // expression structure:
      // {
      //   key: 'myField',
      //   eq: 1,
      //   gt: 1,
      //   gte: 2,
      //   lt: 5,
      //   lte: 6,
      //   or: [1, 2, 3],
      //   intersection: [4, 5, 6],
      //   // OR
      //   intersection: {
      //     by: 'x',
      //     value: [1, 2, 3]
      //   }
      // }
      const { key, ...restExpression } = expression;
      const value = _get(rootGetters['orgApplication/organizationToComplete'], key);

      return checkValueAgainstExpression(value, restExpression);
    })
    : true;
  return allConditionExpressionsAreMet;
});

const packConditionEvents = (conditions) => conditions
  .map((condition) => condition.events || [])
  .reduce((accumulated, events) => [...accumulated, ...events], []);

export default (questions, questionGroupKey, rootGetters) => {
  if (checkMethodArgumentsValid(questions, questionGroupKey)) {
    const questionGroup = getCurrentQuestionGroupQuestions(questions, questionGroupKey);
    if (questionGroup && questionGroup instanceof Object) {
      const groupEntries = Object.entries(questionGroup);
      const mappedQuestions = groupEntries.reduce((acc, currGroupEntry) => {
        const entryShouldBeSkipped = !checkChainedQuestionKeyBelongsToSelectedCoverage(
          currGroupEntry[0],
          rootGetters['orgApplication/chosenCoverage'],
          rootGetters['orgApplication/chosenSubCoverages'],
        );
        if (entryShouldBeSkipped) {
          return acc;
        }
        if (currGroupEntry[1] && currGroupEntry[1] instanceof Object) {
          const questionShouldBeShown = Array.isArray(currGroupEntry[1].conditions)
            ? checkAllConditionsAreMet(currGroupEntry[1].conditions, rootGetters)
            : true;
          // terminate iteration if the question should be skipped
          if (!questionShouldBeShown) {
            return acc;
          }
          const outputQuestion = {
            field: currGroupEntry[0],
            label: currGroupEntry[1].label || '',
            type: currGroupEntry[1].type,
            value: currGroupEntry[1].value,
            hasConditions: !!currGroupEntry[1].conditions,
            events:
              (currGroupEntry[1].value != undefined && currGroupEntry[1].value != null)
              && Array.isArray(currGroupEntry[1].conditions)
              && packConditionEvents(currGroupEntry[1].conditions),
            valid: (currGroupEntry[1].validation && typeof CHAINED_QUESTION_VALIDATORS[currGroupEntry[1].validation] === 'function')
              ? CHAINED_QUESTION_VALIDATORS[currGroupEntry[1].validation](currGroupEntry[1].value)
              : false,
          };
          const currentGroupDependenciesFulfilled = checkAllDependenciesFulfilled(currGroupEntry[1], questions, rootGetters);
          if (currentGroupDependenciesFulfilled) {
            acc.push(outputQuestion);
          }
        }
        return acc;
      }, []);
      return mappedQuestions;
    }
  }
  return [];
};
