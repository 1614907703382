import { getFormDataFromGraphQL } from '@/utils/helpers';
import ServiceProvider from '../provider';
import QUERIES from './queries';
import MUTATIONS from './mutations';

class SessionProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  fetchSessionQuery() {
    return this.query(this.queries.session);
  }

  updateSessionValueMutation(name, value) {
    // check if has files, upload FormData if yes
    const valueEntries = value && value instanceof Object && Object.entries(value);
    if (valueEntries && valueEntries.find((ent) => ent[1] instanceof File)) {
      const formData = getFormDataFromGraphQL(this.mutations.updateSession, {
        name,
        ...valueEntries.reduce((acc, valEnt) => {
          if (valEnt[1] instanceof File) {
            acc[valEnt[0]] = valEnt[1];
          } else {
            acc.value[valEnt[0]] = valEnt[1];
          }
          return acc;
        }, {
          value: {},
        }),
      });
      formData.append('name', name);
      return this.formDataMutate(formData);
    }
    return this.mutate(this.mutations.updateSession, {
      name,
      value,
    });
  }

  updateSessionValuesMutation(values) {
    return this.mutate(this.mutations.updateSession, { values });
  }
}

export default new SessionProvider(QUERIES, MUTATIONS);
