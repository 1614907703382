import buildSportsQuery from '@/utils/helpers/buildSportsQuery';

export default (state, stepName) => {
  const payload = {
    sports: [],
  };
  if (stepName === 'orgSports') {
    payload.sports = buildSportsQuery(state.athletesAgeBySports, true, ['sport', 'monthsInSeason']);
  } else if (stepName === 'orgMonthsInSeason') {
    payload.sports = buildSportsQuery(state.athletesAgeBySports, true, ['monthsInSeason']);
  } else if (stepName === 'orgAgeGroups') {
    payload.sports = buildSportsQuery(state.athletesAgeBySports, true, ['ageGroup1', 'ageGroup2', 'ageGroup3', 'ageGroup4']);
  }
  return payload;
};
