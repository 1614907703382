import { COVERAGE_TYPE_ALIASES } from '@/utils/constants';

const checkQuotesAreValidToDisplay = (quotes) => quotes.length && quotes.some((quote) => !!quote.annualCost);

export default (steps, state, rootGetters) => {
  if ((steps && steps instanceof Object)
    && (rootGetters && rootGetters instanceof Object)) {
    const displayOptionalSubStepsMap = {
      concussionProtocolQuestions: rootGetters['orgApplication/concussionProtocolQuestionsNeeded'],
      orgPreferredCoverageDates: !rootGetters['orgApplication/organizationToCompleteTypeIsSeasonal'],
      orgPreliminaryQuotes: !!(state.preliminaryQuotes.lastUpdatedAtDate && checkQuotesAreValidToDisplay(state.preliminaryQuotes.items)),
      orgMonthsInSeason: rootGetters['orgApplication/chosenCoverageType'] != COVERAGE_TYPE_ALIASES.generalLiabilityAndParticipantLiability,
      orgCurrentCoverage: rootGetters['orgApplication/chosenCoverageType'] != COVERAGE_TYPE_ALIASES.generalLiabilityAndParticipantLiability,
      orgRegProvider: false,
      orgBackgroundCheck: false,
      sexualAbuseLiabilityQuestions: rootGetters['orgApplication/sexualAbuseLiabilityQuestionsNeeded'],
      orgProtocolTemplates: rootGetters['orgApplication/protocolsQuestionsNeeded'],
    };
    return Object.entries(steps).reduce((acc, entry) => {
      const step = entry[0];
      acc[step] = entry[1].filter((subStep) => !displayOptionalSubStepsMap.hasOwnProperty(subStep) || displayOptionalSubStepsMap[subStep]);
      return acc;
    }, {});
  }
  return steps;
};
