<template>
  <v-form :class="b('form')" ref="fillOrgForm">
    <v-row :class="b('contentWrapper', { noneSelected: !selectedOrg })" no-gutters>
      <v-col cols="12" md="5" :class="b('content')" ref="searchContent">
        <div :class="b('smartSearch')" ref="searchInput">
          <SearchableSelect
            @input="selectOrganization"
            :items="organizationsValidOptions"
            :value="selectedOrg"
            :rules="inputRules.required"
            :disabled="!organizationsOptions.length"
            :searchable="$root.isMobile"
            id="smart-search-input"
            name="state"
            :label='computedLabel'
            placeholder="Name of organization"
          />
        </div>
        <div :class="b('hint')">
          <slot name="search-hint">
            {{ $t('ui.orgSearchForClaimHint') }}
          </slot>
        </div>
      </v-col>
      <v-spacer v-if="selectedOrg" />
      <v-col v-if="selectedOrg" cols="12" md="5" :class="b('orgInfo')">
        <transition name="slow-fade">
          <div :class="b('orgInfoContent')">
            <div :class="b('orgInfoHeading')">
              <OrgTypeIcon :type="form.type || ''" :class="b('orgInfoIcon')" />
              <div :class="b('orgInfoHeadingText')">
                <div :class="b('orgInfoHeadingTextType')">
                  {{ computedSelectedType }}
                </div>
                <div :class="b('orgInfoHeadingTextName')">
                  {{ form.name }}
                </div>
              </div>
            </div>
            <div :class="b('orgInfoItem')">
              <div :class="b('orgInfoItemIcon')">
                <img src="@/assets/icons/mark--purple--icon.svg" alt="mark-icon">
              </div>
              <div :class="b('orgInfoItemValue')">
                {{ $formatAddress(form.address) }}
                <span v-if="form && form.address">, {{form.address.zip}}</span>
              </div>
            </div>
            <div :class="b('orgInfoItem')">
              <div :class="b('orgInfoItemIcon')">
                <img src="@/assets/icons/envelop--purple--icon.svg" alt="envelop-icon">
              </div>
              <div :class="b('orgInfoItemValue')">
                {{ form.email || $t('ui.none') }}
              </div>
            </div>
            <div :class="b('orgInfoItem')">
              <div :class="b('orgInfoItemIcon')">
                <img src="@/assets/icons/phone--purple--icon.svg" alt="phone-icon">
              </div>
              <div :class="b('orgInfoItemValue')">
                {{ computedSelectedPhone || $t('ui.none') }}
              </div>
            </div>
            <div :class="b('orgInfoItem')">
              <div :class="b('orgInfoItemIcon')">
                <img src="@/assets/icons/globe--purple--icon.svg" alt="globe-icon">
              </div>
              <div :class="b('orgInfoItemValue')">
                {{ form.site || $t('ui.none') }}
              </div>
            </div>
          </div>
        </transition>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { OrganizationProvider } from '@/graphql/providers';
import inputRules from '@/utils/helpers/inputRules';
import formatPhone from '@/utils/helpers/formatPhone';
import OrgTypeIcon from '@/components/organization/OrgTypeIcon';

const AutocompleteMaterialSearch = () => import('@/components/controls/AutocompleteMaterialSearch');

export default {
  name: 'ClaimActionOrgStep',
  block: 'claimActionOrgStep',
  components: {
    AutocompleteMaterialSearch,
    OrgTypeIcon,
  },
  props: {
    currentStep: {
      type: [String, Number],
    },
    form: {
      default: () => ({}),
    },
  },
  data: () => ({
    selectedOrg: '',
    inputRules,
  }),
  computed: {
    computedSelectedPhone() {
      return (this.form.phone) ? `+${formatPhone(this.form.phone)}` : '';
    },
    computedSelectedType() {
      return (this.organizationTypeMap && this.organizationTypeMap[this.selectedOrgType]) ? this.organizationTypeMap[this.selectedOrgType].value : '';
    },
    organizationsValidOptions() {
      return this.organizationsOptions.filter((el) => el.hasSomeActivePolicies);
    },
    ...mapGetters('user', ['accessToken']),
    ...mapGetters('glossary', ['cities', 'states', 'organizationTypeMap']),
    ...mapGetters('organization', ['organizations', 'organizationsOptions']),
    computedLabel() {
      return this.selectedOrg.length !== 0 ? this.$t('ui.nameOfOrg') : null;
    },
  },
  watch: {
    form: {
      handler(newVal) {
        if (newVal && newVal.orgId && newVal.orgId != this.selectedOrg) this.selectOrganization(newVal.orgId);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions('organization', [
      'fetchOrganization',
    ]),
    onMailingCheckboxChange(val = this.form.$control.noSeparateMailingAddress, update = true) {
      if (val) {
        this.cloneMailingAddress(update);
      }
    },
    cloneMailingAddress(update) {
      let result = {};
      Object.keys(this.form.mailing).map((field) => { result[field] = this.form.address[field]; return true; });
      if (update) {
        this.updateForm('mailing', result);
      }
    },
    selectOrganization(id) {
      if (!id) return;
      this.selectedOrg = id;
      const org = this.organizations.find((el) => el.id == id);
      if (org) this.populateForm(org);
    },
    populateForm(payload) {
      const convertOrganzationObjectToForm = (key, val) => {
        let res = {};
        switch (key) {
          case 'address':
            res = {
              address: {
                street: val.address || '',
                city: val.city || '',
                state: val.state || '',
                zip: val.zip || '',
                country: val.country || '',
              },
            };
            break;
          case 'mailing':
            res = {
              mailing: {
                street: val.address || '',
                city: val.city || '',
                state: val.state || '',
                zip: val.zip || '',
                country: val.country || '',
              },
            };
            break;
          default:
            if (key != 'formatted_address' && key != 'isCompleted') {
              res = {
                [key]: val || '',
              };
            }
            break;
        }
        return res;
      };
      let result = {};
      Object.entries(payload).map((entry) => {
        if (entry[1] || entry[1] == 0) {
          Object.assign(result, convertOrganzationObjectToForm(entry[0], entry[1]));
        } else if (this.form[entry[0]]) {
          result[entry[0]] = '';
        }
        return true;
      });
      this.$emit('updateStepForm', {
        number: this.currentStep,
        form: result,
      });
    },
    setOrgIcon(file) {
      this.updateForm('icon', file);
    },
    clearOrgIcon(file) {
      this.updateForm('icon', file);
    },
    updateForm(key, value) {
      let reqObj = {};
      if (key.split('.').length == 2) {
        reqObj[[key.split('.')[0]]] = { ...this.form[key.split('.')[0]], [key.split('.')[1]]: value };
      } else {
        reqObj = { [key]: value };
      }
      let form = { ...this.form, ...reqObj };
      this.onMailingCheckboxChange(form.$control.noSeparateMailingAddress, false);
      this.$emit('updateStepForm', { number: this.currentStep, form });
    },
    validateForm() {
      const isValid = this.$refs.fillOrgForm.validate();
      if (isValid) {
        this.$emit('nextStep', this.currentStep + 1);
      }
      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'claimActionOrgStep';

.#{$block} {
  max-width: 800px;
  margin: 0 auto;
  &__contentWrapper {
    @include mappy-bp(md) {
      &:before {
        content: '';
        background: #EDEDED;
        width: 1px;
        height: 80%;
        margin-top: 30px;
        position: absolute;
        left: 50%;
        opacity: 1;
        transition: all .5s ease;
      }
    }
    transition: transform .5s ease;
    &--noneSelected {
      @include mappy-bp(md) {
        transform: translateX(30%);
        &:before {
          opacity: 0;
        }
      }
    }
  }
  &__content {
    padding: 15px 0px;
    width: 100%;
    box-sizing: border-box;
    max-width: 358px;
  }
  &__orgInfo {
    padding: 15px 0px;
    width: 100%;
    box-sizing: border-box;
    max-width: 358px;
  }
  &__hint {
    font-weight: 300;
    font-size: 14px;
    color: #5C636C;
    letter-spacing: 0.01em;
    line-height: 140%;
    @include mappy-bp(md) {
      margin-top: 15px;
    }
  }
  &__smartSearch {
    margin-top: 25px;
    @include mappy-bp(md) {
      margin-top: 55px;
    }
    .autocompleteSearch {
      width: 100%;
    }
  }
  &__smartSearchLabel {
    font-size: 13px;
    text-align: left;
    line-height: 2;
    color: #808080;
  }
  &__createNew {
    display: flex;
    margin-top: 60px;
    text-align: left;
  }
  &__createNewButton {
    font-size: 14px;
    font-weight: 500;
    color: $ph-accent;
    text-transform: uppercase;
    cursor: pointer;
    .#{$block}__createNewButtonPlusSpan {
      margin-right: 10px;
    }
  }
  &__secondaryContentTitle {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  &__orgInfo {
    position: relative;
    z-index: 2;
  }
  &__orgInfoTitle {
    font-size: 13px;
  }
  &__orgName {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.15;
    padding-bottom: 15px;
    margin-top: 10px;
    border-bottom: 1px solid #D3D3D3;
  }
  &__orgInfoRow {
    font-size: 18px;
    display: flex;
    &:first-child {
      margin-top: 20px;
    }
    &:nth-child(1n + 2) {
      margin-top: 10px;
    }
  }
  &__orgInfoRowIcon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: linear-gradient(45deg, $lime, $turquoise);
    line-height: 36px;
    text-align: center;
    margin-right: 20px;
    flex-shrink: 0;
  }
  &__orgInfoRowIconImage {
    height: 20px;
    vertical-align: middle;
    &--envelope {
      height: 14px;
    }
  }
  &__orgInfoRowLabel {
    font-weight: 500;
  }
  &__orgInfoRowTextWrapper {
    &--textNoWrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__orgInfoRowText {
    color: #0C1B2D;
    text-decoration: none;
    &--link {
      font-weight: 500;
    }
  }
  &__orgInfoContent {
    display: flex;
    flex-direction: column;
  }
  &__orgInfoHeading {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  &__orgInfoHeadingText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
  }
  &__orgInfoHeadingTextType {
    font-size: 13px;
    color: #9B9B9B;
  }
  &__orgInfoHeadingTextName {
    font-size: 14px;
    font-family: $antarctica;
    font-weight: 700;
    color: $ph-dark;
    line-height: 19px;
  }
  &__orgInfoIcon {
    width: 36px;
    object-fit: contain;
    margin-right: 19px;
  }
  &__orgInfoItem {
    display: flex;
  }
  &__orgInfoItemIcon {
    width: 50px;
    margin-bottom: 0;
    margin-right: 10px;
    display: flex;
    align-items: start;
    justify-content: center;
    height: unset;
    padding-top: 7px;
    img {
      object-fit: contain;
    }
  }
  &__orgInfoItemValue {
    font-weight: 400;
    font-size: 16px;
    @include mappy-bp(md) {
      font-size: 14px;
    }
    color: $ph-dark;
    line-height: 32px;
  }
}
</style>
