<template lang="html">
  <v-app-bar
    v-if="showingToolbar"
    :app="!headerTransparent"
    :light="!headerTransparent"
    :dark="headerTransparent"
    :elevation="0"
    :height="80"
    :class="classes"
    :color="headerColor"
    fixed
    clipped-left
  >
    <v-container pa-0>
      <div :class="b('content')">
        <transition name="fade" appear>
          <v-toolbar-title id="toolbarLogo" :class="b('logoWrapper')">
            <router-link :to="homeRoute">
              <Logo :inverted="headerTransparent" />
            </router-link>
          </v-toolbar-title>
        </transition>
        <transition name="fade" appear>
          <RouteBackBtn v-if="showBackBtn" :color="'blue-grey'" :outline="false" :round="true" />
        </transition>
        <!--notifications start-->
        <transition name="fade">
          <NotificationDropdown
            v-if="authed"
            v-model="showNotificationDropdown"
            :class="b('notificationButton')"
            @notificationClick="onNotificationClick(arguments[0]); hideNotificationDropdown();"
          />
        </transition>
        <!--notifications end-->
        <v-spacer />
        <transition name="fade">
          <AppToolbarMenu
            v-if="!$vuetify.breakpoint.smAndDown"
            :class="b('menu')"
          />
        </transition>
        <!--shopping cart start-->
        <transition name="fade" appear>
          <OrgsShoppingCartDropdown
            v-if="authed"
            v-model="showShoppingCartDropdown"
            :class="b('shoppingCart')"
          />
        </transition>
        <!--shopping cart end-->
        <div class="lang-wrapper" v-if="localeInitialized && !isMobile">
          <LangSwitcher
            :available-locales="availableLocales"
            :current-locale="currentLocale"
            :is-mobile="isMobile"
            @setNewLocale="handleUpdateLocale"
          />
        </div>
        <!--contact us link start-->
        <transition name="fade" appear>
          <a
            target="_blank"
            class="contactUs__link"
            href="https://playershealth.happyfox.com/home/"
          >
            <div class="contactUs__container">
              <img
                class="contactUs__image"
                src="/static/images/misc/question-mark-small.png"
                alt="contact-us-link"
              />
              <div class="contactUs__text" v-if="$vuetify.breakpoint.mdAndUp">
                {{ $t("ui.needHelp") }}
              </div>
            </div>
          </a>
        </transition>
        <!--contact us link end-->
        <transition name="fade" appear>
          <div class="UserHead" v-if="authed">
            <img class="UserHead__image" :src="getProfileAvatar()" :alt="profile.icon"  @click="handleShowPanel" ref="userPanelTrigger" />
            <UserPanel v-show="showUserPanel" v-click-outside="onUserPanelClickOutside" />
          </div>
        </transition>
        <transition name="fade" appear>
          <div v-if="$vuetify.breakpoint.smAndDown" :class="b('menuToggleWrapper')">
            <MenuToggle @click="toggleMenu" />
          </div>
        </transition>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import RouteBackBtn from '@/components/buttons/RouteBackBtn';
import AppToolbarMenu from '@/components/layout/AppToolbarMenu';
import MenuToggle from '@/components/buttons/MenuToggle';
import LangSwitcher from '@/components/controls/LangSwitcher';
import Logo from '@/components/layout/Logo';
import NotificationDropdown from '@/components/layout/NotificationDropdown';
import UserPanel from '@/components/layout/UserPanel';
import OrgsShoppingCartDropdown from '@/components/shoppingCart/ShoppingCartDropdown/OrgsShoppingCartDropdown';
import notificationClickHandler from '@/utils/mixins/notificationClickHandler';

const USER_PLACEHOLDER_IMG = require('@/assets/images/user-placeholder.jpg');

export default {
  name: 'AppToolbar',
  block: 'appToolbar',
  mixins: [notificationClickHandler],
  directives: {
    ClickOutside,
  },
  components: {
    RouteBackBtn,
    AppToolbarMenu,
    MenuToggle,
    LangSwitcher,
    Logo,
    NotificationDropdown,
    OrgsShoppingCartDropdown,
    UserPanel,
  },
  data: () => ({
    drawer: null,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
      },
      {
        text: 'Link 1',
        disabled: false,
      },
      {
        text: 'Link 2',
        disabled: true,
      },
    ],
    showUserPanel: false,
    showNotificationDropdown: false,
    showShoppingCartDropdown: false,
  }),
  computed: {
    ...mapGetters('user', [
      'authed',
      'homeRoute',
      'profile',
    ]),
    ...mapGetters('layout', [
      'headerColor',
      'headerTransparent',
      'isMobile',
      'showingToolbar',
    ]),
    ...mapGetters('locale', [
      'availableLocales',
      'currentLocale',
      'localeInitialized',
    ]),
    classes() {
      return {
        [this.b({ blackGradient: this.headerTransparent })]: true,
        'lighten-4': !this.$vuetify.breakpoint.mdAndDown,
      };
    },
    clipped() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    showBackBtn() {
      return this.$vuetify.breakpoint.lgAndUp && this.$route.meta.backToolbar;
    },
    routePath() {
      return this.$store.state.route.path;
    },
  },
  watch: {
    routePath() {
      this.hideUserPanel();
      this.hideShoppingCartDropdown();
    },
  },
  methods: {
    ...mapActions('layout', ['toggleMenu']),
    ...mapActions('locale', ['setLocale']),
    handleUpdateLocale(value) {
      this.setLocale(value);
    },
    handleShowPanel() {
      this.showUserPanel = !this.showUserPanel;
    },
    hideUserPanel() {
      this.showUserPanel = false;
    },
    onUserPanelClickOutside(e) {
      if (e.target.className !== this.$refs.userPanelTrigger.className) {
        this.showUserPanel = false;
      }
    },
    hideNotificationDropdown() {
      this.showNotificationDropdown = false;
    },
    hideShoppingCartDropdown() {
      this.showShoppingCartDropdown = false;
    },
    getProfileAvatar() {
      if (this.profile && this.profile.icon) {
        return this.profile.icon;
      }
      return USER_PLACEHOLDER_IMG;
    },
  },
};
</script>

<style lang="scss" scoped>
#toolbarLogo {
  & > a {
    display: inline-block;
  }
}
$block: 'appToolbar';
$v-toolbar-block: 'v-toolbar';

.#{$block} {
  .#{$v-toolbar-block}__content {
    .container {
      margin-left: auto;
      margin-right: auto;
    }
    .#{$block}__content {
      display: flex;
      align-items: center;
      .#{$v-toolbar-block} {
        &__items {
          align-self: stretch;
        }
      }
    }
  }
  &--blackGradient {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + 20px);
      position: absolute;
      top: 0px;
      left: 0px;
      background: linear-gradient(180deg, rgba($black, 0.6), $fake-transparent);
      z-index: -1;
    }
  }
  .#{$v-toolbar-block}__title {
    margin-left: 0.5em;
  }

  &__menuToggleWrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0px 8px;
  }

  &__menu {
    @include mappy-bp(lg) {
      margin-right: 0px;
    }
  }

  &__logo {
    max-width: 96px;
    vertical-align: middle;
    @include mappy-bp(min-width 500px){
      max-width: 130px;
    }
  }

  &__shoppingCart {
    margin-right: 8px;
    @include mappy-bp(md) {
      margin-left: 70px;
      margin-right: 16px;
    }
  }

  &__notificationButton {
    margin-left: 10px;
    @include mappy-bp(md) {
      margin: 10px 30px 0px 38px;
    }
  }

  &__logoWrapper {
    flex-shrink: 0;
  }
}
.contactUs__link {
  color: inherit;
}
.contactUs__container {
  display: flex;
  gap: 4px;
  font-size: 10px;
  font-weight: 700;
  font-family: "Antarctica", Helvetica, Arial, sans-serif;
  align-items: center;
  @include mappy-bp(md) {
    width: 115px;
  }

  .contactUs__image {
    border-radius: 100%;
    width: 25px;
    height: 25px;
  }
}

.UserHead {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 10px;
  @include mappy-bp(md) {
    margin-left: 20px;
  }
  .UserHead__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
