import { getPoliciesOfOrg, checkOrgHasAllPolicies } from '@/utils/helpers';
import { ORGANIZATION_STATUS_ALIASES } from '@/utils/constants';
import i18n from '@/i18n';

export default {
  organizations: (ctx, ctxGetters, rootState, rootGetters) => ctx.organizations.map((org) => ({
    ...org,
    status: org.status || (org.isCompleted ? ORGANIZATION_STATUS_ALIASES.completed : ORGANIZATION_STATUS_ALIASES.incompleted),
    hasAllPolicies: checkOrgHasAllPolicies(org, rootGetters['policy/policies'], rootGetters['insurance/coverages']),
    policies: getPoliciesOfOrg(org, rootGetters['policy/policies'], true),
  })),
  orgsWithSubmissions: (ctx, ctxGetters, rootState, rootGetters) => ctx.organizations && ctx.organizations.reduce((acc, org) => {
    if (org.quoteOffersTotal > 0) acc.push({ text: org.name, id: org.id });
    return acc;
  }, [{
    id: 'All',
    text: i18n.t('items.allOrganizations'),
  }] || []),
  organizationsOptions: (ctx, ctxGetters, rootState, rootGetters) => ctxGetters.organizations && ctxGetters.organizations.map((org) => ({
    text: org.name,
    value: org.id,
    hasAllPolicies: org.hasAllPolicies,
    hasSomeActivePolicies: org.policies instanceof Array && org.policies.length > 0,
  })),
  organizationsClaimsOptions: (ctx) => ctx.organizations && ctx.organizations.map((org) => ({ text: org.name, value: org.name })),
  pendingOrganizations: (ctx) => ctx.pendingOrganizations.map((org) => ({
    ...org,
    pending: true,
  })),
  searchOrganizationQuery: (ctx) => ctx.search.query,
  searchOrganizationLoading: (ctx) => ctx.search.loading,
  searchOrganizationResults: (ctx) => ctx.search.results,
};
