// prototype extension, global methods & global variables

import Vue from 'vue';
import defaults from '@/data';
import store from '@/store';
import i18n from '@/i18n';
import moment from '@/plugins/moment';
import { asyncForEach, jsonClone } from '@/utils/helpers';

const title = require('title');

export const busEvents = new Vue({});

Vue.prototype.$bus = busEvents;

Vue.prototype.$app = {
  colors: defaults.colors,
};

Vue.prototype.$lib = {
  moment,
};

// Global methods
Vue.prototype.$scrollTo = (yCoord, el = window, easing = 'smooth') => {
  el.scrollTo({
    top: yCoord,
    behavior: easing,
  });
};

Vue.prototype.$formatAddress = (address) => {
  let output = '';
  if (address.address) {
    output += address.address;
  }
  if (address.city) {
    output += `${address.address ? ', ' : ' '}${address.city}`;
  }
  if (address.state) {
    const state = store.getters['glossary/statesMap'][address.state] || address.state;
    output += `, ${state.value || state}`;
  }
  if (address.country) {
    const country = store.getters['glossary/countriesMap'][address.country] || address.country;
    output += `, ${country.value || country}`;
  }
  return output;
};

Vue.prototype.$truncateText = (text, limit, ending = '...') => {
  if (typeof text === 'string' && text.length > limit) {
    return text.slice(0, limit) + ending;
  }
  return text;
};

Vue.prototype.$formatPhone = (phone) => phone.replace(/[^\d]*/g, '');

// disabling capitalization as per new styleguide
// Vue.prototype.$capitalize = title;
Vue.prototype.$capitalize = (value) => value;

Vue.prototype.$booleanToHuman = (value) => (!!value && i18n.t('ui.yes')) || i18n.t('ui.no');

// Dialog functions init
/*
config
{
  title: String [default = ''] - if title not defined or equals to empty string header section in the dialogs doesn't show
  description: String [default = ''] - can be html string
  btnText: String [default = ('Got it' - fro info || 'OK' - for confirm)]
  contentTextAlign: String [default = ''] - available values ['center']
}
*/

Vue.prototype.$dialog = {
  info(config) {
    return store.dispatch('dialog/showInfoDialog', config);
  },
  confirm(config) {
    return store.dispatch('dialog/showConfirmDialog', config);
  },
  close() {
    return store.dispatch('dialog/closeDialog');
  },
  error(config) {
    return store.dispatch('dialog/showErrorDialog', config);
  },
};

Vue.prototype.$toast = (config) => store.dispatch('notification/createToast', config);

Vue.prototype.$jsonCloneObject = jsonClone;

Vue.prototype.$asyncForEach = asyncForEach;

Vue.prototype.$envToArray = function (value) {
  if (value) {
    return value.split(process.env.ARRAY_DIVIDER || ',');
  }
  return false;
};
