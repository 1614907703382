const getLocalesArrayFromLangs = (langsObj) => {
  if (langsObj instanceof Object) {
    return Object.entries(langsObj).reduce((acc, langEnt) => {
      if (langEnt[1] instanceof Object) {
        acc.push({
          ...langEnt[1],
          value: langEnt[0],
        });
      }
      return acc;
    }, []);
  }
  return [];
};

export default {
  availableLocales: (state) => getLocalesArrayFromLangs(state.languages),
  currentLocale: (state, getters) => state.languages[state.currentLocale] || null,
  localeInitialized: (state, getters) => !!getters.currentLocale,
};
