import { API_DATE_FORMAT, DEFAULT_PAYMENT_CYCLE_VALUE } from '@/utils/constants';
import { ATHLETES_AGE_OBJECT } from '@/store/modules/orgApplication/constants';
import {
  getChainedQuestionGroupByQuestionKey, getDefaultState, getDefaultOrgCoverageDates,
} from '@/store/modules/orgApplication/helpers';

const getDefaultSelectedLimitForCoverage = (coverage, orgType, orgSports) => {
  const selectableLimit = [...new Set(orgSports.map((sportId) => coverage.limits[orgType] && coverage.limits[orgType][sportId]))][0];
  if (selectableLimit && selectableLimit.default) {
    return selectableLimit.default;
  }
  if (coverage.limits && coverage.limits.default) {
    return coverage.limits.default;
  }
  if (coverage.limits && coverage.limits.isDefaultMinimal) {
    return (coverage.limits && coverage.limits instanceof Object && coverage.limits.value && coverage.limits[coverage.limits.value] instanceof Array && coverage.limits[coverage.limits.value].length)
      ? coverage.limits[coverage.limits.value][0]
      : 0;
  }
  return (coverage.limits && coverage.limits instanceof Object && coverage.limits.value && coverage.limits[coverage.limits.value] instanceof Array && coverage.limits[coverage.limits.value].length)
    ? coverage.limits[coverage.limits.value][coverage.limits[coverage.limits.value].length - 1]
    : 0;
};

export default {
  SET_COVERAGES(context, payload = []) {
    context.coverages = payload;
  },
  SET_CHAINED_QUESTIONS(context, payload = {}) {
    context.chainedQuestions = payload;
  },
  UPDATE_ORG_APP_STEPS_STATE(context, payload) {
    Object.assign(context.orgApplicationSteps, payload);
  },
  UPDATE_MANUAL_REPORTS_CHECKBOXES(context, payload) {
    Object.assign(context.manualReportCheckboxes, payload);
  },
  UPDATE_HAVE_POLICY(context, value) {
    context.havePolicy = value;
  },
  SET_ORGANIZATION_PARAMS(context, params) {
    Object.assign(context.organizationToComplete, { params });
  },
  RESET_EXTERNAL_COVERAGES_FORM(context) {
    context.externalCoverages = {};
  },
  SET_CURRENT_CHOSEN_COVERAGE_IDS(context, types) {
    context.externalCoverages = types;
  },
  UPDATE_EXTERNAL_COVERAGES_FIELDS(context, data) {
    if (context.externalCoverages[data.covType]) {
      context.externalCoverages[data.covType][data.field] = data.value;
    }
  },
  RESET_ORG_APPLICATION(context) {
    Object.assign(context, {
      ...getDefaultState(),
      otherProvider: context.otherProvider,
    });
  },
  INCREMENT_STEP(context) {
    context.step += 1;
  },
  INCREMENT_BG_CHECK_STEP(context) {
    context.bgCheckStep += 1;
  },
  INCREMENT_SUB_STEP(context) {
    context.subStep += 1;
  },
  RESET_SUB_STEP(context) {
    context.subStep = 1;
  },
  DECREMENT_STEP(context) {
    const ctx = context;
    ctx.step -= 1;
  },
  DECREMENT_SUB_STEP(context) {
    const ctx = context;
    ctx.subStep -= 1;
  },
  DECREMENT_BG_CHECK_STEP(context) {
    context.bgCheckStep -= 1;
  },
  FORCE_STEP(context, payload = 1) {
    context.step = payload;
  },
  FORCE_SUB_STEP(context, payload = 1) {
    context.subStep = payload;
  },
  SET_BG_CHECKS(context, val) {
    context.bgChecks = val;
  },
  SET_SELECTED_REGISTRATION_PROVIDER(context, prov) {
    context.registrationProvider = prov;
  },
  SET_OTHER_REGISTRATION_PROVIDER(context, name) {
    context.otherProvider = name;
  },
  SET_BG_CHECK_PROVIDER(context, prov) {
    context.bgCheckProvider = prov;
  },
  SET_OTHER_BG_CHECK_PROVIDER(context, prov) {
    context.otherBackgroundProvider = prov;
  },
  SET_PAYMENT_OPTION(context, val) {
    context.paymentOption = val;
  },
  SET_AGE_GROUPS_BY_SPORTS(context, ageGroupsObj) {
    context.athletesAgeBySports = ageGroupsObj;
  },
  SET_AGE_GROUPS_BY_SPORTS_ARRAY(context, selSports) {
    context.athletesAgeBySports = selSports.reduce((acc, obj) => {
      if (obj.sport && obj.sport.id) {
        const { sport, ...ages } = obj;
        acc[sport.id] = { sport, ...(() => ages ? { ...ages } : { ...ATHLETES_AGE_OBJECT })() };
      }
      return acc;
    }, {});
  },
  SET_LAST_VISITED_STEP(context, step) {
    context.lastVisitedStep = step;
  },
  SET_LAST_VISITED_SUB_STEP(context, subStep) {
    context.lastVisitedSubStep = subStep;
  },
  SET_PRELIMINARY_QUOTES(context, quotes) {
    context.preliminaryQuotes = {
      ...context.preliminaryQuotes,
      items: quotes,
      lastUpdatedAtDate: new Date(),
    };
  },
  RESET_PRELIMINARY_QUOTES(context) {
    context.preliminaryQuotes = [];
  },
  UPDATE_ORG_PERSONNEL_FIELD(context, data) {
    context.personnel[data.field] = data.value;
  },
  ADD_ATHLETES_AGE_SPORT(context, sportId) {
    if (sportId) {
      context.athletesAgeBySports[sportId] = { ...ATHLETES_AGE_OBJECT };
    }
  },
  REMOVE_ATHLETES_AGE_SPORT(context, sportId) {
    if (sportId && Object.keys(context.athletesAgeBySports).includes(sportId)) {
      const objectClone = { ...context.athletesAgeBySports };
      delete objectClone[sportId];
      context.athletesAgeBySports = { ...objectClone };
    }
  },
  UPDATE_ATHLETES_AGE_FIELD(context, data) {
    if (context.athletesAgeBySports[data.sport]) {
      context.athletesAgeBySports[data.sport][data.field] = data.value;
    }
  },
  SET_ATHLETES_AGE_BY_SPORT_IDS(context, sportIds) {
    if (sportIds instanceof Array) {
      context.athletesAgeBySports = sportIds.reduce((acc, sportId) => {
        if (sportId && typeof sportId === 'string') {
          acc[sportId] = { ...ATHLETES_AGE_OBJECT };
        }
        return acc;
      }, {});
    }
  },
  SET_ATHLETES_AGE_FROM_SPORTS(context, payload) {
    if (payload instanceof Array) {
      context.athletesAgeBySports = payload.reduce((acc, sportObj) => {
        if (sportObj && sportObj.id && typeof sportObj.id === 'string') {
          const { sport, ...ages } = sportObj;
          acc[sport.id] = { ...ages };
        }
        return acc;
      }, {});
    }
  },
  UPDATE_PROTOCOL_RADIO(context, data) {
    context.protocols[data.field] = data.value;
  },
  UPDATE_PROTOCOL_TEMPLATE(context, data) {
    context.protocols[data.field] = data.value;
  },
  SET_CHOSEN_COVERAGE_ID(context, id) {
    context.chosenCoverageId = id;
  },
  SET_CHOSEN_SUB_COVERAGE_IDS(context, ids) {
    context.chosenSubCoverageIds = ids;
  },
  SET_DEFAULT_COVERAGE_PARAMS(context, coverages) {
    if (coverages instanceof Array && coverages.length) {
      const orgType = context.organizationToComplete.type;
      const orgSports = context.organizationToComplete.sports.map((el) => el.sport.id);
      context.coverageParamsMap = coverages.reduce((acc, currVal) => {
        if (!acc[currVal.type]) {
          const { endDate, startDate } = getDefaultOrgCoverageDates(context.organizationToComplete);
          acc[currVal.type] = {
            endDate,
            selectedLimit: getDefaultSelectedLimitForCoverage(currVal, orgType, orgSports),
            selectedPaymentCycle: DEFAULT_PAYMENT_CYCLE_VALUE,
            startDate,
          };
          if (currVal.subcoverages instanceof Array && currVal.subcoverages.length) {
            acc[currVal.type].subCoverages = currVal.subcoverages.reduce((subCovAcc, subCov) => {
              subCovAcc[subCov.type] = {
                selectedLimit: getDefaultSelectedLimitForCoverage(subCov, orgType, orgSports),
              };
              return subCovAcc;
            }, {});
          }
        }
        return acc;
      }, {});
    }
  },
  SET_COVERAGE_PARAMS(context, paramsMap) {
    context.coverageParamsMap = paramsMap;
  },
  UPDATE_COVERAGE_PARAMS(context, params) {
    if (params && params instanceof Object) {
      const paramsEntries = Object.entries(params);
      if (paramsEntries.length) {
        context.coverageParamsMap = {
          ...context.coverageParamsMap,
          ...paramsEntries.reduce((acc, currVal) => {
            if (currVal.length) {
              acc[currVal[0]] = {
                ...context.coverageParamsMap[currVal[0]],
                ...currVal[1],
              };
            }
            return acc;
          }, {}),
        };
      }
    }
  },
  UPDATE_SUB_COVERAGE_PARAMS(context, { coverage, subCoverage, params }) {
    if (coverage && coverage instanceof Object && subCoverage && subCoverage instanceof Object) {
      const targetCoverageParams = context.coverageParamsMap[coverage.type];
      if (targetCoverageParams
        && targetCoverageParams instanceof Object
        && targetCoverageParams.subCoverages
        && targetCoverageParams.subCoverages instanceof Object
        && targetCoverageParams.subCoverages.hasOwnProperty(subCoverage.type)
        && params
        && params instanceof Object) {
        targetCoverageParams.subCoverages[subCoverage.type] = {
          ...targetCoverageParams[subCoverage.type],
          ...params,
        };
      }
    }
  },
  UPDATE_CARD_INFO_FIELD(context, data) {
    context.cardInfo[data.field] = data.value;
  },
  UPDATE_ECHECK_INFO_FIELD(context, data) {
    context.eCheckInfo[data.field] = data.value;
  },
  UPDATE_ORGANIZATION_TO_COMPLETE(context, data) {
    context.organizationToComplete = data;
  },
  UPDATE_CLAIMS_DATA(context, payload) {
    context.claimsData = { ...context.claimsData, ...payload };
  },
  UPDATE_CHAINED_QUESTION_ANSWER(context, {
    field, value, sectionCurrent, currentSubStep,
  }) {
    let questionSection = null;
    if (typeof currentSubStep === 'string'
      && currentSubStep.length
      && context.chainedQuestions[currentSubStep]
      && context.chainedQuestions[currentSubStep].questions
      && context.chainedQuestions[currentSubStep].questions instanceof Object
      && context.chainedQuestions[currentSubStep].questions[field]
      && context.chainedQuestions[currentSubStep].questions[field] instanceof Object) {
      questionSection = context.chainedQuestions[currentSubStep];
    } else {
      questionSection = getChainedQuestionGroupByQuestionKey(context.chainedQuestions, field);
    }
    if (questionSection) {
      questionSection.questions[field].value = value;
      if (sectionCurrent
        && !Number.isNaN(sectionCurrent)
        && sectionCurrent > 0
        && sectionCurrent < Object.keys(questionSection.questions).length) {
        questionSection.current = sectionCurrent;
      }
    }
  },
  UPDATE_CHAINED_QUESTION_SECTION_CURRENT(context, { current, sectionKey }) {
    let questionSection = null;
    if (typeof sectionKey === 'string'
      && sectionKey.length
      && context.chainedQuestions[sectionKey]
      && context.chainedQuestions[sectionKey] instanceof Object) {
      questionSection = context.chainedQuestions[sectionKey];
    }
    if (questionSection
      && current
      && !Number.isNaN(current)
      && current > 0
      && current < Object.keys(questionSection.questions).length) {
      questionSection.current = current;
    }
  },
  SET_CHAINED_QUESTION_GROUP_CURRENT(context, { field, currentSubStep, current }) {
    let questionSection = null;
    if (typeof currentSubStep === 'string'
      && currentSubStep.length
      && context.chainedQuestions[currentSubStep]
      && context.chainedQuestions[currentSubStep].questions
      && context.chainedQuestions[currentSubStep].questions instanceof Object
      && context.chainedQuestions[currentSubStep].questions[field]
      && context.chainedQuestions[currentSubStep].questions[field] instanceof Object) {
      questionSection = context.chainedQuestions[currentSubStep];
    } else {
      questionSection = getChainedQuestionGroupByQuestionKey(context.chainedQuestions, field);
    }
    if (questionSection && current < Object.keys(questionSection.questions).length) {
      questionSection.current = current;
    }
  },
  PUSH_TO_CLAIMS(context, payload) {
    context.claimsData.claims.push(payload);
  },
  SPLICE_FROM_CLAIMS(context, payload) {
    let index = context.claimsData.claims.findIndex((el) => el.$id == payload.$id);
    context.claimsData.claims.splice(index, 1);
  },
  EDIT_CLAIM(context, payload) {
    let index = context.claimsData.claims.findIndex((el) => el.$id == payload.$id);
    context.claimsData.claims.splice(index, 1, payload);
  },
};
