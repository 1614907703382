import ActivityTracker from './ActivityTracker';

class UserActivityTracker extends ActivityTracker {
  static minIntervalDurationSec = 60;

  constructor(seconds, localStorageSlug = 'last_user_activity') {
    super(localStorageSlug);
    this.tracking = {
      timeLimit: Number(seconds),
      timeoutRefreshLoop: null,
      lastActivity: 0,
    };
  }

  get isInfinite() {
    return !this.tracking.timeLimit;
  }

  get lastActivity() {
    return this.tracking.lastActivity;
  }

  get lastActivityTimeFromLocalStorage() {
    return this.getLastActivityFromLocalStorageValue();
  }

  get isLastActivityOutdated() {
    return this.checkLastActivityDiffs(this.lastActivity);
  }

  get isOutdated() {
    return Math.floor(Date.now() / 1000) >= Math.floor(this.lastActivity / 1000) + this.tracking.timeLimit;
  }

  get refreshState() {
    return this.tracking.timeoutRefreshLoop;
  }

  clearRefreshTimeout() {
    clearInterval(this.tracking.timeoutRefreshLoop);
    this.tracking.timeoutRefreshLoop = null;
  }

  startTracking(tokenTimeoutCallback) {
    if (this.isInfinite || this.refreshState != null) {
      return;
    }

    this.setLastActivityTimeStamp();
    this.startListeners();
    this.setRefreshTimeout(tokenTimeoutCallback);

    // eslint-disable-next-line
    console.log(`EVENT TRACKING LISTENER STARTED FOR ${this.tracking.timeLimit} SEC`);
  }

  stopTracking() {
    this.stopListeners();
    this.clearRefreshTimeout();
    // eslint-disable-next-line
    console.log('EVENT TRACKING LISTENER ENDED');
  }

  setRefreshTimeout(cb) {
    this.tracking.timeoutRefreshLoop = setInterval(async () => {
      // check if there has been any activity in the other tabs
      // if yes (the number in localStorage is bigger than in the store) - sync it
      if (this.isLastActivityOutdated) {
        this.updateLastActivityTime();
      }
      try {
        const shouldLogout = this.isOutdated;
        await cb(shouldLogout);
      } catch (e) {
        this.stopTracking();
      }
    }, this.tracking.timeLimit < this.minIntervalDurationSec
      ? this.minIntervalDurationSec * 1000
      : this.tracking.timeLimit * 1000);
  }

  startListeners() {
    this._updateLastActivityTimeHandler = this.updateLastActivityTime.bind(this);
    document.addEventListener('click', this._updateLastActivityTimeHandler, true);
    document.addEventListener('keyup', this._updateLastActivityTimeHandler, true);
  }

  stopListeners() {
    document.removeEventListener('click', this._updateLastActivityTimeHandler, true);
    document.removeEventListener('keyup', this._updateLastActivityTimeHandler, true);
  }

  setLastActivityTimeStamp() {
    if (!this.lastActivityTimeFromLocalStorage) {
      // set last activity time is now if it's the first time we set up the timer within the tabs
      this.updateLastActivityTime(Date.now());
    } else {
      // sync the local activity tracking object with the value from the other tabs
      this.setLastActivityFromLocalStorageValue(this.lastActivityTimeFromLocalStorage);
    }
  }

  setLastActivityFromLocalStorageValue(value) {
    try {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        this.tracking.lastActivity = value;
      } else if (value !== null) {
        throw new Error('The value is invalid or empty');
      }
    } catch (err) {
      console.error(err);
    }
  }

  updateLastActivityTime() {
    const now = Date.now();
    this.tracking.lastActivity = now;
    this.syncLastActivityTime(now);
  }
}

export default UserActivityTracker;
