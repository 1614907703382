import Vue from 'vue';
import { HUMAN_DATE_FORMAT } from '@/utils/constants';
import moment from '@/plugins/moment';
import convertFromLocale from '@/utils/helpers/convertFromLocale';

Vue.filter('lSepToWhiteSpace', (value) => {
  if (typeof (value) !== 'string') return value;
  return value.replace(/\u{2028}/u, ' ');
});

Vue.filter('capitalize', (value) => {
  if (typeof (value) !== 'string') return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('booleanToHuman', (value) => {
  if (typeof (value) !== 'string') return value;
  // eslint-disable-next-line
  return (eval(value) && 'Yes') || 'No';
});

Vue.filter('commifyNumber', (value) => {
  let str = value && !Number.isNaN(parseInt(value, 10)) && value.toString();
  if (str && str.length > 3) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return str;
});

Vue.filter('dollars', (value) => value ? Number(value.toString().replace(/,/g, '.')).toLocaleString('en') : '');
Vue.filter('convertFromLocale', (value) => convertFromLocale(value));
Vue.filter('dollarsShort', (value) => {
  value = Number(value.replace(/,/g, '.'));
  if (!value) {
    return '';
  }
  value = value.toFixed(2);
  if (value.length > 6 && value.length < 10) {
    value = `${Number(value / 1000).toFixed(2)}K`;
  }
  if (value.length > 10) {
    value = `${Number(value / 1000000).toFixed(2)}M`;
  }
  return value;
});

Vue.filter('humanDate', (value) => moment(value).format(HUMAN_DATE_FORMAT));
