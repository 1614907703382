import moment from '@/plugins/moment';
import {
  buildSportsQuery,
  createIdsForItemsByIndex,
  convertFromLocale,
  getMomentPolicyYear,
  getStepAndSubStepIndexesBySubStepName,
  getStrippedPreviousClaims,
  setMinimumNumberOfAthletesAction,
} from '@/utils/helpers';
import {
  checkOrgTypeHasEventDates,
  getSubmissionParamsPayload,
} from '@/store/modules/orgApplication/helpers';

import {
  API_DATE_FORMAT,
  DEFAULT_COVERAGE_DURATION_YEARS,
  DEFAULT_PERSONNEL_NUMBER,
  REFUSAL_TO_UPDATE_ORG_ERROR_MESSAGE,
  ORGANIZATION_STATUS_ALIASES,
} from '@/utils/constants';
import {
  ATHLETES_AGE_OBJECT,
  PERSONALIZE_STEPS_FILLER,
  EXTERNAL_COVERAGES_OBJECT,
} from '@/store/modules/orgApplication/constants';
import { InsuranceProvider, OrganizationProvider, FileProvider } from '@/graphql/providers';
import i18n from '@/i18n';

export default {
  async fetchOrgChainedQuestionsIfEmpty({ getters, dispatch }, force = false) {
    if (!Object.values(getters.rawChainedQuestions).length || force) {
      return dispatch('fetchOrgChainedQuestions');
    }
    return false;
  },
  fetchOrgChainedQuestions({ commit, getters }) {
    const { id, params = {} } = getters.organizationToComplete;
    return new Promise((resolve, reject) => {
      OrganizationProvider.orgQuestionsQuery(id).then((response) => {
        if (response.data.data && response.data.data.questions) {
          const formattedChainedQuestions = response.data.data.questions.reduce((acc, questionsGroup) => ({
            ...acc,
            [questionsGroup.key]: {
              current: 0,
              ...questionsGroup,
              questions: questionsGroup.questions.reduce((qAcc, question) => {
                const questionWithValueFromOrgParams = {
                  ...question,
                  value: params.hasOwnProperty(question.key)
                    ? params[question.key]
                    : question.value,
                };
                return {
                  ...qAcc,
                  [questionWithValueFromOrgParams.key]: questionWithValueFromOrgParams,
                };
              }, {}),
            },
          }), {});
          commit('SET_CHAINED_QUESTIONS', formattedChainedQuestions);
          commit('UPDATE_ORG_APP_STEPS_STATE', {
            personalize: PERSONALIZE_STEPS_FILLER(getters.chainedQuestionsSteps),
          });
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchCoveragesForCurrentOrg({ commit, getters }, limitsForOrg = getters.organizationToComplete.id) {
    return new Promise((resolve, reject) => {
      InsuranceProvider.coveragesQuery({ limitsForOrg }).then((response) => {
        if (response.data.data && response.data.data.coverages) {
          commit('SET_COVERAGES', response.data.data.coverages);
          if (getters.organizationToComplete && getters.organizationToComplete.id) {
            commit('SET_DEFAULT_COVERAGE_PARAMS', response.data.data.coverages);
          }
        }
        resolve(response);
      }).catch(reject);
    });
  },
  answerChainedQuestion({ commit, dispatch, getters }, { field, value }) {
    if (typeof field === 'string' && field.length && value !== undefined) {
      const currentQuestionIndex = getters.currentSubStepQuestionChainVisibleIndex;
      const targetIndex = currentQuestionIndex + 1;
      dispatch('setOrganizationParams', {
        ...getters.organizationToComplete.params,
        [field]: value,
      });
      commit('UPDATE_CHAINED_QUESTION_ANSWER', {
        field,
        value,
        currentSubStep: getters.currentSubStep,
      });
      // performing a check after the answers are updated
      // and the length of the current chain is updated accordingly
      if (targetIndex < getters.currentSubStepQuestionChain.length) {
        commit('SET_CHAINED_QUESTION_GROUP_CURRENT', {
          current: targetIndex,
          field,
          currentSubStep: getters.currentSubStep,
        });
      }
    }
  },
  showChainedQuestion({ commit, getters }, field) {
    if (typeof field === 'string' && field.length) {
      const targetIndex = getters.currentSubStepQuestionChain.findIndex((question) => question.field === field);
      if (targetIndex !== -1) {
        commit('SET_CHAINED_QUESTION_GROUP_CURRENT', {
          current: targetIndex,
          field,
          currentSubStep: getters.currentSubStep,
        });
      }
    }
  },
  goToNextChainedQuestion({ commit, getters }) {
    const currentQuestionIndex = getters.currentSubStepQuestionChainVisibleIndex;
    if (currentQuestionIndex !== -1) {
      const targetIndex = currentQuestionIndex + 1;
      if (targetIndex < getters.currentSubStepQuestionChain.length) {
        const targetQuestion = getters.currentSubStepQuestionChain[targetIndex];
        commit('SET_CHAINED_QUESTION_GROUP_CURRENT', {
          current: targetIndex,
          field: targetQuestion.field,
          currentSubStep: getters.currentSubStep,
        });
        return true;
      }
    }
    return false;
  },
  goToPrevChainedQuestion({ commit, getters }) {
    const currentQuestionIndex = getters.currentSubStepQuestionChainVisibleIndex;
    if (currentQuestionIndex !== -1) {
      const targetIndex = currentQuestionIndex - 1;
      if (targetIndex >= 0) {
        const targetQuestion = getters.currentSubStepQuestionChain[targetIndex];
        commit('SET_CHAINED_QUESTION_GROUP_CURRENT', {
          current: targetIndex,
          field: targetQuestion.field,
          currentSubStep: getters.currentSubStep,
        });
        return true;
      }
    }
    return false;
  },
  calculateAthletesFromAgeGroups({ state, getters, dispatch }) {
    return new Promise((resolve) => {
      const FIELD = 'athletes';
      const DEFAULT_VALUE = 0;
      const value = Object.values(getters.athletesAgeBySports).reduce((acc, item) => {
        let valuePerSport = 0;
        [item.ageGroup4, item.ageGroup3, item.ageGroup2, item.ageGroup1].forEach((num) => {
          valuePerSport += Number.parseInt(num, 10);
        });
        if (valuePerSport && !Number.isNaN(valuePerSport)) {
          acc += valuePerSport;
        }
        return acc;
      }, 0);
      dispatch('updateOrgPersonnelField', {
        value: !Number.isNaN(value) ? value : DEFAULT_VALUE,
        field: FIELD,
      });
      resolve(state.personnel[FIELD]);
    });
  },
  setMinimumNumberOfAthletes: setMinimumNumberOfAthletesAction(({ getters }) => getters.organizationToComplete),
  async checkCartFillAvailAbility({ rootGetters, dispatch, state }, { action, cb, cbArg }) {
    const checkSelectionWillHaveConflicts = (arg, actionType) => {
      let hasConflicts = false;
      if (rootGetters['shoppingCart/shoppingCartItems']) {
        const itemsSelectedForOrg = rootGetters['shoppingCart/shoppingCartItems'].filter((itm) => itm
          && itm.organization
          && itm.organization.id === state.organizationToComplete.id);
        if (itemsSelectedForOrg.length) {
          if (actionType === 'deselection' && arg && arg instanceof Object && typeof arg.type === 'string' && arg.type.length) {
            hasConflicts = itemsSelectedForOrg.some((itm) => itm && itm.quote && itm.quote.coverageType === arg.type);
          } else if (actionType === 'limitChange' && !Number.isNaN(arg.limit) && arg.coverage && arg.coverage instanceof Object) {
            hasConflicts = itemsSelectedForOrg.some((itm) => itm && itm.quote && itm.quote.coverageType === arg.coverage.type && itm.quote.limit !== arg.limit);
          }
        }
      }
      return hasConflicts;
    };
    if (checkSelectionWillHaveConflicts(cbArg, action)) {
      const CONFIRMED = await dispatch('dialog/showConfirmDialog', {
        title: i18n.t('message.attention'),
        description: i18n.t('message.coverageEditCartNotEmpty'),
      }, {
        root: true,
      });
      if (!CONFIRMED) {
        return false;
      }
      await dispatch('shoppingCart/clearShoppingCartItems', null, { root: true });
    }
    return cb(cbArg);
  },
  updateManualReportCheckbox(context, payload) {
    context.commit('UPDATE_MANUAL_REPORTS_CHECKBOXES', payload);
  },
  updateSignature({ dispatch }, signature) {
    return new Promise((resolve, reject) => {
      if (typeof signature === 'string') {
        dispatch('user/updateUserSignature', signature, {
          root: true,
        }).then(resolve).catch(reject);
      } else {
        reject(new Error('The provided signature was not a valid string'));
      }
    });
  },
  updateClaimsData(context, payload) {
    context.commit('UPDATE_CLAIMS_DATA', payload);
    context.dispatch('repairClaims');
    context.dispatch('setOrganizationParams', {
      ...context.getters.organizationToComplete.params,
      hadClaims: context.getters.claimsData.hadClaims,
    });
  },
  cancelQuoteOffer({ dispatch }, { id, reason }) {
    return dispatch('submission/cancelQuoteOffer',
      {
        id,
        reason,
        remove: true,
      }, {
        root: true,
      });
  },
  rejectQuoteOffer({ dispatch }, { id, reason }) {
    return dispatch('submission/rejectQuoteOffer',
      {
        id,
        reason,
        remove: true,
      }, {
        root: true,
      });
  },
  repairClaims(context) {
    context.commit('UPDATE_CLAIMS_DATA', {
      claims: createIdsForItemsByIndex(context.getters.claimsData.claims.slice(), 'carrier'),
    });
  },
  repairClaimsFiles(context, formattedClaim) {
    let claims = context.getters.claimsData.claims.slice();
    let index = claims.findIndex((el) => el.description == formattedClaim.claimDetails
                           && el.type == formattedClaim.coverageType
                           && el.date == formattedClaim.dateOfClaim);
    claims.splice(index, 1, { ...claims[index], lostReport: formattedClaim.lostReport });
    context.commit('UPDATE_CLAIMS_DATA', { claims });
  },
  addClaim(context, payload) {
    context.commit('PUSH_TO_CLAIMS', payload);
    context.dispatch('repairClaims');
  },
  editClaim(context, payload) {
    context.commit('EDIT_CLAIM', payload);
    context.dispatch('repairClaims');
  },
  removeClaim(context, payload) {
    context.commit('SPLICE_FROM_CLAIMS', payload);
  },
  updateCurrentCoverageField(context, data) {
    if (data.field
      && data.covType
      && context.state.externalCoverages[data.covType]
      && Object.keys(context.state.externalCoverages[data.covType]).includes(data.field)) {
      context.commit('UPDATE_EXTERNAL_COVERAGES_FIELDS', data);
    }
  },
  updateHavePolicy(context, value) {
    context.commit('UPDATE_HAVE_POLICY', value);
  },
  resetCurrentCoverages(context) {
    context.commit('RESET_EXTERNAL_COVERAGES_FORM');
  },
  setChosenCurrentCoverageTypes(context, coverageTypes) {
    if (coverageTypes && coverageTypes instanceof Array) {
      const coverageByTypes = coverageTypes.reduce((acc, covType) => {
        if (covType && typeof covType === 'string') {
          const org = context.state.selectedOrg || {};
          const covObjMatch = org.externalCoverages
            && org.externalCoverages.find((sp) => sp.type === covType);
          if (covObjMatch) {
            const { ...fields } = covObjMatch;
            acc[covType] = fields;
          } else {
            acc[covType] = { ...EXTERNAL_COVERAGES_OBJECT, coverageType: covType };
          }
        }
        return acc;
      }, {});
      context.commit('SET_CURRENT_CHOSEN_COVERAGE_IDS', coverageByTypes);
    }
  },
  addQuoteToCart(context, quote) {
    if (quote && quote instanceof Object && quote.limit) {
      delete quote.limit;
    }
    return new Promise(async (resolve, reject) => {
      if (quote && quote instanceof Object && Object.keys(quote).includes('id')) {
        try {
          await context.dispatch('shoppingCart/addQuoteToShoppingCart', {
            quote,
            replaceQuoteOfSameOrgAndCoverageType: true,
          },
          {
            root: true,
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  removeQuoteFromCart(context, quote) {
    return new Promise(async (resolve, reject) => {
      if (quote && quote instanceof Object && Object.keys(quote).includes('id')) {
        try {
          await context.dispatch('shoppingCart/removeQuoteFromShoppingCart', {
            quoteId: quote.id,
          },
          {
            root: true,
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  forceCoverageSelection({ getters, dispatch }, coverageType) {
    return new Promise(async (resolve, reject) => {
      if (coverageType && coverageType.length) {
        if (!getters.coveragesWithParams.length) {
          try {
            await dispatch('fetchCoveragesForCurrentOrg', getters.organizationToComplete.id);
          } catch (err) {
            reject(err);
          }
        }
        const coverage = getters['orgApplication/coverages'].find((cvg) => cvg.type === coverageType);
        if (coverage instanceof Object && typeof coverage.id === 'string' && coverage.id.length) {
          try {
            await dispatch('shoppingCart/clearShoppingCartItems', null, { root: true });
            dispatch('setChosenCoverageId', coverage.id);
            resolve(getters.chosenCoverageId);
          } catch (err) {
            reject(err);
          }
        } else {
          resolve(getters.chosenCoverageId);
        }
      } else {
        reject(new Error('No coverage types list string was provided'));
      }
    });
  },
  flushOrgApplicationData(context) {
    context.commit('RESET_ORG_APPLICATION');
    context.dispatch('submission/resetSubmissionsData', null, { root: true });
  },
  selectRegProvider(context, prov) {
    context.commit('SET_SELECTED_REGISTRATION_PROVIDER', prov);
  },
  setCompletionStep({ state, getters, dispatch }) {
    let step = 1;
    let subStep = 1;
    if (state.organizationToComplete && state.organizationToComplete instanceof Object) {
      if (state.organizationToComplete.isCompleted) {
        if (typeof getters.chosenCoverageId === 'string' && getters.chosenCoverageId.length) {
          const targetStep = 'policyChoice';
          const targetStepIndexes = getStepAndSubStepIndexesBySubStepName(getters.orgApplicationSteps, targetStep);
          step = targetStepIndexes.step + 1;
          subStep = targetStepIndexes.subStep + 1;
        } else {
          const orgPreferredCoverageStep = 'orgPreferredCoverage';
          const orgPreferredCoverageStepIndexes = getStepAndSubStepIndexesBySubStepName(getters.orgApplicationSteps, orgPreferredCoverageStep);
          step = orgPreferredCoverageStepIndexes.step + 1;
          subStep = orgPreferredCoverageStepIndexes.subStep + 1;
        }
      } else if (getters.lastCompleteStep === 1 && getters.currentStepLastCompleteSubStep === getters.currentStepSubSteps.length) {
        const orgPreferredCoverageStep = 'orgPreferredCoverage';
        const orgPreferredCoverageStepIndexes = getStepAndSubStepIndexesBySubStepName(getters.orgApplicationSteps, orgPreferredCoverageStep);
        step = orgPreferredCoverageStepIndexes.step + 1;
        subStep = orgPreferredCoverageStepIndexes.subStep + 1;
      }
    }
    // dispatch('toSpecificStepAndSubStep', { step: 2, subStep: 4 });
    dispatch('toSpecificStepAndSubStep', { step, subStep });
    return Promise.resolve(step);
  },
  setOtherRegProvider(context, provName) {
    context.commit('SET_SELECTED_REGISTRATION_PROVIDER', {
      id: 'other',
      name: provName,
    });
    context.commit('SET_OTHER_REGISTRATION_PROVIDER', provName);
  },
  updateBgChecks(context, val) {
    context.commit('SET_BG_CHECKS', val);
  },
  updatePaymentOption(context, val) {
    context.commit('SET_PAYMENT_OPTION', val);
  },
  updateBgCheckProvider(context, prov) {
    context.commit('SET_BG_CHECK_PROVIDER', prov);
  },
  setOtherBgCheckProvider(context, provName) {
    context.commit('SET_BG_CHECK_PROVIDER', {
      id: 'other',
      name: provName,
    });
    context.commit('SET_OTHER_BG_CHECK_PROVIDER', {
      id: 'other',
      name: provName,
    });
  },
  updateMultipleProtocols(context, data) {
    if (data.field && Object.keys(context.state.protocols).includes(data.field)) {
      if (typeof data.value === 'boolean') {
        context.commit('UPDATE_PROTOCOL_RADIO', {
          field: data.field,
          value: data.value,
        });
      }
    }
  },
  updateInjuryProtocol(context, val) {
    if (typeof val === 'boolean') {
      context.commit('UPDATE_PROTOCOL_RADIO', {
        field: 'injuryProtocol',
        value: val,
      });
    }
  },
  updateInjuryProtocolTemplate(context, file) {
    context.commit('UPDATE_PROTOCOL_TEMPLATE', {
      field: 'injuryProtocolTemplate',
      value: file,
    });
  },
  updateConcussionProtocol(context, val) {
    if (typeof val === 'boolean') {
      context.commit('UPDATE_PROTOCOL_RADIO', {
        field: 'concussionProtocol',
        value: val,
      });
    }
  },
  updateConcussionProtocolTemplate(context, file) {
    context.commit('UPDATE_PROTOCOL_TEMPLATE', {
      field: 'concussionProtocolTemplate',
      value: file,
    });
  },
  updateWaiverProtocol(context, val) {
    if (typeof val === 'boolean') {
      context.commit('UPDATE_PROTOCOL_RADIO', {
        field: 'waiverProtocol',
        value: val,
      });
    }
  },
  updateWaiverProtocolTemplate(context, file) {
    context.commit('UPDATE_PROTOCOL_TEMPLATE', {
      field: 'waiverProtocolTemplate',
      value: file,
    });
  },
  updateMultipleProtocolsTemplates(context, data) {
    if (data.field && Object.keys(context.state.protocols).includes(data.field)) {
      context.commit('UPDATE_PROTOCOL_TEMPLATE', {
        field: data.field,
        value: data.file,
      });
    }
  },
  removeProtocolTemplate(context, type) {
    if (type && Object.keys(context.state.protocols).includes(type)) {
      context.commit('UPDATE_PROTOCOL_TEMPLATE', {
        field: type,
        value: null,
      });
    }
  },
  setSelectedSports(context, selSports) {
    if (selSports && selSports instanceof Array) {
      context.commit('SET_AGE_GROUPS_BY_SPORTS_ARRAY', selSports);
      context.dispatch('resetPreliminaryQuotes');
    }
  },
  updateSelectedSportsByIds(context, sportIds) {
    if (sportIds && sportIds instanceof Array) {
      const ageBySports = sportIds.reduce((acc, sportId) => {
        if (sportId && typeof sportId === 'string') {
          const sportObjMatch = context.state.organizationToComplete.sports
            && context.state.organizationToComplete.sports.find((sp) => sp.sport && sp.sport.id === sportId);
          if (sportObjMatch) {
            const { sport, ...ages } = sportObjMatch;
            acc[sportId] = ages;
          } else {
            acc[sportId] = { ...ATHLETES_AGE_OBJECT };
          }
        }
        return acc;
      }, {});
      context.commit('SET_AGE_GROUPS_BY_SPORTS', ageBySports);
      context.dispatch('resetPreliminaryQuotes');
    }
  },
  updateOrgPersonnelField(context, data) {
    if (data.field && Object.keys(context.state.personnel).includes(data.field)) {
      context.commit('UPDATE_ORG_PERSONNEL_FIELD', data);
    }
  },
  updateAthletesAgeField(context, data) {
    if (data.field
      && data.sport
      && context.state.athletesAgeBySports[data.sport]
      && Object.keys(context.state.athletesAgeBySports[data.sport]).includes(data.field)) {
      context.commit('UPDATE_ATHLETES_AGE_FIELD', data);
      context.dispatch('resetPreliminaryQuotes');
    }
  },
  updateCardInfoField(context, data) {
    if (data.field && Object.keys(context.state.cardInfo).includes(data.field)) {
      context.commit('UPDATE_CARD_INFO_FIELD', data);
    }
  },
  updateECheckInfoField(context, data) {
    if (data.field && Object.keys(context.state.eCheckInfo).includes(data.field)) {
      context.commit('UPDATE_ECHECK_INFO_FIELD', data);
    }
  },
  toNextStep(context) {
    if (context.state.step < context.getters.totalStepsNum) {
      context.commit('INCREMENT_STEP');
    }
  },
  toNextBgCheckStep(context) {
    if (context.state.bgCheckStep < 2) {
      context.commit('INCREMENT_BG_CHECK_STEP');
    }
  },
  toPrevStep(context) {
    if (context.state.step > 0) {
      context.commit('DECREMENT_STEP');
    }
  },
  toPrevBgCheckStep(context) {
    if (context.state.bgCheckStep > 0) {
      context.commit('DECREMENT_BG_CHECK_STEP');
    }
  },
  goForward({
    state, getters, commit, dispatch,
  }) {
    let currStep = Object.keys(getters.orgApplicationSteps)[state.step - 1];
    if (state.step < getters.totalStepsNum) {
      if (state.subStep < getters.orgApplicationSteps[currStep].length) {
        commit('INCREMENT_SUB_STEP');
        if (state.step >= state.lastVisitedStep && state.subStep > state.lastVisitedSubStep) {
          // setting last visited sub step to track how far the user managed to get
          dispatch('setLastVisitedSubStep', state.subStep);
        }
      } else {
        commit('RESET_SUB_STEP');
        commit('INCREMENT_STEP');
        if (state.step > state.lastVisitedStep) {
          // setting last visited step to track how far the user managed to get
          dispatch('setLastVisitedStep', state.step);
          dispatch('setLastVisitedSubStep', state.subStep);
        }
      }
    }
  },
  goBackward(context) {
    if (context.state.step > 1 && context.state.subStep === 1) {
      context.commit('DECREMENT_STEP');
      context.dispatch('goToLastSubStep');
    } else if (context.state.subStep !== 1) {
      context.commit('DECREMENT_SUB_STEP');
    }
  },
  goToLastSubStep({ state, getters, commit }) {
    const prevStep = Object.keys(getters.orgApplicationSteps)[state.step - 1];
    const stepArr = getters.orgApplicationSteps[prevStep];
    commit('FORCE_SUB_STEP', Object.keys(stepArr).length);
  },
  toPrevSubStep(context) {
    context.commit('DECREMENT_SUB_STEP');
  },
  toNextSubStep(context) {
    context.commit('INCREMENT_SUB_STEP');
  },
  toSpecificStep({
    dispatch, state, getters, commit,
  }, step) {
    if (step >= 1 && step <= getters.totalStepsNum) {
      const goingBackwards = step < state.step;
      commit('FORCE_STEP', step);
      let subStep = goingBackwards ? getters.currentStepLastCompleteSubStep : 1;
      if (state.step === state.lastVisitedStep) {
        subStep = state.lastVisitedSubStep;
      }
      commit('FORCE_SUB_STEP', subStep);
      if (state.step > state.lastVisitedStep || (state.step === state.lastVisitedStep && state.subStep > state.lastVisitedSubStep)) {
        // setting last visited step to track how far the user managed to get
        dispatch('setLastVisitedStepAndSubStep', { step: state.step, subStep: state.subStep });
      }
    }
  },
  toSpecificStepAndSubStep({
    commit, dispatch, getters, state,
  }, { step, subStep }) {
    if (step >= 1 && step <= getters.totalStepsNum) {
      commit('FORCE_STEP', step);
      const stepValues = Object.values(getters.orgApplicationSteps);
      const stepMatch = stepValues[step - 1] instanceof Array
        ? stepValues[step - 1]
        : [];
      if (subStep <= stepMatch.length) {
        commit('FORCE_SUB_STEP', subStep);
      }
      if (state.step > state.lastVisitedStep || (state.step === state.lastVisitedStep && state.subStep > state.lastVisitedSubStep)) {
        // setting last visited step to track how far the user managed to get
        dispatch('setLastVisitedStep', state.step);
        dispatch('setLastVisitedSubStep', state.subStep);
      }
    }
  },
  toSpecificSubStep({ state, commit, dispatch }, payload) {
    if (payload >= 1) {
      commit('FORCE_SUB_STEP', payload);
    }
    if (state.subStep > state.lastVisitedStep) {
      dispatch('setLastVisitedSubStep', state.subStep);
    }
  },
  toSpecificSubStepByName({ getters, dispatch }, subStepName) {
    if (typeof subStepName === 'string' && subStepName.length) {
      const stepValues = Object.values(getters.orgApplicationSteps);
      const subStepMatchIndexes = getStepAndSubStepIndexesBySubStepName(getters.orgApplicationSteps, subStepName);
      if (subStepMatchIndexes.step !== -1 && subStepMatchIndexes.subStep !== -1) {
        const stepMatch = stepValues[subStepMatchIndexes.step] instanceof Array
          ? stepValues[subStepMatchIndexes.step]
          : [];
        const nextStep = subStepMatchIndexes.step + 1;
        const nextSubStep = subStepMatchIndexes.subStep + 1;
        // check if step is valid and if needs to be updated
        if (nextStep <= getters.totalStepsNum) {
          if (nextSubStep !== getters.currentSubStepNumber && stepMatch[subStepMatchIndexes.subStep] === subStepName) {
            dispatch('toSpecificStepAndSubStep', {
              step: nextStep,
              subStep: nextSubStep,
            });
          } else if (nextStep !== getters.currentStepNum) {
            dispatch('toSpecificStep', nextStep);
          }
        }
      }
    }
  },
  fetchNecessaryData({ dispatch, rootGetters }, orgId) {
    return new Promise((resolve, reject) => {
      Promise.all([
        !rootGetters['organization/organizations'].length ? dispatch('organization/fetchOrganizations', null, { root: true }) : null,
        !rootGetters['glossary/organizationTypes'].length ? dispatch('glossary/fetchOrganizationTypes', null, { root: true }) : null,
        !rootGetters['glossary/sports'].length ? dispatch('glossary/fetchSports', null, { root: true }) : null,
        !rootGetters['glossary/regProviders'].length ? dispatch('glossary/fetchRegProviders', null, { root: true }) : null,
        !rootGetters['glossary/bgCheckProviders'].length ? dispatch('glossary/fetchBgCheckProviders', null, { root: true }) : null,
        !rootGetters['glossary/states'].length ? dispatch('glossary/fetchStates', null, { root: true }) : null,
        !rootGetters['insurance/carriers'].length ? dispatch('insurance/fetchCarriers', null, { root: true }) : null,
        !rootGetters['orgApplication/coverages'].length ? dispatch('fetchCoveragesForCurrentOrg', orgId) : null,
        !rootGetters['policy/policies'].length ? dispatch('policy/fetchPolicies', null, { root: true }) : null,
      ]).then(resolve)
        .catch(reject);
    });
  },
  fetchSubmission({ dispatch, getters, state }) {
    const submissionParams = getSubmissionParamsPayload(state, getters);
    if (Object.keys(submissionParams).length) {
      return dispatch('submission/fetchSubmissionForOrgByCoverage', submissionParams, {
        root: true,
      });
    }
    return Promise.reject(new Error('No available submission params'));
  },
  fetchPreliminaryQuotes({
    dispatch, commit, state, getters,
  }) {
    const submissionParams = getSubmissionParamsPayload(state, getters);
    if (Object.keys(submissionParams).length) {
      return dispatch('insurance/fetchQuotes', submissionParams, { root: true })
        .then((quotes) => {
          commit('SET_PRELIMINARY_QUOTES', quotes);
          const hasPreliminaryQuotes = quotes.filter((q) => typeof q.annualCost === 'number').length > 0;
          if (hasPreliminaryQuotes) {
            dispatch('analytics/registerFullStoryEvent', { name: 'received-preliminary-quotes' }, { root: true });
          }
        });
    }
    return Promise.reject(new Error('No available submission params'));
  },
  resetPreliminaryQuotes({ commit, state }) {
    if (state.preliminaryQuotes.length) {
      commit('RESET_PRELIMINARY_QUOTES');
    }
  },
  chooseSubCoverages({ commit, dispatch }, subCoverageIds) {
    if (subCoverageIds instanceof Array) {
      commit('SET_CHOSEN_SUB_COVERAGE_IDS', subCoverageIds);
      dispatch('resetPreliminaryQuotes');
    }
  },
  chooseCoverage(context, coverageId) {
    return new Promise((resolve, reject) => {
      if (typeof coverageId === 'string' && coverageId.length) {
        try {
          context.commit('SET_CHOSEN_COVERAGE_ID', coverageId);
          context.dispatch('resetPreliminaryQuotes');
          const requiredSubcoveragesIds = context.getters.chosenCoverage.subcoverages ? context.getters.chosenCoverage.subcoverages.filter(({ isOptional }) => !isOptional).map(({ id }) => id) : [];
          context.dispatch('chooseSubCoverages', requiredSubcoveragesIds);
          context.dispatch('trackCoverageChoice');
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  trackCoverageChoice(context) {
    if (typeof context.state.chosenCoverageId === 'string' && context.state.chosenCoverageId.length) {
      return context.dispatch('analytics/trackOrgCoverageSelection', {
        organizationId: (context.state.organizationToComplete && context.state.organizationToComplete.id),
        coverages: [context.state.chosenCoverageId],
      },
      {
        root: true,
      });
    }
    return Promise.resolve();
  },
  trackStepEnter({ dispatch, state }, stepName) {
    if (typeof stepName === 'string'
      && stepName.length
      && state.organizationToComplete
      && state.organizationToComplete instanceof Object) {
      return dispatch('analytics/trackOrgApplicationStepEnter', {
        organizationId: state.organizationToComplete.id,
        pageName: stepName,
      },
      {
        root: true,
      });
    }
    return Promise.resolve();
  },
  trackStepLeave({ dispatch, state }, stepName) {
    if (typeof stepName === 'string'
      && stepName.length
      && state.organizationToComplete
      && state.organizationToComplete instanceof Object) {
      return dispatch('analytics/trackOrgApplicationStepLeave', {
        organizationId: state.organizationToComplete.id,
        pageName: stepName,
      },
      {
        root: true,
      });
    }
    return Promise.resolve();
  },
  populateOrganizationData({
    commit, dispatch, rootState, rootGetters,
  }, orgId) {
    return new Promise(async (resolve, reject) => {
      if (typeof orgId === 'string' && orgId.length) {
        dispatch('fetchNecessaryData', orgId)
          .then(async (response) => {
            const organization = rootGetters['organization/organizations'].find((org) => org.id === orgId);
            if (organization instanceof Object && Object.keys(organization).length) {
              await dispatch('setOrganizationToComplete', organization);
              // set default coverage params such as coverage start & end date
              // which may depend on the org to complete
              commit('SET_DEFAULT_COVERAGE_PARAMS', rootGetters['orgApplication/coverages']);
              if (organization.params) {
                dispatch('setOrganizationParams', organization.params);
                dispatch('populateChainedQuestionsFromParams', organization.params);
              }
              if (organization.sports && organization.sports.length) {
                dispatch('setSelectedSports', organization.sports);
              } else {
                dispatch('setSelectedSports', []);
              }
              if (organization.provider) {
                if (organization.provider !== 'other') {
                  dispatch('selectRegProvider', rootState.glossary.regProviders.find((prov) => prov.id === organization.provider));
                } else {
                  dispatch('setOtherRegProvider', organization.otherProvider);
                }
              } else {
                dispatch('selectRegProvider', null);
              }
              if (organization.backgroundCheck) {
                dispatch('updateBgChecks', organization.backgroundCheck);
              }
              if (organization.backgroundProvider) {
                if (organization.backgroundProvider === 'other') {
                  dispatch('setOtherBgCheckProvider', organization.otherBackgroundProvider);
                } else {
                  dispatch('updateBgCheckProvider', rootState.glossary.bgCheckProviders.find((prov) => prov.id === organization.backgroundProvider));
                }
              }
              if (organization.personnel) {
                dispatch('updateOrgPersonnelField', {
                  field: 'athletes',
                  value: organization.personnel.numAthletes || DEFAULT_PERSONNEL_NUMBER,
                });
                dispatch('updateOrgPersonnelField', {
                  field: 'coaches',
                  value: organization.personnel.numCoaches || DEFAULT_PERSONNEL_NUMBER,
                });
                dispatch('updateOrgPersonnelField', {
                  field: 'volunteers',
                  value: organization.personnel.numVolunteers || DEFAULT_PERSONNEL_NUMBER,
                });
                dispatch('updateOrgPersonnelField', {
                  field: 'officials',
                  value: organization.personnel.numOfficials || DEFAULT_PERSONNEL_NUMBER,
                });
              }
              if (organization.externalCoverages instanceof Array && organization.externalCoverages.length) {
                dispatch('updateHavePolicy', true);
                dispatch('setChosenCurrentCoverageTypes', Object.values(organization.externalCoverages).reduce((acc, currVal) => {
                  acc.push(currVal.coverageType);
                  return acc;
                }, []));
                Object.values(organization.externalCoverages).forEach((item) => {
                  // renewal date
                  if (item.renewalDate) {
                    dispatch('updateCurrentCoverageField', {
                      value: moment(item.renewalDate).format(API_DATE_FORMAT),
                      field: 'renewalDate',
                      covType: item.coverageType,
                    });
                  }
                  // premium
                  dispatch('updateCurrentCoverageField', {
                    value: item.premium,
                    field: 'premium',
                    covType: item.coverageType,
                  });
                  // carrier
                  if (item.carrier && item.carrier instanceof Object && item.carrier.id) {
                    dispatch('updateCurrentCoverageField', {
                      value: item.carrier.id,
                      field: 'carrier',
                      covType: item.coverageType,
                    });
                  }
                  if (item.otherCarrier) {
                    dispatch('updateCurrentCoverageField', {
                      value: 'other',
                      field: 'carrier',
                      covType: item.coverageType,
                    });
                    dispatch('updateCurrentCoverageField', {
                      value: item.otherCarrier,
                      field: 'otherCarrier',
                      covType: item.coverageType,
                    });
                  }
                });
              } else {
                dispatch('updateHavePolicy', false);
              }
              if ((organization.params && organization.params.hadClaims) || organization.previousClaims.length) {
                dispatch('updateClaimsData', {
                  hadClaims: true,
                  claims: organization.previousClaims.map((clm) => ({
                    ...clm,
                    carrier: clm.carrier && clm.carrier instanceof Object ? clm.carrier.id : clm.carrier,
                  })),
                });
              }
              dispatch('updateInjuryProtocol', !!organization.injuryProtocol);
              dispatch('updateInjuryProtocolTemplate', organization.injuryProtocol || null);
              dispatch('updateConcussionProtocol', !!organization.concussionProtocol);
              dispatch('updateConcussionProtocolTemplate', organization.concussionProtocol || null);
              dispatch('updateWaiverProtocol', !!organization.waiverProtocol);
              dispatch('updateWaiverProtocolTemplate', organization.waiverProtocol || null);
              // finish the action execution
              dispatch('setCompletionStep');
              resolve(response);
            } else {
              throw new Error('Organization wasn\'t found');
            }
          }).catch((error) => {
            dispatch('dialog/showInfoDialog', {
              title: i18n.t('response.error.error'),
              description: i18n.t('response.error.orgFetchError'),
            }, {
              root: true,
            });
            return reject(error);
          });
      } else {
        reject(new Error('Organization ID was invalid or absent'));
      }
    });
  },
  postSubStepOrganizationUpdate({ dispatch, state, getters }, { subStep, orgId }) {
    return new Promise(async (resolve, reject) => {
      const subStepsToUpdate = ['orgPersonnel', 'orgAgeGroups', 'orgMonthsInSeason', 'orgRegProvider', 'orgBackgroundCheck', 'orgProtocolTemplates', 'orgPreviousClaims', 'orgCurrentCoverage'].concat(getters.chainedQuestionsSteps);
      if (subStepsToUpdate.includes(subStep)) {
        let updateObj = {};
        if (getters.organizationToComplete.status == ORGANIZATION_STATUS_ALIASES.archived) {
          updateObj.status = ORGANIZATION_STATUS_ALIASES.incompleted;
        }
        if (subStep === 'orgPersonnel') {
          updateObj = {
            personnel: {
              numAthletes: getters.orgPersonnelForm.athletes,
              numCoaches: getters.orgPersonnelForm.coaches,
              numVolunteers: getters.orgPersonnelForm.volunteers,
              numOfficials: getters.orgPersonnelForm.officials,
            },
          };
        }
        if (subStep === 'orgCurrentCoverage') {
          updateObj.externalCoverages = Object.values(state.externalCoverages).map((item) => {
            let object = {
              paymentCycle: item.paymentCycle,
              coverageType: item.coverageType,
            };
            if (item.carrier !== 'other') {
              object.carrier = item.carrier;
            }
            if (item.otherCarrier) {
              object.otherCarrier = item.otherCarrier;
            }
            if (item.renewalDate && item.renewalDate.length) {
              object.renewalDate = item.renewalDate;
            }
            object.premium = convertFromLocale(item.premium);
            return object;
          });
        }
        if (subStep === 'orgPreviousClaims') {
          updateObj.params = getters.organizationToComplete.params;
          updateObj.previousClaims = getStrippedPreviousClaims(getters.claimsData.claims);
        }
        if (subStep === 'orgAgeGroups' || subStep === 'orgMonthsInSeason') {
          updateObj.sports = buildSportsQuery(getters.athletesAgeBySports);
        }
        if (subStep === 'orgProtocolTemplates') {
          if (getters.injuryProtocol) {
            if (getters.injuryProtocolTemplate instanceof File) {
              updateObj.injuryProtocol = getters.injuryProtocolTemplate;
            }
          } else {
            updateObj.injuryProtocol = null;
          }
          if (getters.concussionProtocol) {
            if (getters.concussionProtocolTemplate instanceof File) {
              updateObj.concussionProtocol = getters.concussionProtocolTemplate;
            }
          } else {
            updateObj.concussionProtocol = null;
          }
          if (getters.waiverProtocol) {
            if (getters.waiverProtocolTemplate instanceof File) {
              updateObj.waiverProtocol = getters.waiverProtocolTemplate;
            }
          } else {
            updateObj.waiverProtocol = null;
          }
        }
        if (subStep === 'orgRegProvider' && getters.selectedRegProvider) {
          updateObj = {
            provider: getters.selectedRegProvider.id,
          };
          if (getters.selectedRegProvider.id === 'other') {
            updateObj.otherProvider = getters.selectedRegProvider.name;
          }
        }
        if (subStep === 'orgBackgroundCheck') {
          updateObj = {
            backgroundCheck: getters.bgChecks,
          };
          if (getters.bgCheckProvider && getters.bgCheckProvider instanceof Object) {
            updateObj.backgroundProvider = getters.bgCheckProvider.id;
            if (getters.bgCheckProvider.id === 'other') {
              updateObj.otherBackgroundProvider = getters.bgCheckProvider.name;
            }
          }
        }
        if (getters.chainedQuestionsSteps.includes(subStep)) {
          updateObj.params = getters.organizationToComplete.params;
          updateObj.isCompleted = true;
          updateObj.status = ORGANIZATION_STATUS_ALIASES.completed;
        }
        if (Object.keys(updateObj).length) {
          try {
            const willAffectSubmissionsOfOrg = await dispatch('organization/checkOrgUpdatesWillAffectSubmissionsOfOrg',
              {
                orgId,
                updateObj,
              }, {
                root: true,
              });
            if (willAffectSubmissionsOfOrg) {
              const consent = await dispatch('dialog/showConfirmDialog', {
                title: i18n.t('message.warning'),
                description: i18n.t('message.quoteAffectingChanges'),
              }, {
                root: true,
              });
              if (!consent) {
                return reject(new Error(REFUSAL_TO_UPDATE_ORG_ERROR_MESSAGE));
              }
            }
            const updateResponse = await dispatch('updateOrganizationPartially', { orgId, updateObj });
            dispatch('organization/updateLocalOrg', updateResponse.data.data.organization, { root: true });
            if (subStep === 'orgProtocolTemplates') {
              dispatch('updateProtocolTemplatesToLinks', updateResponse.data.data.organization);
            }
            resolve(updateResponse);
          } catch (error) {
            dispatch('dialog/showInfoDialog', {
              title: i18n.t('response.error.error'),
              description: i18n.t('response.error.orgUpdateError'),
            }, {
              root: true,
            });
            return reject(error);
          }
        } else {
          resolve();
        }
      } else {
        // in case the substeb doesn't require update
        resolve();
      }
    });
  },
  setChosenCoverageId(context, coverageId) {
    return new Promise((resolve, reject) => {
      if (typeof coverageId === 'string' && coverageId.length) {
        try {
          context.commit('SET_CHOSEN_COVERAGE_ID', coverageId);
          context.dispatch('trackCoverageChoice');
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  unchooseCoverage(context, coverageId) {
    return new Promise((resolve, reject) => {
      if (typeof coverageId === 'string' && coverageId.length && context.state.chosenCoverageId === coverageId) {
        try {
          context.commit('SET_CHOSEN_COVERAGE_ID', '');
          context.dispatch('chooseSubCoverages', []);
          context.dispatch('trackCoverageChoice');
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  updateCoverageSelectedLimit(context, data) {
    return new Promise((resolve, reject) => {
      const dataKeys = data instanceof Object && Object.keys(data);
      if (dataKeys && dataKeys.includes('coverage') && dataKeys.includes('limit')) {
        try {
          context.commit('UPDATE_COVERAGE_PARAMS', {
            [data.coverage.type]: {
              selectedLimit: data.limit,
            },
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  updateCoverageStartDate(context, data) {
    return new Promise((resolve, reject) => {
      const dataKeys = data instanceof Object && Object.keys(data);
      if (dataKeys && dataKeys.includes('coverage') && dataKeys.includes('date')) {
        try {
          const paramsUpdatePayload = {
            startDate: data.date,
          };
          // set default endDate as a year from now
          // if the org doesn't have a specific event date range
          if (!checkOrgTypeHasEventDates(context.getters.organizationToCompleteType)) {
            try {
              const momentEndDate = getMomentPolicyYear(data.date, DEFAULT_COVERAGE_DURATION_YEARS);
              context.dispatch('updateCoverageEndDate', {
                ...data,
                date: momentEndDate.format(API_DATE_FORMAT),
              });
            } catch (err) {
              console.error(err);
            }
          }
          context.commit('UPDATE_COVERAGE_PARAMS', {
            [data.coverage.type]: paramsUpdatePayload,
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  updateCoverageEndDate(context, data) {
    return new Promise((resolve, reject) => {
      const dataKeys = data instanceof Object && Object.keys(data);
      if (dataKeys && dataKeys.includes('coverage') && dataKeys.includes('date')) {
        try {
          const paramsUpdatePayload = {
            endDate: data.date,
          };
          context.commit('UPDATE_COVERAGE_PARAMS', {
            [data.coverage.type]: paramsUpdatePayload,
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  updateCoveragePaymentCycle(context, data) {
    return new Promise((resolve, reject) => {
      const dataKeys = data instanceof Object && Object.keys(data);
      if (dataKeys && dataKeys.includes('coverage') && dataKeys.includes('cycle')) {
        try {
          context.commit('UPDATE_COVERAGE_PARAMS', {
            [data.coverage.type]: {
              selectedPaymentCycle: data.cycle,
            },
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  updateOrganizationPartially({ dispatch }, { orgId, updateObj }) {
    return new Promise((resolve, reject) => {
      if (orgId && updateObj && updateObj instanceof Object) {
        OrganizationProvider.updateOrgMutation(orgId, updateObj)
          .then(resolve)
          .catch((error) => {
            dispatch('dialog/showInfoDialog', {
              title: i18n.t('response.error.error'),
              description: i18n.t('response.error.orgUpdateError'),
            }, {
              root: true,
            });
            reject(error);
          });
      } else {
        reject(new Error('orgId or updateObj wasn\'t provided'));
      }
    });
  },
  updateProtocolTemplatesToLinks({ state, dispatch }, updateObj) {
    if (updateObj && updateObj instanceof Object) {
      if (updateObj.concussionProtocol
        && typeof updateObj.concussionProtocol === 'string'
        && updateObj.concussionProtocol !== state.protocols.concussionProtocolTemplate) {
        dispatch('updateConcussionProtocolTemplate', updateObj.concussionProtocol);
      }
      if (updateObj.injuryProtocol
        && typeof updateObj.injuryProtocol === 'string'
        && updateObj.injuryProtocol !== state.protocols.injuryProtocolTemplate) {
        dispatch('updateInjuryProtocolTemplate', updateObj.injuryProtocol);
      }
      if (updateObj.waiverProtocol
        && typeof updateObj.waiverProtocol === 'string'
        && updateObj.waiverProtocol !== state.protocols.waiverProtocolTemplate) {
        dispatch('updateWaiverProtocolTemplate', updateObj.waiverProtocol);
      }
    }
  },
  uploadLostReportFile(context, files) {
    return new Promise((resolve, reject) => {
      FileProvider.uploadFilesMutation(files).then((response) => {
        resolve(response);
      }).catch(reject);
    });
  },
  updateSubCoverageSelectedLimit({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data.hasOwnProperty('coverage')
        && data.hasOwnProperty('subCoverage')
        && data.hasOwnProperty('limit')) {
        try {
          commit('UPDATE_SUB_COVERAGE_PARAMS', {
            coverage: data.coverage,
            subCoverage: data.subCoverage,
            params: {
              selectedLimit: data.limit,
            },
          });
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('The coverage data didn\'t have some of the required fields'));
      }
    });
  },
  setOrganizationParams(context, params) {
    context.commit('SET_ORGANIZATION_PARAMS', params);
  },
  populateChainedQuestionsFromParams({ commit, dispatch, state }, params) {
    Object.entries(state.chainedQuestions)
      .map(({ 0: subStepKey, 1: subStepValue }) => Object.entries(subStepValue.questions)
        .map(({ 0: questionKey, 1: questionValue }) => {
          if (questionKey in params) {
            commit('UPDATE_CHAINED_QUESTION_ANSWER', {
              field: questionKey,
              value: params[questionKey],
              currentSubStep: subStepKey,
            });
          }
          return null;
        }));
    dispatch('setChainedQuestionGroupsCurrentBasedOnChainValidity');
  },
  setChainedQuestionGroupsCurrentBasedOnChainValidity({ commit, getters }) {
    Object.entries(getters.chainableSubStepsQuestionChainsValidityMap)
      .forEach((sectionMapEnt) => {
        if (sectionMapEnt[1] === false) {
          const firstInvalidQuestionIndexInChain = getters.chainableSubStepsQuestionChains[sectionMapEnt[0]].findIndex((q) => q.valid === false);
          if (firstInvalidQuestionIndexInChain !== -1) {
            commit('UPDATE_CHAINED_QUESTION_SECTION_CURRENT', {
              current: firstInvalidQuestionIndexInChain,
              sectionKey: sectionMapEnt[0],
            });
          }
        }
      });
  },
  setOrganizationToComplete(context, org) {
    return new Promise((resolve, reject) => {
      if (org && org instanceof Object && Object.keys(org).length) {
        context.commit('UPDATE_ORGANIZATION_TO_COMPLETE', org);
        resolve(org);
      } else {
        reject(new Error('Organization was not a valid object'));
      }
    });
  },
  setLastVisitedStep(context, step) {
    if (typeof step === 'number' && !Number.isNaN(step)) {
      context.commit('SET_LAST_VISITED_STEP', step);
    }
  },
  setLastVisitedSubStep(context, subStep) {
    if (typeof subStep === 'number' && !Number.isNaN(subStep)) {
      context.commit('SET_LAST_VISITED_SUB_STEP', subStep);
    }
  },
  async setLastVisitedStepAndSubStep({ commit }, { step, subStep }) {
    if (typeof step === 'number' && !Number.isNaN(step) && typeof subStep === 'number' && !Number.isNaN(subStep)) {
      try {
        commit('SET_LAST_VISITED_STEP', step);
        commit('SET_LAST_VISITED_SUB_STEP', subStep);
        return Promise.resolve({ step, subStep });
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject('The provided subStep argument was absent or invalid');
  },
};
