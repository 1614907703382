import { STRIPE_KEY } from '@/utils/constants';
import { jsonClone } from '@/utils/helpers';

export default () => jsonClone({
  config: {
    stripeKey: STRIPE_KEY,
  },
  initialized: false,
  instance: {},
});
