import { ProofOfInsuranceProvider, UserProvider } from '@/graphql/providers';

const state = {
  proofs: [],
};

const mutations = {
  SET_PROOFS(ctx, proofs) {
    if (proofs && proofs instanceof Array) {
      ctx.proofs = proofs;
    }
  },
  UPDATE_PROOF(ctx, { proofId, updateFragment }) {
    if (proofId && typeof proofId === 'string' && updateFragment && updateFragment instanceof Object) {
      const proofIndex = ctx.proofs.findIndex((pr) => pr.id === proofId);
      if (proofIndex !== -1) {
        ctx.proofs.splice(proofIndex, 1, { ...ctx.proofs[proofIndex], ...updateFragment });
      }
    }
  },
  PUSH_TO_PROOFS(ctx, proof) {
    if (proof && proof instanceof Object && Object.keys(proof).length) {
      ctx.proofs.push(proof);
    }
  },
};

const actions = {
  fetchProofs(ctx) {
    return UserProvider.userProofQuery().then((r) => {
      if (r.data.data && r.data.data.me && r.data.data.me.proofs) {
        ctx.dispatch('updateProofs', r.data.data.me.proofs);
      }
    });
  },
  updateProofLinks(context, proof) {
    return new Promise((resolve, reject) => {
      if (proof && proof instanceof Object && (proof._id || proof.id) && proof.documentUrl) {
        context.commit('UPDATE_PROOF', {
          proofId: proof._id || proof.id,
          updateFragment: {
            documentPreviewMini: proof.documentPreviewMini || '',
            documentPreviewMidi: proof.documentPreviewMidi || '',
            documentUrl: proof.documentUrl,
          },
        });
        resolve();
      } else {
        reject(new Error('The action payload was incomplete or invalid'));
      }
    });
  },
  updateProofs(ctx, proofs) {
    return new Promise((resolve, reject) => {
      if (proofs instanceof Array) {
        try {
          ctx.commit('SET_PROOFS', proofs);
        } catch (err) {
          reject(err);
        }
      } else {
        reject(new Error('The provided proofs were invalid or absent'));
      }
    });
  },
  requestProof(ctx, data) {
    const requestObj = {
      organization: data.organizationId,
      clubName: data.teamName || '',
      clubAddress: data.teamAddress || '',
      eventName: data.eventName || '',
      eventLocation: data.locations.map((loc) => loc.location),
      eventStartDate: data.eventStart || '',
      eventEndDate: data.eventEnd || '',
      additionalInsured: data.additionalInsuredNames || [],
    };
    return new Promise((resolve, reject) => {
      if (requestObj.organization) {
        ProofOfInsuranceProvider.requestProofMutation(requestObj)
          .then((response) => {
            if (response.data && response.data.data && response.data.data.proof) {
              ctx.commit('PUSH_TO_PROOFS', response.data.data.proof);
            }
            resolve(response);
          }).catch(reject);
      } else {
        reject(new Error('Some of the required parameters weren\'t present in the requestProof action payload'));
      }
    });
  },
};

const getters = {
  proofs: (ctx) => ctx.proofs,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
