<template lang="html">
  <v-form @submit.prevent :class="b('form')" ref="form">
    <v-row :no-gutters="isMobile">
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.name"
            name="name"
            autocomplete="new-password"
            :rules="[...inputRules.required]"
            @input="updateClaimInfo(arguments[0], 'name')"
            :label="$t('fields.claimantName')"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <PhDatepicker @input="updateClaimInfo(arguments[0], 'birthdate')"
            :value="form.birthdate"
            required
            :label="$t('fields.dateOfBirth')" />
        </v-col>
        <v-col cols="12" md="6">
          <v-radio-group
            @change="updateClaimGender"
            :value="form.claimGender"
            :rules="[...inputRules.required]"
            :class="b('radioGroup')"
            row
          >
            <div :class="b('radioBlockRow')">
              <v-radio
                :value="'Male'"
                :label="$t('fields.male')"
              />
            </div>
            <div :class="b('radioBlockRow')">
              <v-radio
                :value="'Female'"
                :label="$t('fields.female')"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.visa"
            @input="updateClaimInfo(arguments[0], 'visa')"
            autocomplete="new-password"
            name="visa"
            required
            :rules="[...inputRules.required]"
            :label="$t('fields.socialSecurity')"
          />
        </v-col>
        <v-col cols="12">
          <div :class="b('formColumnLabel')">{{ $t('ui.mailingAddress') }}:</div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.mailing.street"
            :rules="[...inputRules.street, ...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimMailingInfo(arguments[0], 'street')"
            :label="$t('fields.streetAndBd')"
            name="mailing_street"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.mailing.city"
            :rules="[...inputRules.city, ...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimMailingInfo(arguments[0], 'city')"
            :label="$t('fields.city')"
            name="mailing_city"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <SearchableSelect
            @input="updateClaimMailingInfo(arguments[0], 'country')"
            :items="countriesSelectable"
            :disabled="!countriesSelectable.length"
            :rules="[...inputRules.required]"
            :label="$t('fields.country')"
            :searchable="isMobile"
            name="mailing_country"
            required
            single-line
          />
        </v-col>
        <v-col cols="12" md="6">
          <SearchableSelect
            @input="updateClaimMailingInfo(arguments[0], 'state')"
            :items="states"
            :value="form.mailing.state"
            :disabled="!states.length"
            :rules="[...inputRules.required]"
            :label="$t('fields.state')"
            :searchable="isMobile"
            name="mailing_state"
            single-line
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.mailing.zip"
            :rules="[...inputRules.zip, ...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimMailingInfo(arguments[0], 'zip')"
            :label="$t('fields.zip')"
            name="mailing_zip"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <PhoneInput
            @input="updateClaimMailingInfo(arguments[0], 'phone')"
            :value="form.mailing.phone"
            :rules="[...inputRules.phone]"
            :label="$t('fields.phone')"
            autocomplete="new-password"
            id="mailing-phone"
            name="phone"
          />
        </v-col>
        <v-col cols="12">
          <div :class="b('formColumnLabel')">{{ $t('ui.businessAddress') }}:</div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.business.street"
            :rules="[...inputRules.street, ...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimBusinessInfo(arguments[0], 'street')"
            :label="$t('fields.streetAndBd')"
            name="mailing_street"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.business.city"
            :rules="[...inputRules.city, ...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimBusinessInfo(arguments[0], 'city')"
            :label="$t('fields.city')"
            name="mailing_city"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <SearchableSelect
            @input="updateClaimBusinessInfo(arguments[0], 'country')"
            :items="countriesSelectable"
            :disabled="!countriesSelectable.length"
            :rules="[...inputRules.required]"
            :label="$t('fields.country')"
            :searchable="isMobile"
            name="mailing_country"
            required
            single-line
          />
        </v-col>
        <v-col cols="12" md="6">
          <SearchableSelect
            @input="updateClaimBusinessInfo(arguments[0], 'state')"
            :items="states"
            :value="form.business.state"
            :disabled="!states.length"
            :rules="[...inputRules.required]"
            :label="$t('fields.state')"
            :searchable="isMobile"
            name="mailing_state"
            required
            single-line
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :value="form.business.zip"
            :rules="[...inputRules.zip, ...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimBusinessInfo(arguments[0], 'zip')"
            :label="$t('fields.zip')"
            required
            name="mailing_zip"
          />
        </v-col>
        <v-col cols="12" md="6">
          <PhoneInput
            @input="updateClaimBusinessInfo(arguments[0], 'phone')"
            :value="form.business.phone"
            :rules="[...inputRules.phone]"
            :label="$t('fields.phone')"
            autocomplete="new-password"
            name="phone"
          />
        </v-col>
        <v-col :class="b('radioTitle')" cols="12" md="12">
          {{ $t('fields.isInjuredPersonDependent') }}
        </v-col>
        <v-col cols="12" md="6">
          <v-radio-group
            @change="updateClaimAge"
            :value="form.personAge"
            :class="b('radioGroup')"
            row
          >
            <div :class="b('radioBlockRow')">
              <v-radio
                :value="true"
                :label="$t('ui.yes')"
              />
            </div>
            <div :class="b('radioBlockRow')">
              <v-radio
                :value="false"
                :label="$t('ui.no')"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col v-if="form.personAge === false" cols="12" md="6">
          <v-text-field
            :value="form.employer"
            :rules="[...inputRules.required]"
            autocomplete="new-password"
            @input="updateClaimInfo(arguments[0], 'employer')"
            :label="$t('fields.employerName')"
            name="employer"
          />
        </v-col>
        <v-col v-if="form.personAge === true" cols="12" md="6">
          <v-text-field
            :value="form.guardian"
            :rules="[...inputRules.required]"
            autocomplete="new-password"
            required
            @input="updateClaimInfo(arguments[0], 'guardian')"
            :label="$t('fields.guardianName')"
            name="guardian"
          />
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dateValidator from '@/utils/helpers/dateValidator';
import inputRules from '@/utils/helpers/inputRules';

const PhoneInput = () => import('@/components/controls/PhoneInput');
const PhDatepicker = () => import('@/components/controls/PhDatepicker');

export default {
  name: 'ClaimActionClaimantStep',
  block: 'claimActionClaimantStep',
  components: {
    PhoneInput,
    PhDatepicker,
  },
  props: {
    claimant: Object,
    currentStep: {
      type: [String, Number],
    },
    form: {
      type: Object,
    },
  },
  data() {
    return {
      inputRules,
      showDatePicker: false,
    };
  },
  computed: {
    ...mapGetters('glossary', ['states', 'countriesSelectable']),
    ...mapGetters('layout', ['isMobile']),
  },
  methods: {
    ...mapActions('glossary', ['fetchStates', 'fetchCountries']),
    updateClaimInfo(value, field) {
      if (field) {
        this.updateForm(field, value);
      }
      if (field === 'guardian') {
        this.updateForm('employer', '');
      } else if (field === 'employer') {
        this.updateForm('guardian', '');
      } else if (field === 'birthdate') {
        this.showDatePicker = false;
      }
    },
    updateClaimMailingInfo(value, field) {
      if (this.form.mailing.hasOwnProperty(`${field}`)) {
        this.updateForm(`mailing.${field}`, value);
      }
    },
    updateClaimBusinessInfo(value, field) {
      if (this.form.business.hasOwnProperty(`${field}`)) {
        this.updateForm(`business.${field}`, value);
      }
    },
    getAllowedMinDates(val) {
      return dateValidator.min(val);
    },
    updateForm(key, value) {
      let reqObj = {};
      if (key.split('.').length == 2) {
        reqObj[[key.split('.')[0]]] = { ...this.form[key.split('.')[0]], [key.split('.')[1]]: value };
      } else {
        reqObj = { [key]: value };
      }
      let form = { ...this.form, ...reqObj };
      this.$emit('updateStepForm', { number: this.currentStep, form });
    },
    validateForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$emit('nextStep', this.currentStep + 1);
      }
      return isValid;
    },
    updateClaimAge(val) {
      this.updateClaimInfo(val, 'personAge');
    },
    updateClaimGender(val) {
      this.updateClaimInfo(val, 'claimGender');
    },
    onBirthdayInput(val) {
      this.updateClaimInfo(val, 'birthdate');
    },
    stepBack() {
      this.$emit('handleToPrevStep');
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'claimActionClaimantStep';

.#{$block} {
  max-width: 800px;
  margin: 0 auto;
  &__form {
    @include mappy-bp(md) {
      padding-bottom: 50px;
    }
  }
  &__radioBlockRow {
    min-width: 80px;
  }
  &__radioGroup {
    margin-top: 18px;
  }
  &__formOrgNameBlock {
    > div {
      align-items: flex-start;
    }
  }
  &__formColumnLabel {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: $antarctica;
  }
}
</style>
