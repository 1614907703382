export default (coverage) => {
  if (coverage && coverage instanceof Object) {
    return {
      name: coverage.name || '',
      icon: coverage.icon || '',
      description: coverage.description || '',
      descriptionFile: coverage.descriptionFile || '',
      videoUrl: coverage.videoUrl || '',
      cases: coverage.cases || [],
      proposal: coverage.proposal || {},
    };
  }
};
