<script>
import { BLOCK } from './constants';

const CLASS = `${BLOCK}__title`;
const DEFAULT_LEVEL = 2;

export default {
  name: 'OrgApplicationStepTitle',
  functional: true,
  props: {
    center: {
      type: Boolean,
      default: true,
    },
    level: {
      type: [Number, String],
      default: DEFAULT_LEVEL,
    },
    marginTop: Boolean,
  },
  render(h, context) {
    const {
      center, level, marginTop, ...restProps
    } = context.props;
    const tagName = level >= 1 && level <= 6 ? `h${level}` : `h${DEFAULT_LEVEL}`;
    return h(
      tagName,
      {
        ...context.data,
        class: [
          context.data.class,
          {
            [CLASS]: true,
            [`${CLASS}--center`]: center,
            [`${CLASS}--marginTop`]: marginTop,
          },
        ],
        props: {
          ...restProps,
        },
      },
      context.children,
    );
  },
};
</script>

<style lang="scss">
$block: 'orgApplicationStep';

.#{$block} {
  &__title {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.39px;
    padding: 0 10px;
    @include mappy-bp(md) {
      font-size: 24px;
      line-height: 58px;
      letter-spacing: 0.61px;
    }
    color: $diff-black;
    margin-top: 0px;
    &--center {
      text-align: center;
    }
    &--marginTop {
      margin-top: 25px;
    }
  }
}
</style>
