<template>
  <v-dialog
    :value="value"
    :class="b()"
    :fullscreen="fullscreen"
    :max-width="dialogMaxWidth"
    :content-class="bodyClasses"
    :transition="transition"
    internal-activator
    attach=".v-application--wrap"
    @input="updateDialogValue"
  >
    <div
      v-if="dismissible"
      :class="b('closeButtonWrapper')"
      @click="updateDialogValue(false)"
    >
      <div :class="b('closeButton')"></div>
    </div>
    <div v-if="heading || $slots.header" :class="b('header')">
      <slot name="header">
        <h2 :class="b('heading')">{{ heading }}</h2>
      </slot>
    </div>
    <div v-bar :class="b('contentWrapper')" class="vuebar-element">
      <div
        ref="contentScroll"
        :class="b('contentScroll', {
          shadow: hasScrollShadow,
          shadowBottom: scrollShadow.bottom,
          shadowTop: scrollShadow.top,
        })"
        @scroll="handleContentScroll"
      >
        <div :class="b('content', {
          textCenter: contentTextAlign === 'center',
          contentVerticalCenter: contentVerticalCenter,
          noPadding: noContentPadding,
        })">
          <slot name="content"></slot>
          <div :class="b('footer')" v-if="!noFooter">
            <slot name="footer">
              <v-btn
                :class="b('footerBtn', { info: true })"
                @click.stop="updateDialogValue(false)"
              >
                Got it
              </v-btn>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
const BLOCK = 'phDialog';

export default {
  name: 'PhDialog',
  block: BLOCK,
  props: {
    noContentPadding: Boolean,
    value: Boolean,
    heading: String,
    contentTextAlign: {
      type: String,
      default: 'center',
    },
    dismissible: Boolean,
    fullscreen: Boolean,
    contentVerticalCenter: Boolean,
    maxWidth: {
      type: Number,
      default: 500,
    },
    noFooter: {
      type: Boolean,
      default: () => false,
    },
    narrowContent: Boolean,
    wideContent: Boolean,
    shadowContentScroll: Boolean,
    growContent: Boolean,
    transition: {
      type: String,
      default: 'dialog-transition',
    },
  },
  data() {
    return {
      contentScrollState: {
        scrollTop: 0,
        offsetHeight: 0,
        scrollHeight: 0,
      },
    };
  },
  computed: {
    bodyClasses() {
      let classList = `${BLOCK}__body`;
      if (this.narrowContent) {
        classList += ` ${BLOCK}__body--narrow`;
      }
      if (this.wideContent) {
        classList += ` ${BLOCK}__body--wide`;
      }
      if (this.growContent) {
        classList += ` ${BLOCK}__body--growContent`;
      }
      return classList;
    },
    dialogMaxWidth() {
      return `${this.maxWidth}px`;
    },
    hasScrollShadow() {
      return this.fullscreen && this.shadowContentScroll;
    },
    scrollShadow() {
      const SCROLL_THRESHOLD = 50;
      return {
        bottom: this.hasScrollShadow
          && this.contentScrollState.scrollHeight > this.contentScrollState.offsetHeight
          && this.contentScrollState.scrollTop < (this.contentScrollState.scrollHeight - this.contentScrollState.offsetHeight),
        top: this.hasScrollShadow && this.contentScrollState.scrollTop >= SCROLL_THRESHOLD,
      };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(this.initContentScrollShadows);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleContentScroll(e) {
      this.contentScrollState.scrollTop = e.target.scrollTop;
    },
    initContentScrollShadows() {
      if (this.shadowContentScroll && this.$refs.contentScroll) {
        this.contentScrollState = {
          scrollTop: this.$refs.contentScroll.scrollTop,
          offsetHeight: this.$refs.contentScroll.offsetHeight,
          scrollHeight: this.$refs.contentScroll.scrollHeight,
        };
      }
    },
    updateDialogValue(newVal) {
      newVal ? this.$emit('open') : this.$emit('close');
      this.$emit('input', newVal);
    },
    handleEscClick(e) {
      if (e.keyCode === 27) {
        this.updateDialogValue(false);
      }
    },
    setCloseOnEscListener() {
      document.addEventListener('keydown', this.handleEscClick);
    },
    removeCloseOnEscListener() {
      document.removeEventListener('keydown', this.handleEscClick);
    },
  },
  mounted() {
    this.setCloseOnEscListener();
  },
  beforeDestroy() {
    this.removeCloseOnEscListener();
  },
};
</script>

<style lang="scss">
$block: 'phDialog';

.#{$block} {
  &__body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    background: $background-gradient;
    &:not(.v-dialog--fullscreen) {
      border-radius: 8px;
      background: $white;
    }
    &--narrow {
      .#{$block}__content {
        max-width: 880px;
        margin: 0 auto;
      }
    }
    &--wide {
      .#{$block}__content {
        width: 100%;
        @include mappy-bp(md) {
          padding: 0 48px;
        }
      }
    }
    &--growContent {
      .#{$block}__content {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__header {
    text-align: center;
    padding: 30px 30px 15px;
    background: $fake-transparent;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .#{$block}__heading {
      font-weight: 700;
      color: $ph-dark;
    }
  }
  &__contentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    // to offset the vuebar padding
    margin-right: -17px;
  }
  &__contentScroll {
    flex: 1;
    &--shadow {
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 115px;
        position: fixed;
        top: 0px;
        left: 0px;
        background: linear-gradient(0deg, $fake-transparent, $white);
        opacity: 0;
        @include transition-all();
        z-index: 1;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 115px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(180deg, $fake-transparent, $white);
        opacity: 0;
        @include transition-all();
        z-index: 1;
      }
    }
    &--shadowTop {
      &::before {
        opacity: 1;
      }
    }
    &--shadowBottom {
      position: relative;
      &::after {
        opacity: 1;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    background: $fake-transparent;
    padding: 16px;
    @include mappy-bp(md) {
      padding: 30px 33px 30px 50px;
    }
    font-size: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &--textCenter {
      text-align: center;
    }
    &--contentVerticalCenter {
      justify-content: center;
    }
    &--noPadding {
      padding: 0px;
    }
  }
  &__footer {
    flex-direction: column;
    padding: 0px 0px 80px 0px;
    @include mappy-bp(md) {
      flex-direction: row;
      margin: 40px 0px 0px;
    }
    display: flex;
    justify-content: center;
    .#{$block}__footerBtn {
      &--info {
        min-width: 230px;
      }
      height: 50px;
      text-transform: capitalize;
    }
  }
  &__closeButtonWrapper {
    cursor: pointer;
    position: absolute;
    width: 69px;
    height: 69px;
    @include mappy-bp(xs) {
      width: 83px;
      height: 83px;
    }
    right: 0px;
    top: 0px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    border-radius: 0px;
  }
  &__closeButton {
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: url('~@/assets/icons/cross--white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__closeButtonLabel {
    display: none;
    font-size: 14px;
    color: $diff-black;
    margin-right: 4px;
    cursor: pointer;
    @include mappy-bp(xs) {
      display: initial;
    }
  }
}
</style>
