const FIELD_NAME_MAP = {
  athletes: 'numAthletes',
  coaches: 'numCoaches',
  volunteers: 'numVolunteers',
  officials: 'numOfficials',
};

export default (state) => ({
  personnel: Object.entries(state.personnel).reduce((acc, entry) => {
    acc[FIELD_NAME_MAP[entry[0]] || entry[0]] = entry[1];
    return acc;
  }, {}),
});
