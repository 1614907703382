const Big = require('big.js');

export default (items) => {
  if (items instanceof Array) {
    const amount = items.reduce((acc, itm) => {
      if (itm.paymentCycle) {
        const paymentCycle = (itm.paymentCycle instanceof Object && itm.paymentCycle.value) || itm.paymentCycle;
        const rawCost = paymentCycle === 'annually' ? itm.annualCost : itm.monthlyCost;
        if ((typeof rawCost === 'number' && !Number.isNaN(rawCost)) || (typeof rawCost === 'string' && rawCost.length)) {
          const parsedCost = new Big(rawCost);
          acc = parsedCost.plus(acc);
        }
      }
      return acc;
    }, new Big(0));
    return amount.toFixed(2);
  }
  return new Big(0).toFixed(2);
};
