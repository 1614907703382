<template>
  <div v-touch="{ left: handleSwipeLeft, right: handleSwipeRight }" :class="b('wrapper', { mobile })">
    <div :class="b('scroll', { mobile })">
      <div
        :style="scrollContentTransformStyle"
        :class="b('scrollContent', { mobile })"
      >
        <ul :class="b({
              shadow: hasShadow,
              mobile,
            })"
        >
          <li
            v-for="(step, index) in steps"
            :key="index"
            :class="b('item', {
              shadow: true,
              mobile,
              active: (index + 1) === value,
              complete: (index + 1) <= lastCompleteStep,
              disabled: (index + 1) > lastCompleteStep,
            })"
            @click="handleItemClick(index)"
          >
            <div class="">
              <span v-show="(index + 1) <= lastCompleteStep && (index + 1) !== value" :class="b('itemIconWrapper', { mobile })">
                <img
                  :class="b('itemIcon')"
                  alt="Check icon"
                >
              </span>
              <slot name="step" :step="step" :index="index">
                {{ step }}
              </slot>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const MOBILE_ITEM_WIDTH = 174;

export default {
  name: 'NavStepper',
  block: 'navStepper',
  props: {
    lastCompleteStep: Number,
    mobile: Boolean,
    steps: [Array, Number],
    shadow: {
      type: Boolean,
      default: true,
    },
    value: Number,
  },
  data() {
    return {
      localShownValue: 0,
      innerTranslate: 0,
      lastTouch: {
        start: 0,
        xArr: [],
      },
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.localShownValue = newVal;
      },
      immediate: true,
    },
  },
  computed: {
    maxContentTransform() {
      return (this.steps.length - 1) * MOBILE_ITEM_WIDTH * -1;
    },
    scrollContentTransform() {
      return this.innerTranslate || (((this.localShownValue) * MOBILE_ITEM_WIDTH) * -1) + MOBILE_ITEM_WIDTH + (this.localShownValue == 1 ? 15 : 0);
    },
    scrollContentTransformStyle() {
      return this.mobile ? `transform: translateX(${this.scrollContentTransform}px);` : '';
    },
    hasShadow() {
      return this.shadow && !this.mobile;
    },
  },
  methods: {
    handleItemClick(index) {
      this.$emit('input', index + 1);
    },
    handleSwipeLeft() {
      if (this.localShownValue < this.steps.length) {
        this.localShownValue += 1;
      }
    },
    handleSwipeRight() {
      if (this.localShownValue > 1) {
        this.localShownValue -= 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'navStepper';
$translate-duration: 0.3s;
$transition-duration: 0.2s;

.#{$block} {
  list-style: none;
  display: flex;
  justify-content: center;
  border-radius: 32px;
  overflow: hidden;
  background-color: $white;
  flex-shrink: 0;
  padding: 0;
  font-family: $antarctica;
  &--mobile {
    display: inline-flex;
  }
  &--shadow {
    box-shadow: 0px 5px 10px 0px rgba($shadow-purple, 0.14);
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    &--mobile {
      justify-content: flex-start;
    }
  }
  &__scroll {
    flex-shrink: 0;
    overflow: hidden;
    &--mobile {
      flex: 1;
      overflow-x: hidden;
    }
  }
  &__scrollContent {
    padding: 0px 20px 20px 20px;
    display: inline-flex;
    transform: translateX(0px);
    transition: transform $translate-duration ease-in;
    &--mobile {
      padding: 0px;
    }
  }
  &__item {
    height: 45px;
    padding: 13px 45px 13px 50px;
    cursor: pointer;
    background: $white;
    font-size: 12px;
    color: $ph-grey-dark;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    flex-shrink: 0;
    letter-spacing: 0.81px;
    transition-delay: $translate-duration;
    transition: all $transition-duration ease-in;
    & > * {
      position: relative;
      z-index: 1;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      background: $ph-purple-green;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      opacity: 0;
      transition-delay: inherit;
      transition: opacity $translate-duration ease-in;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 100%;
      right: 1px;
      top: 0px;
      transform: translateX(100%);
      background: $ph-end-of-gradient-arrow;
      -webkit-clip-path: polygon(0 0, 20% 0, 100% 50%, 20% 100%, 0 100%);
      clip-path: polygon(0 0, 20% 0, 100% 50%, 20% 100%, 0 100%);
      z-index: 1;
      opacity: 0;
      transition-delay: inherit;
      transition: opacity $translate-duration ease-in;
    }
    &--mobile {
      height: 36px;
      padding: 8px;
      text-align: center;
      width: 162px;
      white-space: nowrap;
      margin-right: 12px;
      &:last-child {
        margin-right: 0px;
      }
    }
    &--complete {
      color: $ph-dark;
      font-weight: 700;
    }
    &--active {
      color: $white;
      font-weight: 700;
      &::before,
      &::after {
        opacity: 1;
      }
    }
    &--disabled {
      pointer-events: none;
    }
  }
  &__itemIconWrapper {
    display: inline-block;
    width: 18px;
    height: 12px;
    vertical-align: middle;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    &--mobile {
      position: relative;
      height: 9px;
      transform: translateY(-10%);
      left: 0px;
    }
  }
  &__itemIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: top;
    opacity: 1;
    transition: all $transition-duration ease-in;
    content: url("@/assets/icons/check--black--mobile.svg");
    @include mappy-bp(xs){
      content: url("@/assets/icons/check--big--black.svg");
    }
  }
}
</style>
