export default {
  claims: `query getClaims($filters:ClaimsFilterInput) {
            claims(filters:$filters){
              edges{
                id,
                organization {
                  id,
                  name,
                  icon,
                  type,
                  address {
                    address,
                    address2,
                    city,
                    state,
                    country,
                    zip
                  },
                  phone,
                  site,
                  email,
                },
                status,
                name,
                birthdate,
                gender,
                socialSecurity,
                address{
                  address,
                  city,
                  state,
                  country
                }
                mailing{
                  address,
                  city,
                  state,
                  country
                },
                isPersonDependent,
                employerName,
                guardianName,
                injuryLocation,
                injuryDate,
                treatmentDate,
                hospitalName,
                admittanceDate,
                physicalName,
                isPolicyholderJurisdiction,
                isWitness,
                isFNOL,
                supervisorName,
                activityType,
                incidentDescription,
                injuryReport,
                bodyPart,
                injuryType,
                isInjuredPersonCovered,
                otherInsuranceName,
                policyNumber,
                certificateNumber,
                isArchived,
                archivingReason,
                reporterName,
                creator{
                  id,
                  name,
                },
                createdAt,
              },
              pagination {
                totalPages,
                currentPage,
                nextPage,
              },
            }
          }`,
  claim: `query getClaimById($id: ID!) {
            claim(id: $id) {
              id,
              status,
              organization {
                  id,
                  name,
                  icon,
                  type,
                  address {
                    address,
                    address2,
                    city,
                    state,
                    country,
                    zip
                  },
                  phone,
                  site,
                  email,
                },
              name,
              birthdate,
              gender,
              socialSecurity,
              address{
                address,
                address2,
                city,
                state,
                country,
                zip,
              },
              mailing{
                address,
                city,
                state,
                country,
              },
              isPersonDependent,
              employerName,
              guardianName,
              injuryLocation,
              injuryReport,
              injuryDate,
              treatmentDate,
              hospitalName,
              admittanceDate,
              physicalName,
              isPolicyholderJurisdiction,
              isWitness,
              policyNumber,
              certificateNumber,
              otherInsuranceName,
              isInjuredPersonCovered,
              physicalName,
              admittanceDate,
              hospitalName,
              activityType,
              incidentDescription,
              bodyPart,
              injuryType,
              isInjuredPersonCovered,
              otherInsuranceName,
              policyNumber,
              certificateNumber,
              isArchived,
              archivingReason,
              reporterName,
              creator{
                  id,
                  name,
                },
              createdAt,
            }
          }`,
};
