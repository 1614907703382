export default (orgId, cvg, lmt, subCovs, stDate, enDate) => {
  let filters = {
    organization: orgId,
    coverage: [cvg],
  };
  if (typeof lmt === 'number' && !Number.isNaN(lmt)) {
    filters.limit = lmt;
  }
  if (subCovs instanceof Array && subCovs.length) {
    filters.subcoverages = subCovs;
  }
  if (typeof stDate === 'string' && stDate.length) {
    filters.startedAt = stDate;
  }
  if (typeof enDate === 'string' && enDate.length) {
    filters.expiredAt = enDate;
  }
  return filters;
};
