const route = {
  name: 'forgotPassword',
  path: 'forgot-password',
  component: () => import('@/pages/auth/ForgotPassword'),
  meta: {
    disableDrawer: true,
  },
};

export default route;
