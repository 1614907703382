export default (sportsObj, fallbackValues = true, fields = ['ageGroup1', 'ageGroup2', 'ageGroup3', 'ageGroup4', 'monthsInSeason']) => {
  if (sportsObj && sportsObj instanceof Object) {
    if (fields instanceof Array && fields.length) {
      return Object.entries(sportsObj).map((entry) => {
        let queryObj = {
          sport: entry[0],
        };
        if (entry[1]) {
          // ageGroup1
          if (fields.includes('ageGroup1')) {
            if (entry[1].ageGroup1) {
              queryObj.ageGroup1 = parseInt(entry[1].ageGroup1, 10);
            } else if (fallbackValues) {
              queryObj.ageGroup1 = 0;
            }
          }
          // ageGroup2
          if (fields.includes('ageGroup2')) {
            if (entry[1].ageGroup2) {
              queryObj.ageGroup2 = parseInt(entry[1].ageGroup2, 10);
            } else if (fallbackValues) {
              queryObj.ageGroup2 = 0;
            }
          }
          // ageGroup3
          if (fields.includes('ageGroup3')) {
            if (entry[1].ageGroup3) {
              queryObj.ageGroup3 = parseInt(entry[1].ageGroup3, 10);
            } else if (fallbackValues) {
              queryObj.ageGroup3 = 0;
            }
          }
          // ageGroup4
          if (fields.includes('ageGroup4')) {
            if (entry[1].ageGroup4) {
              queryObj.ageGroup4 = parseInt(entry[1].ageGroup4, 10);
            } else if (fallbackValues) {
              queryObj.ageGroup4 = 0;
            }
          }
          // monthsInSeason
          if (fields.includes('monthsInSeason')) {
            if (entry[1].monthsInSeason) {
              queryObj.monthsInSeason = parseInt(entry[1].monthsInSeason, 10);
            } else if (fallbackValues) {
              queryObj.monthsInSeason = 1;
            }
          }
        }
        return queryObj;
      });
    }
  }
  return [];
};
