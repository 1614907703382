import { DEFAULT_ATHLETES_NUMBER, DEFAULT_PERSONNEL_NUMBER, TOURNAMENT_ORG_TYPE_ID } from '@/utils/constants';

export const ATHLETES_AGE_OBJECT = {
  ageGroup1: DEFAULT_ATHLETES_NUMBER || 0,
  ageGroup2: DEFAULT_ATHLETES_NUMBER || 0,
  ageGroup3: DEFAULT_ATHLETES_NUMBER || 0,
  ageGroup4: DEFAULT_ATHLETES_NUMBER || 0,
  monthsInSeason: 1,
};

export const CHAINABLE_SUB_STEP_NAMES = {
  generalCommonQuestions: 'generalCommonQuestions',
  riskManagementQuestions: 'riskManagementQuestions',
  concussionProtocolQuestions: 'concussionProtocolQuestions',
  sexualAbuseLiabilityQuestions: 'sexualAbuseLiabilityQuestions',
};
export const CHAINABLE_SUB_STEP_QUESTION_NAMES = {
  [CHAINABLE_SUB_STEP_NAMES.generalCommonQuestions]: {
    isGuestParticipants: 'isGuestParticipants',
    isOvernightPlanned: 'isOvernightPlanned',
    isMinorsControlled: 'isMinorsControlled',
    isAthletesPlayingFootballOlder: 'isAthletesPlayingFootballOlder',
    isOvernightExposure: 'isOvernightExposure',
    isParticipantsTransported: 'isParticipantsTransported',
  },
  [CHAINABLE_SUB_STEP_NAMES.riskManagementQuestions]: {
    isMaintainRecordSigned: 'isMaintainRecordSigned',
    isProcessCheckPlayingSurfaces: 'isProcessCheckPlayingSurfaces',
    isNonSportRelatedActivities: 'isNonSportRelatedActivities',
    isHavingLifeguardsWhenRequired: 'isHavingLifeguardsWhenRequired',
    isCheerleadingStunts: 'isCheerleadingStunts',
  },
  [CHAINABLE_SUB_STEP_NAMES.concussionProtocolQuestions]: {
    isConcussionManagementPolicy: 'isConcussionManagementPolicy',
    isDistributeWrittenPolicy: 'isDistributeWrittenPolicy',
    isTrainingConcussionRecognition: 'isTrainingConcussionRecognition',
  },
  [CHAINABLE_SUB_STEP_NAMES.sexualAbuseLiabilityQuestions]: {
    isAllegationSexualAbuse: 'isAllegationSexualAbuse',
    isInsuredCriminalInvestigations: 'isInsuredCriminalInvestigations',
    isWrittenStandardsSexualAbuse: 'isWrittenStandardsSexualAbuse',
  },
};
export const CHAINABLE_SUB_STEPS = ['generalCommonQuestions', 'riskManagementQuestions', 'concussionProtocolQuestions', 'sexualAbuseLiabilityQuestions'];

export const EXTERNAL_COVERAGES_OBJECT = {
  carrier: '',
  coverageType: '',
  otherCarrier: '',
  premium: '',
  paymentCycle: '',
  renewalDate: null,
};

export const ORG_APPLICATION_STEP_NAMES = {
  BASIC: 'basic',
  QUOTE: 'quote',
  PERSONALIZE: 'personalize',
  POLICY: 'policy',
  APPLY: 'apply',
};

export const ORG_APPLICATION_SUB_STEP_NAMES = {
  BASIC: {
    ORG_SPORTS: 'orgSports',
    ORG_AGE_GROUPS: 'orgAgeGroups',
    ORG_PERSONNEL: 'orgPersonnel',
  },
  QUOTE: {
    ORG_PREFERRED_COVERAGE: 'orgPreferredCoverage',
    ORG_PREFERRED_COVERAGE_SETTINGS: 'orgPreferredCoverageSettings',
    ORG_PREFERRED_COVERAGE_DATES: 'orgPreferredCoverageDates',
    ORG_PRELIMINARY_QUOTES: 'orgPreliminaryQuotes',
    ORG_PREVIOUS_CLAIMS: 'orgPreviousClaims',
    ORG_CURRENT_COVERAGE: 'orgCurrentCoverage',
  },
  PERSONALIZE: {
    ORG_MONTHS_IN_SEASON: 'orgMonthsInSeason',
    ORG_REGISTRATION_PROVIDER: 'orgRegProvider',
    ORG_BACKGROUND_CHECK: 'orgBackgroundCheck',
    ORG_PROTOCOL_TEMPLATES: 'orgProtocolTemplates',
  },
  POLICY: {
    POLICY_CHOICE: 'policyChoice',
  },
  APPLY: {
    COVERAGE_SUMMARY: 'coverageSummary',
  },
};

export const ORG_APPLICATION_STEPS = {
  [ORG_APPLICATION_STEP_NAMES.BASIC]: [
    ORG_APPLICATION_SUB_STEP_NAMES.BASIC.ORG_SPORTS,
    ORG_APPLICATION_SUB_STEP_NAMES.BASIC.ORG_AGE_GROUPS,
    ORG_APPLICATION_SUB_STEP_NAMES.BASIC.ORG_PERSONNEL,
  ],
  [ORG_APPLICATION_STEP_NAMES.QUOTE]: [
    ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREFERRED_COVERAGE,
    ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREFERRED_COVERAGE_SETTINGS,
    ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREFERRED_COVERAGE_DATES,
    ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PRELIMINARY_QUOTES,
    // ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_PREVIOUS_CLAIMS,
    ORG_APPLICATION_SUB_STEP_NAMES.QUOTE.ORG_CURRENT_COVERAGE,
  ],
  [ORG_APPLICATION_STEP_NAMES.PERSONALIZE]: [
    ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_MONTHS_IN_SEASON,
    ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_REGISTRATION_PROVIDER,
    ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_BACKGROUND_CHECK,
    ORG_APPLICATION_SUB_STEP_NAMES.PERSONALIZE.ORG_PROTOCOL_TEMPLATES,
  ],
  [ORG_APPLICATION_STEP_NAMES.POLICY]: [ORG_APPLICATION_SUB_STEP_NAMES.POLICY.POLICY_CHOICE],
  [ORG_APPLICATION_STEP_NAMES.APPLY]: [ORG_APPLICATION_SUB_STEP_NAMES.APPLY.COVERAGE_SUMMARY],
};

export const PERSONALIZE_STEPS_FILLER = (payload = []) => ['orgMonthsInSeason', ...payload, 'orgRegProvider', 'orgBackgroundCheck', 'orgProtocolTemplates'];

export const STEP_NAMES_MAP = {
  quote: 'coverage',
  apply: 'checkout',
  policy: 'quote',
};

export const PROPOSAL = {
  includes: [],
  excludes: [],
};
