module.exports = {
  longread: {
    insuranceWaiver: `<p>
        In consideration of this request for coverage, the Applicant hereby declares that the partners, officers, directors, or employees are not aware of any error, omission, negligent act, unresolved job disputes, fee disputes or circumstances which may give rise to a Claim or potential Claim being made against the Named Insured, their predecessor in business, or any present or past partners, officers, directors, or employees in connection with the requested coverage. Reasonable efforts have been made to obtain sufficient information from each Director and Officer of the Company, including its Subsidiaries, to facilitate the proper and accurate completion of this information form. If any coverage is granted to any of the Applicants hereunder, the Insurer shall be deemed to have relied upon the declarations and statements in this document and attached hereto. All such declarations and statements shall be deemed the basis of coverage and shall be considered incorporated into and constituting part of the policy should one be issued. The undersigned warrants that to the best of their knowledge, the statements set forth in this Information are true, that no material facts have been suppressed or misstated, and that any financial statements submitted in conjunction with this information are representative of the current financial position of the Company including its subsidiaries.
      </p>
      <p>
        The undersigned agrees that if the information supplied on this document changes between the date of this information and the effective date of the insurance, should any be offered, the undersigned will, in order for the information to be accurate on the effective date of the insurance, immediately notify the Insurer of such changes and the Insurer may withdraw or modify any outstanding quotations and/or authorizations or agreements to bind the insurance. The Insurer is hereby authorized to make any investigation and inquiry in connection with this proposal as it may deem necessary.
      </p>
      <p>
        Signing of this document does not bind the Applicant or the Insurer to complete any insurance, but it is agreed that this document shall be the basis of the contract should a policy be issued, and it will be deemed attached to and become part of the policy. Signing this Information does not bind the undersigned to purchase this insurance, nor does it bind the Insurer to issue this insurance. However, should the Insurer issue a policy, this Information shall serve as the basis of such policy and will be attached to and form part thereof. The undersigned is duly authorized to make representations and to sign on behalf of all of the Directors and Officers and the Company. I have read and agree with the privacy policy of this site.
      </p>`,
  },
  message: {
    policyPurchaseDisclaimer: 'Please note that your policy is subject to an OFAC check. If your OFAC check comes back as restricted, your policy will not be bound. You will be notified immediately.',
    poiDisclaimer: 'Coverage for Hosted Tournaments and/or Hosted Camps/Clinics that involve guest participants is excluded under your policy.  For more information, please contact the Player’s Health Inc.',
    nonSportRelatedActivitiesAreNotCovered: 'Non-sport related activities or trips are not covered.',
    almostDoneTimeToReview: 'We’re almost done! Time to review',
    alreadyHaveAnAcсount: 'Already have an account',
    alreadyHaveCoverageInCartOrAlreadyPurchased: 'You already have this coverage in the shopping cart or you\'ve already purchased it',
    archiveOrgConfirm: 'Are you sure you want to archive this organization?',
    archiveClaimsConfirm: 'Are you sure you want to archive the selected claims? If yes, you may as well enter the reason for archiving (optional).',
    archiveFnolsConfirm: 'Are you sure you want to archive the selected FNOLs? If yes, you may as well enter the reason for archiving (optional).',
    attention: 'Attention',
    beforePurchaseMsg: 'Before purchasing insurance, you will need to enter a payment method.',
    bgCheck1: 'Is there a formal injury management protocol being implemented within your organization?',
    bgCheck2: 'Is there a concussion protocol being implemented within your organization?',
    bgCheck3: 'Are participants required to sign waivers and / or assume risk statements?',
    bySumbittinThisApplicationYouAgree: 'By submitting this application you agree that...',
    bySubmittingYouAgree: 'By submitting this information you agree that',
    chainedQuestions: {
      areAllMinorsUnderCustody: 'Are all minors under the care, custody and control of their parents/legal guardians at all times, when not on the playing field?',
      areLifeguardsPresentAccordingToRedCross: 'Do you adhere to the American Red Cross recommendations of having a minimum of two certified lifeguards always present?',
      areNonSportActivitiesPlanned: 'Are there any non-sport related activities or trips planned by this insured?',
      areOvernightCampsPlanned: 'Will there be any overnight games, camps, clinics or tournaments planned?',
      areYouHostingCampsForGuests: 'Are you hosting any camps/clinics or tournaments that includes any guest participants?',
      cheerleadingStunts: 'If cheerleading allows stunts, describe safety measures such as height of stunts, spotting, and supervision.',
      everHadSexualAbuseIncidents: 'Is your organization aware of any circumstances currently or that may have existed, that could possibly result in an allegation of sexual abuse or misconduct?',
      isOvernightExposure: 'Is there any overnight exposure associated with your tournament?',
      isProcessForSurfaceChecks: 'Is there a process in place to check the playing surfaces for defects, debris and/or hazards before play, as well as to report any premises concerns to the landlord? ',
      isParticipantsTransported: 'Are participants ever transported to or from practices or competitions by organization members?',
      doYouDistributeConcussionManagementPolicy: 'Do you distribute the written policy to coaches, parents and players and require parents written acknowledgement that they have received and reviewed? This includes your policy or the policy of your governing body that you adopted.',
      doYouHaveAbusePreventionStandards: 'Do you have and enforce written standards regarding Sexual Abuse/Molestation including but not limited to adherence to the “Three Person Rule”?',
      doYouHaveConcussionManagementPolicy: 'Do you have a written concussion management policy in place that is compliant with current state legislation and consistent with the guidelines set forth by the US Centers for Disease Control and Prevention (CDC)?',
      doYouHaveTackleFootballPlayers: 'Do you have any athletes playing tackle football who are 15 years old or older?',
      doYouMaintainPlayingSurfaces: 'Do you own or maintain any of the playing surfaces used by your organization?',
      doYouMainatainRecordOfWaivers: 'Do you get and maintain record of signed Waiver/Release from all parents/legal guardians?',
      doYouRequireConcussionRecognitionTrainingCertification:
        'Do you require all coaches and officials are certified for concussion training and concussion recognition?',
      doYouRequireConcussionRecognitionTraining: 'Do you require all coaches to undergo formal training for concussion recognition?',
      doYouRequireStaffBackgroundChecks:
        'Do you require criminal background checks and a check against the National Sex Offender Registry for all staff, directors, officers, coaches and trainers (even if services/labor contracted)?',
      doesTheOrganizationRequireWaivers: 'Does the organization require a signed Waiver/Release form from all participants or parent/legal guardian as appropriate? You will need a waiver in place to secure insurance. Great thing is we can assist with getting that up and running for you.',
      doesTheOrganizationHaveAndEnforceWrittenStandardsRegardingSexualAbuse: 'Does the organization have and enforce written standards regarding Sexual Abuse and Molestation prevention and reporting? Does the organization conduct criminal background checks on all coaches and staff?',
      doYouDistributeAWrittenConcussionAwarenessPolicy: 'Does the organization distribute a written concussion awareness policy to coaches, parents, and players? Does your organization’s concussion policy require a medical doctor’s release prior to the child returning to play after a suspected concussion?',
      hasTheOrganizationHadAnyLiabilityClaimsInTheLastFiveYears: 'Has the organization had any liability claims including civil lawsuits and incidents, or been non-renewed in the last 5 years? Has the organization had any sexual abuse and molestation incidents or claims in the last 5 years?',
      doesTheInsuredTransportParticipants: 'Does the insured transport participants to games, practices, or other events?',
    },
    checkYourEmail: 'Check your email',
    checkoutTermsHint: 'Please note that by submitting this form you are only requesting an insurance coverage quote. \nYou are not yet purchasing coverages.',
    chooseBgCheckProvider: 'Choose your background check provider',
    chooseCoverageStartDate: 'When do you want your cover to start?',
    chooseCoverageAndClickNext: 'Choose your coverage and click next',
    concussionProtocolAssistanceWillFollow: 'One of our customer success representatives will be following up with you to implement your concussion protocol',
    coverageEditCartNotEmpty: 'Are you sure you want to change your coverage parameters? All your previously selected quotes will have to be re-calculated and picked again.',
    chooseQuoteAndClickNext: 'Choose your quote and click next',
    createAccountToProceed: 'Create an account to proceed',
    currentCoverage: 'Current coverage',
    defaultPaymentMethodChangeConfirm: 'Are you sure you want to change your default payment method?',
    deletePreviousClaimConfirm: 'Are you sure you want to delete this claim?',
    didYouLikeOurService: 'Did you like our service?',
    divingActivitiesExcluded: 'Diving activities and competitions are excluded',
    downloadCertificateWhenReady: 'You\'ll be able to download your certificate once the file is ready',
    doubleCheckInbox: 'Please check your inbox and make sure you followed the right link. If it doesn\'t help, please contact our administrator to help resolve your issue',
    doYouBgCheck: 'Do you secure background checks on employees and volunteers?',
    doYouCurrentylHaveASimilarInsurance: 'Do you currently have a similar insurance policy in place?',
    doYouSecureYourBgChecks: 'Do you secure background checks on employees and volunteers?',
    doYouWantToSaveYouCreditCard: 'Do you want to save your credit card',
    duplicateCoveragesInCart: 'You cannot purchase more than one policy of the same coverage. Please choose one and delete the others',
    emailConfirmation: 'Look for the ‘Activate your Player\'s Health cover Account’ email and click the confirm email buttons to complete your set-up',
    emailChangeVerification: 'Email change verification',
    enterCardInfo: 'Please fill in your credit/debit card information below.',
    enterCarrierName: 'Enter carrier name',
    enterOtherCarrierName: 'Enter your carrier name',
    escalateFnolsConfirm: 'Are you sure you want to escalate selected FNOLs?',
    exportAllFnolsConfirm: 'Are you sure you want to export all visible FNOLs? This may take a while',
    exportFnolsConfirm: 'Are you sure you want to export selected FNOLs? This may take a while',
    fillPaymentInfo: 'Fill in the payment information and have your insurance before you know it.',
    gRecaptchaDisclaimer: 'This site is protected by reCAPTCHA and the Google <a href="{privacyPolicyHref}" target="_blank">Privacy Policy</a> and <a href="{termsAndConditionsHref}" target="_blank">Terms of Service</a> apply.',
    hasCompanyClaimsIn3Years: 'Has the organization had claims against them in last 3 years?',
    haveConcussionProtocol: 'Is there a concussion protocol being implemented within your organization?',
    haveInjuryProtocol: 'Is there a formal injury management protocol being implemented within your organization?',
    haveWaivers: 'Are participants required to sign waivers and/or assume risk statements?',
    hereIsYourQuote: "Here's your price",
    howCanWeContactYou: 'How can we contact you?',
    howOftenBgCheck: 'How often do you secure background checks?',
    hnoaDisclaimer: 'Participants that are ever transported to or from practices or competitions by organization members cannot be covered.',
    hnoaQuestion: 'Are participants ever transported to or from practices or competitions by organization members?',
    iAlreadyHaveThisCoverage: 'I have this coverage, show me the next one',
    iConfirmInfoIsCorrect: 'I hereby certify that all the information provided is correct',
    ifSoShareWithYourFriends: 'If so, please share with your friends!',
    invalidCoverageInCart: 'The price for this coverage is no longer relevant. Please remove this item from your cart and choose another one.',
    inviteNewAdmin: 'Invite a new admin',
    inviteAdminSuccess: 'You have successfully invited an administrator to your organization.',
    includeEightCharactersOrMore: 'Be at least 8 characters long',
    includeNumbers: 'Include at least one number',
    includeSpecialCharacters: 'Include at least one special symbol',
    includeUppercaseLowercaseCharacters: 'Include both lowercase and uppercase characters',
    isInjuredPersonCovered: 'Is the Injured Person covered under any other insurance plans?',
    keepInMindYouWillOnlyBeInsuredForTHeWrokDescribed: 'Keep in mind, you will only be insured for the incidents described. So it’s important to review everything closely.',
    lackPolicyNumbers: 'It seems there are no available policies left as of now. <br> Don\'t worry, you can still get your coverage! Click the button below to request a quote, and we\'ll reach out to you and help you get covered.',
    lackSports: 'Don’t see your sport listed?  Please contact <a href="mailto:{email}">Player’s Health</a> for assistance.',
    letsGetYouCovered: 'Let\'s get you covered',
    letsCreateYourAccount: 'Let\'s start by creating your Player\'s Health Cover account',
    manualCheckBox: {
      awareOfPaperCopy: 'I am aware that I can change this election at any time or request a paper copy at no cost.',
      consentForElectronicPolicies: 'I agree to receive the policy documents electronically and confirm that I am able to access the electronic documents.',
      iHaveRead: 'I confirm that I have read and understand the terms and conditions of the',
      iUnderstandThatIDontHaveActiveInsurancePolicy: 'I understand that I do not have an active insurance policy until confirmed by Player’s Health broker in writing. Furthemore, I have declined certain coverages and policies in this application. I understand the coverages and policies that have been offered to me or I have asked my broker to explain then.',
      sendRisk: 'Please send me occasional risk management tools and tips via email to help run a more effective business.',
      termsOfUse: 'I understand the Player’s Health <a href={corporate_policy} id="tnc-corporate-policy-link" target="_blank">Corporate Policy</a> and agree to the Player’s Health <a href={terms_of_use} id="tnc-terms-of-use-link" target="_blank">Terms of Service</a>.',
      understand: 'I understand that by submitting the requested information we will begin the process of issuing a quote. There is no insurance coverage until a policy has been issued.',
    },
    maxCoverageStartDays: 'Your cover start must be within {days} days of today',
    noAdminsFound: 'No admins found',
    noIChangedMyMind: 'No, I Changed My Mind',
    noPoliciesAvailableForClaims: 'It appears you had no active policies at the time of incident',
    noQuotesForYourParameters: 'Unfortunately, we can\'t give any quotes for the parameters you entered. Please contact us in order to get a personalized coverage',
    noQuotesYet: `We are unable to generate your quote at this time, but don't worry.
      This commonly happens when carriers require additional information.
      Please email <a href="mailto:{email}">sales@playershealth.com</a>, and a broker will reach out to assist you.`,
    noPoliciesYet: 'Oops, no quotes yet.',
    NUFICDisclaimer: 'Participant accident insurance is underwritten by National Union Fire Insurance Company of Pittsburgh, Pa., a Pennsylvania insurance company with its principal place of business at 175 Water Street, 15th Floor, New York, NY 10038. It is currently authorized to transact business in all states and the District of Columbia. NAIC No. 19445.',
    orgAdmins: 'Admins of {orgName}',
    passwordMust: 'The password must',
    paymentMethodChangeConfirm: 'Are you sure you want to change this payment method?',
    passwordRecoveryEnterEmail: 'Please enter the email you signed up with so we could send you a password reset link',
    payAndRegister: 'Pay & go',
    playAroundWithTheQuote: 'Play around with the slider to adjust your coverage limit, and pick the best policy for you',
    pleaseNotThatYourInsuranceCoverageIsNotYetInEffect: 'Please note that your insurance coverage is not yet in effect!',
    pleaseNoteThatBySubmittingThisFormYouAreOnlyRequestingAnInsuranceCoverageQuote: 'Please note that by submitting this form you are only requesting an insurance coverage quote. You are not yet purchasing coverages.',
    policyCancelTitle: 'You are about to cancel your monthly Participant insurance coverage subscription. <br>\n Please share the reason for your cancellation to help us improve!',
    policyPurchaseTitle: 'You successfully purchased a policy. <br> We wish you good health!',
    proofOfInsuranceForm: 'Proof of Insurance Request Form',
    previousClaimsHint: 'If an accident insurance program has been in force for your organization, please give full details for at least the past {numberOfTerms} complete policy terms',
    previousClaimsDisclaimer: 'Upon review, more detail may be requested',
    quoteAffectingChanges: 'Are you sure you want to apply these changes? All the quotes you may have received or selected for the current organization will have to be recalculated and picked by you again',
    quoteRejectionHeading: 'Quote rejection',
    quoteCancelattionHeading: 'Quote cancellation',
    quoteRejectionSubheading: 'Please tell us why this quote doesn’t work for you',
    quoteWillBeRejected: 'This quote will be rejected and you won\'t be able to purchase it anymore. Are you sure you want to reject it?',
    quoteWillBeReplaced: 'The quote you chose will replace the previously selected quote of this type. Are you sure you want to choose this one instead?',
    readFullCoverageDescription: 'Read full details of coverage',
    readASummaryOfBenefits: 'Read a Summary of the Benefits',
    readyForInsurance: 'Ready for on-demand insurance for your sport organization?',
    rejectionHint: 'We\'d be grateful if you specified your reasons for rejecting this quote',
    registerWithoutAQuote: 'The organization you\'re filling out application for can\'t have any more coverages for now, so your purchase can\'t be completed. Would you like to finish your registration anyway?',
    removeItemFromCartConfirm: 'Are you sure you want to remove this item from cart?',
    removePaymentMethodConfirm: 'Are you sure you want to remove this payment method?',
    requestAccessToThisOrg: 'Would you like to request access to this organization?',
    requestOrgSuccess: "You have successfully requested access to this organization. Once you are approved by any of it's administrators, it will appear in your list of organizations",
    saveBankAccountConfirm: 'Do you want to save this bank account?',
    saveCreditCardConfirm: 'Do you want to save this credit card?',
    selectAllThenInsurancePolicies: 'Please select all the insurance policies you currently have in place',
    selectAnotherOrg: 'Are you sure you want to select another organization? All the quotes you may have received or selected for the previously selected organization will have to be recalculated and picked by you again',
    selectCoverageLimit: 'Select your coverage limit',
    selectOrg: 'Please, select organization',
    selectOrgForClaim: 'Please select an organization on behalf of which you want to file a claim.',
    selectOrgForFnol: 'Please select an organization on behalf of which you want to file a FNOL.',
    selectSportTypes: 'Please select all the sport types',
    signInToProceed: 'Sign in to proceed',
    slideToCoveragePrice: 'If required, move the slider to the preferred insurance limit',
    thankYou: 'A great big thank you',
    thanksForYourSubmission: 'Thanks for your submission!',
    thisCoversOrg: 'The Participant Accident Insurance policy covers a player or coach injured in the course of a sports and recreational activity.',
    toContinueToVerifyAch: 'To continue trying to verify the bank account, please reach out to us directly',
    unableToProvideQuotes: 'Sorry, but we are unable to suggest you any quotes for now. Please, stay close, we`re working on this.',
    verifyAchFirstSentence: 'To verify your account, enter the two micro deposits below. <br> If you don’t see them, they should arrive in 1 - 3 buisiness days.',
    verifyAchSecondSentence: 'Please be aware about the limit of 10 failed verification attemps. <br> Once this limit has been crossed, the bank account will be unable to be verified.',
    warning: 'Warning',
    weAppreciateYouGivingUsTheOpportunityToHelpYOu: 'We appreciate you giving us the opportunity to help you.',
    weHaveReceivedYourApplication: 'We have received your application',
    weveEmailedYouALinkToYourDashboard: 'We’ve emailed you a link to your dashboard where you can monitor the status of your quote and contact us with any questions.',
    weWillFindTheRightPolicyAtACompetitiveRate: 'We will find the right policy at a competitive rate',
    weWillReviewAndGetInTouchIfWeNeedMoreInfo: 'We will review and get in touch if we need more info',
    welcome: 'Welcome',
    welcomeBack: 'Welcome back',
    welcomeToPHC: 'Welcome to Player\'s Health Cover! Well take it from here.',
    whatKindCoverage: 'What kind of coverage are you looking for?',
    whatSports: 'What sports does your organization offer?',
    whenDoYourPolicyRenew: 'When does your policy renew?',
    whenDoYouWantCoverageStart: 'When would you like your coverage to start?',
    whichCarriers: 'Which carrier provides your coverage? | Which carriers provide your coverages?',
    youCanUseItForPayments: 'You\'ll be able to use it for subsequent purchases in Player\'s Health Cover',
    youCanSelectAsMany: 'You can select as many as you want',
    yourInsuranceQuoteWillBeBasedOnInfoDescribedBelow: 'Your insurance Quote will be based on the Information described below.',
    yourRoutingNumberIs: 'Your routing number is',
    yourQuoteEstimateIs: 'You quote estimate is',
    youWillBeAbleToUseItForSubsequestPurchases: "You'll be able to use it for subsequent purchases in Player's Health Cover",
  },
  ui: {
    addAnother: 'Add another',
    timeLeft: '{time} left',
    daysNum: '{days} day | {days} days',
    hoursNum: '{hours} hour | {hours} hours',
    checkYourInbox: 'Check your inbox',
    overnightCamp: 'Overnight camp',
    accessRequest: 'Access request',
    accidentMedicalExpenseBenefit: 'Accident medical expense benefit',
    accidentMedicalExpenseLimit: 'Accident Medical Expense Benefit Maximum Amount',
    accountHolderName: 'Account Holder Name',
    accountHolderType: 'Account Holder Type',
    accountNumber: 'Account Number',
    add: 'Add',
    addAdmins: 'Add admin',
    addClaim: 'Add claim',
    addLogo: 'Add logo',
    addManually: 'Add manually',
    addOrg: 'Add an organization',
    addOrganization: 'Add organization',
    addOrganizationShort: 'Add org.',
    addPhoto: 'Add photo',
    addPolicy: 'Add policy',
    address: 'Address',
    admins: 'Admins',
    aggregate: 'Aggregate',
    amount: 'Amount',
    amountNum: '{currency} Amount {number}',
    amountPaid: 'Amount paid',
    annual: 'Annual',
    annualPremium: 'Annual premium',
    annualPrice: 'Annual price',
    annually: 'Annually',
    addMoreQuotes: 'Add more quotes',
    addFnol: 'Add FNOL',
    apply: 'Apply',
    archiveClaims: 'Archive claim | Archive claims',
    archiveFnols: 'Archive FNOL | Archive FNOLs',
    archivingClaims: 'Archiving claims',
    archivingFnols: 'Archiving FNOLs',
    archivingReason: 'Archiving reason',
    athletesAge: 'Age of athletes',
    athletesOfAgeGroupWhoPlay: '{num} athlete of age group {group} who plays {sport} | {num} athletes of age group {group} who play {sport}',
    awaitingApproval: 'Awaiting approval',
    back: 'Back',
    basic: 'Basic',
    benefitSummary: 'Benefit summary',
    billing: 'Billing',
    billingAddress: 'Billing Address',
    biyearly: 'Every two years',
    businessAddress: 'Business address',
    buy: 'Buy',
    cancel: 'Cancel',
    cantRememberPassword: "Can't remember your password",
    carrier: 'Carrier',
    cartEmpty: "You don't have any items in your cart",
    changePassword: 'Change password',
    changePhoto: 'Change photo',
    checkInbox: 'Check your inbox',
    checkout: 'Checkout',
    choose: 'Choose',
    choosePlan: 'Choose the plan you like the most',
    chooseRegProvider: 'Choose your registration provider',
    chosen: 'Chosen',
    claims: 'Claims',
    claimInfo: 'Claim info',
    claimantInfo: 'Claimant info',
    certificates: 'Certificates',
    clear: 'Clear',
    clickRegProvider: 'Click on the logo of your registration provider',
    clickToProceedToCheckout: 'Click this button in order to proceed to checkout with the selected quotes',
    clickToProceedToDashboard: 'Click the button below to proceed to the dashboard',
    close: 'Close',
    concussionProtocol: 'Concussion protocol',
    confirm: 'Confirm',
    confirmExcludeSOA: 'Are you sure you want to exclude this administrator from the organization?',
    confirmOrgName: 'Is it the right name of your organization?',
    confirmation: 'Confirmation',
    congratulations: 'Congratulations',
    continue: 'Continue',
    continueToCheckout: 'Continue to checkout',
    coverage: 'Coverage',
    coverageDetails: 'Coverage details',
    createNewOrg: 'Create a new organization',
    createYourAccount: 'Create your account',
    creditCard: 'Credit card',
    date: 'Date',
    datesOfCamping: 'Dates of camping',
    dayCamp: 'Day camp',
    decrease: 'decrease',
    default: 'Default',
    defaultPaymentMethodChange: 'Default payment source change',
    details: 'Details',
    download: 'Download',
    downloadInfo: 'Download info',
    eCheck: 'E-Check',
    edit: 'Edit',
    editOrgInfo: 'Edit organization info',
    enterPaymentDetails: 'Enter your payment details',
    enterProviderName: 'Enter your provider name',
    escalateFnols: 'Escalate FNOL | Escalate FNOLs',
    estimatedPremium: 'Estimated {cycle} Premium',
    excluded: 'Excluded',
    expiration: 'Expiration',
    exportFnols: 'Export FNOLs',
    exportSelectedFnols: 'Export selected FNOL | Export selected FNOLs',
    exportSuccess: 'Successful export',
    fnols: 'FNOLs',
    fnolInfo: 'FNOL info',
    fnolEscalation: 'FNOL escalation',
    fnolExport: 'FNOL export',
    fullCoverageDetails: 'Full details of coverage',
    familyPlanMember: 'Family Member Plan',
    forgotPassword: 'Forgot password?',
    goBack: 'Go back',
    formalProtocols: 'Formal Protocols',
    fillOut: 'Fill Out',
    finish: 'Finish',
    general: 'General',
    policyAndProcedureQuestions: 'Policy & procedure questions',
    getQuotes: 'Get quotes',
    gotIt: 'Got it',
    goToOrganization: 'Go to organization | Go to organizations',
    hadClaimsInThreeYears: 'Has the organization had claims against them in last 3 years?',
    home: 'Home',
    homePage: 'Home page',
    iAgree: 'I Agree',
    iDisagree: 'I Disagree',
    increase: 'increase',
    injuryInfo: 'Injury info',
    instruction: 'Instruction | Instruction',
    insuranceInfo: 'Insurance info',
    invite: 'Invite',
    inviteSuccess: 'Success!',
    inviteSuccessDesc: 'You have accepted the admin invitation successfully!',
    letsGetStarted: 'Let\'s get started',
    limit: 'Limit',
    liability: 'Liability',
    liabilityLimit: 'Liability Limit',
    hiWelcomeBack: 'Hi, welcome back!',
    login: 'Login',
    logIn: 'Log in',
    logOut: 'Log out',
    mailingAddress: 'Mailing address',
    monthly: 'Monthly',
    monthlyPremium: 'Monthly premium',
    monthlyPrice: 'Monthly price',
    monthlyPremiumIs: 'The monthly premium is',
    moreInfo: 'More info',
    myProfile: 'My profile',
    numberOfAttempts: 'Number of failed attempts',
    numberOfFailedAttempts: 'Number of failed attempts',
    numberOfRemainingAttempts: 'Number of remaining attempts',
    needHelp: 'Need help?',
    next: 'Next',
    nextCoverage: 'Next coverage',
    numberOfMonths: 'Number of months in the season',
    newCustomer: 'New customer',
    no: 'No',
    noDataAvailable: 'No data available',
    notFound: 'Not found',
    noThanks: 'No, thanks',
    notifications: 'Notifications',
    nameOrg: 'Name organization',
    nameOfOrg: 'Name of organization',
    separateMailingAddress: 'Mailing address is different from the organization address',
    notSure: 'I\'m not sure',
    none: 'None',
    noClaimsYet: 'No claims yet',
    noPoliciesToProceed: 'Sorry, but you don\'t have any policies to proceed.',
    numberOfMembers: 'Number of members',
    coverageLimit: 'Coverage Limit',
    deductible: 'Deductible',
    occurrenceLimit: 'Per occurrence limit',
    occurrence: 'occurrence',
    ok: 'OK',
    orgAddress: 'Organization Address',
    organizations: 'Organizations',
    orgInfo: 'Organization info',
    orgName: 'Organization name',
    orgNotUpdated: 'Organization not updated.',
    orgSearchHint: 'Please enter the name of the organization you want to buy a policy for.',
    orgType: 'Organization type',
    orgSearchForFnolHint: 'Please enter the name of the organization you want to add a FNOL to',
    orgSearchForClaimHint: 'Please enter the name of the organization you want to add a claim to',
    other: 'Other',
    pageNotFound: 'The page could not be found',
    passwordRecovery: 'Password reset',
    paymentMethodChange: 'Payment source change',
    pendingQuote: 'Pending quote',
    perOccurrence: 'Per occurrence',
    pay: 'Pay',
    payment: 'Payment',
    paymentMethod: 'Payment method',
    personalize: 'Personalize',
    paymentSettings: 'Payment settings',
    percentCompleted: '{percent}% completed',
    policies: 'Policies',
    policy: 'Policy',
    policyCancellation: 'Policy cancellation',
    policyDetails: 'Policy details',
    policyFee: 'Policy fee',
    terrorismAmount: 'Terrorism fee',
    rpgAmount: 'RPG Amount',
    policyAdminAmount: 'Policy admin fee',
    policyTerm: 'Policy term',
    premium: 'Premium',
    premiumPrice: 'Premium Price',
    primaryContant: 'Primary contact',
    proceed: 'Proceed',
    proceedToCheckout: 'Proceed to checkout',
    proceedToPayment: 'Proceed to payment',
    profile: 'Profile',
    proofOfInsurance: 'Proof of Insurance',
    provider: 'Provider',
    purchase: 'Purchase',
    purchasedQuotes: 'Purchased quotes',
    purchasedPolicies: 'Purchased policies',
    quote: 'Quote',
    quoteEstimate: 'Quote estimate',
    quotes: 'Quotes',
    quotesSelected: 'Quotes selected',
    quoteRejection: 'Quote rejection',
    quoteReplacement: 'Quote replacement',
    rateUnavailable: 'Rate unavailable',
    recommended: 'Recommended',
    reject: 'Reject',
    rejected: 'Rejected',
    reset: 'Reset',
    required: 'Required',
    resend: 'Resend',
    review: 'Review',
    resendActivationEmail: 'Resend activation email',
    resendConfirmationEmail: 'Resend confirmation email',
    returningCustomer: 'Returning customer',
    requestAccess: 'Request access',
    requestPOI: 'Request proof of insurance',
    requestPOIShort: 'Request proof',
    requestedStartDate: 'Requested start date',
    riskManagement: 'Risk management',
    routingNumber: 'Routing Number',
    save: 'Save',
    search: 'Search',
    searchForOrg: 'Start typing and see suggestions',
    securityCode: 'Security Code',
    select: 'Select',
    selectAll: 'Select all',
    selectCoverageType: 'Select coverage type | Select coverage type | Select coverage types',
    sexualAbuseLiability: 'Sexual abuse liability',
    sorry: 'Sorry',
    selectBgCheckProvider: 'Select your background check provider',
    selectInsuranceType: 'Select type of insurance you need for your organization',
    selectOrgType: 'Select organization type',
    selectQuotesForOrg: 'Select quotes for your organization',
    sendPayment: 'Send Payment',
    shoppingCart: 'Shopping cart',
    signIn: 'Sign in',
    signUp: 'Sign up',
    stampingFee: 'Stamping fee',
    startDate: 'Start date',
    stepOf: 'Step {step} of {total}',
    submitForQuote: 'Submit for quote',
    summaryOfTheBenefits: 'Participant Accident Summary of Benefits',
    surplusLines: 'Surplus lines',
    tax: 'Tax',
    temporary: 'Temporary',
    termsAndServices: 'Terms and services',
    termsAndConditions: 'Terms and conditions',
    toDashboard: 'To your dashboard',
    total: 'Total',
    totalAnnualPremium: 'Total annual premium',
    totalPremium: 'Total premium',
    type: 'Type',
    upTo: 'Up to',
    update: 'update',
    uploadAvatar: 'Upload avatar',
    uploadProtocolTemplate: 'Upload the protocol template',
    uploadTheFileReport: 'Upload the file report',
    uploadTheLostReport: 'Upload the lost report',
    userInfo: 'User information',
    viewMore: 'View more',
    verify: 'Verify',
    verifyBankAccount: 'Verify your Bank Account',
    verifyYourBankAccount: 'Verify your Bank Account',
    whatAreYouCoveredFor: 'What are you covered for',
    whatIs: 'What is {text}?',
    whatIsNotCovered: 'What is not covered',
    whatSportsDoesYourOrgOffer: 'What sports does your sports organization offer',
    welcome: 'Welcome',
    yearly: 'Every year',
    yes: 'Yes',
    youAreAlreadyHere: "You're already here!",
    youPurchased: 'You purchased',
    yourCart: 'Your cart',
    yourContact: 'Your contact',
    noAdditionalInsureds: 'No additional insureds',
  },
  limits: {
    aggregate: 'Aggregate',
    occurrence: 'Occurrence',
    personalAndAdvertisingInjury: 'Personal & Advertising Injury',
    productsAndCompletedOps: 'Products & Completed Ops',
    damageToPremiseRented: 'Damage To Premise Rented',
    medicalPayments: 'Medical Payments',
    participantLiability: 'Participant Liability',
  },
  fields: {
    numberOfTeams: 'Number of teams',
    tournamentAddress: 'Tournament address',
    ageGroup1: '12 and under',
    ageGroup1Short: '<12',
    ageGroup2: '13 to 15 years old',
    ageGroup2Short: '13 to 15',
    ageGroup3: '16 to 18 years old',
    ageGroup3Short: '16 to 18',
    ageGroup4: '19 and older',
    ageGroup4Short: '19>',
    amountPaid: 'Amount paid',
    addLocation: 'Add location',
    addressOfTeam: 'Address of team/club',
    accidentDate: 'Accident date',
    accidentOccuranceDate: 'Accident occurrence date',
    addNameOfAdditionalInsured: 'Add name of additional insured',
    accidentDescription: 'Describe fully how the accident happened',
    accidentTime: 'Accident time',
    accountNumber: 'Account number',
    activityType: 'Type of sport activity',
    address: 'Address',
    admittanceDate: 'Date of admittance',
    admittanceTime: 'Time of admittance',
    athletes: 'Athlete participants',
    athletesAgeGroups: 'Number of athletes per age group',
    bankAccountName: 'Name of bank account',
    bankName: 'Bank name',
    bodyPart: 'Body part',
    cardHolderName: 'Card holder name',
    cardNumber: 'Credit card number',
    carrier: 'Carrier',
    certificateNumber: 'Certificate number',
    claimDetails: 'Claim details',
    coaches: 'Coaches',
    country: 'Country',
    city: 'City',
    claimantName: 'Name of claimant',
    cvv: 'CVV',
    coverageStartDate: 'Coverage start date',
    coverageType: 'Coverage type',
    date: 'Date',
    dateOfClaim: 'Date of claim',
    dateOfBirth: 'Date of birth',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    email: 'Email',
    eventStartDate: 'Event start date',
    eventEndDate: 'Event end date',
    eventName: 'Event name',
    eventLocation: 'Event location',
    employerName: 'Name of employer',
    endDate: 'End date',
    enterRole: 'Enter your role',
    expirationDate: 'Expiration date',
    female: 'Female',
    fromStartDateToEndDate: 'From start date to end date',
    fullName: 'Full name',
    gender: 'Gender',
    guardianName: 'Name of guardian',
    hospitalName: 'Name of hospital taken to',
    incidentDescription: 'Incident description',
    injuryLocation: 'Location of incident / Occurence',
    injuryReport: 'Injury report',
    injuryType: 'Type of injury',
    isInjuredPersonCovered: 'The Injured Person is covered under any other insurance plans',
    isInjuredPersonDependent: 'Is the Injured Person under 18 or otherwise dependent?',
    isPolicyHolderJurisdiction: 'The Insured Person is under the jurisdiction of Policyholder',
    isWitness: 'He/she was a witness to',
    month: 'Month',
    male: 'Male',
    nameOfAdditionalInsured: 'Name of additional insured',
    additionalInsured: 'Additional Insured',
    nameOfTeamClub: 'Name of team/club',
    nameOfPhysical: 'Name of physical',
    newPassword: 'New password',
    number: 'Number',
    numberOfClaims: 'Number of claims',
    nameOnCard: 'Name on Card',
    oldPassword: 'Old password',
    officials: 'Trainers/Managers',
    orgMembers: 'Organization members',
    orgName: 'Organization name',
    otherInsuranceName: 'Name of other insurance company',
    password: 'Password',
    passwordConfirmation: 'Confirm password',
    permanent: 'Permanent',
    phone: 'Phone',
    policyEndDate: 'Policy end date',
    policyStartDate: 'Policy start date',
    policyTermStartDate: 'Policy term start date',
    policyTermEndDate: 'Policy term end date',
    rejectionReason: 'Rejection reason',
    reporterName: 'Name of reporter',
    requestedCoverageStartDate: 'Requested coverage start date',
    role: 'Role',
    routingNumber: 'Routing number',
    singleName: 'Name',
    site: 'Website',
    signature: 'Signature',
    socialSecurity: 'Social Security or Student Visa',
    startTyping: 'Start typing ...',
    startDate: 'Start date',
    stateOrProvince: 'State/Province',
    street: 'Street',
    state: 'State',
    streetAndBd: 'Street and building',
    supervisorName: 'Name of supervisor of activity',
    totalAnnualPremium: 'Total annual premium, {currency}',
    totalAnnualPaidClaims: 'Total annual paid claims, {currency}',
    totalPaidClaims: 'Total paid claims, {currency}',
    totalPremium: 'Total premium, {currency}',
    totalNumberOfIncurredClaims: 'Total number of incurred claims',
    treatmentDate: 'Date of first treatment',
    type: 'Type',
    trainersAndManagers: 'Trainers/Managers',
    volunteers: 'Volunteers',
    year: 'Year',
    yourOrg: 'Your organization',
    zip: 'ZIP',
  },
  items: {
    active: 'Active',
    activeClaims: 'Active claims',
    activeFnols: "Active FNOL's",
    allClaims: 'All claims',
    allFnols: "All FNOL's",
    allOrganizations: 'All organizations',
    allCoverages: 'All coverages',
    allPolicies: 'All policies',
    allStatuses: 'All statuses',
    allTypes: 'All types',
    annually: 'Annually',
    archivedClaims: 'Archived claims',
    archivedFnols: "Archived FNOL's",
    association: 'Association',
    bodilyInjury: 'Bodily Injury',
    claimant: 'Claimant',
    company: 'Company',
    individual: 'Individual',
    completeStatus: 'Complete',
    archiveStatus: 'Archived',
    date: 'Date',
    expired: 'Expired',
    iHaveCoverage: 'I have coverage',
    inactive: 'Inactive',
    incompleteStatus: 'Incomplete',
    injury: 'Injury',
    injuryType: 'Injury type',
    insurance: 'Insurance',
    league: 'League',
    monthly: 'Monthly',
    notTheRightCoverForMe: 'Not the right cover for me',
    org: 'Organization',
    organization: 'Organization',
    pendingStatus: 'Pending',
    pendingQuote: 'Pending...',
    personalInjury: 'Personal Injury',
    policyNumber: 'Policy number',
    price: 'Price',
    propertyDamage: 'Property Damage',
    school: 'School',
    sportsClub: 'Sports club',
    status: 'Status',
    tournament: 'Tournament',
    tooManyQuotes: 'Too many quotes',
  },
  response: {
    success: {
      archiveFnols: 'You have successfully archived the selected FNOLs',
      archiveClaims: 'You have successfully archived the selected claims',
      claimCreated: 'Claim successfully created',
      claimUpdated: 'Claim successfully updated',
      emailConfirmation: 'You have successfully confirmed your email and signed up',
      emailConfirmationSendTo: 'We’ve sent a confirmation email to',
      emailConfirmationResend: 'We have resent the confirmation email to {email}. Please check your inbox once again',
      emailChangeConfirmation: 'You have successfully confirmed your email and changed it',
      escalateFnols: 'You have successfully escalated the selected FNOLs',
      exportFnols: 'You have successfully exported the selected FNOLs. Follow this link to download your export file: <a href="{link}" target="_blank">Exported FNOLs</a>',
      fnolCreated: 'FNOL successfully created',
      fnolUpdate: 'FNOL successfully updated',
      passwordReset: 'If the email address you entered is in our system, you will receive an email with a password reset link.',
      passwordUpdate: 'Your password has been updated',
      paymentSent: 'Payment has been successfully sent',
      poiRequest: 'You have successfully requested a proof of insurance',
      policyCancellation: 'This policy will no longer be automatically prolonged',
      profileUpdateSuccess: 'Profile successfully updated',
      profileUpdateSuccessWithEmailConfirmation: 'An email has been sent to <strong>{email}</strong>. Please check your inbox to verify this change. You may need to check your spam/junk folder. Upon verification, any communication will be sent to this address.',
      success: 'Success',
    },
    error: {
      accountNumberIsRequired: 'Account number is required',
      accountNumbersDoesntMatch: "The Account numbers don't match",
      amountError: 'Amount should be less than the cost',
      archiveClaims: 'There was an error while archiving your claims',
      archiveFnols: 'There was an error while archiving your FNOLs',
      athletesAgeFormMissing: "The athletesAgeForm element wasn't present in the current component",
      athletesNumberExceed: 'It appears the number of athletes in your organization exceeds <strong>{limit}</strong> and we are currently unable to provide coverage. Our specialists will contact you shortly',
      authenticationError: 'Authentication error',
      claimCreated: 'There was an error while creating the claim',
      claimUpdated: 'There was an error while updating the claim',
      confirmationError: 'Confirmation error',
      confirmationErrorDescription: 'There has been an issue confirming your registration',
      createAccount: 'There has been an error while creating your account',
      error: 'Error',
      escalateFnols: 'There was an error while escalating your FNOLs',
      emailChangeConfirmationErrorDescription: 'There has been an issue confirming your change of email',
      fetchClaim: 'There was an error while fetching your claim',
      fetchClaims: 'There was an error while fetching claims',
      fetchFnol: 'There was an error while fetching FNOL',
      fetchFnols: 'There was an error while fetching FNOLs',
      fetchQuotes: 'There was an error while fetching your quotes',
      fetchSubmission: 'There was an error while fetching your quotes',
      fileMaxSize: 'The uploaded file exceeds the maximum upload size ({size} MB)',
      fileShouldBeOneOfFollowingFormats: 'The file should be one of following formats: {allowedTypes}',
      fileUploadError: "The file size is more than 20MB or doesn't belong to .jpeg, .jpg or .png types",
      filterClaims: 'There was an error applying your filters to the claims',
      filterFnols: 'There was an error applying your filters to the FNOLs',
      fnolCreated: 'There was an error while creating the FNOL',
      fnolUpdate: 'There was an error while updating the FNOL',
      formInvalid: 'The form is not valid',
      gReCaptchaFraud: 'We have spotted suspicious activity on the page. Please <a href="mailto:{email}">contact support</a> to perform this action.',
      imageFileError: "The file size is more than 20MB or doesn't belong to .jpeg, .jpg or .png types",
      incidentLocationMissing: 'Please, select location of incedent',
      invitationLinkInvalid: 'The invitation link you\'ve followed is invalid or expired',
      loginError: 'Wrong username or password',
      nothingToSave: 'Nothing to save',
      orgCreateError: 'There was an error creating your organization',
      orgUpdateError: 'There was an error while updating your organization',
      orgFetchError: 'There was an error while fetching data for your organization.',
      orgAlreadyExists: 'The organization you\'re trying to create already exists',
      orgAlreadyExistsShort: 'The organization already exists',
      orgCreateFromSearch: 'There was an error while creating your organization out of a search result. Please create your organization manually and return to this step',
      orgNotChosen: "The organization wasn't selected",
      orgNotFound: "The organization wasn't found",
      passwordReset: 'There was an error while resetting your password',
      policyCancellation: 'There was an error while cancelling your policy subscription',
      quoteRejectionError: 'There was an error while rejecting a quote',
      smthWrong: 'Something went wrong',
      soaApproval: 'Something went wrong with approval',
      soaRejection: 'Something went wrong with rejection',
      sorryWeAccept: 'Sorry, we accept {allowedTypes} files only',
      validationError: 'Validation error',
      wrongApproval: 'Something went wrong with approval',
      wrongRejection: 'Something went wrong with rejection',
    },
  },
  notifications: {
    RequestSOA: '<strong>{userName}</strong> requested access into {orgName}',
    RequestApproved: 'You have been granted access to <strong>{orgName}</strong>',
    RequestRejected: 'You have been denied access to <strong>{orgName}</strong>',
    PolicyExpired: 'Your {coverageName} coverage for <strong>{orgName}</strong> expires on {policyExpirationDate}',
    PolicyProlonged: 'Your {coverageName} coverage for <strong>{orgName}</strong> has been prolonged till {policyExpirationDate}',
    PolicyCanceled: 'Your {coverageName} coverage for <strong>{orgName}</strong> has been canceled',
    PolicyPurchased: 'You have acquired {coverageName} coverage for <strong>{orgName}</strong>',
    PolicyPDF: 'Your policy file for {coverageName} coverage for <strong>{orgName}</strong> is ready',
    ProofPDF: 'Your certificate file for <strong>{orgName}</strong> is ready',
    ProtocolFilesMissing: 'The protocol files you have uploaded for <strong>{orgName}</strong> went missing',
    FnolCreated: 'A FNOL was created for <strong>{orgName}</strong>',
    FnolUpdated: 'A FNOL for <strong>{orgName}</strong> was updated',
    FnolEscalated: 'A FNOL for <strong>{orgName}</strong> was escalated',
    YourQuoteIsReadyToReview: 'Your quote is ready to review',
    QuoteExpirationSoon: 'Your quote for {coverageName} coverage for {orgName} is going to expire soon, 24 hours left. You’ll be required to order quotes again, don’t miss it! | Your quotes for {coverageName} coverage for {orgName} are going to expire soon, 24 hours left. You’ll be required to order quotes again, don’t miss it!',
    QuoteExpirationJust: 'Sorry, your quote for {orgName} has expired. Would you like to order new? | Sorry, your quotes for {orgName} have expired. Would you like to order new?',
  },
  validation: {
    charactersExceed: 'Must not exceed {number} characters',
    cityBeAValid: 'Must be a valid city name',
    fieldNotValid: 'This field is not valid',
    fieldRequired: 'This field is required',
    integerOnly: 'The value must be a number',
    locationRequired: 'The location is required',
    minTotalNumberOfAthletes: 'The total number of athletes should be {number} or greater',
    mustBeCharAndHaveLength: '{name} must be {char} characters long',
    mustBeCharAndLonger: '{name} must be {char} characters or longer',
    mustBeCharAndLongerAndValid: '{name} must be {char} characters or longer and valid',
    mustBeLess: '{name} must be less than {num} characters',
    mustBeNumberAndHaveLength: '{name} must have {char} digits',
    mustBeValidEmail: 'Must be a valid email',
    mustBeValidZipOrPostalCode: 'Must be a valid US Zip Code or CA Postal code',
    mustContainAtLeast: '{name} must be at least {num} characters',
    mustHaveMoneyFormat: 'The field must have money format',
    orgNameRequired: 'The organization name is required',
    password: 'Password must contain <ul><li>no less than 8 characters;</li><li>include at least 1 uppercase letter;</li><li>at least 1 number;</li><li>and a special character.</li></ul>',
    passwordDontMatch: "The passwords don't match",
    passwordIsRequired: 'Password is required',
    passwordMustNotContainSpaces: 'The password must not contain spaces',
    passwordRules: 'The password must be no less than 8 characters long, contain a capital letter, a special symbol and a number',
    phoneMustHaveFormat: "Phone must adhere to the '+X XXX XXX XX XX' format",
    protocolTemplateRequired: 'The protocol template is required',
    realisticNumber: 'The field must be a realistic number',
    slugIsNotValid: '{slug} is not valid',
  },
  particles: {
    with: 'with',
    or: 'or',
    to: 'to',
  },
  steps: {
    basic: 'Basic Info',
    coverage: 'Coverage',
    personalize: 'Personalize',
    quote: 'Quote',
    checkout: 'Checkout',
  },
  stripe: {
    address: 'Address',
    cardHolderName: 'Card Holder Name',
    expirationDate: 'Expiration Date',
    numberCard: 'Number Card',
  },
  bank: {
    accountHolderName: 'Account Holder Name',
    accountHolderType: 'Account Holder Type',
    accountNumber: 'Account Number',
    bankAccount: 'Bank account',
    debitCard: 'Debit card',
    routingNumber: 'Routing Number',
  },
};
