export default {
  rejectQuote: `mutation ($id: ID!, $requesterEmail: EmailAddress, $reason: String) {
    quoteReject(id: $id, requesterEmail: $requesterEmail, reason: $reason) {
      code,
      message,
    }
  }`,
  rejectQuoteOffer: `mutation ($id: ID!, $reason: String) {
    quoteOfferReject(id: $id, reason: $reason) {
      code,
      message,
    }
  }`,
  cancelQuoteOffer: `mutation ($id: ID!, $reason: String) {
    quoteOfferCancel(id: $id, reason: $reason) {
      code,
      message,
    }
  }`,
  submission: `mutation ($input: SubmissionInput!) {
                  submission(input: $input) {
                    id,
                    limits,
                    startedAt,
                    expiredAt,
                    organization {
                      id,
                      name,
                    },
                    coverage {
                      id,
                      name,
                      type,
                    },
                    subcoverages {
                      coverage {
                        id,
                        name,
                        limits,
                        isOptional
                      },
                      limit,
                    },
                    limit,
                    offers {
                      id,
                      carrier {
                        id,
                        name
                      },
                      organization {
                        id,
                        name,
                      },
                      isRejected,
                      currency,
                      quotes {
                        coverageType,
                        id,
                        limit,
                        annualCost,
                        monthlyCost,
                        barePremiumAmount,
                        rpgAmount,
                        stampingAmount,
                        taxAmount,
                        discount,
                        isRejected,
                        isInCart,
                        policyStartedAt,
                        policyExpiredAt,
                      }
                    }
                  }
                }`,
};
