<template lang="html">
  <v-toolbar-items :class="b()" id="appToolbarList">
      <template v-for="(item, index) in items">
        <v-btn
          :key="'item_' + index"
          :class="b('btn', userIsSoa ? '' : 'no-decoration')"
          :exact="item.exact"
          :to="item.to"
          :uppercase="false"
          ripple
          text
        >
          <div class="nav-link-decoration"></div>
          <span class="v-btn__content-span" :title="item.text">{{ item.text }}</span>
        </v-btn>
      </template>
    </v-toolbar-items>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getMenuItemsByRoles, mapArrayObjectsWithTranslation } from '@/utils/helpers';

export default {
  block: 'appToolbarMenu',
  data() {
    return {
      confirmSignOut: false,
      menuItems: getMenuItemsByRoles(),
    };
  },
  computed: {
    ...mapGetters('user', ['userIsSoa']),
    clipped() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    drawer: {
      get() {
        return this.$store.state.layout.showingMenu;
      },
      set(val) {
        this.$store.commit(val ? 'layout/SHOW_MENU' : 'layout/HIDE_MENU');
      },
    },
    items() {
      let items = this.menuItems.guest && this.menuItems.guest.items instanceof Array ? this.menuItems.guest.items : [];
      if (this.userIsSoa && this.menuItems.soa && this.menuItems.soa.items instanceof Array) {
        items = this.menuItems.soa.items;
      }
      return mapArrayObjectsWithTranslation.call(this, items);
    },
  },
  methods: {
    ...mapActions('layout', ['hideMenu']),
  },
  mounted() {
    this.hideMenu();
  },
};
</script>

<style lang="scss">
$block: 'appToolbarMenu';

.#{$block} {
  &__btn {
    &.v-btn {
      .nav-link-decoration {
        display: none;
      }
      .v-btn__content::before {
        display: none;
      }
      .v-btn__content-span {
        display: inline-block;
        text-align: center;
        font-weight: 700;
        font-family: $antarctica;
        font-size: 11px;
        color: $ph-dark;
        &::after {
          display: block;
          content: attr(title);
          font-weight: 700;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
      &--active:not(.no-decoration) {
        .nav-link-decoration {
          display: inline;
          position: absolute;
          top: -33px;
          height: 2px;
          width: 110%;
          background-color: $ph-accent;
        }
        .v-btn__content-span {
          color: $ph-accent;
          &::after {
            color: $ph-accent;
          }
        }
      }
    }
  }
}
</style>
