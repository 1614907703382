import { isValidString } from '@/utils/helpers/string';
import { inputRules } from '@/utils/helpers';
import { CUSTOM_ICONS, DEFAULT_SELECT_CARET_ICON } from './constants';

export const getCustomIconProps = ({ appendCustomIcon }) => {
  const outputProps = {};
  const customIconAliases = {
    [CUSTOM_ICONS.CALENDAR]: 'mdi-calendar',
    [CUSTOM_ICONS.CLOCK_PURPLE]: 'mdi-clock--purple',
    [CUSTOM_ICONS.SEARCH_ICON]: 'mdi-search-icon',
  };
  if (customIconAliases[appendCustomIcon]) {
    outputProps.appendIcon = customIconAliases[appendCustomIcon];
  }
  return outputProps;
};
export const getDefaultSelectProps = () => ({
  appendIcon: {
    type: String,
    default: DEFAULT_SELECT_CARET_ICON,
  },
  attach: {
    type: Boolean,
    default: true,
  },
  label: String,
  placeholder: String,
  required: Boolean,
  dense: Boolean,
  outlined: {
    type: Boolean,
    default: true,
  },
  rules: {
    type: Array,
    default: () => [],
  },
});

export const getRequiredInputProps = (
  {
    required, label, placeholder, rules = [], ignoreDefaultRules,
  },
  { transformLabel = true, transformPlaceholder = true } = {},
) => {
  const REQUIRED_POSTFIX = '*';
  const outputProps = {
    label,
    placeholder,
    rules,
  };
  if (transformLabel && required && isValidString(label)) {
    outputProps.label = `${label}${REQUIRED_POSTFIX}`;
  }
  if (transformPlaceholder && required && isValidString(placeholder)) {
    outputProps.placeholder = `${placeholder}${REQUIRED_POSTFIX}`;
  }
  // check if "rules" doesn't have the "required" input rule already
  if (!ignoreDefaultRules && required && !outputProps.rules.some((rule) => rule === inputRules.required[0])) {
    outputProps.rules.unshift(inputRules.required[0]);
  }
  return outputProps;
};
