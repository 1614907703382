export default (ageMap, orgSports) => {
  if (ageMap && ageMap instanceof Object && orgSports && orgSports instanceof Array) {
    const orgSportsMap = orgSports.reduce((acc, currVal) => {
      if (currVal && currVal instanceof Object && currVal.sport) {
        const { sport, ...otherFields } = currVal;
        acc[sport instanceof Object ? sport.id : sport] = otherFields;
      }
      return acc;
    }, {});
    if (Object.keys(ageMap).length === Object.keys(orgSportsMap).length) {
      let different = false;
      // eslint-disable-next-line
      for (let key in orgSportsMap) {
        if (ageMap[key] && ageMap[key] instanceof Object) {
          // eslint-disable-next-line
          for (let field in orgSportsMap[key]) {
            if (!ageMap[key][field] || ageMap[key][field] != orgSportsMap[key][field]) {
              different = true;
            }
          }
        } else {
          different = true;
        }
      }
      return different;
    }
    return true;
  }
  throw new Error('Invalid arguments');
};
