export const SOA = {
  COVERAGE_CHOICE: 'OrgPreferredCoverage',
  SIGNUP_CART_UPDATE: 'UpdateUnregisteredCart',
  PAGE_VIEW: 'PageView',
  PAGE_ENTER: 'PageEnter',
  PAGE_LEAVE: 'PageLeave',
  PROFILE_STARTED: 'ProfileStarted',
  PROVIDER_CHOICE: 'ProviderChoice',
  REGISTRATION_SPENT_TIME: 'RegistrationSpentTime',
  REGISTRATION_STEP_ENTER: 'RegistrationStepEnter',
  REGISTRATION_STEP_LEAVE: 'RegistrationStepLeave',
  ORG_APPLICATION_STEP_ENTER: 'OrgApplicationStepEnter',
  ORG_APPLICATION_STEP_LEAVE: 'OrgApplicationStepLeave',
  EMAIL_CONFIRMED: 'EmailConfirmed',
  PROFILE_CREATION: 'ProfileCreation',
};
