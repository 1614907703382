import Vue from 'vue';
import EventBus from 'vue-events';
import Vuebar from 'vuebar';
import VueYouTubeEmbed from 'vue-youtube-embed';
import vueBemCn from 'vue-bem-cn';

// BEM class names generator
Vue.use(vueBemCn);

// custom scrollbar (v-bar)
Vue.use(Vuebar);

// Event bus
// @see https://github.com/cklmercer/vue-events
Vue.use(EventBus);

// vue-youtube-embed
Vue.use(VueYouTubeEmbed);
