export default {
  SET_CARRIERS(context, crs) {
    context.carriers = crs;
  },
  SET_COVERAGES(context, cvgs) {
    context.coverages = cvgs;
  },
  SET_CARRIER_COVERAGE_INFO(context, { carrierId, coverages }) {
    context.carrierCoverageInfo = {
      ...context.carrierCoverageInfo,
      [carrierId]: coverages.reduce((acc, { type, ...coverage }) => {
        acc[type] = {
          type,
          ...coverage,
        };
        return acc;
      }, {}),
    };
  },
};
