import Component from '@/pages/soa/fnol/FnolAdd';

const route = {
  name: 'fnolAdd',
  path: 'fnol/add',
  props: true,
  component: Component,
  meta: {},
};

export default route;
