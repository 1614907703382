import ServiceProvider from '../provider';
import QUERIES from './queries';
import MUTATIONS from './mutations';

class ParamProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  paramsQuery(filters = []) {
    return this.query(this.queries.general, { filters }, { noAuth: true });
  }

  sportsQuery() {
    return this.query(this.queries.sports, null, { noAuth: true });
  }

  numberTeamsLeagueQuery() {
    return this.query(this.queries.numberTeamsLeague, null, { noAuth: true });
  }

  citiesQuery(queryString) {
    const filters = ['city'];
    if (typeof queryString === 'string' && queryString.length) {
      filters.push(queryString);
    }
    return this.query(this.queries.general, { filters }, { noAuth: true });
  }

  statesQuery(queryString) {
    const filters = [];
    if (typeof queryString === 'string' && queryString.length) {
      filters.push(queryString);
    }
    return this.query(this.queries.states, { filters }, { noAuth: true });
  }

  countriesQuery(queryString) {
    const filters = [];
    if (typeof queryString === 'string' && queryString.length) {
      filters.push(queryString);
    }
    return this.query(this.queries.countries, { filters }, { noAuth: true });
  }

  bodyPartsQuery() {
    return this.query(this.queries.general, { filters: ['bodyPart'] }, { noAuth: true });
  }

  injuryTypesQuery() {
    return this.query(this.queries.general, { filters: ['injuryType'] }, { noAuth: true });
  }

  activityTypesQuery() {
    return this.query(this.queries.general, { filters: ['activityType'] }, { noAuth: true });
  }

  orgTypesQuery() {
    return this.query(this.queries.orgTypes, { noAuth: true });
  }

  regProvidersQuery() {
    return this.query(this.queries.regProviders);
  }

  bgCheckProvidersQuery() {
    return this.query(this.queries.bgCheckProviders);
  }

  generalParamUpdateMutation(paramId, variables) {
    const updateObj = { updateId: paramId, input: { ...variables } };
    return this.mutate(this.mutations.updateGeneral, updateObj);
  }
}

export default new ParamProvider(QUERIES, MUTATIONS);
