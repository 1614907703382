import { TOURNAMENT_ORG_TYPE_ID } from '@/utils/constants';
import { inputRules } from '@/utils/helpers';

export default (state, getters, rootState, rootGetters) => Object.entries(getters.signUpSteps).reduce((acc, stepEntry) => {
  if (stepEntry[0] === 'basic') {
    acc[stepEntry[0]] = stepEntry[1].reduce((subAcc, subStep) => {
      subAcc[subStep] = false;
      if (subStep === 'orgType') {
        subAcc[subStep] = !!getters.selectedOrgType || (getters.selectedOrgType === TOURNAMENT_ORG_TYPE_ID && !getters.eventTimeValid);
      } else if (subStep === 'orgChoice') {
        if (getters.createNewOrgShown) {
          subAcc[subStep] = getters.createNewOrgFormValid;
        } else {
          subAcc[subStep] = !!getters.selectedOrg || getters.enableOrgSearchBtn;
        }
      } else if (subStep === 'orgSports') {
        subAcc[subStep] = !!getters.selectedSports.length;
      } else if (subStep === 'orgPersonnel') {
        subAcc[subStep] = (getters.orgPersonnelForm.athletes !== ''
          && getters.orgPersonnelForm.coaches !== ''
          && getters.orgPersonnelForm.volunteers !== ''
          && getters.orgPersonnelForm.officials !== '');
      } else if (subStep === 'orgAgeGroups') {
        subAcc[subStep] = getters.athletesAgeBySportsValid;
      } else if (subStep === 'orgMonthsInSeason') {
        subAcc[subStep] = true;
      } else if (subStep === 'userInfo') {
        subAcc[subStep] = subAcc[subStep] = Boolean((getters.userInfoForm.name && inputRules.firstName[0](getters.userInfoForm.name) === true)
          && (getters.userInfoForm.email && inputRules.email[0](getters.userInfoForm.email) === true)
          && (getters.userInfoForm.soaRole && inputRules.role[0](getters.userInfoForm.soaRole) === true)
          && (!getters.userInfoForm.phone || inputRules.phone[0](getters.userInfoForm.phone) === true)
          && (getters.userInfoForm.password && inputRules.password[0](getters.userInfoForm.password) === true && getters.userInfoForm.password === getters.userInfoForm.password_confirmation));
      }
      return subAcc;
    }, {});
  }
  return acc;
}, {});
