import moment from 'moment';

export default (org, policies, activeOnly = false) => {
  if (org && org instanceof Object && org.id) {
    if (policies instanceof Array && policies.length) {
      const momentNow = activeOnly ? moment() : null;
      return policies.reduce((acc, pol) => {
        if (pol.organization && pol.organization.id === org.id && (!momentNow || momentNow.isBefore(pol.expiredAt))) {
          acc.push({
            ...pol,
            type: (pol.quote && pol.quote.coverageType) || '',
          });
        }
        return acc;
      }, []);
    }
    return [];
  }
};
