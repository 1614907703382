import { PERSONNEL_OBJECT } from '@/store/modules/signUp/constants/index';

export default () => ({
  name: null,
  address: {
    address: '',
    city: null,
    state: null,
    country: null,
    zip: '',
  },
  email: null,
  site: null,
  phone: null,
  backgroundCheck: 'none',
  backgroundProvider: null,
  concussionProtocol: null,
  icon: null,
  id: null,
  injuryProtocol: null,
  isCompleted: null,
  otherBackgroundProvider: null,
  otherProvider: null,
  externalCoverages: [],
  personnel: { ...PERSONNEL_OBJECT },
  params: {},
  previousClaims: [],
  placeId: null,
  provider: null,
  sport: [],
  sports: [],
  type: null,
  waiverProtocol: null,
});
