import store from '@/store';
import { ServiceProvider } from '@/graphql/providers';

ServiceProvider.on('errorHandlerPost', (err) => {
  if (err.response && err.response.status === 401) {
    store.dispatch('user/forceLogout');
  }
});
ServiceProvider.on('beforeHandlePostResponse', (response) => {
});
