export default {
  auth: `query UserAuth($email: EmailAddress!, $password: String!) {
              auth(email:$email, password:$password){
                id,
                name,
                token
              }
           }`,
  me: `{
           me {
            id,
            token,
            name,
            icon,
            role,
            soaRole,
            address,
            email,
            phone,
            signature,
            stripeCardObject,
            stripeBankObject,
            stripeBankObjects,
            defaultPaymentMethod,
            locale,
            notifications {
              id,
              type,
              message,
              createdAt,
            },
            timezone,
           }
         }`,
  userPolicies: `{
           me {
            policies {
              id,
              isCanceled,
              isPaid,
              isProofAvailable,
              carrier {
                id,
              },
              createdAt,
              code,
              codeCarrier,
              coverage {
                carrierParams,
              },
              creator {
                id,
                name
              },
              policyFile,
              policyPreview,
              expiredAt,
              organization {
                id,
                name,
                type,
                address {
                  address,
                  address2,
                  city,
                  state,
                  country,
                  zip
                }
              },
              paymentCycle,
              paymentSource,
              quote {
                id,
                coverageType,
                coverageName,
                annualCost,
                monthlyCost
              },
              startedAt
            },
           }
         }`,
  userProofs: `{
           me {
            proofs {
              id,
              policies {
                id,
                policyFile,
                policyPreview,
                quote {
                  id,
                  coverageType,
                  coverageName
                },
              },
              organization {
                id,
                name
              },
              coverageType,
              clubName,
              clubAddress,
              contactName,
              contactEmail,
              contactPhone,
              eventName,
              eventLocation,
              eventStartDate,
              eventEndDate,
              additionalInsured,
              isArchived,
              documentUrl,
              documentPreviewMini,
              documentPreviewMidi,
            },
           }
         }`,
  countryCode: `query GetLocationCountryCode {
                  countryCode {
                    code,
                    message
                  }
                }`,
  userOrganizations: `query UserOrganizations {
                        me {
                          organizations {
                            id,
                            name,
                            placeId,
                            icon,
                            status,
                            type,
                            sports {
                              sport {
                                id,
                                name,
                                value,
                                values
                              },
                              ageGroup1,
                              ageGroup2,
                              ageGroup3,
                              ageGroup4,
                              monthsInSeason,
                            },
                            params,
                            backgroundCheck,
                            backgroundProvider,
                            otherBackgroundProvider,
                            provider,
                            otherProvider,
                            users {
                              email,
                              name,
                              phone
                            },
                            address {
                              address,
                              address2,
                              city,
                              state,
                              country,
                              zip
                            },
                            mailing {
                              address,
                              address2,
                              city,
                              state,
                              country,
                              zip
                            },
                            personnel {
                              numAthletes,
                              numCoaches,
                              numVolunteers,
                              numOfficials
                            },
                            users {
                              id,
                              name,
                              phone,
                              email,
                              isEmailConfirmed,
                              soaRole
                            },
                            requests {
                              id,
                              user {
                                id,
                                name,
                                phone,
                                email,
                                isEmailConfirmed,
                                soaRole
                              },
                              organization {
                                id,
                              }
                            },
                            invitations {
                              id,
                              name,
                              email,
                              isFinished,
                              organization {
                                id,
                              }
                            },
                            externalCoverages {
                              carrier {
                                icon,
                                id,
                                name
                              },
                              coverageType,
                              otherCarrier,
                              premium,
                              paymentCycle,
                              renewalDate,
                            },
                            previousClaims {
                              amountPaid,
                              carrier {
                                id,
                                name,
                              },
                              policyStartDate,
                              policyEndDate,
                              totalPremium,
                              numberOfClaims,
                            },
                            phone,
                            site,
                            email,
                            injuryProtocol,
                            concussionProtocol,
                            waiverProtocol,
                            isCompleted,
                            isQuoteRequested,
                            quoteOffersTotal,
                            quoteOffersStatus,
                          }
                        }
                      }`,
  userPendingOrganizations: `query UserPendingOrganizations {
                              me {
                                pending {
                                  id,
                                  name,
                                  icon,
                                  type,
                                  address {
                                    address,
                                    address2,
                                    city,
                                    state,
                                    country,
                                    zip
                                  }
                                }
                              }
                            }`,
};
