import ToastCollection from '@/utils/services/ToastCollection';
import getNotificationMessage from '@/utils/helpers/getNotificationMessage';
import getNotificationIcon from '@/utils/helpers/getNotificationIcon';
import { TOAST_TYPES } from '@/utils/constants';

const state = {
  toastCollection: new ToastCollection(),
  newUnread: [],
  oldUnread: [],
};

const mutations = {
  ADD_TO_NEW_NOTIFICATIONS(ctx, payload) {
    ctx.newUnread.push(payload);
  },
  SET_OLD_NOTIFICATIONS(ctx, payload) {
    ctx.oldUnread = payload;
  },
  CREATE_TOAST(ctx, payload) {
    ctx.toastCollection.createToast(payload);
  },
  DELETE_TOAST(ctx, id) {
    ctx.toastCollection.deleteToast(id);
  },
  DELETE_SYSTEM_TOAST(ctx, systemId) {
    ctx.toastCollection.deleteSystemToast(systemId);
  },
  REMOVE_ALL_TOASTS(ctx) {
    ctx.toastCollection.deleteAllToasts();
  },
  REMOVE_NEW_NOTIFICATIONS(ctx) {
    ctx.newUnread = [];
  },
  REMOVE_OLD_NOTIFICATIONS(ctx) {
    ctx.oldUnread = [];
  },
  REMOVE_SEEN_NOTIFICATIONS(ctx, ids) {
    ctx.newUnread = ctx.newUnread.filter((ntf) => !ids.includes(ntf.id));
    ctx.oldUnread = ctx.oldUnread.filter((ntf) => !ids.includes(ntf.id));
  },
};

const actions = {
  createToast(ctx, config) {
    let toast = {
      content: config.content || '',
      type: config.type || TOAST_TYPES.INFO,
      clickHandler: config.clickHandler || '',
      systemId: config.systemId || '',
      systemType: config.systemType || '',
      dismissible: config.dismissible || false,
      origin: config.origin || 'websocket',
      timeout: (config.timeout === false || config.timeout === 0) ? false : (config.timeout || 5),
    };
    ctx.commit('CREATE_TOAST', toast);
    if (config.origin !== 'interface') {
      ctx.commit('ADD_TO_NEW_NOTIFICATIONS', {
        id: config.systemId,
        content: config.content,
        icon: config.icon,
        type: config.type,
        systemType: config.systemType,
      });
    }
  },
  deleteToast(ctx, id) {
    ctx.commit('DELETE_TOAST', id);
  },
  deleteSystemToast(ctx, systemId) {
    ctx.commit('DELETE_SYSTEM_TOAST', systemId);
  },
  setUnseenNotifications(ctx, payload) {
    if (payload && payload instanceof Array) {
      const newUnreadIds = ctx.state.newUnread.map((ntf) => ntf.id);
      const filteredNotifications = payload.filter((ntf) => !newUnreadIds.includes(ntf.id));
      const unseenNotifications = filteredNotifications.map((ntf) => ({
        id: ntf.id,
        content: getNotificationMessage(ntf.message, ntf.type),
        icon: getNotificationIcon(ntf.message),
        type: TOAST_TYPES.INFO,
        systemType: ntf.type,
        createdAt: ntf.createdAt,
      }));
      ctx.commit('SET_OLD_NOTIFICATIONS', unseenNotifications);
    }
  },
  makeNotificationsSeen(ctx, ids) {
    if (ids instanceof Array && ids.length) {
      ctx.dispatch('socket/updateNotificationsSeenStatus', ids, { root: true });
      ctx.commit('REMOVE_SEEN_NOTIFICATIONS', ids);
    }
  },
  clearAllNotifications(ctx) {
    ctx.commit('REMOVE_ALL_TOASTS');
    ctx.commit('REMOVE_NEW_NOTIFICATIONS');
    ctx.commit('REMOVE_OLD_NOTIFICATIONS');
  },
};

const getters = {
  toasts: (ctx) => ctx.toastCollection.toasts,
  notifications: (ctx) => [...ctx.newUnread, ...ctx.oldUnread],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
