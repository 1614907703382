import moment from '@/plugins/moment';
import ClaimProvider from '@/graphql/providers/claim';

window.moment = moment;
const FILTER_GLOSSARY = {
  name: 'name',
  organizationName: 'organizationName',
  injuryType: 'injuryType',
  status: 'status',
  policyNumber: 'policyNumber',
};

const SORTING_GLOSSARY = {
  organizationName: 'organization.name',
};

const state = {
  claims: [],
  pagination: {
    currentPage: 1,
    nextPage: null,
    totalPages: 1,
  },
  filters: {},
  sorting: {
    field: '',
    order: '',
  },
  selectedClaim: {},
};

const mutations = {
  ARCHIVE_CLAIMS(context, archivedClaimIds) {
    const archivedClaims = context.claims.filter((clm) => archivedClaimIds.includes(clm.id));
    archivedClaims.map((clm) => ({ ...clm, isArchived: true }));
  },
  UPDATE_CLAIM(context, updatedClaim) {
    const claimToUpdateIndex = context.claims.findIndex((clm) => clm.id === updatedClaim.id);
    if (claimToUpdateIndex) {
      context.claims.splice(claimToUpdateIndex, 1, updatedClaim);
    }
  },
  SET_CLAIM_PAGINATION(context, pagination) {
    context.pagination = pagination;
  },
  UPDATE_CLAIM_PAGINATION(context, pagination) {
    context.pagination = Object.assign(context.pagination, pagination);
  },
  SET_CLAIM_FILTERS(context, filters) {
    context.filters = filters;
  },
  UPDATE_CLAIM_FILTERS(context, filters) {
    context.filters = { ...context.filters, ...filters };
  },
  UPDATE_CLAIM_SORTING(context, sorting) {
    context.sorting = Object.assign(context.sorting, sorting);
  },
  UPDATE_CLAIMS(context, claims) {
    context.claims = claims;
  },
  PUSH_ONE_TO_CLAIMS(context, claim) {
    context.claims.push(claim);
  },
  PUSH_BATCH_TO_CLAIMS(context, claimsBatch) {
    if (claimsBatch instanceof Array) {
      context.claims = [...context.claims, ...claimsBatch];
    }
  },
};

const actions = {
  archiveClaims(context, payload) {
    return new Promise((resolve, reject) => {
      ClaimProvider.updateClaimsMutation(payload.selectedIds, {
        isArchived: true,
        archivingReason: context.archivingReason,
      }).then((response) => {
        if (response.data.data.claims && response.data.data.claims.code === 200) {
          context.commit('ARCHIVE_CLAIMS', payload.selectedIds);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  clearClaims(context) {
    context.commit('UPDATE_CLAIMS', []);
  },
  createClaim(context, claim) {
    return ClaimProvider.createClaimMutation({ ...claim, isFNOL: false });
  },
  fetchSingleClaim(context, id) {
    return ClaimProvider.getClaimQuery(id);
  },
  editClaim(context, payload) {
    return new Promise((resolve, reject) => {
      ClaimProvider.updateClaimsMutation(payload.claimId, payload.claim)
        .then((response) => {
          if (response.data.data.claims && response.data.data.claims.code === 200) {
            context.commit('UPDATE_CLAIM', payload.claim);
          }
          resolve(response);
        }).catch(reject);
    });
  },
  fetchClaim(context, id) {
    return ClaimProvider.getClaimQuery(id);
  },
  fetchClaims(context, update = true) {
    return new Promise((resolve, reject) => {
      let filterObj = {
        ...context.state.filters,
        pageNumber: context.state.pagination.nextPage || 1,
        sortField: context.getters.requestReadyColumnSorting.field,
        sortOrder: context.state.sorting.order === 'asc' ? 1 : -1,
        isFNOL: false,
      };
      ClaimProvider.claimsQuery({
        filters: filterObj,
      }).then((response) => {
        if (update) {
          context.dispatch('updateClaims', response);
        }
        resolve(response);
      }).catch(reject);
    });
  },
  fetchNextClaimsByPagination(context) {
    return new Promise((resolve, reject) => {
      context.dispatch('fetchClaims', false).then((response) => {
        context.dispatch('pushToClaims', response);
        resolve(response);
      }).catch(reject);
    });
  },
  filterClaimsByField(context, config) {
    return new Promise((resolve, reject) => {
      context.dispatch('resetPagination');
      let headerName = FILTER_GLOSSARY[config.column] || config.column;
      if (config.value) {
        context.commit('UPDATE_CLAIM_FILTERS', { [headerName]: config.value });
      } else {
        const { [headerName]: oldFilter, ...newFilters } = context.state.filters;
        context.commit('SET_CLAIM_FILTERS', newFilters);
      }
      context.dispatch('fetchClaims')
        .then(resolve)
        .catch(reject);
    });
  },
  filterClaimsByArchivedValue(context, type) {
    return new Promise((resolve, reject) => {
      if (type === 'active') {
        context.commit('UPDATE_CLAIM_FILTERS', { isArchived: false });
      } else if (type === 'archived') {
        context.commit('UPDATE_CLAIM_FILTERS', { isArchived: true });
      } else if (type === 'all') {
        const { isArchived: oldFilter, ...newFilters } = context.state.filters;
        context.commit('SET_CLAIM_FILTERS', newFilters);
      }
      context.dispatch('resetPagination');
      context.dispatch('fetchClaims')
        .then(resolve)
        .catch(reject);
    });
  },
  filterClaimsByDate(context, payload) {
    return new Promise((resolve, reject) => {
      if (payload && payload instanceof Object && typeof payload.createdAtFrom === 'string') {
        if (payload.createdAtFrom) {
          context.commit('UPDATE_CLAIM_FILTERS', { createdAtFrom: payload.createdAtFrom });
        } else {
          const {
            createdAtFrom: oldCreatedAtFromFilter,
            ...newFilters
          } = context.state.filters;
          context.commit('SET_CLAIM_FILTERS', newFilters);
        }
      } else if (payload && payload instanceof Object && typeof payload.createdAtTo === 'string') {
        if (payload.createdAtTo) {
          context.commit('UPDATE_CLAIM_FILTERS', { createdAtTo: payload.createdAtTo });
        } else {
          const {
            createdAtTo: oldCreatedAtToFilter,
            ...newFilters
          } = context.state.filters;
          context.commit('SET_CLAIM_FILTERS', newFilters);
        }
      } else if (!payload || !(payload instanceof Object) || !Object.keys(payload).length) {
        const {
          createdAtFrom: oldCreatedAtFromFilter,
          createdAtTo: oldCreatedAtToFilter,
          ...newFilters
        } = context.state.filters;
        context.commit('SET_CLAIM_FILTERS', newFilters);
      }
      context.dispatch('resetPagination');
      context.dispatch('fetchClaims')
        .then(resolve)
        .catch(reject);
    });
  },
  resetPagination(context) {
    context.commit('SET_CLAIM_PAGINATION', {
      currentPage: 1,
      nextPage: null,
      totalPages: 1,
    });
  },
  sortClaimsByField(context, config) {
    return new Promise((resolve, reject) => {
      context.dispatch('resetPagination');
      if (config instanceof Object && Object.keys(config).length) {
        context.commit('UPDATE_CLAIM_SORTING', config);
        context.dispatch('fetchClaims')
          .then(resolve)
          .catch(reject);
      }
    });
  },
  updateClaims(context, response) {
    if (response.data && response.data.data && response.data.data.claims && response.data.data.claims.edges instanceof Array) {
      context.commit('UPDATE_CLAIMS', response.data.data.claims.edges);
      context.commit('UPDATE_CLAIM_PAGINATION', response.data.data.claims.pagination);
    }
  },
  pushToClaims(context, response) {
    if (response.data.data.claims && response.data.data.claims.edges) {
      context.commit('PUSH_BATCH_TO_CLAIMS', response.data.data.claims.edges);
      context.commit('UPDATE_CLAIM_PAGINATION', response.data.data.claims.pagination);
    }
  },
};

const getters = {
  claims: (context) => context.claims,
  claimFilters: (context) => context.filters,
  claimPagination: (context) => context.pagination,
  claimSorting: (context) => context.sorting,
  formattedClaims: (context) => context.claims.map((claim) => {
    const claimObj = {
      ...claim,
      createdAt: moment(claim.createdAt).format('YYYY-MM-DD'),
      organizationName: claim.organization ? claim.organization.name : '',
    };
    return claimObj;
  }),
  requestReadyColumnSorting: (context) => {
    if (SORTING_GLOSSARY[context.sorting.field]) {
      return {
        ...context.sorting,
        field: SORTING_GLOSSARY[context.sorting.field],
      };
    }
    return { ...context.sorting };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
