import i18n from '@/i18n';
import { concatGraphQLErrors } from '@/utils/helpers';
import { DIALOG_TYPES } from '@/utils/constants';

const state = {
  title: '',
  description: '',
  fullscreen: false,
  type: '',
  btnText: '',
  showDialog: false,
  contentTextAlign: '',
  resolveDialog: () => {},
};

const mutations = {
  SHOW_DIALOG(ctx, payload) {
    ctx.showDialog = typeof payload !== 'undefined' ? payload : true;
  },
  SET_CONFIG(ctx, config) {
    ctx.title = config.title;
    ctx.description = config.description;
    ctx.fullscreen = config.fullscreen;
    ctx.type = config.type;
    ctx.btnText = config.btnText;
    ctx.contentTextAlign = config.contentTextAlign;
  },
  SET_RESOLVE_FUNC(ctx, func) {
    ctx.resolveDialog = func;
  },
  RESOLVE_DIALOG(ctx, payload) {
    ctx.resolveDialog(payload);
  },
};

const actions = {
  showDialog(ctx, config) {
    let conf = {
      title: config.title || '',
      description: config.description || '',
      fullscreen: config.fullscreen || false,
      type: config.type || DIALOG_TYPES.INFO,
      contentTextAlign: config.contentTextAlign || 'center',
    };
    conf.btnText = config.btnText || (conf.type === DIALOG_TYPES.INFO ? i18n.t('ui.gotIt') : i18n.t('ui.confirm'));

    ctx.commit('SET_CONFIG', conf);
    ctx.commit('SHOW_DIALOG');

    return new Promise((resolve) => {
      ctx.commit('SET_RESOLVE_FUNC', resolve);
    }).then((response) => {
      ctx.commit('SHOW_DIALOG', false);
      return response;
    });
  },
  showInfoDialog(ctx, config) {
    return ctx.dispatch('showDialog', Object.assign(config, { type: DIALOG_TYPES.INFO }));
  },
  showConfirmDialog(ctx, config) {
    return ctx.dispatch('showDialog', Object.assign(config, { type: DIALOG_TYPES.CONFIRM }));
  },
  showErrorDialog(ctx, { title, error, description }) {
    const config = {
      title: title || i18n.t('response.error.error'),
      description,
    };
    if (!description && error instanceof Object) {
      config.description = concatGraphQLErrors(error);
    }
    return ctx.dispatch('showInfoDialog', config);
  },
  closeDialog(ctx) {
    ctx.commit('RESOLVE_DIALOG', false);
    ctx.commit('SHOW_DIALOG', false);
  },
  resolveDialog(ctx, payload) {
    ctx.commit('RESOLVE_DIALOG', payload);
  },
};

const getters = {
  dialog: (st) => ({
    title: st.title,
    description: st.description,
    fullscreen: st.fullscreen,
    type: st.type,
    btnText: st.btnText,
    showDialog: st.showDialog,
    contentTextAlign: st.contentTextAlign,
  }),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
