export default {
  getInviteByToken: `query InviteByToken($token: Token!) {
                        invite(token: $token ){
                          code,
                          message,
                          token,
                          user {
                            id,
                            name,
                            email,
                            organizations {
                              id,
                            },
                            isEmailConfirmed
                          }
                        }
                     }`,
};
