import { convertFromLocale } from '@/utils/helpers';

export default (prevClaims) => {
  if (prevClaims instanceof Array && prevClaims.length) {
    return prevClaims.map((clm) => {
      const {
        $id, carrier, otherCarrier, type, ...strippedClaim
      } = clm;
      const payload = {
        ...strippedClaim,
        amountPaid: convertFromLocale(strippedClaim.amountPaid),
        totalPremium: convertFromLocale(strippedClaim.totalPremium),
        numberOfClaims: Number.parseInt(strippedClaim.numberOfClaims, 10),
      };
      if (carrier !== 'other') {
        payload.carrier = carrier;
      } else if (otherCarrier) {
        payload.otherCarrier = otherCarrier;
      }
      return payload;
    });
  }
  return [];
};
