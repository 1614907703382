import ServiceProvider from '../provider';
import MUTATIONS from './mutations';

const QUERIES = {};

class FileProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  uploadFilesMutation(files) {
    if (files instanceof Object) {
      const fileEntries = Object.entries(files);
      if (fileEntries) {
        let formData = fileEntries.reduce((acc, ent, i) => {
          if (ent && ent[1] instanceof File) {
            acc.append(ent[0], ent[1]);
          }
          return acc;
        }, new FormData());
        formData.append('query', this.mutations.uploadFiles);
        return this.formDataMutate(formData);
      }
    }
    return Promise.reject(new Error('The fileArray argument was invalid or empty'));
  }
}

export default new FileProvider(QUERIES, MUTATIONS);
