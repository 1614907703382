import ServiceProvider from '../provider';
import MUTATIONS from './mutations';

const QUERIES = {};

class CaptchaProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  validateCaptchaByTokenMutation(token) {
    return this.mutate(this.mutations.verifyRecaptcha, { token });
  }
}
export default new CaptchaProvider(QUERIES, MUTATIONS);
