import ExceptionHelper from '@/utils/helpers/exceptionHelper';

class EventEmitter {
  static events = {};

  contructor() {
    this.events = {};
  }

  on(eventName, callback) {
    ExceptionHelper.expectedType(eventName, String());
    ExceptionHelper.expectedType(callback, (() => {}));

    if (!(eventName in this.events)) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  static on(eventName, callback) {
    ExceptionHelper.expectedType(eventName, String());
    ExceptionHelper.expectedType(callback, (() => {}));

    if (!(eventName in EventEmitter.events)) {
      EventEmitter.events[eventName] = [];
    }
    EventEmitter.events[eventName].push(callback);
  }

  dispatch(eventName, ...params) {
    ExceptionHelper.expectedType(eventName, String());
    if (!(eventName in this.events)) {
      return;
    }
    this.events[eventName].forEach((func) => func.apply(func, params));
  }

  static dispatch(eventName, ...params) {
    ExceptionHelper.expectedType(eventName, String());
    if (!(eventName in EventEmitter.events)) {
      return;
    }
    EventEmitter.events[eventName].forEach((func) => func.apply(func, params));
  }
}

export default EventEmitter;
