import moment from 'moment';
import 'moment/locale/fr';

const DEFAULT_MOMENT_LOCALE = 'en';

const getMoment = function (defaultLocale) {
  if (defaultLocale) {
    // set default locale
    moment.locale(defaultLocale);
  }
  return moment;
};

export default getMoment(DEFAULT_MOMENT_LOCALE);
