import {
  AIG_MIN_ATHLETES_PER_STATE_MAP,
  API_DATE_FORMAT,
  DEFAULT_PAYMENT_CYCLE_VALUE,
  TOURNAMENT_ORG_TYPE_ID,
} from '@/utils/constants';
import { STEP_NAMES_MAP } from '@/store/modules/signUp/constants';
import { checkStateHasCoverages, objectHasAllValues, getTotalNumberOfAthletesFromAthletesAge } from '@/utils/helpers';
import { getSignUpStepsValidityMap } from '@/store/modules/signUp/helpers';

export default {
  selectedOrgStateHasCoverages: (state, getters, rootState, rootGetters) => !!(getters.selectedOrg
    && getters.selectedOrg.address
    && getters.selectedOrg.address.state
    && rootGetters['glossary/sports'].length
    && checkStateHasCoverages(getters.selectedOrg.address.state, rootGetters['glossary/states'])),
  orgPersonnelForm: (state) => state.personnel,
  athletesAgeBySports: (state) => state.athletesAgeBySports,
  athletesAgeBySportsValid: (state) => Object.values(state.athletesAgeBySports).reduce((acc, obj) => {
    if (!objectHasAllValues(obj)) {
      acc = false;
    }
    return acc;
  }, true),
  createNewOrgForm: (state) => state.createNewOrgForm,
  createNewOrgFormValid: (state) => {
    const objToValidate = {
      name: state.createNewOrgForm.name,
      address: state.createNewOrgForm.address.address,
      city: state.createNewOrgForm.address.city,
      state: state.createNewOrgForm.address.state,
      zip: state.createNewOrgForm.address.zip,
    };
    if (!state.createNewOrgForm.noSeparateMailingAddress) {
      Object.assign(objToValidate, {
        mailingAddress: state.createNewOrgForm.mailingAddress.address,
        mailingCity: state.createNewOrgForm.mailingAddress.city,
        mailingState: state.createNewOrgForm.mailingAddress.state,
        mailingZip: state.createNewOrgForm.mailingAddress.zip,
      });
    }
    return objectHasAllValues(objToValidate);
  },
  createNewOrgFormNoSeparateMailing: (state) => state.createNewOrgForm.noSeparateMailingAddress,
  createNewOrgShown: (state) => state.visibilityMap.createNewOrg,
  signUpStepKeys: (state, getters) => Object.keys(getters.signUpSteps),
  signUpStepNames: (state, getters) => getters.signUpStepKeys.reduce((acc, currVal) => {
    if (STEP_NAMES_MAP[currVal]) {
      if (!acc.includes(STEP_NAMES_MAP[currVal])) {
        acc.push(STEP_NAMES_MAP[currVal]);
      }
    } else if (!acc.includes(currVal)) {
      acc.push(currVal);
    }
    return acc;
  }, []),
  signUpStepsValidityMap: getSignUpStepsValidityMap,
  currentStepNum: (state) => state.step,
  currentStep: (state, getters) => getters.signUpStepKeys[[~-getters.currentStepNum]],
  currentSubStep: (state, getters) => {
    // eslint-disable-next-line
    let currStep = Object.keys(getters.signUpSteps)[~-getters.currentStepNum];
    let currSubStep = getters.signUpSteps[currStep];
    return currSubStep[getters.subStep - 1];
  },
  currentSubStepNumber: (state, getters) => {
    let currStep = Object.keys(getters.signUpSteps)[~-getters.currentStepNum];
    let currSubStepIndex = getters.signUpSteps[currStep].indexOf(getters.currentSubStep);
    return currSubStepIndex !== -1 ? currSubStepIndex + 1 : 1;
  },
  currentStepSubSteps: (state, getters) => getters.signUpSteps[getters.currentStep] || [],
  currentStepSubStepsValidityMap: (state, getters) => getters.signUpStepsValidityMap[getters.currentStep] || {},
  currentStepLastAllowedSubStep: (state, getters) => {
    if (state.lastVisitedStep === getters.currentStepNum && getters.currentStepLastCompleteSubStep > state.lastVisitedSubStep) {
      return state.lastVisitedSubStep;
    }
    return getters.currentStepLastCompleteSubStep;
  },
  currentStepLastCompleteSubStep: (state, getters) => {
    const comparisonObj = getters.currentStepSubSteps.reduce((acc, currVal, i, arr) => {
      if (i > 0) {
        if (acc.allPrevStepsValid && getters.currentStepSubStepsValidityMap[currVal] && getters.currentStepSubStepsValidityMap[arr[i - 1]]) {
          acc.lastValidStep = i + 1;
        } else {
          acc.allPrevStepsValid = false;
        }
      }
      return acc;
    }, {
      lastValidStep: 1,
      allPrevStepsValid: true,
    });
    return comparisonObj.lastValidStep;
  },
  lastAllowedStep: (state, getters) => getters.lastCompleteStep > state.lastVisitedStep
    ? state.lastVisitedStep
    : getters.lastCompleteStep,
  lastCompleteStep: (state, getters) => {
    const comparisonObj = Object.entries(getters.signUpStepsValidityMap).reduce((acc, step, i, arr) => {
      const allSubStepsValid = (subStep) => subStep && subStep instanceof Object && !(Object.values(subStep).some((sub) => sub == false));
      if (i > 0) {
        if (acc.allPrevStepsValid && allSubStepsValid(step[1]) && allSubStepsValid(arr[i - 1][1])) {
          acc.lastValidStep = i + 1;
        } else {
          acc.allPrevStepsValid = false;
        }
      }
      return acc;
    }, {
      lastValidStep: 1,
      allPrevStepsValid: true,
    });
    return comparisonObj.lastValidStep;
  },
  subStep: (state) => state.subStep,
  searchOrgQuery: (state) => state.searchOrgQuery,
  selectedOrgParams: (state) => state.params,
  selectedOrg: (state) => {
    if (state.selectedOrg && state.selectedOrg instanceof Object) {
      if (state.selectedOrg.organizationId
        && state.session
        && state.session instanceof Object
        && state.session.organization instanceof Object) {
        return state.session.organization;
      } if (!state.selectedOrg.organizationId) {
        return {
          ...state.selectedOrg,
          id: (state.session
            && state.session instanceof Object
            && state.session.organization instanceof Object
            && state.session.organization.id) || '',
        };
      }
    } else if (state.createNewOrgForm.valid
      && state.session
      && state.session instanceof Object
      && state.session.organization
      && state.session.organization instanceof Object) {
      return state.session.organization;
    }
    return state.selectedOrg;
  },
  selectedOrgType: (state) => state.selectedOrgType,
  eventTime: (state) => ({
    eventStartDate: state.params.eventStartDate,
    eventEndDate: state.params.eventEndDate,
  }),
  eventTimeValid: (state, getters) => getters.eventTime.eventStartDate && getters.eventTime.eventEndDate,
  totalStepsNum: (state, getters) => Object.keys(getters.signUpSteps).length,
  userInfoForm: (state) => state.userInfo,
  selectedSports: (state) => Object.keys(state.athletesAgeBySports),
  organizationTypes: (state) => state.organizationTypes,
  signUpSteps: (state, getters) => state.signUpSteps,
  signUpStepsArray: (state, getters) => Object.entries(getters.signUpSteps)
    .map((st, i) => ({
      name: st[0],
      label: STEP_NAMES_MAP[st[0]] || st[0],
      children: st[1],
    })),
  havePolicy: (state) => state.havePolicy,
  enableOrgSearchBtn: (state) => state.enableOrgSearchBtn,
  loading: (state) => state.loadingSession,
  totalNumberOfAthletes: (state) => getTotalNumberOfAthletesFromAthletesAge(state.athletesAgeBySports),
  minTotalNumberOfAthletes: (state) => {
    let org = null;
    if (state.newlyCreatedOrg && state.newlyCreatedOrg instanceof Object) {
      org = state.newlyCreatedOrg;
    } else if (state.selectedOrg && state.selectedOrg instanceof Object) {
      org = state.selectedOrg;
    } else {
      org = state.createNewOrgForm;
    }
    if (org
      && org instanceof Object
      && org.address
      && org.address instanceof Object
      && org.address.state
      && typeof org.address.state === 'string') {
      const lowerCaseState = org.address.state.toLowerCase();
      const stateMapMatch = AIG_MIN_ATHLETES_PER_STATE_MAP[lowerCaseState];
      if (stateMapMatch) {
        return stateMapMatch;
      }
    }
    return 0;
  },
};
