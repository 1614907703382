import config from '../../config/index';

const qs = require('qs');
const io = require('socket.io-client');

const options = {
  autoConnect: false,
  reconnectionAttempts: 3,
  transports: ['websocket'],
};

export default (query) => io.connect(`${config.serverUrl}?${qs.stringify(query)}`, options);
