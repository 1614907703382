import ServiceProvider from '../provider';
import QUERIES from './queries';
import MUTATIONS from './mutations';

class ShoppingCartProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  shoppingCartItemsQuery() {
    return this.query(this.queries.getShoppingCartItems);
  }

  shoppingCartCheckoutMutation(input) {
    return this.mutate(this.mutations.checkout, { input });
  }

  addItemToShoppingCartMutation(input) {
    return this.mutate(this.mutations.addItemToShoppingCart, { input });
  }

  addItemsToShoppingCartMutation(inputs, userToken) {
    const config = {};
    if (userToken && typeof userToken === 'string' && userToken.length) {
      config.headers = ServiceProvider.getAuthBearerHeader(userToken);
    }
    return this.mutate(this.mutations.addItemsToShoppingCart, { inputs }, config);
  }

  removeItemFromShoppingCartMutation(deleteId) {
    return this.mutate(this.mutations.removeItemFromShoppingCart, { deleteId });
  }

  updateItemInShoppingCartMutation(updateId, input) {
    return this.mutate(this.mutations.updateItemInShoppingCart, { updateId, input });
  }
}

export default new ShoppingCartProvider(QUERIES, MUTATIONS);
