<template>
  <vue100vh>
    <v-app id="inspire" v-resize="onAppResize" :class="{phApplication: true}">
      <PhModalDialog />
      <AchApprovalDialog :value="showAchDialog" />
      <PhToast @toastClick="handleToastClick" />
      <PreloaderFullscreen v-if="showingPreloader" />
      <AppSidebarMenu id="appMenu"/>
      <AppToolbar v-if="showingHeader" id="appToolbar"/>
      <v-main id="content" fluid>
        <div class="scroll-container">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-content dark class="grey darken-4" :value="$vuetify.breakpoint.mdAndDown && $route.meta.backToolbar">
                <v-container class="backBtn" fluid>
                  <RouteBackBtn color="accent" :block="true" :dark="true" :large="true"/>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <transition name="fade" mode="out-in" :duration="300">
            <router-view>
              <v-container fluid fill-height id="route-content"></v-container>
            </router-view>
          </transition>
        </div>
      </v-main>
    </v-app>
  </vue100vh>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vue100vh from 'vue-100vh';
import PreloaderFullscreen from '@/components/layout/PreloaderFullscreen';
import RouteBackBtn from '@/components/buttons/RouteBackBtn';
import AppSidebarMenu from '@/components/layout/AppSidebarMenu';
import AppToolbar from '@/components/layout/AppToolbar';
import PhModalDialog from '@/components/dialogs/PhModalDialog';
import AchApprovalDialog from '@/components/dialogs/AchApprovalDialog';
import PhToast from '@/components/PhToast';
import notificationClickHandler from '@/utils/mixins/notificationClickHandler';
import i18n from '@/i18n';

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: i18n.t('message.welcome'),
    // all titles will be injected into this template
    titleTemplate: '%s | Player\'s Health Cover',
    // Meta
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
      },
      // Fonts and Material Icons
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Material+Icons',
      },
    ],
  },
  mixins: [notificationClickHandler],
  components: {
    AppSidebarMenu,
    AppToolbar,
    RouteBackBtn,
    PreloaderFullscreen,
    PhModalDialog,
    PhToast,
    AchApprovalDialog,
    vue100vh,
  },
  props: {
    source: String,
  },
  computed: {
    ...mapGetters('user', ['authed', 'userIsSoa', 'userIsGuest', 'homeRoute', 'showAchDialog', 'userHasVerificationAttempts', 'userHasUnverifiedTemporaryAch']),
    ...mapGetters('layout', ['showingMenu', 'showingHeader', 'showingPreloader', 'isMobile']),
  },
  mounted() {
    this.animateIn();
    window.onload = this.onWindowLoad;
    if (this.$ga) {
      this.$ga.page(this.$router);
    }
    // set a listener for localStorage change to share info across tabs
    this.setTokenChangeListener();
    // fetch location country code to determine where's the user at
    this.fetchLocationCountryCode();
    // listen to mobile keyboard open
    this.listenToMobileKeyboardOpen();
    // set invisible reCAPTCHA
    this.mountCaptcha()
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    ...mapActions('captcha', ['mountCaptcha']),
    ...mapActions('user', ['setTokenChangeListener', 'fetchLocationCountryCode']),
    ...mapActions('layout', ['resizeWindow', 'listenToMobileKeyboardOpen']),
    ...mapActions('notification', ['makeNotificationsSeen', 'deleteToast']),
    onAppResize() {
      this.resizeWindow({ width: window.innerWidth, height: window.innerHeight });
      this.$events.emit('appResize');
    },
    onWindowLoad() {},
    animateIn: () => {
      const preloader = document.querySelector('#preloader');
      if (preloader) {
        preloader.style.opacity = 0;
        preloader.style.display = 'none';
      }
    },
    handleToastClick(toast) {
      this.makeToastSeen(toast);
      this.deleteToast(toast.id);
      if (toast.clickHandler) toast.clickHandler(this.$router);
    },
    makeToastSeen(toast) {
      if (toast.origin === 'websocket') {
        this.makeNotificationsSeen([toast.systemId]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/app";
@import "assets/scss/base/index";

$block: 'phApplication';

.#{$block} {
  &.v-application {
    font-family: $favorit;
    height: 100%;
    overflow: hidden;
    @include mappy-bp(md) {
      overflow: inherit;
    }
  }

  .v-main {
    max-height: 100%;
    flex-shrink: 1;
  }

  .backBtn {
    padding: 0 !important;

    .v-btn {
      margin: 0;
    }
  }

  .scroll-container {
    height: 100%;
    overflow-y: auto;
    backface-visibility: hidden;
    scroll-behavior: smooth;
    &::-webkit-scrollbar-button {
      visibility: hidden;
      height: 0px;
    }
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background: $white;
      @include mappy-bp(md) {
        background: transparent;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: $dark-blue;
    }
  }
}

</style>
