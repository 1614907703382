var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{class:_vm.b(),attrs:{"value":_vm.value,"fullscreen":_vm.fullscreen,"max-width":_vm.dialogMaxWidth,"content-class":_vm.bodyClasses,"transition":_vm.transition,"internal-activator":"","attach":".v-application--wrap"},on:{"input":_vm.updateDialogValue}},[(_vm.dismissible)?_c('div',{class:_vm.b('closeButtonWrapper'),on:{"click":function($event){return _vm.updateDialogValue(false)}}},[_c('div',{class:_vm.b('closeButton')})]):_vm._e(),_vm._v(" "),(_vm.heading || _vm.$slots.header)?_c('div',{class:_vm.b('header')},[_vm._t("header",[_c('h2',{class:_vm.b('heading')},[_vm._v(_vm._s(_vm.heading))])])],2):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"bar",rawName:"v-bar"}],staticClass:"vuebar-element",class:_vm.b('contentWrapper')},[_c('div',{ref:"contentScroll",class:_vm.b('contentScroll', {
        shadow: _vm.hasScrollShadow,
        shadowBottom: _vm.scrollShadow.bottom,
        shadowTop: _vm.scrollShadow.top,
      }),on:{"scroll":_vm.handleContentScroll}},[_c('div',{class:_vm.b('content', {
        textCenter: _vm.contentTextAlign === 'center',
        contentVerticalCenter: _vm.contentVerticalCenter,
        noPadding: _vm.noContentPadding,
      })},[_vm._t("content"),_vm._v(" "),(!_vm.noFooter)?_c('div',{class:_vm.b('footer')},[_vm._t("footer",[_c('v-btn',{class:_vm.b('footerBtn', { info: true }),on:{"click":function($event){$event.stopPropagation();return _vm.updateDialogValue(false)}}},[_vm._v("\n              Got it\n            ")])])],2):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }