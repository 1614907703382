export default {
  updateGeneral: `mutation UpdateParam($input:ParamInput, $updateId:ID){
                      param(updateId:$updateId,input:$input) {
                        id,
                        name,
                        value,
                        icon
                      }
                    }`,
};
