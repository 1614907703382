export default {
  confirmEmail: `mutation ConfirmUserSignUp ($email: EmailAddress!, $token: Token!) {
      confirm(email: $email, token: $token) {
        code,
        message,
        token
      }
  }`,
  confirmEmailChange: `mutation ConfirmUserEmailChange ($email: String!, $token: Token!) {
      emailChange(email: $email, token: $token) {
        code,
        message,
        token,
        user {
          email
        }
      }
  }`,
  registerUser: `mutation UserSignUp($input: UserInput) {
        user(input: $input) {
          code,
          message,
          token,
          user {
            id,
            name,
            email,
            soaRole
          }
        }
    }`,
  updateUser: `mutation UserUpdate($input: UserInput, $updateId: ID!) {
        user(updateId: $updateId, input: $input) {
          code,
          message,
          user {
            id,
            name,
            email,
            soaRole,
            phone,
            icon,
          }
        }
    }`,
  updateUserPaymentMethod: `mutation UserUpdatePaymentMethod($token: Token, $method: PaymentMethod!,
    $defaultMethod: PaymentMethod) {
      paymentMethod(token: $token, method: $method, defaultMethod: $defaultMethod) {
        code,
        message,
    }
  }`,
  verifyAchAccount: `mutation verifyAchAccount($amounts: [Int]) {
      paymentMethod(amounts: $amounts) {
        code,
        message,
    }
  }`,
  verifyTemporaryAchAccount: `mutation verifyAchAccount($amounts: [Int], $isTemporary: Boolean, $token: Token, $method: PaymentMethod) {
      paymentMethod(amounts: $amounts, isTemporary: $isTemporary, token: $token, method: $method) {
        code,
        message,
    }
  }`,
  removeUserPaymentMethod: `mutation UserUpdatePaymentMethod($method: PaymentMethod!,
    $isDelete: Boolean!) {
      paymentMethod(method: $method, isDelete: $isDelete) {
        code,
        message,
    }
  }`,
  updateDefaultPaymentMethod: `mutation UserUpdateDefaultPaymentMethod($defaultMethod: PaymentMethod) {
      paymentMethod(defaultMethod: $defaultMethod) {
        code,
        message,
    }
  }`,
  resendConfirmation: `mutation ResendSignupConfirm ($email: EmailAddress!) {
      resendConfirmation(email: $email) {
        code,
        message
      }
    }`,
  resetPassword: `mutation UserForgotPassword($email: EmailAddress!) {
      resetPassword(email: $email) {
        code,
        message
      }
    }`,
};
