import { loadStripe } from '@stripe/stripe-js';
import i18n from '@/i18n';

export default {
  async createToken({ dispatch, state }, { element, tokenData = {} }) {
    if (!state.initialized) {
      await dispatch('initialize');
    }
    try {
      const result = state.instance.createToken(element, tokenData);
      if (result && result.error) {
        dispatch('dialog/showInfoDialog', {
          title: i18n.t('response.error.error'),
          description: result.error.message,
        }, {
          root: true,
        });
        throw new Error(result.error.message);
      }
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async initialize({ commit, state }) {
    if (!state.initialized) {
      if (typeof state.config.stripeKey === 'string' && state.config.stripeKey.length) {
        try {
          const stripeInstance = await loadStripe(state.config.stripeKey);
          commit('SET_INSTANCE', stripeInstance);
          commit('SET_INITIALIZED', true);
          return state.instance;
        } catch (err) {
          console.error('Stripe module initialization error:', err);
          throw err;
        }
      } else {
        throw new Error('Stripe key is not defined or invalid');
      }
    }
    return state.instance;
  },
};
