const validateChainObject = (obj) => obj && obj instanceof Object && typeof obj.field === 'string' && obj.field.length && typeof obj.hasOwnProperty('value');

export default (chain, params = {}) => {
  if (chain instanceof Array && chain.length) {
    return chain.reduce((acc, question) => {
      if (validateChainObject(question)) {
        acc.params[question.field] = question.value;
      }
      return acc;
    }, {
      params: { ...params },
    });
  }
  return {};
};
