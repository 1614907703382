export default (questions, questionKey) => {
  if (questions && questions instanceof Object && typeof questionKey === 'string' && questionKey.length) {
    let groupMatch = Object.entries(questions).find((queEnt) => Boolean(queEnt[0]
      && queEnt[1] instanceof Object
      && queEnt[1].questions
      && queEnt[1].questions instanceof Object
      && queEnt[1].questions[questionKey]
      && queEnt[1].questions[questionKey] instanceof Object));
    if (groupMatch && groupMatch instanceof Object) {
      return groupMatch[1];
    }
  }
  return null;
};
