<template>
  <v-menu
    v-if="!isMobile"
    v-model="langMenu"
    :nudge-bottom="37"
    :class="b()"
    :content-class="b('menu')"
    bottom
  >
    <div slot="activator">
      <div :class="b('active')">
        <img
          v-if="currentLocale && currentLocale.label && currentLocale.label != null"
          :src="icons[currentLocale.label]"
          alt="lang"
        >
      </div>
    </div>
    <v-list :class="b('list')">
      <div v-for="(item, i) in computedAvailAbleLocales"
           :key="i"
           :class="b('row')"
           @click="handleChooseLocale(item)">
        <div :class="b('item')">
          <img
            v-if="item && item.label"
            :src="icons[item.label]"
            alt="lang"
          >
        </div>
      </div>
    </v-list>
  </v-menu>
  <div :class="b({ mobile: true })" v-else>
    <div v-for="(item, i) in computedAvailAbleLocales"
         :key="i"
         :class="b('row')"
         @click="handleChooseLocale(item)">
      <div
        :class="b('item', {
          active: currentLocale && currentLocale.label && currentLocale.label != null && currentLocale.label == item.label,
          mobile: true
        })"
      >
        <img
          v-if="item && item.label"
          :src="icons[item.label]"
          alt="lang"
        >
      </div>
    </div>
  </div>
</template>

<script>
const localeImages = {
  EN: require('@/assets/images/locale/US.svg'),
  FR: require('@/assets/images/locale/FR.svg'),
};

export default {
  name: 'LangSwitcher',
  block: 'langSwitcher',
  components: {
  },
  props: {
    availableLocales: Array,
    currentLocale: Object,
    isMobile: Boolean,
  },
  data() {
    return {
      langMenu: false,
      icons: localeImages,
    };
  },
  computed: {
    computedAvailAbleLocales() {
      return this.availableLocales.filter((item) => this.currentLocale && this.currentLocale.label && this.currentLocale.label != null && this.currentLocale.label != item.label);
    },
  },
  methods: {
    handleChooseLocale(item) {
      let chosenLocale = item.value;
      this.$emit('setNewLocale', chosenLocale);
    },
  },
};
</script>

<style lang="scss" scoped>
$block: 'langSwitcher';

.#{$block} {
  &--mobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__item {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    &--mobile {
      margin-right: 22px;
    }
  }

  &__active {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #12D1AD;
    position: relative;
    img {
      height: 25px;
      width: 25px;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  &__list {
    background: transparent;
    box-shadow: none;
    padding: 0;
    .#{$block}__row {
      &:nth-child(1n + 2) {
        margin-bottom: 8px;
      }
      .#{$block}__item {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin: 0 auto;
        display: block;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
