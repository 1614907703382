import ServiceProvider from '../provider';
import QUERIES from './queries';
import MUTATIONS from './mutations';

class UserProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  authUserQuery(variables) {
    return this.query(this.queries.auth, variables, { noAuth: true });
  }

  fetchUserQuery(token) {
    return this.query(this.queries.me, undefined, {
      headers: ServiceProvider.getAuthBearerHeader(token),
    });
  }

  locationCountryCodeQuery() {
    return this.query(this.queries.countryCode);
  }

  userOrganizationsQuery() {
    return this.query(this.queries.userOrganizations);
  }

  userPoliciesQuery() {
    return this.query(this.queries.userPolicies);
  }

  userProofQuery() {
    return this.query(this.queries.userProofs);
  }

  userPendingOrganizationsQuery() {
    return this.query(this.queries.userPendingOrganizations);
  }

  apiSendStripeToken(obj) {
    return this.post(this.stripeURL, obj, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
  }

  registerUserMutation(input) {
    return this.mutate(this.mutations.registerUser, { input }, { noAuth: true });
  }

  confirmEmailMutation({ email, token }) {
    return this.mutate(this.mutations.confirmEmail, { email, token }, { noAuth: true });
  }

  confirmEmailChangeMutation({ email, token }) {
    return this.mutate(this.mutations.confirmEmailChange, { email, token }, { noAuth: true });
  }

  resendConfirmationMutation(email) {
    return this.mutate(this.mutations.resendConfirmation, { email }, { noAuth: true });
  }

  updateUserPaymentMethodMutation(token, method, defaultMethod) {
    return this.mutate(this.mutations.updateUserPaymentMethod, { token, method, defaultMethod });
  }

  removeUserPaymentMethodMutation(method, isDelete) {
    return this.mutate(this.mutations.removeUserPaymentMethod, { method, isDelete });
  }

  updateDefaultPaymentMethodMutation(defaultMethod) {
    return this.mutate(this.mutations.updateDefaultPaymentMethod, { defaultMethod });
  }

  userVerifyAchAccount(amounts) {
    return this.mutate(this.mutations.verifyAchAccount, { amounts });
  }

  userVerifyTemporaryAchAccount(amounts, isTemporary, token) {
    return this.mutate(this.mutations.verifyTemporaryAchAccount, {
      amounts, isTemporary, token, method: 'ACH',
    });
  }

  resetPasswordMutation(email) {
    return this.mutate(this.mutations.resetPassword, { email }, { noAuth: true });
  }

  recoverPasswordMutation(userId, password, token) {
    return this.mutate(this.mutations.updateUser, { updateId: userId, input: { password, token } });
  }

  updateUserInfoMutation(userId, data) {
    const input = { ...data, phone: data.phone || null };

    if (data.icon) {
      let variableObj = {};
      const fileFields = ['icon'];
      Object.entries(data).forEach((entry) => {
        if (entry[1] && !fileFields.includes(entry[0])) {
          variableObj[entry[0]] = entry[1];
        }
      });
      let formData = new FormData();
      formData.append('query', this.mutations.updateUser);
      formData.append('variables', JSON.stringify({ updateId: userId, input }));
      if (data.icon) {
        formData.append('icon', data.icon);
      }
      return this.formDataMutate(formData);
    }

    return this.mutate(this.mutations.updateUser, { updateId: userId, input });
  }
}

export default new UserProvider(QUERIES, MUTATIONS);
