import { jsonClone } from '@/utils/helpers';

const debitCard = {
  card_number: '',
  holder_name: '',
  exp_date: '',
  cvv: '',
};

const bankAccount = {
  routing_number: '',
  account_number: '',
  country: 'us',
  confirm_account: '',
  billing: '',
  account_holder_name: '',
  account_holder_type: '',
};

export default () => jsonClone({
  accessToken: '',
  billing: {},
  role: 'guest',
  signature: {},
  profile: {},
  tokenRefreshTimeout: null,
  nextToken: '',
  setNewIcon: false,
  storageListenerAttached: false,
  debitCard,
  bankAccount,
  showAchDialog: false,
  shownAchDialogSource: '',
  locale: '',
  timezone: '',
  locationCountryCode: '',
});
