import moment from '@/plugins/moment';
import i18n from '@/i18n';
import { HUMAN_DATE_FORMAT } from '@/utils/constants';

export default (message, type) => {
  if (message instanceof Object && Object.keys(message).length) {
    switch (type) {
      case 'QuoteExpirationSoon':
        return i18n.tc('notifications.QuoteExpirationSoon', message.quotes.length, {
          coverageName: message.quotes[0].coverageName,
          orgName: message.quotes[0].organization.name,
        });
      case 'QuoteExpirationJust':
        return i18n.tc('notifications.QuoteExpirationJust', message.quotes.length, {
          orgName: message.quotes[0].organization.name,
        });
      case 'QuoteManual':
        return i18n.t('notifications.YourQuoteIsReadyToReview');
      case 'RequestSOA':
        return i18n.t('notifications.RequestSOA', {
          userName: message.user && message.user.name,
          orgName: message.organization && message.organization.name,
        });
      case 'RequestApproved':
        return i18n.t('notifications.RequestApproved', {
          orgName: message.organization && message.organization.name,
        });
      case 'RequestRejected':
        return i18n.t('notifications.RequestRejected', {
          orgName: message.organization && message.organization.name,
        });
      case 'PolicyExpired':
        return i18n.t('notifications.PolicyExpired', {
          coverageName: message.policy && message.policy.quote && message.policy.quote.coverageName,
          orgName: message.policy && message.policy.organization && message.policy.organization.name,
          policyExpirationDate: moment((message.policy && message.policy.expiredAt) || '').format(HUMAN_DATE_FORMAT),
        });
      case 'PolicyProlonged':
        return i18n.t('notifications.PolicyProlonged', {
          coverageName: message.policy && message.policy.quote && message.policy.quote.coverageName,
          orgName: message.policy && message.policy.organization && message.policy.organization.name,
          policyExpirationDate: moment((message.policy && message.policy.expiredAt) || '').format(HUMAN_DATE_FORMAT),
        });
      case 'PolicyCanceled':
        return i18n.t('notifications.PolicyCanceled', {
          coverageName: message.policy && message.policy.quote && message.policy.quote.coverageName,
          orgName: message.policy && message.policy.organization && message.policy.organization.name,
        });
      case 'PolicyPurchased':
        return i18n.t('notifications.PolicyPurchased', {
          coverageName: message.policy && message.policy.quote && message.policy.quote.coverageName,
          orgName: message.policy && message.policy.organization && message.policy.organization.name,
        });
      case 'PolicyPDF':
        return i18n.t('notifications.PolicyPDF', {
          coverageName: message.policy && message.policy.quote && message.policy.quote.coverageName,
          orgName: message.policy && message.policy.organization && message.policy.organization.name,
        });
      case 'ProofPDF':
        return i18n.t('notifications.ProofPDF', {
          orgName: message.proof && message.proof.organization && message.proof.organization.name,
        });
      case 'ProtocolFilesMissing':
        return i18n.t('notifications.ProtocolFilesMissing', {
          orgName: message.organization && message.organization.name,
        });
      case 'FnolCreated':
        return i18n.t('notifications.FnolCreated', {
          orgName: message.claim && message.claim.organization && message.claim.organization.name,
        });
      case 'FnolUpdated':
        return i18n.t('notifications.FnolUpdated', {
          orgName: message.claim && message.claim.organization && message.claim.organization.name,
        });
      case 'FnolEscalated':
        return i18n.t('notifications.FnolEscalated', {
          orgName: message.claim && message.claim.organization && message.claim.organization.name,
        });
      case 'Hello':
        return 'Hello';
      default:
        return '';
    }
  }
  return '';
};
