export default (error) => {
  if (error
    && error instanceof Object
    && error.errors instanceof Array) {
    return error.errors.reduce((acc, err) => {
      if (typeof err.message === 'string' && err.message.length) {
        acc += `${err.message} \n`;
      }
      return acc;
    }, '');
  }
  return error.message;
};
