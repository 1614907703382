export default (arr, prop, idProp = prop) => arr.map((el, i) => {
  // let haveDuplicates = arr.reduce((acc, element) => {
  //   if (element[prop] == el[prop] && !element.$id) {
  //     acc = true;
  //   }
  //   return acc;
  // }, false);

  // el.$id = el.$id ? el.$id : `${el[prop]}_${i}`;
  el.$id = `${el[prop]}_${i}`;
  return el;
});
