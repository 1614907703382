<script>
import { BLOCK } from './constants';

const CLASS = `${BLOCK}__container`;

export default {
  name: 'OrgApplicationStepContainer',
  functional: true,
  props: {
    narrow: Boolean,
    noPaddingX: Boolean,
    noPaddingY: {
      type: Boolean,
      default: false,
    },
  },
  render(h, context) {
    const {
      narrow, noPaddingX, noPaddingY, ...restProps
    } = context.props;
    const tagName = 'v-container';
    return h(
      tagName,
      {
        ...context.data,
        class: [
          context.data.class,
          {
            [CLASS]: true,
            [`${CLASS}--narrow`]: narrow,
            'px-0': noPaddingX,
            'py-0': noPaddingY,
          },
        ],
        props: {
          ...restProps,
        },
      },
      context.children,
    );
  },
};
</script>

<style lang="scss">
$block: 'orgApplicationStep';

.#{$block} {
  &__container {
    &.container {
      box-sizing: border-box;
    }
    &--narrow {
      &.container {
        max-width: 390px;
      }
    }
  }
}
</style>
