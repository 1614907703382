import { jsonClone } from '@/utils/helpers';

const checkoutParams = {
  initialized: false,
  organizations: [],
};

export default () => jsonClone({
  items: [],
  checkoutParams: {
    ...checkoutParams,
  },
});
