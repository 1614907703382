import moment from '@/plugins/moment';
import { API_DATE_FORMAT, DEFAULT_PAYMENT_CYCLE_VALUE } from '@/utils/constants';
import { ShoppingCartProvider } from '@/graphql/providers';

export default {
  clearShoppingCartItems(context) {
    return Promise.all(context.getters.shoppingCartItems.reduce((acc, el) => {
      acc.push(context.dispatch('removeItemFromShoppingCart', {
        itemId: el.id,
      }));
      return acc;
    }, []));
  },
  checkout(context, payload) {
    return new Promise((resolve, reject) => {
      if (context.state.items.length) {
        let checkoutParams = { ...payload };
        if (context.state.checkoutParams.organizations.length) {
          checkoutParams.organizations = context.state.checkoutParams.organizations;
        }
        ShoppingCartProvider.shoppingCartCheckoutMutation(checkoutParams)
          .then(async (response) => {
            if (response.data.data && response.data.data.checkout && response.data.data.checkout.code === 200) {
              try {
                // refresh shopping cart with a repeated
                // initialization of the checkout params
                await context.dispatch('fetchShoppingCartItems', true);
                if (response.data.data.checkout.policies instanceof Array) {
                  await context.dispatch('policy/pushToPolicies', response.data.data.checkout.policies, { root: true });
                }
                resolve(response);
              } catch (err) {
                reject(err);
              }
            } else if (response.data.data.checkout.code === 402) {
              reject(new Error(response.data.data.checkout.message));
            } else {
              reject(new Error('Something went wrong with the purchase'));
            }
          }).catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('The shopping cart is empty'));
      }
    });
  },
  async initCheckoutParams({ commit, state, getters }, reset = false) {
    if (reset || !state.checkoutParams.initialized) {
      try {
        const getOrgIdsFromShoppingCartItems = (items) => items.map((itm) => itm.organization.id);
        const orgIds = getters.shoppingCartItems.length ? getOrgIdsFromShoppingCartItems(getters.shoppingCartItems) : [];
        commit('INIT_CHECKOUT_PARAMS', { organizations: orgIds });
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return state.checkoutParams;
  },
  updateCheckoutParams({ commit, state }, { param, value }) {
    if (typeof param === 'string' && param.length && state.checkoutParams[param] !== undefined && value !== undefined) {
      if (param === 'organizations' && value instanceof Array) {
        commit('UPDATE_CHECKOUT_PARAM', { param, value });
      }
    } else {
      throw new Error('Invalid checkout parameter specified');
    }
  },
  addOrgToOrgsToCheckoutWith({ dispatch, state }, orgId) {
    if (!state.checkoutParams.initialized) {
      dispatch('initCheckoutParams');
    }
    if (typeof orgId === 'string' && orgId.length && !state.checkoutParams.organizations.includes(orgId)) {
      dispatch('updateCheckoutParams', {
        param: 'organizations',
        value: [...state.checkoutParams.organizations, orgId],
      });
    }
  },
  removeOrgFromOrgsToCheckoutWith({ dispatch, state }, orgId) {
    if (!state.checkoutParams.initialized) {
      dispatch('initCheckoutParams');
    }
    if (typeof orgId === 'string' && orgId.length && state.checkoutParams.organizations.includes(orgId)) {
      dispatch('updateCheckoutParams', {
        param: 'organizations',
        value: state.checkoutParams.organizations.filter((org) => org !== orgId),
      });
    }
  },
  fetchShoppingCartItems({ commit, dispatch }, initCheckoutParams = false) {
    return new Promise((resolve, reject) => {
      ShoppingCartProvider.shoppingCartItemsQuery()
        .then((response) => {
          if (response.data.data && response.data.data.cart instanceof Array) {
            commit('SET_ITEMS', response.data.data.cart);
            if (initCheckoutParams) {
              dispatch('initCheckoutParams');
            }
          }
          resolve(response);
        }).catch(reject);
    });
  },
  addItemToShoppingCart(context, data) {
    return new Promise((resolve, reject) => {
      if (data && data.item && data.item instanceof Object && Object.keys(data.item).length) {
        const strippedItem = {
          quoteId: data.item.quoteId,
          type: 'quote',
          paymentCycle: data.item.paymentCycle || DEFAULT_PAYMENT_CYCLE_VALUE,
          limit: data.item.limit,
          startDate: data.item.startDate || moment().format(API_DATE_FORMAT),
        };
        ShoppingCartProvider.addItemToShoppingCartMutation(strippedItem)
          .then(async (response) => {
            if (response.data.data && response.data.data.cart) {
              if (data.replaceQuoteOfSameOrgAndCoverageType && response.data.data.cart.organization && response.data.data.cart.organization instanceof Object) {
                const newItem = response.data.data.cart;
                const itemOfCoverageTypeMatchIndex = context.state.items.findIndex((itm) => itm.quote && itm.quote.coverageType === newItem.quote.coverageType && itm.organization.id === newItem.organization.id && itm.quote.id !== newItem.quote.id);
                if (itemOfCoverageTypeMatchIndex !== -1 && context.state.items[itemOfCoverageTypeMatchIndex]) {
                  // eslint-disable-next-line
                  const deleteResponse = await ShoppingCartProvider.removeItemFromShoppingCartMutation(context.state.items[itemOfCoverageTypeMatchIndex].id);
                  if (deleteResponse.data && deleteResponse.data.data && deleteResponse.data.data.cart) {
                    context.commit('REPLACE_IN_ITEMS', {
                      replaceIndex: itemOfCoverageTypeMatchIndex,
                      item: response.data.data.cart,
                    });
                  }
                } else {
                  context.commit('PUSH_TO_ITEMS', response.data.data.cart);
                }
              } else {
                context.commit('PUSH_TO_ITEMS', response.data.data.cart);
              }
              if (response.data.data.cart.organization && response.data.data.cart.organization instanceof Object) {
                context.dispatch('addOrgToOrgsToCheckoutWith', response.data.data.cart.organization.id);
              }
            }
            resolve(true);
          })
          .catch(reject);
      } else {
        reject(new Error('The item object was absent in the data object'));
      }
    });
  },
  removeItemFromShoppingCart(context, { itemId }) {
    return new Promise((resolve, reject) => {
      if (itemId && typeof itemId === 'string') {
        ShoppingCartProvider.removeItemFromShoppingCartMutation(itemId)
          .then((response) => {
            if (response.data.data && response.data.data.cart) {
              context.commit('REMOVE_FROM_ITEMS', itemId);
              if (response.data.data.cart.organization && response.data.data.cart.organization.id) {
                context.dispatch('removeOrgFromOrgsToCheckoutWith', response.data.data.cart.organization.id);
              }
            }
            resolve(true);
          })
          .catch(reject);
      } else {
        reject(new Error('itemId was absent or invalid'));
      }
    });
  },
  updateItemInShoppingCart(context, { itemId, updateFragment }) {
    return new Promise((resolve, reject) => {
      if (itemId && typeof itemId === 'string') {
        ShoppingCartProvider.updateItemInShoppingCartMutation(itemId, updateFragment)
          .then((response) => {
            if (response.data.data && response.data.data.cart) {
              context.commit('UPDATE_ITEM', { itemId, updateFragment });
            }
            resolve(true);
          })
          .catch(reject);
      } else {
        reject(new Error('itemId was absent or invalid'));
      }
    });
  },
  addQuoteToShoppingCart(context, { quote, replaceQuoteOfSameOrgAndCoverageType }) {
    return new Promise((resolve, reject) => {
      if (quote && quote instanceof Object && Object.keys(quote).length) {
        let item = {
          quoteId: quote.id,
          quote,
          type: 'quote',
          paymentCycle: quote.selectedPaymentCycle ? quote.selectedPaymentCycle.value : DEFAULT_PAYMENT_CYCLE_VALUE,
          limit: quote.limit,
          startDate: quote.startDate,
        };
        context.dispatch('addItemToShoppingCart', { item, replaceQuoteOfSameOrgAndCoverageType })
          .then(resolve)
          .catch(reject);
      } else {
        reject(new Error('The quote object was absent in the data object'));
      }
    });
  },
  removeQuoteFromShoppingCart(context, { quoteId }) {
    return new Promise((resolve, reject) => {
      if (quoteId && typeof quoteId === 'string') {
        const itemMatch = context.state.items.find((itm) => itm.quoteId === quoteId);
        if (itemMatch && typeof itemMatch.id === 'string' && itemMatch.id.length) {
          context.dispatch('removeItemFromShoppingCart', { itemId: itemMatch.id })
            .then(resolve)
            .catch(reject);
        } else {
          reject(new Error('The corresponding shopping cart item wasn\'t found'));
        }
      } else {
        reject(new Error('quoteId was absent or invalid'));
      }
    });
  },
  removeItemsOfCoverageTypeFromCart(context, { coverageType }) {
    return new Promise(async (resolve, reject) => {
      if (coverageType && typeof coverageType === 'string') {
        const promiseChain = context.state.items.reduce((acc, item) => {
          // if (coverageType === coverageType && item.id) {
          if (item.id && item.quote && item.quote.coverageType === coverageType) {
            acc.push(context.dispatch('removeItemFromShoppingCart', { itemId: item.id }));
          }
          return acc;
        }, []);
        Promise.all(promiseChain)
          .then((response) => {
            if (response.data && response.data.data && response.data.data.cart) {
              context.commit('SET_ITEMS', context.state.items.filter((itm) => itm.coverageType !== coverageType));
            }
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('The coverageType parameter wasn\'t provided'));
      }
    });
  },
  flushLocalItems(context) {
    context.commit('SET_ITEMS', []);
  },
};
