const EXCLUSION_MAP = {
  generalLiability: ['registrationProvider', 'backgroundCheck'],
};

export default (steps, coverages, exclusionMap = EXCLUSION_MAP) => {
  if ((steps && steps instanceof Object)
    && (exclusionMap && exclusionMap instanceof Object)
    && (coverages instanceof Array && coverages.length)) {
    const forbiddenSubStepsForSelectedCoverages = coverages.reduce((acc, coverage) => {
      if (exclusionMap[coverage] instanceof Array) {
        acc = acc.concat(exclusionMap[coverage]);
      }
      return acc;
    }, []);
    if (forbiddenSubStepsForSelectedCoverages.length) {
      return Object.entries(steps).reduce((acc, entry) => {
        const step = entry[0];
        acc[step] = entry[1].filter((subStep) => !forbiddenSubStepsForSelectedCoverages.includes(subStep));
        return acc;
      }, {});
    }
  }
  return steps;
};
