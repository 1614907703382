import ServiceProvider from '../provider';

import QUERIES from './queries';
import MUTATIONS from './mutations';

class ProofOfInsuranceProvider extends ServiceProvider {
  constructor(queries, mutations) {
    super();
    this.queries = queries;
    this.mutations = mutations;
  }

  requestProofMutation(input) {
    return this.mutate(this.mutations.requestPOI, { input });
  }
}
export default new ProofOfInsuranceProvider(QUERIES, MUTATIONS);
