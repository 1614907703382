export default function (array, sourceKey = 'i18nKey', outputKey = 'text', customReducer) {
  if (!this || typeof this.$t !== 'function') {
    throw new Error('The function doesn\'t have the required context');
  }
  if (array instanceof Array
    && array.length
    && typeof sourceKey === 'string'
    && sourceKey.length
    && typeof outputKey === 'string'
    && outputKey.length) {
    if (typeof customReducer === 'function') {
      return array.reduce(customReducer, []);
    }
    return array.reduce((acc, item) => {
      if (item instanceof Object) {
        acc.push({
          ...item,
          [outputKey]: item[sourceKey]
            ? this.$t(item[sourceKey])
            : (item[outputKey] || ''),
        });
      }
      return acc;
    }, []);
  }
  return array;
}
