import { CAMP_ORG_TYPE_ID, TOURNAMENT_ORG_TYPE_ID } from '@/utils/constants';
import { formatPhone } from '@/utils/helpers';

const checkOrgTypeIsValid = (state) => Boolean(state
  && state instanceof Object
  && state.session
  && state.session instanceof Object
  && state.session.orgType
  && state.session.orgType instanceof Object
  && typeof state.session.orgType.type === 'string'
  && state.session.orgType.type.length);

const getParamsPayload = (params) => {
  const fields = ['isOvernight', 'eventLocation', 'eventStartDate', 'eventEndDate'];
  if (params && params instanceof Object) {
    return Object.entries(params).reduce((acc, ent) => {
      if (fields.includes(ent[0]) && ent[1] !== undefined && ent[1] !== null) {
        acc[ent[0]] = ent[1];
      }
      return acc;
    }, {});
  }
  return {};
};

const getFormattedPayload = (payload, state) => {
  let formattedPayload = { ...payload };
  // format phone before sending to the backend
  if (typeof payload.phone === 'string' && payload.phone.length) {
    formattedPayload.phone = formatPhone(payload.phone);
  }
  // add org type to org creation payload
  if (checkOrgTypeIsValid(state)) {
    formattedPayload.type = state.session.orgType.type;
  }
  // populate with tournament time if org type is 'Tournament' or 'Camp'
  if (formattedPayload.type
    && (formattedPayload.type === TOURNAMENT_ORG_TYPE_ID || formattedPayload.type === CAMP_ORG_TYPE_ID)
    && state.session
    && state.session.orgType instanceof Object
    && state.session.orgType.params instanceof Object) {
    const paramsPayload = getParamsPayload(state.session.orgType.params);
    if (paramsPayload && Object.keys(paramsPayload).length) {
      formattedPayload = {
        ...formattedPayload,
        params: paramsPayload,
      };
    }
  }
  // supplement with mailing address if none was provided
  if (!payload.mailing && payload.address) {
    formattedPayload.mailing = {
      ...payload.address,
    };
  }
  return formattedPayload;
};

export default (state) => {
  let payload = {};
  if (state.selectedOrg && state instanceof Object && Object.keys(state.selectedOrg).length) {
    // select an org which exists in our system
    if (typeof state.selectedOrg.isCompleted === 'boolean') {
      payload = {
        organizationId: state.selectedOrg.id,
      };
    } else if (typeof state.selectedOrg.organizationId === 'string') {
      payload = {
        organizationId: state.selectedOrg.organizationId,
      };
    } else {
      const fieldsToCreateOrgWith = ['placeId', 'name', 'email', 'phone', 'address', 'mailing', 'site'];
      payload = Object.entries(state.selectedOrg).reduce((acc, entry) => {
        if (fieldsToCreateOrgWith.includes(entry[0]) && entry[1]) {
          acc[entry[0]] = entry[1];
        }
        return acc;
      }, {});
      payload = getFormattedPayload(payload, state);
    }
  } else if (state.createNewOrgForm.valid) {
    const fieldsToCreateOrgWith = ['placeId', 'name', 'email', 'phone', 'address', 'mailingAddress'];
    payload = Object.entries(state.createNewOrgForm).reduce((acc, entry) => {
      if (fieldsToCreateOrgWith.includes(entry[0]) && entry[1]) {
        if (entry[0] === 'mailingAddress') {
          acc.mailing = state.createNewOrgForm.noSeparateMailingAddress ? state.createNewOrgForm.address : entry[1];
        } else {
          acc[entry[0] === 'mailingAddress' ? 'mailing' : entry[0]] = entry[1];
        }
      }
      return acc;
    }, {});
    payload = getFormattedPayload(payload, state);
  }
  return payload;
};
