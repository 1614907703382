<template>
  <div :class="b({ hasFooter: $slots.footer })">
    <div :class="b('content')">
      <slot name="title"></slot>
      <div v-if="isMobile" :class="b('primaryContentContainer')">
        <div :class="b('primaryContent')">
          <slot name="primary"></slot>
        </div>
      </div>
      <div v-else :class="b('primaryContentContainer')">
        <img v-if="topShadow"
             :style="scrollGradientWidth"
             :class="b('scrollGradient', { top: true })"
             src="~@/assets/images/shadow-gradient--blue.png"
             alt="Scroll gradient top shadow" />
        <div v-bar :class="[b('primaryContent'), { visibleOverflow }]" class="vuebar-element" ref="orgApplicationStepScroll">
          <slot name="primary"></slot>
        </div>
        <img v-if="botShadow"
             :style="scrollGradientWidth"
             :class="b('scrollGradient', { bot: true })"
             src="~@/assets/images/shadow-gradient--bluish--white.png"
             alt="Scroll gradient bottom shadow" />
      </div>
    </div>
    <div v-if="$slots.footer" :class="b('footer')">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BLOCK } from './constants';

export default {
  name: 'OrgApplicationStep',
  block: BLOCK,
  props: {
    visibleOverflow: {
      type: Boolean,
      default: true,
    },
    noPrimaryScroll: {
      type: Boolean,
      default: false,
    },
    topShadow: {
      type: Boolean,
      default: true,
    },
    botShadow: {
      type: Boolean,
      default: true,
    },
    scrollShadowWidthPercent: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    ...mapGetters('layout', ['isMobile']),
    scrollGradientWidth() {
      return `width: ${this.scrollShadowWidthPercent}%;`;
    },
  },
};
</script>

<style lang="scss">
$block: 'orgApplicationStep';

.#{$block} {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  &--hasFooter {
    .#{$block}__content {
      display: flex;
      flex-direction: column;
    }
    @include mappy-bp(md) {
      border-radius: 8px;
    }
  }
  &__primaryContentContainer {
    display: flex;
    height: 100%;
    position: relative;
    flex: 1;
    min-height: 0px;
  }
  &__scrollGradient {
    position: absolute;
    right: 50%;
    height: 40px;
    width: 50%;
    opacity: 1;
    z-index: 2;
    pointer-events: none;
    @include mappy-bp(md) {
      height: 70px;
    }
    &--top {
      transform: translateX(50%);
      top: 0;
    }
    &--bot {
      transform: translateX(50%) rotate(180deg);
      bottom: 0;
    }
  }
  &__content {
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    padding-bottom: 16px;
    @include mappy-bp(md) {
      border-radius: 8px;
      flex-direction: column;
    }
    display: flex;
    .#{$block}__primaryContent {
      padding-top: 24px;
      height: auto;
      &--noScroll {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
      &:not(.#{$block}__primaryContent--noScroll) {
        z-index: 1;
      }
    }
  }
  &__contentWrapper {
    display: flex;
  }
  &__subTitle {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    color: $text-grey;
    margin-top: 8px;
  }
  &__footer {
    width: 100%;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0px 0px 6px 0px rgba($black, 0.3);
    padding: 15px;
    @include mappy-bp(md) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &--flexEnd {
      justify-content: flex-end;
    }
  }
  &__primaryContent {
    width: 100%;
  }
  &__scrollDownHint {
    position: fixed;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
  }
}
</style>
