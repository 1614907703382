export default (params) => {
  const paramsMap = {};
  if (params instanceof Array && params.length) {
    params.forEach((param) => {
      const { carrier, ...carrierParams } = param;
      if (carrierParams instanceof Object) {
        Object.assign(paramsMap, carrierParams);
      }
    });
  }
  return paramsMap;
};
